import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import {API_URL_IMAGE} from "../../../config/apiUrl";
import PropTypes from "prop-types";
import { getGereja } from "../../../actions/gerejaAction";
import { connect } from 'react-redux';
import axios from 'axios';

const ListGereja = ({ getGereja, gerejaData, auth: { user, token } }) => {
    const [dataGereja, setDataGereja] = useState([]);
    const [kode, setKode] = useState();
    const deleteData = (id) => {
        let url2 = `https://gkmi.one:8080/api/gereja/${id}`
        axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
           
            //if(res.data[0].status === "failed"){
              // alert(res.data[0].msg);
            //}else{
                const del = dataGereja.filter(tableRow => id !== tableRow._id)
                setDataGereja(del);
            //}
            
        })
      }
    
    useEffect(() => {
        fetchData();
    }, [user]);

    const fetchData = async () => {

        let codeChurch = user && (user.gereja[0].kode_gereja);
        let codeMasterChurch = user && (user.gereja[0].kode_gereja_induk);
        console.log('PROFILE: ' + codeChurch + " - " + codeMasterChurch);
        let link='';
        setKode(codeChurch);
        let codeMasterChurch2 = 0;
        if (user && (user.level_user === 7 || user.level_user === 0)) {
            link = 'https://gkmi.one:8080/api/gereja/gereja_search2';
        }else if(user && (user.level_user === 8 )) {
            link = `https://gkmi.one:8080/api/gereja/gereja_search3/${codeChurch}/${codeMasterChurch2}`;
        }
        else{
            if(codeMasterChurch !== ''){
                codeMasterChurch2 = codeMasterChurch;
            }
            link = `https://gkmi.one:8080/api/gereja/gereja_search3/${codeChurch}/${codeMasterChurch2}`;
            console.log('LINK: ' + link);
        }
              
         const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
        });
       
       setDataGereja(response.data[0].gereja);
      
      }
    
    

    const data = {
        columns: [
            {
                label: "No.",
                field: "no",
                sort: "asc",
                width: 10,
            },
            {
                label: "Nama",
                field: "nama",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 150,
            },
            {
                label: "Telepon",
                field: "telp",
                sort: "asc",
                width: 150,
            },
            {
                label: "Alamat",
                field: "alamat",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                width: 150,
            },
        ],
      
        
        rows: dataGereja ? dataGereja.map((apiData, i) => ({
            no: (i+1),
            image_pemimpin: <center><img src={(apiData.image_pemimpin !== "" ? (apiData.image_pemimpin !== undefined ? apiData.tautan + apiData.image_pemimpin : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
            className="profile-user-img img-fluid img-circle"
            alt="Gereja" /></center>,
            kode_gereja: apiData.kode_gereja,
            nama: <span>{apiData.nama}<br/>{apiData.area}<br/>{apiData.status.toUpperCase()}</span>,
            email: apiData.email,
            telp: apiData.telp,
            tagline: apiData.tagline,
            visi: apiData.visi,
            misi: apiData.misi,
            alamat: apiData.alamat,
            
            action: 
            
            <span> {
              apiData.status === "dewasa" ?
                
                <Link className="btn btn-block bg-gradient-primary" to={`/gereja/edit-gereja/${apiData.kode_gereja}`}>Ubah</Link>
                : apiData.kode_gereja !== kode ? 
                <span> <Link className="btn btn-block bg-gradient-primary" to={`/gereja/edit-gereja/${apiData.kode_gereja}`}>Ubah</Link>
            <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData._id) } }>Hapus</a></span>
        : <span> <Link className="btn btn-block bg-gradient-primary" to={`/gereja/edit-gereja/${apiData.kode_gereja}`}>Ubah</Link></span>}
        </span>
        
        })) : '',
        //rows: res[0].gereja.map((apiData, i) => <tr> <td> <a href={`/item/${apiData.nama}`}> {apiData.nama} </a> </td> <td> {apiData.nama} </td> </tr>),
      
      };


    return(
        <Fragment>
            <MDBDataTable responsive striped bordered hover data={data} />
        </Fragment>
    )
};

ListGereja.propTypes = {
    getGereja: PropTypes.func.isRequired,
    gerejaData: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, {getGereja})(ListGereja);
