import { GET_KARYA_CATEGORY, GET_KARYA_CATEGORY2,GET_KARYA_TOKO,GET_KARYA_PRODUK, KARYA_CATEGORY_ERROR,KARYA_TOKO_ERROR,GET_KARYA_PRODUK_ERROR } from "../actions/types";

const initialState = {
    karyaCategory: null,
    karyaCategory2: [],
     karyaproduk: null,
    karyatoko: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_KARYA_CATEGORY:
            return{
                ...state,
                karyaCategory: payload,
                loading: false
            };
        case GET_KARYA_CATEGORY2:
            return{
                ...state,
                karyaCategory2: payload,
                loading: false
            };
            case GET_KARYA_TOKO:
                return{
                    ...state,
                    karyatoko: payload,
                    loading: false
                };
                case GET_KARYA_PRODUK:
                   return{
                       ...state,
                       karyaproduk: payload,
                       loading: false
                   };
        case KARYA_CATEGORY_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
            case KARYA_TOKO_ERROR:
                return{
                    ...state,
                    error: payload,
                    loading: false
                };
                case GET_KARYA_PRODUK_ERROR:
               return{
                   ...state,
                   error: payload,
                   loading: false
               };
        default:
            return state;
    }
}
