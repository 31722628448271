import React, { Fragment, useState, useEffect } from "react";

import { Link, Redirect } from "react-router-dom";

import Alert from "../alert/Alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authAction";
import "../auth/styles/vendor/bootstrap/css/bootstrap.min.css";
import "../auth/styles/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "../auth/styles/fonts/Linearicons-Free-v1.0.0/icon-font.min.css";
import "../auth/styles/vendor/animate/animate.css";
import "../auth/styles/vendor/css-hamburgers/hamburgers.min.css";
import "../auth/styles/vendor/animsition/css/animsition.min.css";
import "../auth/styles/vendor/select2/select2.min.css";
import "../auth/styles/vendor/daterangepicker/daterangepicker.css";
import "../auth/styles/css/util.css";
import "../auth/styles/css/main.css";
import logo from "../auth/styles/images/logo-gkmi.png";

const Login = ({ login, isAuthenticated,auth: { user } }) => {
  const [formData, setFormData] = useState({
    no_telepon: "",
    password: "",
  });

  const { no_telepon, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("NOO: " + no_telepon);
    login(no_telepon, password);
  };

  const imgContainer = {
    textAlign: "center",
    margin: "10px 0 0 0",
  };

  const imgAvatar = {
    width: "38%",
  };

  const labelStyle = {
    display: "inline-block",
    marginBottom: ".5rem",
  };

  useEffect(() => {
    document.title = `GKMI ONE`;
  }, []);

  // Redirect if logged in
  if (isAuthenticated) {
    // return <Redirect to="/dashboard" />;
    if (user && (user.level_user === 10)) {
      return <Redirect to="/dashboardFirman" />;

   } else if (user && (user.level_user === 7)) {
     return <Redirect to="/dashboardSinode" />;

  }else if(user && (user.kode_gereja === "uk_0000002")){

       return <Redirect to="/dashboardBeritaGkmi" />;
     } if (user && (user.level_user === 8)) {
          return <Redirect to="/dashboardPipka" />;

       
     }else{
        return <Redirect to="/dashboard" />;


   }
  }

  return (
    <Fragment>
      <div style={{ backgroundColor: "#666666" }}>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form validate-form"
                style={{ paddingTop: "15px" }}
                onSubmit={(e) => onSubmit(e)}
              >
                <div style={imgContainer}>
                  <img src={logo} alt="GKMI" style={imgAvatar} />
                </div>
                <blockquote
                  className="blockquote"
                  style={{
                    fontSize: "17px",
                    borderLeft: "3px solid #dc241c",
                    borderRight: "3px solid #dc241c",
                    padding: "5px 5px",
                    fontWeight: "bold",
                    backgroundColor: "#005da3",
                  }}
                >
                  <center>
                    <span style={{ color: "#ffffff" }}>GKMI ONE</span>
                  </center>
                </blockquote>

                <Alert />

                <div className="form-group">
                  <label style={labelStyle}>User Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="no_telepon"
                    value={no_telepon}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group">
                  <label style={labelStyle}>Password</label>
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                  />
                </div>

                <div className="container-login100-form-btn">
                  <input
                    type="submit"
                    className="login100-form-btn"
                    style={{ height: "40px" }}
                    value="Login"
                  />
                </div>
                <div className="text-center p-t-46 p-b-20">
                  <Link to="/privacy-policy" className="txt1">
                    Kebijakan Privasi
                  </Link>
                </div>
              </form>
              <div
                className="login100-more"
                style={{
                  backgroundImage:
                    "url(" + require(`../auth/styles/images/bg-01.jpg`) + ")",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth
});

export default connect(mapStateToProps, { login })(Login);
