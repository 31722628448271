import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { updateKaryaCategory } from "../../../actions/karyaAction";
import { getKaryaCategoryById } from "../../../actions/karyaAction";

const EditKaryaCategory = ({ karya: { loading, karyaCategory }, getKaryaCategoryById, updateKaryaCategory, auth: { user }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [urutan, setUrutan] = useState();
    const [loadings, setLoadings] = useState(false);
    const [fileName, setFileName] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [formData, setFormData] = useState({
        nama_kategori:'',
    });

    useEffect(() => {
        setFormData({
            nama_kategori:  karyaCategory && (karyaCategory.nama_kategori ? karyaCategory.nama_kategori : ''),
        });
        setUrutan( karyaCategory && (karyaCategory.urutan ? karyaCategory.urutan : ''));

    }, [loading, karyaCategory]);

    useEffect(() => {
      setFileName( karyaCategory && (karyaCategory.avatar ? karyaCategory.avatar : ''));
      console.log('NAMA: ' + fileName);
      setImagePreviewUrl( karyaCategory && (karyaCategory.tautan ? karyaCategory.tautan + '/' +fileName : ''));
    }, [loading, karyaCategory, fileName]);

    useEffect(() => {
        getKaryaCategoryById(Buffer.from(id, 'base64').toString('ascii'));
    }, [getKaryaCategoryById, id]);

    const {
        nama_kategori,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const numberOnChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setUrutan(value);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        data.append("nama_image", file);
        data.append("urutan", urutan);
        data.append("nama_kategori", formData.nama_kategori);
        console.log(file);
        updateKaryaCategory(data, Buffer.from(id, 'base64').toString('ascii'), history);

    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/karya/category" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Kategori Karya</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Kategori Karya</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Urutan</label>
                          <input type="text" className="form-control" required placeholder="Urutan ..." name="urutan" value={urutan} onChange={numberOnChange} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Nama Kategori</label>
                          <input type="text" className="form-control" required placeholder="Nama Kategori ..." name="nama_kategori" value={nama_kategori} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Gambar Kategori</label>
                            <img style={{border: "1px solid #adb5bd"}} src={imagePreviewUrl}
                                className="img-fluid mb-3"
                                alt=""
                            />
                            <input type="file" className="form-control" placeholder="Gambar Kategori ..." name="nama_image" onChange={fileOnChange} />
                            </div>
                        </div>
                    </div>

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditKaryaCategory.propTypes = {
    updateKaryaCategory: PropTypes.func.isRequired,
    getKaryaCategoryById: PropTypes.func.isRequired,
    karya: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    karya: state.karya
});

export default connect(mapStateToProps, { updateKaryaCategory, getKaryaCategoryById })(withRouter(EditKaryaCategory));
