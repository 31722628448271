import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/img_store.png";
import { createKaryaToko } from "../../../actions/karyaAction";
import { getJemaat2 } from '../../../actions/jemaatAction';

const CreateKaryaToko = ({ createKaryaToko, jemaat,getJemaat2,auth: { user }, history }) => {

    const [file, setFile] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [formData, setFormData] = useState({
        nama_toko:'',
        kode_pemilik:'',
    });

    const {
      nama_toko,alamat,no_telepon,no_whatsapp,kota,map_url,deskripsi,kode_pemilik
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl);
    }

    useEffect(() => {
      getJemaat2();
    }, [getJemaat2]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
       // console.log("ID kode_pemilik", formData.kode_pemilik);

        data.append("nama_image", file);
        data.append("nama_toko", formData.nama_toko);
        data.append("alamat", formData.alamat);
        data.append("no_hp", formData.no_telepon);
        data.append("no_wa", formData.no_whatsapp);
        data.append("kota", formData.kota);
        data.append("gmap_link", formData.map_url);
        data.append("kode_pemilik", formData.kode_pemilik);
        data.append("deskripsi", formData.deskripsi);
        createKaryaToko(data, history);
    }
    const handleJemaatChange = e => {
      setFormData({ ...formData,
        kode_pemilik: e.target.options[e.target.selectedIndex].value,
          nama: e.target.options[e.target.selectedIndex].text
      });
    }
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/karya/toko" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Karya Toko</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create Karya Toko</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Gambar Toko </label>
                          <div class="jss213-small">Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px  </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div className="imgPreviewUpload">
                          <img className="imgPreview200" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar Toko ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                        </div>
                      </div>
                      </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Pemilik Toko *</label>
                          <div class="jss213-small">Pemilik Toko Harus Anggota atau Jemaat Gereja Setempat </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <select className="form-control" required name="kode_pemilik" value={kode_pemilik} onChange={handleJemaatChange}>
                                  <option value="">--Pilih Jemaat--</option>
                                      {
                                          jemaat && jemaat.jemaat2.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama} ({pem.no_telepon2})</option>)
                                      }
                              </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Nama Toko *</label>
                          <div class="jss213-small">Nama Toko Tidah lebih 20 charakter </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <input type="text" className="form-control" required placeholder="Nama Toko ..." name="nama_toko" value={nama_toko} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>No. Telepon</label>
                          <div class="jss213-small">No Telepon  pemilik toko </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <input type="text" className="form-control" required placeholder="No. Telepon ..." name="no_telepon" value={no_telepon} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>No. Whatsapp</label>
                          <div class="jss213-small">No Telepon yang digunakan sebagai sarana komunikasi dengan pembeli </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="No. Whatsapp ..." name="no_whatsapp" value={no_whatsapp} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Alamat *</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <textarea className="form-control" rows="3" required placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Kota *</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <input type="text" className="form-control" required  placeholder="Kota ..." name="kota" value={kota} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Google Map Link URL</label>
                        <a href="https://www.google.com/maps/preview" target="_blank" rel="noopener">Link Google Maps</a>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">

                            <input type="text" className="form-control" placeholder="Google Map Link URL ..." name="map_url" value={map_url} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Deskripsi Toko *</label>
                        <div class="jss213-small">Deskripsi Toko secara umum apa saja yang di jual di toko</div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                          <textarea className="form-control" rows="3" required placeholder="Deskripsi Toko ..." name="deskripsi" value={deskripsi} onChange={e => onChange(e)}></textarea>
                        </div>
                      </div>
                    </div>




                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreateKaryaToko.propTypes = {
  createKaryaToko: PropTypes.func.isRequired,
  getJemaat2: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    jemaat:state.jemaat
});

export default connect(mapStateToProps, { createKaryaToko,getJemaat2 })(withRouter(CreateKaryaToko));
