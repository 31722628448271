import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

const ConnectLagu = ({ auth: { user, token } }) => {
    const [dataKaryaToko, setKaryaToko] = useState([]);
    const [status, setStatus] = useState(true);
    useEffect(() =>{
        let link='';
        link = 'https://gkmi.one:8080/api/connectbackend/pujian';
        fetch(`${link}`, {
            headers: {
                'x-auth-token': token
            }
        })
          .then((response) => {
            setStatus(response.ok);
            return response.json();
          })
          .then((res) => {
            console.log(res, "data");
            const data = {
              columns: [
                {
                    label: "No.",
                    field: "no",
                    sort: "asc",
                    width: 10,
                },
                {
                    label: "Judul Pujian",
                    field: "pujian",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Status",
                    field: "active",
                    width: 150,
                },
                {
                    label: "Action",
                    field: "action",
                    width: 150,
                },
              ],
              rows: res.map((apiData, i) => {
                  let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
                
                  var active;
                  var classStatus;
                  if(apiData.active==1){
                    active="Active";
                    classStatus="badge bg-gray"
                        }else{
                            active="In Active";
                            classStatus="badge badge-danger"
                        }
                  return({
                    no: (i+1), 
                     pujian: <span>{apiData.nama_lagu}</span>,
                    active:<span class={classStatus}>{active}</span>,
                    action: <span><Link className="btn btn-block bg-gradient-primary" to={`/connect/lagu/edit-lagu/${base64EncodeString}`}>Ubah</Link>
                    
                    </span>
                  });

              }),

            };
            setKaryaToko(data);
          });
    }, [user]);

    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={dataKaryaToko} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <Link to='/connect/lagu/create-lagu' className="btn btn-block bg-gradient-green  ">Tambah Pujian</Link>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardSinode">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Pujian Connect</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita Category */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Pujian Connect</h3>
                    <div className="card-tools">
                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(ConnectLagu);
