import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { GET_PEMIMPIN, GET_PEMIMPIN2, PEMIMPIN_ERROR } from "./types";

// Get Pemimpin by Id
export const getPemimpin= ()=> async dispatch => {
    try{
        console.log('AAA');
        const res = await axios.get(`/api/gereja/pemimpingereja`);
      //  console.log('OK: ' + res.data[0].nama);
        dispatch({
            type: GET_PEMIMPIN2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: PEMIMPIN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}
// Get Pemimpin by Id
export const getPemimpinById = id => async dispatch => {
    try{
        console.log('AAA');
        const res = await axios.get(`/api/gereja/pemimpingereja/${id}`);
        console.log('OK: ' + res.data[0].nama);
        dispatch({
            type: GET_PEMIMPIN,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: PEMIMPIN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Create Pemimpin
export const createPemimpin = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/gereja/pemimpingereja`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/gereja/pemimpin');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PEMIMPIN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Pemimpin Berdasarkan ID
export const updatePemimpin = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/gereja/pemimpingereja/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/gereja/pemimpin');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PEMIMPIN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create Pemimpin
export const createPemimpinbranches = (formData, history,path) => async dispatch => {
    try{
        const res = await axios.post(`/api/gereja/pemimpinbranches`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }


        history.push(`/pipka/branches/pemimpin/${path}`);
    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PEMIMPIN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Update Pemimpin Berdasarkan ID
export const updatePemimpinBranches = (formData, id, history,path) => async dispatch => {
    try{
        const res = await axios.put(`/api/gereja/pemimpingereja/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push(`/pipka/branches/pemimpin/${path}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PEMIMPIN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
