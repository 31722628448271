import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import store from "../../../store";
import axios from 'axios';
import { getGereja } from "../../../actions/gerejaAction";
import { getJadwal } from "../../../actions/gerejaJadwalAction";
import iconImage from "../../auth/styles/images/icons/ic_plus.png";
const Kegiatan = ({gereja, jadwal, auth: { user,token }}) => {
    const [dataKegiatan, setDataKegiatan] = useState([]);  
     const [title, setTitle] = useState();
    // const [formData, setFormData] = useState({
    //     kode_gereja: ''
    // });

    const deleteData = (id) => {
        let url2 = `https://gkmi.one:8080/api/gereja/jadual/kegiatan/${id}`
        axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
            const del = dataKegiatan.filter(tableRow => id !== tableRow.id)
            setDataKegiatan(del);
        })
      }

    useEffect(() =>{
        fetchData();
   }, [user]);
  
   const fetchData = async () => {
    let codeChurch = '';
          if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
              codeChurch = user && (user.kode_gereja);
          }else{
              codeChurch = user && (user.gereja[0].kode_gereja);
          }
          console.log('AA: ' + codeChurch);
          let link='';
          let codeMasterChurch2 = 0;
          link = `https://gkmi.one:8080/api/gereja/${codeChurch}`;
          
     const response = await axios.get(link, {
      headers: {
          'x-auth-token': token
      }
    });
   // console.log('MASUK: ' + JSON.stringify(response.data[0].gerejaSlider));
   setDataKegiatan(response.data[0].jadual);
  }
 
  const data = {
    columns: [
      {
          label: "No.",
          field: "no",
          sort: "asc",
          width: 10,
      },
      {
        label: "Nama Kegiatan",
        field: "nama",
        sort: "asc",
        width: 150,
      },
      {
          label: "Hari",
          field: "hari",
          sort: "asc",
          width: 150,
      },
      {
          label: "Jam Mulai",
          field: "jam_mulai",
          sort: "asc",
          width: 150,
      },
      {
          label: "Jam Selesai",
          field: "jam_selesai",
          sort: "asc",
          width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
    },
    ],
   
   rows: dataKegiatan.map((apiData, i) => {
    let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
    let base64EncodeString2 = Buffer.from(`${apiData.id};${user && (user.gereja[0].kode_gereja)};${user && (user.gereja[0].nama)};${apiData.nama_kegiatan}`).toString('base64');
    return({
        no: (i+1),
        nama: <span>{apiData.nama_kegiatan}</span>,
        hari: <span>{apiData.hari}</span>,
        jam_mulai: <span>{apiData.jam_mulai}</span>,
        jam_selesai: <span>{apiData.jam_selesai}</span>,
        action: 
        <span><Link className="btn btn-block bg-gradient-primary" to={`/jadual/kegiatan/edit-kegiatan/${base64EncodeString}`}>Ubah</Link>
            <Link className="btn btn-block bg-gradient-secondary" to={`/jadual/ibadah/${base64EncodeString2}`}>Jadwal Ibadah</Link>
        <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData.id) } }>Hapus</a></span>
                                                  
      });
  }),
  };

      function cekStatus(){
          if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
            return (
              <Fragment>                
              </Fragment>
            );
          }else{
              return(
              <Fragment>
                  <MDBDataTable striped bordered hover data={data} />
              </Fragment>
              );
         }
        }
        return(
            <Fragment>
                <Header />
                <Navbar />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-4">
                            <Link to={`/jadual/kegiatan/create-kegiatan/${user && (Buffer.from(`${user.gereja[0].kode_gereja}`).toString('base64'))}`} className="btn btn-block bg-gradient-success">Tambah Kegiatan Ibadah/Persekutuan</Link>
                            </div>
                            <div className="col-sm-8">
                                <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Jadual</li>
                                <li className="breadcrumb-item active">Kegiatan</li>
                                </ol>
                            </div>
                        </div>
                        </div>
                    </section>
                    {/* Main content */}
                    <section className="content">
                    <div className="container-fluid">
                    {/* Display Berita */}
                    <div className="card">
                        <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                        <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Data Jadual Ibadah/Persekutuan</h3>
                        <div className="card-tools">
    
    
                        </div>
                        </div>
                        <div className="card-body">
                        <Alert />
                        {cekStatus()}
                        </div>
                        {/* /.card-body */}
    
                    </div>
                    </div>
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
    
                <Footer />
            </Fragment>
        )
    };
  
  const mapStateToProps = state => ({
    auth: state.auth,
  });
  
  export default connect(mapStateToProps, null)(Kegiatan);

