import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link, useParams }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

const ActionKegiatanAbseni = ({ auth: { user, token } }) => {
    const {id} = useParams();
    const [idAction, setIdAction] = useState();
    const [dataAbsensi, setAbsensi] = useState([]);
    const [status, setStatus] = useState(true);
    useEffect(() =>{
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
       
        let idKategori = decodeString.split(';')[1];
       
        let link='';
        let codeMasterChurch2 = 0;
        link = `https://gkmi.one:8080/api/backendaction/kegiatan/absensi/${idKategori}`;
       
        fetch(`${link}`, {
            headers: {
                'x-auth-token': token
            }
        })
          .then((response) => {
            setStatus(response.ok);
            return response.json();
          })
          .then((res) => {
          //  console.log("DATA: " + res);
            const data = {
              columns: [
                {
                    label: "No.",
                    field: "no",
                    sort: "asc",
                    width: 3,
                },
               
                {
                    label: "Nama ",
                    field: "nama_peserta",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Masuk",
                    field: "masuk",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Pulang",
                    field: "pulang",
                    sort: "asc",
                    width: 150,
                },
               
                {
                    label: "Gereja",
                    field: "nama_gereja",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Nama User",
                    field: "user",
                    sort: "asc",
                    width: 150,
                },
               
                
              ],
              rows: res.map((apiData, i) => {
               
                  return({
                    no: (i+1),
                    
                    nama_peserta: <span>{apiData.nama_peserta}</span>,
                
                    
                masuk: <span>{apiData.jam_masuk}</span>,
                pulang: <span>{apiData.jam_pulang}</span>,
                nama_gereja: <span>{apiData.nama_gereja}</span>,
                user: <span>{apiData.user}</span>,
               
                  });

              }),

            };
            setAbsensi(data);
          });
    }, [user]);

    useEffect(() =>{
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
        setIdAction(decodeString2.split(';')[0]);
       
    }, [id]);

    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={dataAbsensi} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <div className="container-fluid">
                                <div className="row mb-4">
                                    <Link to=  {`/action/kegiatan/${idAction}`} className="btn btn-block bg-gradient-danger">Back</Link>
                                  
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Absen Kegiatan</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita Category */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Absen Kegiatan </h3>
                    <div className="card-tools">
                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(ActionKegiatanAbseni);
