import { GET_OUTVISION,GET_HISTORY,GET_LETTERS,GET_DATABASE,GET_DATA_DATABASE,GET_TATA_GEREJA,GET_TATA_GEREJA_PARENT,GET_TATA_GEREJA_CHILD,GET_TATA_GEREJA_DOCUMENT,GET_PROJECT_SINODE,GET_PROJECT_PROGRESS_SINODE,GET_PROJECT_DONASI_SINODE,GET_GALLERY_VIDEO,GET_GALLERY_FOTO,GET_SCHEDULE,
    OUTVISION_ERROR,HISTORY_ERROR,LETTERS_ERROR,DATABASE_ERROR,DATA_DATABASE_ERROR,TATA_GEREJA_ERROR,TATA_GEREJA_PARENT_ERROR,TATA_GEREJA_CHILD_ERROR,TATA_GEREJA_DOCUMENT_ERROR,PROJECT_SINODE_ERROR,PROJECT_PROGRESS_SINODE_ERROR,PROJECT_DONASI_SINODE_ERROR,GALLERY_VIDEO_ERROR,GALLERY_FOTO_ERROR,SCHEDULE_ERROR} from "../actions/types";

const initialState = {
  galleryVideo:null,
    galleryFoto:null,
    projectDonasi:null,
    projectProgress:null,
    project:null,
    tatagerejadocument:null,
    tatagerejachild:null,
    tatagerejaparent:null,
    tatagereja:null,
    datadatabase:null,
    database:null,
    letters:null,
    sinodeHistory:null,
    outVision:null,
    sinode: "",
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_OUTVISION:
            return{
                ...state,
                outVision: payload,
                loading: false
            };
            case GET_HISTORY:
                return{
                    ...state,
                    sinodeHistory: payload,
                    loading: false
                };

                case GET_LETTERS:
                    return{
                        ...state,
                        letters: payload,
                        loading: false
                    };
                case GET_DATABASE:
                    return{
                        ...state,
                        database: payload,
                        loading: false
                    };

                case GET_DATA_DATABASE:
                return{
                    ...state,
                    datadatabase: payload,
                    loading: false
                };
                case GET_TATA_GEREJA:
                   return{
                       ...state,
                       tatagereja: payload,
                       loading: false
                   };
                   case GET_TATA_GEREJA_PARENT:
                       return{
                           ...state,
                           tatagerejaparent: payload,
                           loading: false
                       };
                       case GET_TATA_GEREJA_CHILD:
                           return{
                               ...state,
                               tatagerejachild: payload,
                               loading: false
                           };
                      case GET_TATA_GEREJA_DOCUMENT:
                            return{
                            ...state,
                            tatagerejadocument: payload,
                            loading: false
                                    };
                    case GET_PROJECT_SINODE:
                        return{
                        ...state,
                        project: payload,
                        loading: false
                        };
                  case GET_PROJECT_PROGRESS_SINODE:
                        return{
                          ...state,
                          projectProgress: payload,
                          loading: false
                              };
                  case GET_PROJECT_DONASI_SINODE:
                            return{
                                  ...state,
                                  projectDonasi: payload,
                                  loading: false
                                  };
                case GET_GALLERY_VIDEO:
                          return{
                            ...state,
                            galleryVideo: payload,
                            loading: false
                              };
              case GET_GALLERY_FOTO:
                          return{
                              ...state,
                              galleryFoto: payload,
                              loading: false
                                  };
              case GET_SCHEDULE:
                          return{
                              ...state,
                              schedule: payload,
                              loading: false
                              };

        case OUTVISION_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
                    };

        case HISTORY_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
                    };
        case LETTERS_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
                    };
        case DATABASE_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
                    };
        case DATA_DATABASE_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
                    };
                    case TATA_GEREJA_ERROR:
                    return{
                        ...state,
                        error: payload,
                        loading: false
                          };
                    case TATA_GEREJA_PARENT_ERROR:
                        return{
                                ...state,
                                error: payload,
                                loading: false
                                };
                  case TATA_GEREJA_CHILD_ERROR:
                          return{
                                ...state,
                                error: payload,
                                loading: false
                                  };
                  case TATA_GEREJA_DOCUMENT_ERROR:
                        return{
                              ...state,
                              error: payload,
                              loading: false
                              };
                  case PROJECT_SINODE_ERROR:
                          return{
                              ...state,
                              error: payload,
                              loading: false
                              };
                  case PROJECT_PROGRESS_SINODE_ERROR:
                              return{
                                    ...state,
                                    error: payload,
                                    loading: false
                                    };
                                    case PROJECT_DONASI_SINODE_ERROR:
                                    return{
                                    ...state,
                                    error: payload,
                                    loading: false
                                    };

                case GALLERY_VIDEO_ERROR:
                          return{
                          ...state,
                          error: payload,
                          loading: false
                          };
                case GALLERY_FOTO_ERROR:
                          return{
                          ...state,
                          error: payload,
                          loading: false
                          };
              case SCHEDULE_ERROR:
                         return{
                  ...state,
                           error: payload,
                             loading: false
                            };

        default:
            return state;
    }

}
