import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

const KaryaToko = ({ auth: { user, token } }) => {
    const [dataKaryaToko, setKaryaToko] = useState([]);
    const [status, setStatus] = useState(true);
    useEffect(() =>{
        let codeChurch = user && (user.gereja[0].kode_gereja);
        let codeMasterChurch = user && (user.gereja[0].kode_gereja_induk);
        console.log('PROFILE: ' + codeChurch + " - " + codeMasterChurch);
        let link='';
        let codeMasterChurch2 = 0;
        link = 'https://gkmi.one:8080/api/karyatoko';
        fetch(`${link}`, {
            headers: {
                'x-auth-token': token
            }
        })
          .then((response) => {
            setStatus(response.ok);
            return response.json();
          })
          .then((res) => {
            console.log(res, "data");
            const data = {
              columns: [
                {
                    label: "No.",
                    field: "no",
                    sort: "asc",
                    width: 10,
                },
                {
                    label: "Avatar",
                    field: "avatar",
                    sort: "asc",
                    width: 150,
                },

                {
                    label: "Nama Toko",
                    field: "nama_toko",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Pemilik",
                    field: "pemilik_toko",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Telepon",
                    field: "no_hp",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Alamat",
                    field: "alamat",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Status",
                    field: "status_toko",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Action",
                    field: "action",
                    width: 150,
                },
              ],
              rows: res.map((apiData, i) => {
                  let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
                  let base64EncodeString2 = Buffer.from(`${apiData.kode_toko}`+';'+`${apiData.nama_toko}`).toString('base64');
                  var status;
                                   if(apiData.status==1){
                                     status="Buka"
                  }else{
                     status="Tutup"
                  }
                  return({
                    no: (i+1),
                    avatar: <center><img src={(apiData.avatar ? (apiData.avatar !== undefined ? apiData.tautan + '/' + apiData.avatar : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')} className="profile-user-img img-fluid img-circle" alt="Avatar" /></center>,

                    nama_toko: <span>{apiData.nama_toko}</span>,
                    pemilik_toko: <span>{apiData.pemilik_toko}</span>,
                    no_hp: <span>No. HP: {apiData.no_hp} <br/>No. WA: {apiData.no_wa}</span>,
                    alamat: <span>{apiData.alamat} <br/>{apiData.kota} <br/>{apiData.kode_pos}</span>,
                    status_toko: <span className="btn btn-block btn-outline-success btn-sm">{status}</span>,
                    action: <span><Link className="btn btn-block bg-gradient-primary" to={`/karya/toko/edit-toko/${base64EncodeString}`}>Ubah</Link><br/><Link className="btn btn-block bg-gradient-red" to={`/karya/toko/produk/${base64EncodeString2}`}>Produk</Link></span>
                  });

              }),

            };
            setKaryaToko(data);
          });
    }, [user]);

    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={dataKaryaToko} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <Link to='/karya/toko/create-toko' className="btn btn-block bg-gradient-green  ">Tambah Data Toko</Link>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Toko Karya</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita Category */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Toko Karya</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(KaryaToko);
