import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { updatePemimpinBranches } from "../../../actions/pemimpinAction";
import { getPemimpinById } from "../../../actions/pemimpinAction";

import { SunEditorAtom } from '../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditBranchesPemimpin = ({ pemimpin: { pemimpin, loading }, getPemimpinById, updatePemimpinBranches, auth: { user, token }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [idValues, setIdValues] = useState();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [profil, setProfil] = useState();
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [formData, setFormData] = useState({
        nama: '',
        jabatan:'',
        gelar:'',
    });

    const {
        nama,
        jabatan,
        gelar,
    } = formData;

    const [value, setValue] = useState();
    const [loadings, setLoadings] = useState(false);

    useEffect(() => {
        setFormData({
          nama: pemimpin && (pemimpin.nama ? pemimpin.nama : ''),
            jabatan: pemimpin && (pemimpin.jabatan ? pemimpin.jabatan : ''),
            gelar: pemimpin && (pemimpin.gelar ? pemimpin.gelar : ''),
        });

        //setProfil(pemimpin && (pemimpin.profil ? pemimpin.profil : ''));
        setValue((pemimpin && (pemimpin.profil ? pemimpin.profil : '')));

        var content = pemimpin && (pemimpin.profil ? pemimpin.profil : '');

	   const fixedHTML = content ? content.toString().replace(/<figure.*>/gi, "\n") : '';
       const blocksFromHtml = htmlToDraft("<p>"+fixedHTML+"</p>");
       const { contentBlocks, entityMap } = blocksFromHtml;
       const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
       const editorState = EditorState.createWithContent(contentState);
       setEditorState(editorState);

	}, [loading, pemimpin]);

    useEffect(() => {
      if(pemimpin && (pemimpin.namafile_image)){
            setFileName(pemimpin && (pemimpin.namafile_image ? pemimpin.namafile_image : ''));
            console.log('NAMA: ' + fileName);
            setImagePreviewUrl((pemimpin && (pemimpin.tautan ? pemimpin.tautan : ''))+fileName);
        }else{
            setImagePreviewUrl(defaultImage);
        }

    }, [loading, pemimpin, fileName]);

    useEffect(() => {
      let decodeString = Buffer.from(id, 'base64').toString('ascii');
        getPemimpinById(decodeString.split(';')[0]);
        setIdValues(decodeString.split(';')[1]);
        
    }, [getPemimpinById, id]);

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const onEditorStateChange = (editorState) => {
        console.log('OKK: ' + stateToHTML(editorState.getCurrentContent()));
        setEditorState(editorState);
    }

    function uploadImageCallBack(file, uploadHandler) {
      const uploadFile = file[0];
      console.log('KESINI: ' + uploadFile);
      return new Promise(
        (resolve, reject) => {
          const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
          xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
          xhr.setRequestHeader('x-auth-token', token);
          const data = new FormData(); // eslint-disable-line no-undef
          data.append('file', uploadFile);
          data.append("tautan", API_URL_IMAGE + '/ckeditor/')
          xhr.send(data);
          xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText);
            console.log('OK: ' + response);
            uploadHandler(response);
            resolve(response);
          });
          xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
          });
        },
      );
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
        const data = new FormData();
        data.append("image_pemgereja", file);
        data.append("nama", formData.nama);
        data.append("jabatan", formData.jabatan);
        data.append("gelar", formData.gelar);

        //data.append("profil", draftToHtml(convertToRaw(editorState.getCurrentContent())));
         data.append("profil", value);

        console.log(file);
        updatePemimpinBranches(data, decodeString2.split(';')[0], history,idValues);
    }

    function handleImageUploadBefore(files, info, uploadHandler){
      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString())
      }
    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to={`/pipka/branches/pemimpin/${idValues}`}  className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Pemimpin</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Pemimpin {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Nama</label>
                          <input type="text" className="form-control" placeholder="Nama ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Jabatan</label>
                          <input type="text" className="form-control" placeholder="Jabatan ..." required name="jabatan" value={jabatan} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Gelar</label>
                          <input type="text" className="form-control" placeholder="Gelar ..." name="gelar" value={gelar} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                            <label>Profil</label>
                            <div style={{position: 'relative'}}>
                                <div style={{zIndex: 1}}>
                                    <SunEditor
                                        autoFocus={true}
                                        lang="en"
                                        width='100%'
                                        height='450px'
                                        setOptions={{
                                        minWidth : '450px',
                                        showPathLabel: false,
                                        placeholder: "Enter your text here!!!",
                                        plugins: [
                                            align,
                                            font,
                                            fontColor,
                                            fontSize,
                                            formatBlock,
                                            hiliteColor,
                                            horizontalRule,
                                            lineHeight,
                                            list,
                                            paragraphStyle,
                                            table,
                                            template,
                                            textStyle,
                                            image,
                                            link
                                        ],
                                        buttonList: [
                                            ["undo", "redo"],
                                            ["font", "fontSize", "formatBlock"],
                                            ["paragraphStyle"],
                                            [
                                            "bold",
                                            "underline",
                                            "italic",
                                            "strike",
                                            "subscript",
                                            "superscript"
                                            ],
                                            ["fontColor", "hiliteColor"],
                                            ["removeFormat"],
                                            "/", // Line break
                                            ["outdent", "indent"],
                                            ["align", "horizontalRule", "list", "lineHeight"],
                                            ["table", "link", "image"]
                                        ],
                                        formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                        font: [
                                            "Arial",
                                            "Calibri",
                                            "Comic Sans",
                                            "Courier",
                                            "Garamond",
                                            "Georgia",
                                            "Impact",
                                            "Lucida Console",
                                            "Palatino Linotype",
                                            "Segoe UI",
                                            "Tahoma",
                                            "Times New Roman",
                                            "Trebuchet MS"
                                        ]
                                        }}
                                        onImageUploadBefore={handleImageUploadBefore}
                                        setContents={value}
                                        onChange={setValue}

                                    />

                                </div>

                                <div style={{zIndex: -999, position: 'absolute'}}>
                                    <SunEditorAtom initialContent={value}/>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Foto </label>
                            <img style={{border: "1px solid #adb5bd"}} src={imagePreviewUrl}
                                className="img-fluid mb-3"
                                alt=""
                            />
                            <input type="file" className="form-control" placeholder="Gambar ..." name="image_pemgereja" onChange={fileOnChange} />

                            </div>
                        </div>
                    </div>

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditBranchesPemimpin.propTypes = {
  updatePemimpinBranches: PropTypes.func.isRequired,
    getPemimpinById: PropTypes.func.isRequired,
    pemimpin: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    pemimpin: state.pemimpin
});

export default connect(mapStateToProps, { updatePemimpinBranches, getPemimpinById })(withRouter(EditBranchesPemimpin));
