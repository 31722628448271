import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import parse from 'html-react-parser';

const MissionHome = ({ auth: { user, token } }) => {
    const [dataMissionHome, setMissionHome] = useState([]);
    const [status, setStatus] = useState(true);
    useEffect(() =>{
        fetchData();
   }, [user]);

   const fetchData = async () => {
       
        let link='';
        
         link = `https://gkmi.one:8080/api/pipkabackend/missionhome`;
      
     const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
        setMissionHome(response.data);
   }

   const deleteData = (id) => {
     let url2 = `https://gkmi.one:8080/api/pipkabackend/missionhome/${id}`
     axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
         const del = dataMissionHome.filter(tableRow => id !== tableRow._id)
         setMissionHome(del);
     })
   }

   const data = {
    columns: [
                    {
                        label: "No.",
                        field: "no",
                        sort: "asc",
                        width: 10,
                    },
                    {
                        label: "Image",
                        field: "nama_image",
                        sort: "asc",
                        width: 150,
                    },
                    
                    {
                        label: "Judul Mission Home",
                        field: "judul",
                        sort: "asc",
                        width: 150,
                    },
                    {
                        label: "Tanggal",
                        field: "tanggal",
                        sort: "asc",
                        width: 150,
                    },
                    {
                        label: "Status",
                        field: "status",
                        sort: "asc",
                        width: 30,
                    },
                    {
                        label: "Action",
                        field: "action",
                        width: 150,
                    },
                  ],
              rows: dataMissionHome.map((apiData, i) => {
                // var content = apiData.isi_berita;
                // var fixedHTML = "";
                // if(content !== undefined){
                //     fixedHTML = parse(content);
                // }
                let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
               
                var active;
                  var classStatus;
                  if(apiData.active==1){
                    active="Active";
                    classStatus="badge bg-gray"
                        }else{
                            active="In Active";
                            classStatus="badge badge-danger"
                        }

                return({
                  no: (i+1),
                  nama_image: <center><img src={(apiData.images[0] !== "" ? (apiData.images[0] !== undefined ? apiData.images[0].tautan + apiData.images[0].nama_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
                  className="profile-user-img img-fluid img-circle"
                  alt="Image" /></center>,
                
                  judul: <span>{apiData.judul}</span>,
                  tanggal: <span>{apiData.tanggal}</span>,
                  status:<span class={classStatus}>{active}</span>,
                  action: <span><Link className="btn btn-block bg-gradient-primary" to={`/pipka/missionhome/edit-missionhome/${base64EncodeString}`}>Ubah</Link>
          {/* <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData.id) } }>Hapus</a> */}
          </span>
    });
  }),
};
    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <Link to='/pipka/missionhome/create-missionhome' className="btn btn-block bg-gradient-green  ">Tambah Data Mission Home</Link>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardPIPKA">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Mission Home</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Mission Home</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(MissionHome);
