import { GET_PEMIMPIN,GET_PEMIMPIN2, PEMIMPIN_ERROR } from "../actions/types";

const initialState = {
   pemimpin2: [],
    pemimpin: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_PEMIMPIN:
            return{
                ...state,
                pemimpin: payload,
                loading: false
            };
            case GET_PEMIMPIN2:
               return{
                   ...state,
                   pemimpin2: payload,
                   loading: false
               };
        case PEMIMPIN_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
