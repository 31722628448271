import { GET_ACTION,GET_ACTION_KEGIATAN, ACTION_ERROR,ACTION_KEGIATAN_ERROR } from "../actions/types";

const initialState = {

    actionacara: null,
    actionKegiatan: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_ACTION:
            return{
                ...state,
                actionacara: payload,
                loading: false
            };
            case GET_ACTION_KEGIATAN:
                     return{
                         ...state,
                         actionKegiatan: payload,
                         loading: false
                     };

        case ACTION_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
            case ACTION_KEGIATAN_ERROR:
               return{
                   ...state,
                   error: payload,
                   loading: false
               };
        default:
            return state;
    }
}
