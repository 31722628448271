import React, { Fragment, useState, useEffect,useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';
import { updateCaraPembayaran } from "../../../actions/beritaGkmiAction";
import { getPembayaran } from "../../../actions/beritaGkmiAction";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { SunEditorAtom } from '../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditBeritaGkmiPembayaran = ({ beritaGkmi: { loading, beritaGkmiPembayaran },updateCaraPembayaran,getPembayaran, auth: { user ,token}, history }) => {
  const editor = useRef();
      const {id} = useParams();
    const [loadings, setLoadings] = useState(false);
    let [editorState, setEditorState] = useState(EditorState.createEmpty());


    const [formData, setFormData] = useState({
      nama:'',
      phone:'',
      bank:'',
      rekening:'',
      atasnama:'',


    });
    const [caraBayar, setCaraBayar] = useState();
    useEffect(() => {
        setFormData({
          nama: beritaGkmiPembayaran && (beritaGkmiPembayaran.nama ? beritaGkmiPembayaran.nama : ''),
          phone: beritaGkmiPembayaran && (beritaGkmiPembayaran.no_hanphone ? beritaGkmiPembayaran.no_hanphone : ''),
           bank: beritaGkmiPembayaran && (beritaGkmiPembayaran.nama_bank ? beritaGkmiPembayaran.nama_bank : ''),
           rekening: beritaGkmiPembayaran && (beritaGkmiPembayaran.no_rekening ? beritaGkmiPembayaran.no_rekening : ''),
           atasnama: beritaGkmiPembayaran && (beritaGkmiPembayaran.atas_nama ? beritaGkmiPembayaran.atas_nama : ''),

        });

      setCaraBayar((beritaGkmiPembayaran && (beritaGkmiPembayaran.cara_bayar ? beritaGkmiPembayaran.cara_bayar : '')));
    //  var content = beritaGkmiPembayaran && (beritaGkmiPembayaran.cara_bayar ? beritaGkmiPembayaran.cara_bayar : '');

    // const fixedHTML =  content ? content.toString().replace(/<figure.*>/gi, "\n") : '';
    //   const blocksFromHtml = htmlToDraft("<p>"+fixedHTML+"</p>");
    //   const { contentBlocks, entityMap } = blocksFromHtml;
    //   const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    //   const editorState = EditorState.createWithContent(contentState);
    //   setEditorState(editorState);

    }, [loading, beritaGkmiPembayaran]);



    useEffect(() => {
         let decodeString = Buffer.from(id, 'base64').toString('ascii');
      // console.log("id "+decodeString.split(';')[0])
      getPembayaran();
      // getBeritaGkmiKategori2();
      //  getBeritaGkmiEdisi2();
      //  getBeritaGkmiKontributor2();
      }, [getPembayaran, id]);

    const {
      nama,
      phone,
      bank,
      rekening,
      atasnama,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);

       const data = new FormData();
        data.append("nama", formData.nama);
        data.append("phone", formData.phone);
        data.append("bank", formData.bank);
        data.append("rekening", formData.rekening);
        data.append("atasnama", formData.atasnama);
        data.append("carabayar", caraBayar);
        // data.append("kontributor", formData.kode_kontributor);
        // data.append("status", valuep.payment);
        //  data.append("status_name", varpayment);
        // data.append("active", values.status);

        // updateBeritaGkmi(data,  Buffer.from(id, 'base64').toString('ascii'), history);
        // const data = {
        //   "nama": formData.nama,
        //   "phone": formData.phone,
        //   "bank": formData.bank,
        //   "rekening": formData.rekening,
        //   "atasnama": formData.atasnama,
        //   "carabayar": caraBayar,
        // }
        // console.log(caraBayar);
        updateCaraPembayaran(data,  Buffer.from(id, 'base64').toString('ascii'), history);
    }

  function uploadImageCallBack(file, uploadHandler) {
      const uploadFile = file[0];
      return new Promise(
        (resolve, reject) => {
          const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
          xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
          xhr.setRequestHeader('x-auth-token', token);
          const data = new FormData(); // eslint-disable-line no-undef
          data.append('file', uploadFile);
          data.append("tautan", API_URL_IMAGE + '/ckeditor/')
          xhr.send(data);
          xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText);
            console.log('OK: ' + response);
            uploadHandler(response);
            resolve(response);
          });
          xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
          });
        },
      );
    }
    function handleImageUploadBefore(files, info, uploadHandler){
      // uploadHandler is a function
      console.log('UPLOAD: ' + files, info);

      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString());
      }
    }

    const getSunEditorInstance = (sunEditor) => {
      editor.current = sunEditor;

    };

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI/pembayaran" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Cara Pembayaran</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Cara Pembayaran </h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />




                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Nama</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Nama ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>No Hanphone</label>
                    <div class="jss213-small">Sebagai No Hanphone Customer Service  </div>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="No Hanphone ..." required name="phone" value={phone} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Nama Bank</label>
                    <div class="jss213-small">Bank Pembayaran Berlangganan </div>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Nomor Bank ..." required name="bank" value={bank} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Nomor Rekening</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Nomor Rekening ..." required name="rekening" value={rekening} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Atas Nama</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Atas Nama ..." required name="atasnama" value={atasnama} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Cara Pembayaran</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div style={{position: 'relative'}}>
                    <div style={{zIndex: 1}}>
                        <SunEditor
                            autoFocus={true}
                            lang="en"
                            width='100%'
                            height='450px'
                            setOptions={{
                            minWidth : '450px',
                            showPathLabel: false,
                            placeholder: "Enter your text here!!!",
                            plugins: [
                                align,
                                font,
                                fontColor,
                                fontSize,
                                formatBlock,
                                hiliteColor,
                                horizontalRule,
                                lineHeight,
                                list,
                                paragraphStyle,
                                table,
                                template,
                                textStyle,
                                image,
                                link
                            ],
                            buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                                ],
                                ["fontColor", "hiliteColor"],
                                ["removeFormat"],
                                "/", // Line break
                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                                "Arial",
                                "Calibri",
                                "Comic Sans",
                                "Courier",
                                "Garamond",
                                "Georgia",
                                "Impact",
                                "Lucida Console",
                                "Palatino Linotype",
                                "Segoe UI",
                                "Tahoma",
                                "Times New Roman",
                                "Trebuchet MS"
                            ]
                            }}
                            onImageUploadBefore={handleImageUploadBefore}
                            setContents={caraBayar}
                            onChange={setCaraBayar}

                        />

                    </div>

                    <div style={{zIndex: -999, position: 'absolute'}}>
                        <SunEditorAtom initialContent={caraBayar}/>
                    </div>
                </div>


                   </div>
                  </div>
                </div>



                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditBeritaGkmiPembayaran.propTypes = {
  updateCaraPembayaran: PropTypes.func.isRequired,
  getPembayaran: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    beritaGkmi: state.beritaGkmi,
});

export default connect(mapStateToProps, { updateCaraPembayaran, getPembayaran })(withRouter(EditBeritaGkmiPembayaran));
