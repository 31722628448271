import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import Alert from "../../../../components/alert/Alert";
import Header from "../../../layouts/Header";
import Navbar from "../../../layouts/Navbar";
import Footer from "../../../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createKegiatan } from "../../../../actions/gerejaJadwalKegiatanAction";

const CreateKegiatan = ({ createKegiatan, history, auth: { user } }) => {
  const {id} = useParams();
  const [loadings, setLoadings] = useState(false);
  const [formData, setFormData] = useState({
    kode_gereja: '',
    nama_kegiatan: '',
    hari: '',
    jam_mulai: '',
    jam_selesai: '',
  });

  useEffect(() => {
    user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0) ? setFormData({kode_gereja: Buffer.from(id, 'base64').toString('ascii')}) : user && (setFormData({kode_gereja: user.gereja[0].kode_gereja}))
  }, [user, id]);

  const {
    kode_gereja,
    nama_kegiatan,
    hari,
    jam_mulai,
    jam_selesai
  } = formData;

  const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = async (e) => {
      e.preventDefault();
      setLoadings(true);
      createKegiatan(formData, history);
  }

  return (

    <Fragment>
      <Header />
      <Navbar />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-2">
                <Link to="/jadual/kegiatan" className="btn btn-block bg-gradient-danger">Back</Link>
              </div>
              <div className="col-sm-10">
                <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Jadual</li>
                  <li className="breadcrumb-item active">Kegiatan</li>
                </ol>
              </div>
            </div>
            </div>
        </section>
        {/* Main content */}
        <section className="content">
        <div className="container-fluid">
          {/* Default box */}
          <div className="card">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
              <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Tambah Jadual Ibadah/Persekutuan</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fas fa-minus" />
                </button>

              </div>
            </div>

            <div className="card-body">
                <Alert />
                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>Nama Kegiatan</label>
                    <input type="hidden" className="form-control" placeholder="Kode Gereja ..." name="kode_gereja" value={kode_gereja} onChange={e => onChange(e)} />
                    <input type="text" className="form-control" placeholder="Nama Kegiatan ..." required name="nama_kegiatan" value={nama_kegiatan} onChange={e => onChange(e)} />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>Hari</label>
                    <input type="text" className="form-control" placeholder="Hari ..." name="hari" value={hari} onChange={e => onChange(e)} />
                    </div>
                </div>
                </div>

                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>Jam Mulai</label>
                    <input type="text" className="form-control" placeholder="Jam Mulai ..." name="jam_mulai" value={jam_mulai} onChange={e => onChange(e)} />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>Jam Selesai</label>
                    <input type="text" className="form-control" placeholder="Jam Selesai ..." name="jam_selesai" value={jam_selesai} onChange={e => onChange(e)} />
                    </div>
                </div>
                </div>
            </div>

            {/* /.card-body */}
            <div className="card-footer">
            {
              loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
              :
              <input type="submit" className="btn btn-primary pull-right" value="Save" />
            }
            </div>
            {/* /.card-footer*/}
            </form>
          </div>
          {/* /.card */}

          </div>
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </Fragment>
  );
};

CreateKegiatan.propTypes = {
  createKegiatan: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps, { createKegiatan })(withRouter(CreateKegiatan));
