import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {API_URL_IMAGE} from '../../config/apiUrl'


  const NavbarBeritaGKMI = ({ auth: { user } }) => {
  const location = useLocation();
  const [branchesTreeClass, setBranchesTreeClass] = useState("nav-item has-treeview");
  const [gerejaClass, setGerejaClass] = useState("nav-item has-treeview");
  const [settingsTreeClass, setSettingsTreeClass] = useState("nav-item has-treeview");
  const homeClass = location.pathname === "/dashboardBeritaGkmi" ? "nav-link active" : "nav-link";
  const [beritaTreeClass, setBeritaTreeClass] = useState("nav-item has-treeview");
  const [beritaGKMITreeClass, setBeritaGKMITreeClass] = useState("nav-item has-treeview");
  const [misionTreeClass, setMissionTreeClass] = useState("nav-item has-treeview");
  const [galleryTreeClass, setGalleryTreeClass] = useState("nav-item has-treeview");

  const gerejaDataClass = location.pathname === "/gereja/gereja-data" ? "nav-link active" : "nav-link";
  const gerejaSliderClass = location.pathname === "/gereja/slider" ? "nav-link active" : "nav-link";
  const gerejaPemimpinClass = location.pathname === "/gereja/pemimpin" ? "nav-link active" : "nav-link";

  const userClass = location.pathname === "/beritaGKMI/user" ? "nav-link active" : "nav-link";
  const iklanClass = location.pathname === "/beritaGKMI/iklan" ? "nav-link active" : "nav-link";
  const orderlass = location.pathname === "/beritaGKMI/order" ? "nav-link active" : "nav-link";
  const pembayaranClass = location.pathname === "/beritaGKMI/pembayaran" ? "nav-link active" : "nav-link";
  const beritaCategoryClass = location.pathname === "/berita/category" ? "nav-link active" : "nav-link";
  const beritaClass = location.pathname === "/pipka/berita" ? "nav-link active" : "nav-link";
  const homeSliderClass = location.pathname === "/pipka/pipka-slider" ? "nav-link active" : "nav-link";
  const firmanClass = location.pathname === "/pipka/firman" ? "nav-link active" : "nav-link";
  const donasiClass = location.pathname === "/pipka/donasi" ? "nav-link active" : "nav-link";
  const settingsClass = location.pathname === "/pipka/setting" ? "nav-link active" : "nav-link";
   const regionClass = location.pathname === "/pipka/region" ? "nav-link active" : "nav-link";

   const menuClass = location.pathname === "/pipka/menu" ? "nav-link active" : "nav-link";
   const projectClass = location.pathname === "/pipka/project" ? "nav-link active" : "nav-link";
   const kategoriClass = location.pathname === "/pipka/mission/kategori" ? "nav-link active" : "nav-link";
  const missionhomeClass = location.pathname === "/pipka/missionhome" ? "nav-link active" : "nav-link";
  const eventClass = location.pathname === "/pipka/event" ? "nav-link active" : "nav-link";
  const fotoClass = location.pathname === "/pipka/galleryfoto" ? "nav-link active" : "nav-link";
  const videoClass = location.pathname === "/pipka/galleryvideo" ? "nav-link active" : "nav-link";
  const handleOnClick = (menu) => {
    console.log("MENU: " + menu); // remove the curly braces

    if(menu !=="DashboardBeritaGkmi")
      document.title = `GKMI ONE - ${menu}`;
    else
     document.title = `GKMI ONE`;
  };

  const handleClick = (e) => {
	e.preventDefault();
  }

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
  }, []);

  useEffect(() => {
    if(location.pathname === "/pipka/branches"){
      setBranchesTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/pipka/region"){
      setBranchesTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/pipka/settings"){
          setSettingsTreeClass("nav-item has-treeview menu-open");
        }
        if(location.pathname === "/pipka/menu"){
          setSettingsTreeClass("nav-item has-treeview menu-open");
        }
        if(location.pathname === "/pipka/mission/kategori"){
              setMissionTreeClass("nav-item has-treeview menu-open");
            }
            if(location.pathname === "/pipka/missionhome"){
              setMissionTreeClass("nav-item has-treeview menu-open");
            }
            if(location.pathname === "/pipka/galleryfoto"){
                  setGalleryTreeClass("nav-item has-treeview menu-open");
                }
                if(location.pathname === "/pipka/galleryvideo"){
                  setGalleryTreeClass("nav-item has-treeview menu-open");
                }
  }, [location.pathname]);

  return (
    <Fragment>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/dashboardBeritaGkmi" className="brand-link navbar-danger" style={{lineHeight: "2.1"}}>
          <img
            src={process.env.PUBLIC_URL + '/logo-gkmi.png'}
            alt="GKMI ONE"
            className="brand-image img-circle"
            style={{ opacity: ".8", height: "58px", maxHeight: "58px", marginTop: "-12px", marginLeft: "1px" }}
          />
          <span className="brand-text font-weight-light">GKMI ONE</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar" style={{backgroundColor: "#3c8dbc"}}>
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">

          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-legacy"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item">
                <Link
                  to="/dashboardPIPKA"
                  className={homeClass}
                  onClick={() => handleOnClick("DashboardPIPKA")}
                >
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p className="linkcolor">
                    Dashboard
                    <i className="right" />
                  </p>
                </Link>
              </li>

              <li className={branchesTreeClass}>
              <a href="#!" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p className="linkcolor">
                  Branches
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/pipka/branches" className={gerejaDataClass} onClick={() => handleOnClick("Branches")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Branches</p>
                  </Link>
                </li>
                <li className="nav-item">
                 <Link to="/pipka/region" className={regionClass} onClick={() => handleOnClick("Region")}>
                   <i className="far fa-circle nav-icon"></i>
                   <p className="linkcolor">Region</p>
                 </Link>
               </li>


              </ul>
            </li>
            <li className="nav-item">
                           <Link
                             to="/pipka/donasi"
                             className={donasiClass}
                             onClick={() => handleOnClick("Donasi")}
                           >
                             <i className="nav-icon far fa fa-2x fa-book" />
                             <p className="linkcolor">
                             Donasi/Suport
                               <i className="right" />
                             </p>
                           </Link>
                         </li>
            <li className="nav-item">
                <Link
                 to="/pipka/berita"
                  className={beritaClass}
                  onClick={() => handleOnClick("Berita")}
                >
                  <i className="nav-icon far fas fa-newspaper" />
                  <p className="linkcolor">
                  Berita
                    <i className="right" />
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/pipka/firman"
                  className={firmanClass}
                  onClick={() => handleOnClick("Firman")}
                >
                  <i className="nav-icon far fa fa-2x fa-book" />
                  <p className="linkcolor">
                  Firman
                    <i className="right" />
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                             <Link
                               to="/pipka/project"
                               className={projectClass}
                               onClick={() => handleOnClick("Project")}
                             >
                               <i className="nav-icon far fas fa-newspaper" />
                               <p className="linkcolor">
                               Project
                                 <i className="right" />
                               </p>
                             </Link>
                           </li>

                           <li className={misionTreeClass}>
                           <a href="#!" className="nav-link">
                             <i className="nav-icon fas fa-th"></i>
                             <p className="linkcolor">
                               Mission Home
                               <i className="right fas fa-angle-left"></i>
                             </p>
                           </a>
                           <ul className="nav nav-treeview">

                             <li className="nav-item">
                               <Link to="/pipka/missionhome" className={missionhomeClass} onClick={() => handleOnClick("Mission Home")}>
                                 <i className="far fa-circle nav-icon"></i>
                                 <p className="linkcolor">Mission Home</p>
                               </Link>
                             </li>
                             <li className="nav-item">
                               <Link to="/pipka/mission/kategori" className={kategoriClass} onClick={() => handleOnClick("Kategori")}>
                                 <i className="far fa-circle nav-icon"></i>
                                 <p className="linkcolor">Kategori Mission</p>
                               </Link>
                             </li>
                           </ul>
                         </li>
                         <li className="nav-item">
               <Link
                 to="/pipka/event"
                 className={eventClass}
                 onClick={() => handleOnClick("Event")}
               >
                 <i className="nav-icon far fas fa-newspaper" />
                 <p className="linkcolor">
                 Event
                   <i className="right" />
                 </p>
               </Link>
             </li>
              <li className="nav-item">
              <Link to="/pipka/pipka-slider" className={homeSliderClass} onClick={() => handleOnClick("Pipka Slider")}>
                  <i className="nav-icon fas fa-upload" />
                  <p className="linkcolor">
                  Home Slider
                    <i className="right" />
                  </p>
                </Link>
              </li>
              <li className={galleryTreeClass}>
             <a href="#!" className="nav-link">
               <i className="nav-icon fas fa-th"></i>
               <p className="linkcolor">
                 Gallery
                 <i className="right fas fa-angle-left"></i>
               </p>
             </a>
             <ul className="nav nav-treeview">

               <li className="nav-item">
                 <Link to="/pipka/galleryfoto" className={fotoClass} onClick={() => handleOnClick("Foto")}>
                   <i className="far fa-circle nav-icon"></i>
                   <p className="linkcolor">Gallery Foto</p>
                 </Link>
               </li>
               <li className="nav-item">
                 <Link to="/pipka/galleryvideo" className={videoClass} onClick={() => handleOnClick("Video")}>
                   <i className="far fa-circle nav-icon"></i>
                   <p className="linkcolor">Gallery Video</p>
                 </Link>
               </li>
             </ul>
           </li>
              <li className={settingsTreeClass}>
              <a href="#!" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p className="linkcolor">
                  Settings
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/pipka/setting" className={settingsClass} onClick={() => handleOnClick("About PIPKA")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">About PIPKA</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/pipka/menu" className={menuClass} onClick={() => handleOnClick("Menu")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Home Menu</p>
                  </Link>
                </li>


              </ul>
            </li>
              <li className="nav-item">
                <Link
                  to="/beritaGKMI/user"
                  className={userClass}
                  onClick={() => handleOnClick("user")}
                >
                  <i className="nav-icon fas fa-user" />
                  <p className="linkcolor">
                    User Role
                    <i className="right" />
                  </p>
                </Link>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </Fragment>
  );
};

NavbarBeritaGKMI.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(NavbarBeritaGKMI);
