import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {API_URL_IMAGE} from '../../config/apiUrl'

const NavbarBeritaGKMI = ({ auth: { user } }) => {
  const location = useLocation();
  const [firmanTreeClass, setFirmanTreeClass] = useState("nav-item has-treeview");

  const homeClass = location.pathname === "/dashboardBeritaGkmi" ? "nav-link active" : "nav-link";

  const firmanClass = location.pathname === "/firman" ? "nav-link active" : "nav-link";
  const [beritaGKMITreeClass, setBeritaGKMITreeClass] = useState("nav-item has-treeview");


  const beritaGKMIkategoriClass = location.pathname === "/beritaGKMI/kategori" ? "nav-link active" : "nav-link";
  const beritaGKMIEdisiClass = location.pathname === "/beritaGKMI/edisi" ? "nav-link active" : "nav-link";
  const beritaGKMIClass = location.pathname === "/beritaGKMI" ? "nav-link active" : "nav-link";
  const kontributorClass = location.pathname === "/beritaGKMI/kontributor" ? "nav-link active" : "nav-link";
  const langgananClass = location.pathname === "/beritaGKMI/langganan" ? "nav-link active" : "nav-link";
  const userClass = location.pathname === "/beritaGKMI/user" ? "nav-link active" : "nav-link";
  const iklanClass = location.pathname === "/beritaGKMI/iklan" ? "nav-link active" : "nav-link";
  const orderlass = location.pathname === "/beritaGKMI/order" ? "nav-link active" : "nav-link";
  const pembayaranClass = location.pathname === "/beritaGKMI/pembayaran" ? "nav-link active" : "nav-link";
  const handleOnClick = (menu) => {
    console.log("MENU: " + menu); // remove the curly braces

    if(menu !=="DashboardBeritaGkmi")
      document.title = `GKMI ONE - ${menu}`;
    else
     document.title = `GKMI ONE`;
  };

  const handleClick = (e) => {
	e.preventDefault();
  }

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
  }, []);

  useEffect(() => {

    if(location.pathname === "/beritaGKMI/kategori"){
      setBeritaGKMITreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/beritaGKMI/edisi"){
      setBeritaGKMITreeClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/beritaGKMI"){
      setBeritaGKMITreeClass("nav-item has-treeview menu-open");
    }

  }, [location.pathname]);

  return (
    <Fragment>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/dashboardBeritaGkmi" className="brand-link navbar-danger" style={{lineHeight: "2.1"}}>
          <img
            src={process.env.PUBLIC_URL + '/logo-gkmi.png'}
            alt="GKMI ONE"
            className="brand-image img-circle"
            style={{ opacity: ".8", height: "58px", maxHeight: "58px", marginTop: "-12px", marginLeft: "1px" }}
          />
          <span className="brand-text font-weight-light">GKMI ONE</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar" style={{backgroundColor: "#3c8dbc"}}>
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">

          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-legacy"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item">
                <Link
                  to="/dashboardBeritaGkmi"
                  className={homeClass}
                  onClick={() => handleOnClick("DashboardBeritaGkmi")}
                >
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p className="linkcolor">
                    Dashboard
                    <i className="right" />
                  </p>
                </Link>
              </li>

              <li className={beritaGKMITreeClass}>
              <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                <i className="nav-icon far fa-newspaper"></i>
                <p className="linkcolor">
                  Berita
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
			      	<li className="nav-item">
                  <Link to="/beritaGKMI/kategori" className={beritaGKMIkategoriClass} onClick={() => handleOnClick("Kategori Berita GKMI")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Kategori</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/beritaGKMI/edisi" className={beritaGKMIEdisiClass} onClick={() => handleOnClick("Edisi Berita GKMI")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Edisi</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/beritaGKMI" className={beritaGKMIClass} onClick={() => handleOnClick("Berita GKMI")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Berita GKMI</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
                <Link
                  to="/beritaGKMI/kontributor"
                  className={kontributorClass}
                  onClick={() => handleOnClick("Jemaat")}
                >
                  <i className="nav-icon fas fa-user" />
                  <p className="linkcolor">
                  Kontributor
                    <i className="right" />
                  </p>
                </Link>
              </li>
              <li className="nav-item">
               <Link
                 to="/beritaGKMI/iklan"
                 className={iklanClass}
                 onClick={() => handleOnClick("user")}
               >
                 <i className="nav-icon fas fa-user" />
                 <p className="linkcolor">
                 Banner Iklan
                   <i className="right" />
                 </p>
               </Link>
             </li>
              <li className="nav-item">
                              <Link
                                to="/beritaGKMI/langganan"
                                className={langgananClass}
                                onClick={() => handleOnClick("langganan")}
                              >
                                <i className="nav-icon fas fa-handshake" />
                                <p className="linkcolor">
                                Langganan
                                  <i className="right" />
                                </p>
                              </Link>
                            </li>


                            <li className="nav-item">
                <Link
                  to="/beritaGKMI/order"
                  className={orderlass}
                  onClick={() => handleOnClick("user")}
                >
                  <i className="nav-icon fas fa-user" />
                  <p className="linkcolor">
                  Order Langganan
                    <i className="right" />
                  </p>
                </Link>
              </li>
              <li className="nav-item">
 <Link
   to="/beritaGKMI/pembayaran"
   className={pembayaranClass}
   onClick={() => handleOnClick("pembayaran")}
 >
  <i className="nav-icon far fa-newspaper"></i>
   <p className="linkcolor">
   Cara Pembayaran
     <i className="right" />
   </p>
 </Link>
</li>
              <li className="nav-item">
               <Link
                 to="/beritaGKMI/user"
                 className={userClass}
                 onClick={() => handleOnClick("user")}
               >
                 <i className="nav-icon fas fa-user" />
                 <p className="linkcolor">
                   User Role
                   <i className="right" />
                 </p>
               </Link>
             </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </Fragment>
  );
};

NavbarBeritaGKMI.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(NavbarBeritaGKMI);
