import React, { Fragment, useState, useEffect,useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import DatePicker from "react-datepicker";
import moment from "moment";
import { updateEvent } from "../../../actions/pipkaAction";
import { getEventById } from "../../../actions/pipkaAction";

import { SunEditorAtom } from '../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditEvent = ({ pipka: { event, loading }, getEventById, updateEvent, auth: { user, token }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [isi, setIsi] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl]  = useState(defaultImage);
    const editor = useRef();
    const [values, setValues] = useState({
      status: '1',
    });
    const [formData, setFormData] = useState({
      tanggal: new Date(),
      jam_event: new Date(),
        sosmed_url:'',
        tempat:'',
        alamat:'',
        map_url:'',
        nama_event:'',
    });
    const [keterangan, setKeterangan] = useState();
    

    const {
      tanggal,nama_event,sosmed_url,tempat,alamat,map_url,url_googleform,jam_event
    } = formData;

    useEffect(() => {


      if(event && event.images[0]){
            console.log('KESINI KAN');
            setFormData({
              nama_event: event && (event.nama_event ? event.nama_event : ''),
              sosmed_url: event && (event.url_sosmet ? event.url_sosmet : ''),
              tempat: event && (event.tempat ? event.tempat : ''),
              alamat: event && (event.address ? event.address : ''),
              map_url: event && (event.map_url ? event.map_url : ''),
              url_googleform: event && (event.url_googleform ? event.url_googleform : ''),
            
              jam_event:  event && (event.jam_event  ?  new Date(event.tanggal_event+" "+event.jam_event): ''),
              tanggal:  event && (event.tanggal_event  ?  new Date(event.tanggal_event) : ''),
                images_id: event && (event.images[0].id ? event.images[0].id : '')
            });
        }else{
            setFormData({
              nama_event: event && (event.nama_event ? event.nama_event : ''),
              sosmed_url: event && (event.url_sosmet ? event.url_sosmet : ''),
              tempat: event && (event.tempat ? event.tempat : ''),
              alamat: event && (event.address ? event.address : ''),
              map_url: event && (event.map_url ? event.map_url : ''),
              url_googleform: event && (event.url_googleform ? event.url_googleform : ''),
              jam_event:  event && (event.jam_event  ?  new Date(event.tanggal_event+" "+event.jam_event): ''),
              tanggal:  event && (event.tanggal_event  ?  new Date(event.tanggal_event) : ''),
                images_id: '0',
            });
        }

        setKeterangan((event && (event.keterangan ? event.keterangan : '')));
        setValues({ status: event && (event.active.toString()) });

	}, [loading, event]);

    useEffect(() => {
      if(event && (event.images[0])){
            setFileName(event && (event.images[0].nama_image ? event.images[0].nama_image : ''));
            console.log('NAMA: ' + fileName);
            setImagePreviewUrl((event && (event.images[0].tautan ? event.images[0].tautan : ''))+fileName);
        }else{
            setImagePreviewUrl(defaultImage);
        }

    }, [loading, event, fileName]);

    useEffect(() => {
      getEventById(Buffer.from(id, 'base64').toString('ascii'));
    }, [getEventById, id]);

   

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const setStatus = (event) => {
      //console.log(event.target.value);
      setValues({ ...values, status: event.target.value });
      //console.log(values);
    };
      function uploadImageCallBack(file, uploadHandler) {
        const uploadFile = file[0];
        console.log('KESINI: ' + uploadFile);
        return new Promise(
          (resolve, reject) => {
            const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
            xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
            xhr.setRequestHeader('x-auth-token', token);
            const data = new FormData(); // eslint-disable-line no-undef
            data.append('file', uploadFile);
            data.append("tautan", API_URL_IMAGE + '/ckeditor/')
            xhr.send(data);
            xhr.addEventListener('load', () => {
              const response = JSON.parse(xhr.responseText);
              console.log('OK: ' + response);
              uploadHandler(response);
              resolve(response);
            });
            xhr.addEventListener('error', () => {
              const error = JSON.parse(xhr.responseText);
              reject(error);
            });
          },
        );
      }
      const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
  
      };
      const handleDateChange = e => {
        setFormData({ ...formData,
            tanggal: new Date(e)
        });
      }
      const handleDatePendaftaranChange = e => {
        setFormData({ ...formData,
          jam_event: new Date(e)
        });
      }
  
    const onSubmit = async (e) => {
        e.preventDefault();
        // setLoadings(true);
        const data = new FormData();
        data.append("images_event", file);
        data.append("nama_event", formData.nama_event);
        data.append("tanggal_event", moment(formData.tanggal).format('YYYY-MM-DD'));
         data.append("jam_event",moment(formData.jam_event).format("HH:mm"));
        data.append("keterangan", keterangan);
         data.append("url_sosmet", formData.sosmed_url);
         data.append("address", formData.alamat);
         data.append("map_url", formData.map_url);
         data.append("tempat", formData.tempat);
         data.append("url_googleform", formData.url_googleform);
        data.append("active", values.status);
        updateEvent(data, Buffer.from(id, 'base64').toString('ascii'), history);
    }

    function handleImageUploadBefore(files, info, uploadHandler){
        // uploadHandler is a function
      console.log('UPLOAD: ' + files, info);
      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString());
      }
    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/pipka/event" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardPIPKA">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Event Pipka</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Event {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>
                         
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                      <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Nama Event *</label>
                          
                         
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <input type="text" className="form-control" required placeholder="Nama Event ..." name="nama_event" value={nama_event} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    
                    <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tempat</label>
                          
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Tempat ..." name="tempat" value={tempat} onChange={e => onChange(e)} />
                      </div>
                      </div>
                      </div>
                      <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Alamat</label>
                          
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                    <textarea className="form-control" rows="3"  placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tanggal Event</label>
                          <div class="jss213-small">Tanggal Event  </div>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal"
                                    dateFormat={moment(tanggal).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal}
                                    onChange={handleDateChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Jam Event</label>
                          <div class="jss213-small">Jam Event  </div>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                        <DatePicker
                                selected={jam_event}
                                onChange={(date) => handleDatePendaftaranChange(date)}
                                showTimeSelect
                                className="form-control"
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onFocus={e => e.target.blur()}
                              />

                                </div>
                        </div>
                      </div>
                    </div>
                      <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Google Map Url</label>
                          
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Google Map ..." name="map_url" value={map_url} onChange={e => onChange(e)} />
                      </div>
                      </div>
                      </div>
                      <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Sosial Media</label>
                        <div class="jss213-small">Youtube,Facebook,Instagram,Twitter  </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Url Sosial Mesia ..." name="sosmed_url" value={sosmed_url} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Google Form</label>
                       
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Google Form ..." name="url_googleform" value={url_googleform} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    </div>
                <div className="row">
                <div className="col-sm-3"> 
                    <div className="form-group">
                    <label>Keterangan</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <SunEditor
                          autoFocus={true}
                          lang="en"
                          width='100%'
                          height='450px'
                          setOptions={{
                            minWidth : '450px',
                            showPathLabel: false,
                            placeholder: "Enter your text here!!!",
                            plugins: [
                              align,
                              font,
                              fontColor,
                              fontSize,
                              formatBlock,
                              hiliteColor,
                              horizontalRule,
                              lineHeight,
                              list,
                              paragraphStyle,
                              table,
                              template,
                              textStyle,
                              image,
                              link
                            ],
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize", "formatBlock"],
                              ["paragraphStyle"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                              ],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              "/", // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["table", "link", "image"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                              "Arial",
                              "Calibri",
                              "Comic Sans",
                              "Courier",
                              "Garamond",
                              "Georgia",
                              "Impact",
                              "Lucida Console",
                              "Palatino Linotype",
                              "Segoe UI",
                              "Tahoma",
                              "Times New Roman",
                              "Trebuchet MS"
                            ]
                          }}
                          getSunEditorInstance={getSunEditorInstance}
                          onImageUploadBefore={handleImageUploadBefore}
                          setContents={keterangan}
                          onChange={setKeterangan}

                        />
                   </div>
                   <div style={{zIndex: -999, position: 'absolute'}}>
                        <SunEditorAtom initialContent={keterangan}/>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                <div className="col-sm-3"> 
                    <div className="form-group">
                    <label>Gambar</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div className="imgPreviewUpload">
                          <img className="imgPreview" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                   </div>
                  </div>
                </div>
                


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditEvent.propTypes = {
  updateEvent: PropTypes.func.isRequired,
    getEventById: PropTypes.func.isRequired,
   
}
const mapStateToProps = state => ({
    auth: state.auth,
    pipka: state.pipka,
});

export default connect(mapStateToProps, { updateEvent, getEventById })(withRouter(EditEvent));
