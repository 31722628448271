import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import parse from 'html-react-parser';

const FirmanPipka = ({ auth: { user, token } }) => {
    const [dataFirman, setFirman] = useState([]);
    const [status, setStatus] = useState(true);
    const deleteData = (id) => {
        let url2 = `https://gkmi.one:8080/api/firman/firman/${id}`
        axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
            const del = dataFirman.filter(tableRow => id !== tableRow._id)
            setFirman(del);
        })
      }

      useEffect(() =>{
        fetchData();
   }, [user]);

   const fetchData = async () => {
       let codeChurch = '';
        if (user && (user.level_user === 10)) {
            codeChurch = user && (user.kode_gereja);
        }else{
            codeChurch = user && (user.gereja[0].kode_gereja);
        }
        console.log('AA: ' + codeChurch);
        let link='';
        let codeMasterChurch2 = 0;
        link = `https://gkmi.one:8080/api/firman/gereja/${codeChurch}`;
      const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
      setFirman(response.data);

   }

           const data = {
              columns: [
                {
                    label: "No.",
                    field: "no",
                    sort: "asc",
                    width: 10,
                },
                {
                    label: "Image",
                    field: "nama_image",
                    sort: "asc",
                    width: 150,
                },
                // {
                //     label: "Kode Gereja",
                //     field: "kode_gereja",
                //     sort: "asc",
                //     width: 150,
                // },
                {
                    label: "Judul Firman",
                    field: "judul_firman",
                    sort: "asc",
                    width: 150,
                },
                // {
                //     label: "Isi Firman",
                //     field: "isi_firman",
                //     sort: "asc",
                //     width: 150,
                // },
                {
                    label: "Link Youtube",
                    field: "link_url",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Kontributor",
                    field: "kontributor_nama",
                    sort: "asc",
                    width: 150,
                },
                // {
                //     label: "Urutan",
                //     field: "urutan",
                //     sort: "asc",
                //     width: 150,
                // },
                {
                    label: "Action",
                    field: "action",
                    width: 150,
                },
              ],
              rows: dataFirman.map((apiData, i) => {
                var content = apiData.isi_firman;
                var fixedHTML = "";
                if(content !== undefined){
                    fixedHTML = parse(content);
                }
                let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
                return({
                  no: (i+1),
                  nama_image: <center><img src={(apiData.nama_image !== "" ? (apiData.nama_image !== undefined ? apiData.tautan + apiData.nama_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
                  className="profile-user-img img-fluid img-circle"
                  alt="Image" /></center>,
             
                  judul_firman: <span>{apiData.judul_firman}</span>,
             
                  link_url: <span>{apiData.link_url}</span>,
                  kontributor_nama: <span>{apiData.kontributor_nama}</span>,
               
                  action: <span><Link className="btn btn-block bg-gradient-primary" to={`/pipka/firman/edit-firman/${base64EncodeString}`}>Ubah</Link>
                  <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData._id) } }>Hapus</a></span>
                });
              }),

            };

    

    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <Link to='/pipka/firman/create-firman' className="btn btn-block bg-gradient-green  ">Tambah Data Firman</Link>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardPIPKA">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Firman</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Firman */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Firman</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(FirmanPipka);
