import React, { Fragment, useEffect,useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import axios from 'axios';
import { getDashbordSinode } from "../../actions/dashboardAction";

const DashboardSinode = ({ dashboard, getDashbordSinode, auth: { user,token }  }) => {
  const [beritaTop, setBeritaTop] = useState([]);
  const [dataPgmw, setPGMW] = useState([]);
  useEffect(() => {
    getDashbordSinode();
    fetchDataBeritatop();
    fetchDataPGMW();
  }, user);
  const fetchDataPGMW = async () => {

    let link='';
      link = 'https://gkmi.one:8080/api/dashboard/sinode_pgw';
    //  link = 'http://localhost:8080/api/dashboard/dashboardBerita';
    const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
      });

      setPGMW(response.data);
  }
  const dataSinodePgmw = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 10,
    },
      {
          label: "Nama",
          field: "nama",
          sort: "asc",
          width: 150,
      },
      {
        label: "Gereja",
        field: "gereja",
        sort: "asc",
        width: 100,
    },
    {
      label: "Anggota",
      field: "anggota",
      sort: "asc",
      width: 100,
  },
  {
    label: "Simpatisan",
    field: "simpatisan",
    sort: "asc",
    width: 100,
},
{
  label: "Gues",
  field: "gues",
  sort: "asc",
  width: 100,
},




    ],
    rows: dataPgmw.map((apiData, i) => {
      return({
        no: (i+1),
          nama: <span>{apiData.nama}</span>,
          gereja:<span>{apiData.gereja}</span>,
          anggota:<span>{apiData.anggota}</span>,
          simpatisan:<span>{apiData.simpatisan}</span>,
          gues:<span>{apiData.gues}</span>,

    });
  }),
  };
  const fetchDataBeritatop = async () => {

    let link='';
      link = 'https://gkmi.one:8080/api/dashboard/dashboardSinodeFirman';
    //  link = 'http://localhost:8080/api/dashboard/dashboardBerita';
    const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
      });

      setBeritaTop(response.data);
  }
  const dataBeritaTop = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 10,
    },
      {
          label: "Kategori",
          field: "nama",
          sort: "asc",
          width: 150,
      },
      {
        label: "Publish",
        field: "publish",
        sort: "asc",
        width: 100,
    },
    {
      label: "Tulisan",
      field: "tulisan",
      sort: "asc",
      width: 100,
  },
  {
    label: "Youtube",
    field: "youtube",
    sort: "asc",
    width: 100,
  },
  {
    label: "View",
    field: "view",
    sort: "asc",
    width: 100,
  },



    ],
    rows: beritaTop.map((apiData, i) => {

      let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');

      return({
        no: (i+1),
          nama: <span>{apiData.nama_kategori}</span>,
          publish:<span>{apiData.publish}</span>,
          tulisan:<span>{apiData.tulisan}</span>,
          youtube:<span>{apiData.youtube}</span>,
          view:<span>{apiData.view}</span>,
    });
  }),
  };
  return (
    <Fragment>
      <Header />
      <Navbar />

      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        {/* Main content */}
        <section className="content">

          <div class="container-fluid">

            <div class="row">
            <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.anggota)}</h3>
                    <p style={{color: 'white'}}>Anggota</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.simpatisan)}</h3>
                    <p style={{color: 'white'}}>Simpatisan</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.guest)}</h3>
                    <p style={{color: 'white'}}>Guest</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.pemimpin)}</h3>
                    <p style={{color: 'white'}}>Pemimpin Gereja</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

            </div>
            <div class="row">
            <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.gereja)}</h3>
                    <p style={{color: 'white'}}>Gereja Dewasa</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-university"></i>
                  </div>
                   <a href="/dashboardSinode/gereja-dewasa" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.gereja_cabang)}</h3>
                    <p style={{color: 'white'}}>Gereja Cabang</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-home"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.connect)}</h3>
                    <p style={{color: 'white'}}>Connect</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <a href="/dashboardSinode/connect" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.toko)}</h3>
                    <p style={{color: 'white'}}>Karya Toko</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-store"></i>
                  </div>
                 <a href="/dashboardSinode/karya" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>


            </div>
            <div class="row">

            <div className="col-sm-8">
            <div className="card">
                  <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                  <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>PGMW</h3>
                  <div className="card-tools">
                  </div>
                  </div>
                  <div className="card-body">
                  <MDBDataTable responsive striped bordered hover data={dataSinodePgmw} />
              </div>
            </div>
            </div>
            <div class="col-md-4">

           <div class="info-box mb-3 bg-warning">
             <span class="info-box-icon"><i class="fas fa-tag"></i></span>
             <div class="info-box-content">
               <span class="info-box-text">Kegiatan Ibadah</span>
               <span class="info-box-number">0</span>
             </div>
             </div>
             <div class="info-box mb-3 bg-info">
             <span class="info-box-icon"><i class="far fa-comment"></i></span>

             <div class="info-box-content">
               <span class="info-box-text">Ibadah Gereja</span>
               <span class="info-box-number">0</span>
             </div>

           </div>
             <div class="info-box mb-3 bg-success">
             <span class="info-box-icon"><i class="far fa-heart"></i></span>

             <div class="info-box-content">
               <span class="info-box-text">Firman Gereja</span>
               <span class="info-box-number">0</span>
             </div>
           </div>
           <a href="/dashboardSinode/gereja-dewasa" class="small-box-footer">
           <div class="info-box mb-3 bg-danger">
             <span class="info-box-icon"><i class="fas fa-cloud-download-alt"></i></span>

             <div class="info-box-content">
               <span class="info-box-text">Berita Gereja</span>
               <span class="info-box-number">{dashboard.userCount && (dashboard.userCount.berita)}</span>
             </div>
           </div>
 </a>
           </div>

            </div>
            <div class="row">

            <div className="col-sm-12">
          <div className="card">
                  <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                  <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Firman</h3>
                  <div className="card-tools">
                  </div>
                  </div>
                  <div className="card-body">
                  <MDBDataTable responsive striped bordered hover data={dataBeritaTop} />
              </div>
            </div>
            </div>
            </div>


          </div>

        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </Fragment>
  );
};


DashboardSinode.propTypes = {
  dashboard: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  auth: state.auth
});

export default connect(mapStateToProps, { getDashbordSinode })(DashboardSinode);
