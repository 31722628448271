import axios from "../util/http-common";

import { GET_COUNT_USER, COUNT_USER_ERROR } from "./types";

// Get Count User
export const getCountUser = () => async dispatch => {
    try{
        const res = await axios.get(`/api/dashboard/count`);
        console.log(JSON.stringify(res.data));
        dispatch({
            type: GET_COUNT_USER,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: COUNT_USER_ERROR,
			payload: { msg: err.message, status: err }

        });
    }
}
export const getDashbordBeritaGkmi = () => async dispatch => {
    try{
        const res = await axios.get(`/api/dashboard/beritaGkmi`);
        console.log(JSON.stringify(res.data));
        dispatch({
            type: GET_COUNT_USER,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: COUNT_USER_ERROR,
			payload: { msg: err.message, status: err }

        });
    }
}
export const getDashbordSinode = () => async dispatch => {
    try{
        const res = await axios.get(`/api/dashboard/dashboardSinode`);
        console.log(JSON.stringify(res.data));
        dispatch({
            type: GET_COUNT_USER,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: COUNT_USER_ERROR,
			payload: { msg: err.message, status: err }

        });
    }
}

// Get Count User
export const getDashbordFirman = () => async dispatch => {
    try{
        const res = await axios.get(`/api/dashboard/dashboardFirman`);
        console.log(JSON.stringify(res.data));
        dispatch({
            type: GET_COUNT_USER,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: COUNT_USER_ERROR,
			payload: { msg: err.message, status: err }

        });
    }
}
// Get Count User
export const getDashbordPipka = () => async dispatch => {
    try{
        const res = await axios.get(`/api/dashboard/dashboardPipka`);
        console.log(JSON.stringify(res.data));
        dispatch({
            type: GET_COUNT_USER,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: COUNT_USER_ERROR,
			payload: { msg: err.message, status: err }

        });
    }
}
