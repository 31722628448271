import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { updateLangganan } from "../../../actions/beritaGkmiAction";
import { getLanggananById } from "../../../actions/beritaGkmiAction";

const EditBeritaGkmiKategori = ({ beritaGkmi: { loading, langganan }, getLanggananById, updateLangganan, auth: { user }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [loadings, setLoadings] = useState(false);
    const [values, setValues] = useState({
        status: '1',
      });
    const [formData, setFormData] = useState({
        nama:'',
    });

    useEffect(() => {
        setFormData({
            nama: langganan && (langganan.nama ? langganan.nama : ''),
            keterangan: langganan && (langganan.keterangan ? langganan.keterangan : ''),
            harga: langganan && (langganan.harga ? langganan.harga : ''),
            duration: langganan && (langganan.duration ? langganan.duration : ''),
            slot: langganan && (langganan.slot ? langganan.slot : ''),
            status: langganan && (langganan.active ? langganan.active : ''),
             urutan: langganan && (langganan.urutan ? langganan.urutan : ''),
        });
        setValues({ status: langganan && (langganan.active.toString()) });
    }, [loading, langganan]);

    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
      //  getBeritaGkmiKategoriById(Buffer.from(id, 'base64').toString('ascii'));
      //console.log("id "+decodeString.split(';')[0])
      getLanggananById( decodeString.split(';')[0]);
      }, [getLanggananById, id]);

    const {
        nama,
        keterangan,
        harga,
        duration,
       slot,
       urutan
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);

        const data = {
            "nama": formData.nama,
            "keterangan": formData.keterangan,
            "harga": formData.harga,
            "duration": formData.duration,
            "slot": formData.slot,
            "active": values.status,
             "urutan": formData.urutan,
          }
          updateLangganan(data,  Buffer.from(id, 'base64').toString('ascii'), history);
     //   updateBeritaGkmiKategori(formData, Buffer.from(id, 'base64').toString('ascii'), history);
    }
    const setStatus = (event) => {
        //console.log(event.target.value);
        setValues({ ...values, status: event.target.value });
        //console.log(values);
      };
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI/langganan" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Kategori Berita</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Kategori Berita {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                              <label>Status *</label>

                                            </div>
                                          </div>
                                        <div className="col-sm-9">
                                            <div className="form-group">
                                            <div onChange={setStatus}>
                                                    <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                                                </div>
                                            </div>
                                          </div>
                                          </div>

                                          <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                        <label>Urutan  </label>
                                        <div class="jss213-small">Urutan bedarkan ASC  </div>
                                        </div>
                                      </div>
                                    <div className="col-sm-9">
                                    <div className="form-group">
                                    <select className="form-control" required name="urutan" value={urutan} onChange={e => onChange(e)}>
                                          <option value="">--Pilih--</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>

                                          </select>
                                       </div>
                                      </div>
                                    </div>

                    <div className="row">
               <div className="col-sm-3">
                   <div className="form-group">
                   <label>Nama</label>
                   </div>
                 </div>
               <div className="col-sm-9">
               <div className="form-group">
               <input type="text" className="form-control" placeholder="Nama ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                  </div>
                 </div>
               </div>
               <div className="row">
               <div className="col-sm-3">
                   <div className="form-group">
                   <label>Durasi</label>
                   </div>
                 </div>
               <div className="col-sm-9">
               <div className="form-group">
               <select className="form-control" required name="duration" value={duration} onChange={e => onChange(e)}>
                         <option value="">--Pilih--</option>
                         <option value="1">1 Bulan</option>
                           <option value="2">2 Bulan</option>
                           <option value="3">3 Bulan</option>
                           <option value="4">4 Bulan</option>
                           <option value="5">5 Bulan</option>
                           <option value="6">6 Bulan</option>
                           <option value="7">7 Bulan</option>
                           <option value="8">8 Bulan</option>
                           <option value="9">9 Bulan</option>
                           <option value="10">10 Bulan</option>
                           <option value="11">11 Bulan</option>
                           <option value="12">12 Bulan</option>
                         </select>
                  </div>
                 </div>
               </div>
               <div className="row">
               <div className="col-sm-3">
                   <div className="form-group">
                   <label>Jumlah User</label>
                   </div>
                 </div>
               <div className="col-sm-9">
               <div className="form-group">
               <select className="form-control" required name="slot" value={slot} onChange={e => onChange(e)}>
                           <option value="">--Pilih--</option>
                           <option value="1">1 User</option>
                           <option value="2">2 User</option>
                           <option value="3">3 User</option>
                           <option value="4">4 User</option>
                           <option value="5">5 User</option>

                         </select>
                  </div>
                 </div>
               </div>
               <div className="row">
               <div className="col-sm-3">
                   <div className="form-group">
                   <label>Keterangan</label>
                   </div>
                 </div>
               <div className="col-sm-9">
               <div className="form-group">
               <input type="text" className="form-control" placeholder="Keterangan ..."  name="keterangan" value={keterangan} onChange={e => onChange(e)} />
                  </div>
                 </div>
               </div>

               <div className="row">
               <div className="col-sm-3">
                   <div className="form-group">
                   <label>Harga</label>
                   </div>
                 </div>
               <div className="col-sm-9">
               <div className="form-group">
               <input type="number" className="form-control" placeholder="Harga ..." required name="harga" value={harga} onChange={e => onChange(e)} />
                  </div>
                 </div>
               </div>


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditBeritaGkmiKategori.propTypes = {
  updateLangganan: PropTypes.func.isRequired,
    getLanggananById: PropTypes.func.isRequired,
    // beritaGkmi: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    beritaGkmi: state.beritaGkmi
});

export default connect(mapStateToProps, { updateLangganan, getLanggananById })(withRouter(EditBeritaGkmiKategori));
