import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/img_store.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getActionKegiatanId } from "../../../actions/actionKegiatan";
import { updateActionKegiatan } from "../../../actions/actionKegiatan";

const EditActionKegiatan = ({ action: { actionKegiatan, loading }, updateActionKegiatan,getActionKegiatanId, auth: { user, token }, history }) => {
 const [multipleFiles, setMultipleFiles] = useState()
const {id} = useParams();
const [idAction, setIdAction] = useState();
const [idActionKegiatan, setIdActionKegiatan] = useState();
const [loadings, setLoadings] = useState(false);
const [values, setValues] = useState({
  status: '1',
});

    // const [value, setValue] = useState('');

    const [formData, setFormData] = useState({
      tanggal: new Date(),
      jam_mulai: new Date(),
      jam_selesai: new Date(),
    });
    const {
      tanggal,jam_mulai,jam_selesai,nama_kegiatan,keterangan,link_url,no_phone
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    useEffect(() => {
      setFormData({
        nama_kegiatan:  actionKegiatan && (actionKegiatan.nama_categori ? actionKegiatan.nama_categori : ''),

          tanggal:  actionKegiatan && (actionKegiatan.tanggal  ?  new Date(actionKegiatan.tanggal) : ''),
          jam_mulai:  actionKegiatan && (actionKegiatan.jam_mulai  ?  new Date(actionKegiatan.tanggal+" "+actionKegiatan.jam_mulai): ''),

        jam_selesai:  actionKegiatan && (actionKegiatan.jam_selesai  ?  new Date(actionKegiatan.tanggal+" "+actionKegiatan.jam_selesai): ''),
         keterangan:  actionKegiatan && (actionKegiatan.keterangan ? actionKegiatan.keterangan : ''),
         status: actionKegiatan && (actionKegiatan.status ? actionKegiatan.status : ''),
         no_phone: actionKegiatan && (actionKegiatan.no_telepon ? actionKegiatan.no_telepon : ''),
        link_url: actionKegiatan && (actionKegiatan.link_url ? actionKegiatan.link_url : ''),

      });
      setValues({ status: actionKegiatan && (actionKegiatan.status.toString()) });

      // var thistime = "15:00";
      //  var res = moment(thistime, "hh:mm:").format("DD-MM-YYYY HH:mm:ss");


     // console.log('STATUS: ' + (actionKegiatan && (actionKegiatan.status ? actionKegiatan.status : '')));
    //console.log('MASUK SINI 1f: ' , actionAcara.nama_action.toString);
    //var content = karyaproduk && (karyaproduk.deskripsi_produk ? karyaproduk.deskripsi_produk : '');


  }, [loading, actionKegiatan]);

    useEffect(() => {
      let decodeString = Buffer.from(id, 'base64').toString('ascii');

      console.log("base::: jj " + decodeString.split(';')[0]);

      console.log("base::: kkk " + decodeString.split(';')[1]);
      getActionKegiatanId( decodeString.split(';')[1]);
      setIdAction(decodeString.split(';')[0]);
      setIdActionKegiatan(decodeString.split(';')[1]);
    }, [getActionKegiatanId, idAction,idActionKegiatan]);

//   useEffect(() => {
//     let decodeString3 = Buffer.from(id, 'base64').toString('ascii');
//     setIdAction(decodeString3.split(';')[0]);
//    // console.log("VAL a : " + decodeString3.split(';')[0]);
// }, [id]);
const setStatus = (event) => {
  //console.log(event.target.value);
  setValues({ ...values, status: event.target.value });
  //console.log(values);
};
useEffect(() => {
  console.log("VAL: " + values.status);

}, [values]);


    const onSubmit = async (e) => {
        e.preventDefault();
        //setLoadings(true);
        const data = {
          "nama_kegiatan": formData.nama_kegiatan,
          "tanggal": moment(formData.tanggal).format('YYYY-MM-DD'),
          "jam_mulai":moment(formData.jam_mulai).format("HH:mm"),
          "jam_selesai": moment(formData.jam_selesai).format("HH:mm"),
          "keterangan": formData.keterangan,
          "status": values.status,
          "no_telepon": formData.no_phone,
                   "link_url": formData.link_url,

        }
        updateActionKegiatan(data, idActionKegiatan,idAction, history);
      // createAction(data, history);
    }


  const handleDateChange = e => {
    setFormData({ ...formData,
      tanggal: new Date(e)
    });
  }
  const handleDatePendaftaranChange = e => {
    setFormData({ ...formData,
      jam_mulai: new Date(e)
    });
  }
  const handleDateAhirPendaftaranChange = e => {
    setFormData({ ...formData,
      jam_selesai: new Date(e)
    });
  }
  const MultipleFileChange = (e) => {
    setMultipleFiles(e.target.files);
   // setMultipleProgress(0);
  }
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">

                    <Link to={`/action/kegiatan/${idAction}`} className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Action Kegiatan</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Tambah Kegiatan</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                  <div className="card">
                    <div className="card-body">

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>
                          <div class="jss213-small">Pilihan kegiatan akan muncul di pendaftaran peserta dan memilih kegiatan yang akan di ikuti</div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Wajib &nbsp;&nbsp;&nbsp;&nbsp;(Peserta ketika daftar tidak memilih kegiatan ini dan automatis memgikutinya)&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Wajib &nbsp;&nbsp;(Peserta ketika daftar  memilih kegiatan mana yang akan di ikuti)
                            </div>
                        </div>
                      </div>
                      </div>


                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Nama Kegiatan *</label>


                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <input type="text" className="form-control" required placeholder="Nama Kegiatan ..." name="nama_kegiatan" value={nama_kegiatan} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tanggal Kegiatan *</label>
                          <div class="jss213-small">Tanggal Kegiatan  </div>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal"
                                    dateFormat={moment(tanggal).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal}
                                    onChange={handleDateChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Jam Mulai *</label>

                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                        <DatePicker
                                selected={jam_mulai}
                                onChange={(date) => handleDatePendaftaranChange(date)}
                                showTimeSelect
                                className="form-control"
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                               dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onFocus={e => e.target.blur()}
                              />

                                </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Jam Selesai *</label>

                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                        <DatePicker
                                selected={jam_selesai}
                                onChange={(date) => handleDateAhirPendaftaranChange(date)}
                                showTimeSelect
                                className="form-control"
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                               dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onFocus={e => e.target.blur()}
                              />

                                </div>
                        </div>
                      </div>
                    </div>





                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Keterangan *</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <textarea className="form-control" rows="5" required placeholder="Keterangan ..." name="keterangan" value={keterangan} onChange={e => onChange(e)}></textarea>
                      </div>
                    </div>

                    </div>
                   </div>

                   <div className="card">
                                 <div className="card-body">
                                 <div className="row">
                                     <div className="col-sm-12">
                                       <div className="form-group">
                                         <label>Materi Kegiatan</label>
                                       </div>
                                     </div>
                                     </div>
                                     <div className="row">
                                 <div className="col-sm-3">
                                     <div className="form-group">
                                     <label>Hubungi Kami</label>
                                     <div class="jss213-small">Sarana Komunikasi ketika kegiatan berlangsung media whatsapp</div>
                                     </div>
                                   </div>
                                 <div className="col-sm-9">
                                 <div className="form-group">
                                   <input type="text"  className="form-control"  placeholder=" No Hanphone..." name="no_phone" value={no_phone} onChange={e => onChange(e)} />
                                   </div>
                                   </div>
                                 </div>
                                 <div className="row">
                                 <div className="col-sm-3">
                                     <div className="form-group">
                                     <label>Link Url</label>
                                     <div class="jss213-small">Link Zoom/Google Meet/ Web Site</div>
                                     </div>
                                   </div>
                                 <div className="col-sm-9">
                                 <div className="form-group">
                                   <input type="text"  className="form-control"  placeholder="Link Url  ..." name="link_url" value={link_url} onChange={e => onChange(e)} />
                                   </div>
                                 </div>
                                 </div>



                                 <div className="row">
                                 <div className="col-sm-3">
                                     <div className="form-group">
                                     <label>Materi Kegiatan</label>
                                     <div class="jss213-small">Materi penunjang Kegiatan</div>
                                     </div>
                                   </div>
                                 <div className="col-sm-9">
                                 <div className="form-group">
                                 <input type="file" multiple onChange={MultipleFileChange} />



                                   </div>
                                   </div>
                                 </div>

                                 </div>
                                 </div>
















                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditActionKegiatan.propTypes = {
  updateActionKegiatan: PropTypes.func.isRequired,
  getActionKegiatanId: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({
    auth: state.auth,
    action: state.action,

});

export default connect(mapStateToProps, { updateActionKegiatan,getActionKegiatanId})(withRouter(EditActionKegiatan));
