import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Alert from "../../alert/Alert";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ListGereja3 from "./ListGereja";
import { getGereja } from "../../../actions/gerejaAction";

const Gereja = ({ gereja, auth: { user } }) => { 

  function cekButtonRegistrasi(){
    if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
      return (
        <Fragment>
        <div className="col-sm-4">
          <Link to='/gereja/create-gereja' className="btn btn-block bg-gradient-success">Registrasi Gereja</Link>
        </div>
		{/*
		<div className="col-sm-4">
          <Link to='/gereja/edit-gereja' className="btn btn-block bg-gradient-primary">Ubah Gereja</Link>
        </div>*/}
        <div className="col-sm-8">
          <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Gereja</li>
          </ol>
        </div>
        </Fragment>
      );
    }
    return(
      <Fragment>
      {/*<div className="col-sm-4">
        <Link to='/gereja/edit-gereja' className="btn btn-block bg-gradient-primary">Ubah Gereja</Link>
      </div>*/}
      <div className="col-sm-5">
      <Link to='/gereja/create-gereja' className="btn btn-block bg-gradient-success">Tambah Gereja Cabang/POS</Link>
      </div>
      <div className="col-sm-7">
        <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">Gereja</li>
        </ol>
      </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Header />
      <Navbar />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">              
            {cekButtonRegistrasi()}              
            </div>
            </div>
        </section>
        {/* Main content */}
        <section className="content">
        <div className="container-fluid">
          {/* Display Gereja */}
          <div className="card">
            <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
              <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Gereja</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fas fa-minus" />
                </button>
               
              </div>
            </div>
            <div className="card-body">
              <Alert />
              {/*<ListGereja gerejaData={gereja.gereja} />*/}
              <ListGereja3 />
            </div>
            {/* /.card-body */}
            
          </div>
          </div>
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </Fragment>
  );
};

Gereja.propTypes = {  
  gereja: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  gereja: state.gereja,
  auth: state.auth
});

export default connect(mapStateToProps, { getGereja })(Gereja);
