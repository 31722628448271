import { GET_FIRMAN_CATEGORY, GET_FIRMAN_CATEGORY2, GET_FIRMAN, FIRMAN_CATEGORY_ERROR, FIRMAN_ERROR } from "../actions/types";

const initialState = {
    firmanCategory: null,
    firmanCategory2: [],
    firman: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_FIRMAN_CATEGORY:
            return{
                ...state,
                firmanCategory: payload,
                loading: false
            };
        case GET_FIRMAN_CATEGORY2:
            return{
                ...state,
                firmanCategory2: payload,
                loading: false
            };
        case GET_FIRMAN:
            return{
                ...state,
                firman: payload,
                loading: false
            };
        case FIRMAN_CATEGORY_ERROR:
        case FIRMAN_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
