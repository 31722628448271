import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";

import {useFormik} from 'formik';
import * as Yup from 'yup';
import { createConnect } from "../../../actions/connectAction";
import { getLeader } from "../../../actions/connectAction";

const CreateConnect = ({ createConnect,connect,getLeader, auth: { user, token }, history }) => {
  
  const [loadings, setLoadings] = useState(false);
  const [formData, setFormData] = useState({
    tanggal: new Date(),
      jam_mulai: new Date(),
      jam_selesai: new Date(),
      nama_connect:'',
      alamat:'',
      kota:'',
      hari:'',
      latitude:'',
      longitude:'',
      kode_leader:'',
      
  });

  const {
    nama_connect,
    alamat,
    kota,
    hari,
    latitude,
    longitude,
    tanggal,
    jam_mulai,
    jam_selesai,
    kode_leader,
  } = formData;
  const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
  useEffect(() => {
    getLeader();
}, [getLeader]);
  const handleDateChange = e => {
    setFormData({ ...formData,
        tanggal: new Date(e)
    });
  }
  const handleJamMulaiChange = e => {
    setFormData({ ...formData,
      jam_mulai: new Date(e)
    });
  }

  const handleJamSelesaiChange = e => {
    setFormData({ ...formData,
      jam_selesai: new Date(e)
    });
  }
  const handleLeaderChange = e => {
    setFormData({ ...formData,
      kode_leader: e.target.options[e.target.selectedIndex].value,
        nama: e.target.options[e.target.selectedIndex].text
    });
  }
  const onSubmit = async (e) => {
      e.preventDefault();
      //setLoadings(true);
    
      const data = new FormData();
     
      data.append("nama_connect", formData.nama_connect);
        data.append("kota", formData.kota);
        data.append("alamat", formData.alamat);
        data.append("hari", formData.hari);
        data.append("latitude", formData.latitude);
        data.append("longitude", formData.longitude);
        data.append("tanggal", moment(formData.tanggal).format('YYYY-MM-DD'));
        data.append("jam_mulai", moment(formData.jam_mulai).format("HH:mm"));
        data.append("jam_selesai", moment(formData.jam_selesai).format("HH:mm"));
        data.append("kode_leader", formData.kode_leader);
    
     
    
     
        createConnect(data, history);
       
    
  }

  
 
  
  return(
      <Fragment>
      <Header />
      <Navbar />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
          {/* Content fileHeader (Page header) */}
          <section className="content-header">
          <div className="container-fluid">
              <div className="row mb-2">
              <div className="col-sm-2">
                  <Link to="/connect" className="btn btn-block bg-gradient-danger">Back</Link>
              </div>
              <div className="col-sm-10">
                  <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                  <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Connect</li>
                  </ol>
              </div>
              </div>
              </div>
          </section>
          {/* Main content */}
          <section className="content">
          <div className="container-fluid">
          {/* Default box */}
          <div className="card">
          <form onSubmit={(e) => onSubmit(e)}>
              <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
              <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Tambah Connect </h3>
              <div className="card-tools">
                  <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                  >
                  <i className="fas fa-minus" />
                  </button>
              </div>
              </div>
              <div className="card-body">
                  <Alert />
                  
                    <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Leader</label>
                  </div>
                </div>
              <div className="col-sm-9">
              
              <div className="form-group">
                               
                                <select className="form-control" required name="kode_leader" value={kode_leader} onChange={handleLeaderChange}>
                                    <option value="">--Pilih--</option>
                                        {
                                            connect && connect.connectLeader.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama}</option>)
                                        }
                                </select>
                            </div>
                 </div>
              
              </div>
              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Nama Connect</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="Nama Connect ..." required name="nama_connect" value={nama_connect} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>
             
              <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Alamat</label>
                          
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                    <textarea className="form-control" rows="3"  placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                      </div>
                      </div>
                      </div>
              
              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Kota</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="Kota ..." required name="kota" value={kota} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Hari</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="Hari ..." required name="hari" value={hari} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>
              
              <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tanggal</label>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal"
                                    dateFormat={moment(tanggal).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal}
                                    onChange={handleDateChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Jam Mulai</label>
                        
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                        <DatePicker
                                selected={jam_mulai}
                                onChange={(date) => handleJamMulaiChange(date)}
                                showTimeSelect
                                className="form-control"
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onFocus={e => e.target.blur()}
                              />
                                </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Jam Selesai</label>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                        <DatePicker
                                selected={jam_selesai}
                                onChange={(date) => handleJamSelesaiChange(date)}
                                showTimeSelect
                                className="form-control"
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onFocus={e => e.target.blur()}
                              />

                                </div>
                        </div>
                      </div>
                    </div>

              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Latitude</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="latitude ..." required name="latitude" value={latitude} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>

              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Longitude</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="Longitude ..." required name="longitude" value={longitude} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>

              </div>
              {/* /.card-body */}
              <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
              </div>
              {/* /.card-footer*/}
              </form>
          </div>
          {/* /.card */}

          </div>
          </section>
          {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
      </Fragment>
  )
};

CreateConnect.propTypes = {
  createConnect: PropTypes.func.isRequired,
getLeader: PropTypes.func.isRequired,
  
}
const mapStateToProps = state => ({
  auth: state.auth,
  connect: state.connect,
});

export default connect(mapStateToProps, { createConnect,getLeader})(withRouter(CreateConnect));
