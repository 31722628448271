import { GET_JEMAAT, GET_JEMAAT2, JEMAAT_ERROR } from "../actions/types";

const initialState = {
    jemaat2: [],
    jemaat: [],
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_JEMAAT:
            return{
                ...state,
                jemaat: payload,
                loading: false
            };
            case GET_JEMAAT2:
           return{
               ...state,
               jemaat2: payload,
               loading: false
           };
        case JEMAAT_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
