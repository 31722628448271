import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import {API_URL_IMAGE} from "../../config/apiUrl";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import store from "../../store";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import parse from 'html-react-parser';

const PipkaSlider = ({homeSlider, auth: { user, token }}) => {
    const [dataHomeSlider, setHomeSlider] = useState([]);
    const [status, setStatus] = useState(true);

    const deleteData = (id) => {
        let url2 = `https://gkmi.one:8080/api/pipkabackend/home-slider/${id}`
        axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
            const del = dataHomeSlider.filter(tableRow => id !== tableRow.id)
            setHomeSlider(del);
        })
      }
    useEffect(() =>{
        fetchData();
   }, [user]);

   const fetchData = async () => {

     let link='';
       link = `https://gkmi.one:8080/api/pipkabackend/home-slider`;
      // link = `http://localhost:8080/api/pipkabackend/home-slider`;
   const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
   setHomeSlider(response.data);

}

        const data = {
           columns: [
             {
                 label: "No.",
                 field: "no",
                 sort: "asc",
                 width: 10,
             },
             {
                 label: "Image",
                 field: "namafile",
                 sort: "asc",
                 width: 150,
             },
             {
                label: "Urutan",
                field: "urutan",
                sort: "asc",
                width: 10,
            },
             {
                 label: "Action",
                 field: "action",
                 width: 100,
             },
           ],
          rows: dataHomeSlider.map((apiData, i) => {


             let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
             return({
               no: (i+1),
               namafile:<center>
               <img style={{border: "3px solid #adb5bd", padding: "3px"}} src={apiData.tautan + '/' + (apiData.namafile !== '' ? '/' + apiData.namafile : 'avatar.jpg')}
                   className="img-fluid mb-3"
                   alt="Image"
               />
               </center>,
              urutan: <center>{apiData.urutan}</center>,
               action: <span><Link className="btn btn-block bg-gradient-primary" to={`/pipka/edit-pipkaslider/${base64EncodeString}`}>Ubah</Link>
               <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData._id) } }>Hapus</a></span>

             });

}),
};



      function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                        <Link to='/pipka/create-pipkaslider' className="btn btn-block bg-gradient-success">Tambah Gambar Slider Home</Link>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardPIPKA">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Home Slider</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Firman */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Home Slider</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )

 }

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(PipkaSlider);
