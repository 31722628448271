import React, { Fragment, useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DatePicker from "react-datepicker";
import moment from "moment";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useParams, Link, withRouter  } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Alert from "../../../../components/alert/Alert";
import Header from "../../../layouts/Header";
import Navbar from "../../../layouts/Navbar";
import Footer from "../../../layouts/Footer";
import { updateGereja } from "../../../../actions/gerejaAction";
import { getJadwal } from "../../../../actions/gerejaJadwalAction";

import { API_URL_IMAGE } from "../../../../config/apiUrl";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { SunEditorAtom } from '../../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditGereja2 = ({ jadwal: { jadwal, loading }, updateGereja, getJadwal, history, auth: { user, token } }) => {
    const {kode_gereja} = useParams();
	const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [sejarah, setSejarah] = useState();
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [formData, setFormData] = useState({
        nama: '',
        kota: '',
        email: '',
        telp: '',
        tagline: '',
        status: '',
        visi: '',
        misi: '',
        latitude: '',
        longitude: '',
        alamat: '',
		instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
        website: '',
        google: '',
        instagram_id: '',
        facebook_id: '',
        twitter_id: '',
        youtube_id: '',
        website_id: '',
        google_id: '',
        tgl_pendewasaan: '',
        kode_gereja_induk: '',
        image_pemimpin: '',
        pgmw: '',
	});

  const [value, setValue] = useState();
  const [loadings, setLoadings] = useState(false);

	useEffect(() => {
        setFormData({
          nama:  jadwal && (jadwal.gereja[0].nama ? jadwal.gereja[0].nama : '' ),
          kota:  jadwal && (jadwal.gereja[0].kota ? jadwal.gereja[0].kota : ''),
          email:  jadwal && (jadwal.gereja[0].email ? jadwal.gereja[0].email : ''),
          telp:  jadwal && (jadwal.gereja[0].telp ? jadwal.gereja[0].telp : ''),
          tagline:  jadwal && (jadwal.gereja[0].tagline ? jadwal.gereja[0].tagline : ''),
          status:  jadwal && (jadwal.gereja[0].status ? jadwal.gereja[0].status : ''),
          pgmw:  jadwal && (jadwal.gereja[0].pgmw ? jadwal.gereja[0].pgmw : ''),
          visi:  jadwal && (jadwal.gereja[0].visi ? jadwal.gereja[0].visi : ''),
          misi:  jadwal && (jadwal.gereja[0].misi ? jadwal.gereja[0].misi : ''),
          //image_pemimpin: jadwal && (jadwal.gereja[0].image_pemimpin ? jadwal.gereja[0].image_pemimpin : ''),
          latitude:  jadwal && (jadwal.gereja[0].latitude ? jadwal.gereja[0].latitude : ''),
          longitude:  jadwal && (jadwal.gereja[0].longitude ? jadwal.gereja[0].longitude : ''),
          alamat:  jadwal && (jadwal.gereja[0].alamat ? jadwal.gereja[0].alamat : ''),
          kode_gereja_induk:  jadwal && (jadwal.gereja[0].kode_gereja_induk ? jadwal.gereja[0].kode_gereja_induk : ''),
          //tgl_pendewasaan:  jadwal && (jadwal.gereja[0].tgl_pendewasaan ? moment(jadwal.gereja[0].tgl_pendewasaan, 'DD-MM-YYYY').format('YYYY-MM-DD') : ''),
          tgl_pendewasaan:  jadwal && (jadwal.gereja[0].tgl_pendewasaan ? jadwal.gereja[0].tgl_pendewasaan : ''),
          instagram:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Instagram' ? jadwal.tautan[0].tautan :
              jadwal.tautan[1].nama_tautan === 'Instagram' ? jadwal.tautan[1].tautan :
              jadwal.tautan[2].nama_tautan === 'Instagram' ? jadwal.tautan[2].tautan :
              jadwal.tautan[3].nama_tautan === 'Instagram' ? jadwal.tautan[3].tautan :
              jadwal.tautan[4].nama_tautan === 'Instagram' ? jadwal.tautan[4].tautan :
              jadwal.tautan[5].nama_tautan === 'Instagram' ? jadwal.tautan[5].tautan : ''),
          facebook:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Facebook' ? jadwal.tautan[0].tautan :
              jadwal.tautan[1].nama_tautan === 'Facebook' ? jadwal.tautan[1].tautan :
              jadwal.tautan[2].nama_tautan === 'Facebook' ? jadwal.tautan[2].tautan :
              jadwal.tautan[3].nama_tautan === 'Facebook' ? jadwal.tautan[3].tautan :
              jadwal.tautan[4].nama_tautan === 'Facebook' ? jadwal.tautan[4].tautan :
              jadwal.tautan[5].nama_tautan === 'Facebook' ? jadwal.tautan[5].tautan : ''),
          twitter:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Twitter' ? jadwal.tautan[0].tautan :
              jadwal.tautan[1].nama_tautan === 'Twitter' ? jadwal.tautan[1].tautan :
              jadwal.tautan[2].nama_tautan === 'Twitter' ? jadwal.tautan[2].tautan :
              jadwal.tautan[3].nama_tautan === 'Twitter' ? jadwal.tautan[3].tautan :
              jadwal.tautan[4].nama_tautan === 'Twitter' ? jadwal.tautan[4].tautan :
              jadwal.tautan[5].nama_tautan === 'Twitter' ? jadwal.tautan[5].tautan : ''),
          youtube:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Youtube' ? jadwal.tautan[0].tautan :
              jadwal.tautan[1].nama_tautan === 'Youtube' ? jadwal.tautan[1].tautan :
              jadwal.tautan[2].nama_tautan === 'Youtube' ? jadwal.tautan[2].tautan :
              jadwal.tautan[3].nama_tautan === 'Youtube' ? jadwal.tautan[3].tautan :
              jadwal.tautan[4].nama_tautan === 'Youtube' ? jadwal.tautan[4].tautan :
              jadwal.tautan[5].nama_tautan === 'Youtube' ? jadwal.tautan[5].tautan : ''),
          website:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Website' ? jadwal.tautan[0].tautan :
              jadwal.tautan[1].nama_tautan === 'Website' ? jadwal.tautan[1].tautan :
              jadwal.tautan[2].nama_tautan === 'Website' ? jadwal.tautan[2].tautan :
              jadwal.tautan[3].nama_tautan === 'Website' ? jadwal.tautan[3].tautan :
              jadwal.tautan[4].nama_tautan === 'Website' ? jadwal.tautan[4].tautan :
              jadwal.tautan[5].nama_tautan === 'Website' ? jadwal.tautan[5].tautan : ''),
          google:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Google' ? jadwal.tautan[0].tautan :
              jadwal.tautan[1].nama_tautan === 'Google' ? jadwal.tautan[1].tautan :
              jadwal.tautan[2].nama_tautan === 'Google' ? jadwal.tautan[2].tautan :
              jadwal.tautan[3].nama_tautan === 'Google' ? jadwal.tautan[3].tautan :
              jadwal.tautan[4].nama_tautan === 'Google' ? jadwal.tautan[4].tautan :
              jadwal.tautan[5].nama_tautan === 'Google' ? jadwal.tautan[5].tautan : ''),
          instagram_id:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Instagram' ? jadwal.tautan[0].id :
              jadwal.tautan[1].nama_tautan === 'Instagram' ? jadwal.tautan[1].id :
              jadwal.tautan[2].nama_tautan === 'Instagram' ? jadwal.tautan[2].id :
              jadwal.tautan[3].nama_tautan === 'Instagram' ? jadwal.tautan[3].id :
              jadwal.tautan[4].nama_tautan === 'Instagram' ? jadwal.tautan[4].id :
              jadwal.tautan[5].nama_tautan === 'Instagram' ? jadwal.tautan[5].id : ''),
          facebook_id:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Facebook' ? jadwal.tautan[0].id :
              jadwal.tautan[1].nama_tautan === 'Facebook' ? jadwal.tautan[1].id :
              jadwal.tautan[2].nama_tautan === 'Facebook' ? jadwal.tautan[2].id :
              jadwal.tautan[3].nama_tautan === 'Facebook' ? jadwal.tautan[3].id :
              jadwal.tautan[4].nama_tautan === 'Facebook' ? jadwal.tautan[4].id :
              jadwal.tautan[5].nama_tautan === 'Facebook' ? jadwal.tautan[5].id : ''),
          twitter_id:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Twitter' ? jadwal.tautan[0].id :
              jadwal.tautan[1].nama_tautan === 'Twitter' ? jadwal.tautan[1].id :
              jadwal.tautan[2].nama_tautan === 'Twitter' ? jadwal.tautan[2].id :
              jadwal.tautan[3].nama_tautan === 'Twitter' ? jadwal.tautan[3].id :
              jadwal.tautan[4].nama_tautan === 'Twitter' ? jadwal.tautan[4].id :
              jadwal.tautan[5].nama_tautan === 'Twitter' ? jadwal.tautan[5].id : ''),
          youtube_id:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Youtube' ? jadwal.tautan[0].id :
              jadwal.tautan[1].nama_tautan === 'Youtube' ? jadwal.tautan[1].id :
              jadwal.tautan[2].nama_tautan === 'Youtube' ? jadwal.tautan[2].id :
              jadwal.tautan[3].nama_tautan === 'Youtube' ? jadwal.tautan[3].id :
              jadwal.tautan[4].nama_tautan === 'Youtube' ? jadwal.tautan[4].id :
              jadwal.tautan[5].nama_tautan === 'Youtube' ? jadwal.tautan[5].id : ''),
          website_id:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Website' ? jadwal.tautan[0].id :
              jadwal.tautan[1].nama_tautan === 'Website' ? jadwal.tautan[1].id :
              jadwal.tautan[2].nama_tautan === 'Website' ? jadwal.tautan[2].id :
              jadwal.tautan[3].nama_tautan === 'Website' ? jadwal.tautan[3].id :
              jadwal.tautan[4].nama_tautan === 'Website' ? jadwal.tautan[4].id :
              jadwal.tautan[5].nama_tautan === 'Website' ? jadwal.tautan[5].id : ''),
          google_id:  jadwal && (jadwal.tautan.length <= 0 ? '' :
              jadwal.tautan[0].nama_tautan === 'Google' ? jadwal.tautan[0].id :
              jadwal.tautan[1].nama_tautan === 'Google' ? jadwal.tautan[1].id :
              jadwal.tautan[2].nama_tautan === 'Google' ? jadwal.tautan[2].id :
              jadwal.tautan[3].nama_tautan === 'Google' ? jadwal.tautan[3].id :
              jadwal.tautan[4].nama_tautan === 'Google' ? jadwal.tautan[4].id :
              jadwal.tautan[5].nama_tautan === 'Google' ? jadwal.tautan[5].id : ''),
        });
        //setSejarah( jadwal && (jadwal.gereja[0].sejarah ? jadwal.gereja[0].sejarah : ''));
        setValue((jadwal && (jadwal.gereja[0].sejarah ? jadwal.gereja[0].sejarah : '')));

        var content =  jadwal && (jadwal.gereja[0].sejarah ? jadwal.gereja[0].sejarah : '');

		const fixedHTML = content ? content.toString().replace(/<figure.*>/gi, "\n") : '';
       const blocksFromHtml = htmlToDraft("<p>"+fixedHTML+"</p>");
       const { contentBlocks, entityMap } = blocksFromHtml;
       const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
       const editorState = EditorState.createWithContent(contentState);
       setEditorState(editorState);
       //setImagePreviewUrl(API_URL_IMAGE + '/gereja/'+image_pemimpin);



	}, [loading, jadwal]);


    useEffect(() => {
        setFileName(jadwal && (jadwal.gereja[0].image_pemimpin ? jadwal.gereja[0].image_pemimpin : ''));
        console.log('NAMA: ' + fileName);
        setImagePreviewUrl((jadwal && (jadwal.gereja[0].tautan ? jadwal.gereja[0].tautan : ''))+fileName);

    }, [loading, jadwal, fileName]);

    const onEditorStateChange = (editorState) => {
      console.log('OKK: ' + stateToHTML(editorState.getCurrentContent()));
      //setSejarah(editorState);
      setEditorState(editorState);
    }

    function uploadImageCallBack(file, uploadHandler) {
        const uploadFile = file[0];
        console.log('KESINI: ' + uploadFile);
        return new Promise(
          (resolve, reject) => {
            const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
            xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
            xhr.setRequestHeader('x-auth-token', token);
            const data = new FormData(); // eslint-disable-line no-undef
            data.append('file', uploadFile);
            data.append("tautan", API_URL_IMAGE + '/ckeditor/')
            xhr.send(data);
            xhr.addEventListener('load', () => {
              const response = JSON.parse(xhr.responseText);
              console.log('OK: ' + response);
              uploadHandler(response);
              resolve(response);
            });
            xhr.addEventListener('error', () => {
              const error = JSON.parse(xhr.responseText);
              reject(error);
            });
          },
        );
      }

	useEffect(() => {
        getJadwal(kode_gereja);
    }, [getJadwal, kode_gereja]);

    const {
    nama,
    kota,
    email,
    telp,
    tagline,
    status,
    visi,
    misi,
    latitude,
    longitude,
    alamat,
	instagram,
    facebook,
    twitter,
    youtube,
    website,
    google,
    instagram_id,
    facebook_id,
    twitter_id,
    youtube_id,
    website_id,
    google_id,
    tgl_pendewasaan,
    kode_gereja_induk,
    image_pemimpin,
    pgmw
    } = formData;

    const handleDateChange = e => {
        setFormData({ ...formData,
          tgl_pendewasaan: new Date(e)
        });
      }

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const onChangeDewasa = e => setFormData({...formData, [e.target.name]: e.target.value});
	const fileOnChange = e => {
        setFile(e.target.files[0]);
	}

	const sejarahOnChange = (e, editor) => {
        const data = editor.getData();
        console.log('EDITOR 1: ' + data);
        setSejarah(data);
    }
    const fileOnChangePemimpin = e => {
        console.log(e);
      setFile(e.target.files[0]);
      const objectUrl = URL.createObjectURL(e.target.files[0])
      setImagePreviewUrl(objectUrl)
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
		const data = new FormData();
        data.append("image_pemimpin", file);
        //data.append("nama", formData.nama);
        //data.append("kota", formData.kota);
        //data.append("email", formData.email);
        //data.append("telp", formData.telp);
        //data.append("tagline", formData.tagline);
        data.append("status", formData.status);
        //data.append("visi", formData.visi);
        //data.append("misi", formData.misi);
        //data.append("sejarah", draftToHtml(convertToRaw(editorState.getCurrentContent())));
        data.append("sejarah", value);
        if(typeof formData.nama !== 'undefined'){
          data.append("nama", formData.nama);
        }
        if(typeof formData.kota !== 'undefined'){
          data.append("kota", formData.kota);
        }
        if(typeof formData.email !== 'undefined'){
          data.append("email", formData.email);
        }
        if(typeof formData.telp !== 'undefined'){
          data.append("telp", formData.telp);
        }
        if(typeof formData.tagline !== 'undefined'){
          data.append("tagline", formData.tagline);
        }
        if(typeof formData.visi !== 'undefined'){
          data.append("visi", formData.visi);
        }
        if(typeof formData.misi !== 'undefined'){
          data.append("misi", formData.misi);
        }
        if(typeof formData.latitude !== 'undefined'){
            data.append("latitude", formData.latitude);
          }
          if(typeof formData.longitude !== 'undefined'){
            data.append("longitude", formData.longitude);
          }
          if(typeof formData.alamat !== 'undefined'){
            data.append("alamat", formData.alamat);
          }
		if(typeof formData.instagram !== 'undefined'){
            data.append("instagram", formData.instagram);
          }
            if(typeof formData.facebook !== 'undefined'){
            data.append("facebook", formData.facebook);
          }
          if(typeof formData.twitter !== 'undefined'){
            data.append("twitter", formData.twitter);
          }
          if(typeof formData.youtube !== 'undefined'){
            data.append("youtube", formData.youtube);
          }
          if(typeof formData.website !== 'undefined'){
            data.append("website", formData.website);
          }
          if(typeof formData.google !== 'undefined'){
            data.append("google", formData.google);
          }
        data.append("pgmw", formData.pgmw);
        data.append("instagram_id", instagram_id);
        data.append("facebook_id", facebook_id);
        data.append("twitter_id", twitter_id);
        data.append("youtube_id", youtube_id);
        data.append("website_id", website_id);
        data.append("google_id", google_id);
        if(kode_gereja_induk === ""){
            data.append("tgl_pendewasaan", moment(tgl_pendewasaan).format('YYYY-MM-DD'));
        }

        updateGereja(data, kode_gereja, history);
        //updateGereja(formData, kode_gereja, history);
    }

    function handleImageUploadBefore(files, info, uploadHandler){
        try {
          uploadImageCallBack(files, uploadHandler);
        } catch (err) {
            uploadHandler(err.toString())
        }
      }

    return (
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-2">
                        <Link to="/gereja" className="btn btn-block bg-gradient-danger">Back</Link>
                    </div>
                    <div className="col-sm-10">
                        <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Gereja</li>
                        </ol>
                    </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Default box */}
                        <div className="card">
                            <form onSubmit={(e) => onSubmit(e)}>
                                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Gereja</h3>
                                    <div className="card-tools">
                                        <button
                                        type="button"
                                        className="btn btn-tool"
                                        data-card-widget="collapse"
                                        data-toggle="tooltip"
                                        title="Collapse"
                                        >
                                        <i className="fas fa-minus" />
                                        </button>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <Alert />
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                            <label>PGMW</label>
                                            <select className="form-control" required name="pgmw" value={pgmw} onChange={e => onChange(e)}>
                                            <option value="">--Pilih--</option>
                                                <option value="pgmw1">PGMW 1</option>
                                                <option value="pgmw2">PGMW 2</option>
                                                <option value="pgmw3">PGMW 3</option>
                                                <option value="pgmw4">PGMW 4</option>
                                                <option value="pgmw5">PGMW 5</option>
                                                <option value="pgmw6">PGMW 6</option>
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                            <label>Nama</label>
                                            <input type="text" className="form-control" placeholder="Nama ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                            <label>Kota</label>
                                            <input type="text" className="form-control" required placeholder="Kota ..." name="kota" value={kota} onChange={e => onChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                <label>Alamat</label>
                                                <textarea className="form-control" rows="5" placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" className="form-control" placeholder="Email ..." name="email" value={email} onChange={e => onChange(e)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                            <label>Telepon</label>
                                            <input type="text" className="form-control" placeholder="Telepon ..." name="telp" value={telp} onChange={e => onChange(e)} />
                                            </div>
                                        </div>
                                        </div>

                                        <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                            <label>Tagline</label>
                                            <input type="text" className="form-control" placeholder="Tagline ..." name="tagline" value={tagline} onChange={e => onChange(e)} />
                                            </div>
                                        </div>
                                        {
                                            status === "dewasa" ?
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                            <label>Status</label>
                                            <select className="form-control" required name="status" value={status} onChange={e => onChangeDewasa(e)}>
                                            <option value="">--Pilih--</option>

                                                <option value="dewasa">Dewasa</option>

                                            </select>
                                            </div>
                                        </div>
                                        :<div className="col-sm-6">
                                        <div className="form-group">
                                        <label>Status</label>
                                        <select className="form-control" required name="status" value={status} onChange={e => onChange(e)}>
                                        <option value="">--Pilih--</option>
                                            <option value="cabang">Cabang</option>

                                        </select>
                                        </div>
                                    </div>
                                    }

                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                <label>Visi</label>
                                                <input type="text" className="form-control" placeholder="Visi ..." name="visi" value={visi} onChange={e => onChange(e)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                <label>Misi</label>
                                                <input type="text" className="form-control" placeholder="Misi ..." name="misi" value={misi} onChange={e => onChange(e)} />
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            kode_gereja_induk === "" ?
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                    <label>Tanggal Pendewasaan</label>
                                                    <div className="customDatePickerWidth">
                                                        <DatePicker
                                                        name="tanggal"
                                                        dateFormat="dd-MM-yyyy"
                                                        className="form-control"
                                                        selected={tgl_pendewasaan ? new Date(tgl_pendewasaan) : new Date()}
                                                        onChange={handleDateChange}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        onFocus={e => e.target.blur()}/>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                        }





									<div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                            <label>Instagram</label>
                                            <input type="text" className="form-control" placeholder="Instagram ..." name="instagram" value={instagram} onChange={e => onChange(e)} />
                                            </div>
                                        </div>
										<div className="col-sm-6">
											<div className="form-group">
											<label>Facebook</label>
											<input type="text" className="form-control" placeholder="Facebook ..." name="facebook" value={facebook} onChange={e => onChange(e)} />
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-6">
											<div className="form-group">
											<label>Twitter</label>
											<input type="text" className="form-control" placeholder="Twitter ..." name="twitter" value={twitter} onChange={e => onChange(e)} />
											</div>
										</div>
										<div className="col-sm-6">
											<div className="form-group">
											<label>Youtube</label>
											<input type="text" className="form-control" placeholder="Youtube ..." name="youtube" value={youtube} onChange={e => onChange(e)} />
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-6">
											<div className="form-group">
											<label>Website</label>
											<input type="text" className="form-control" placeholder="Website ..." name="website" value={website} onChange={e => onChange(e)} />
											</div>
										</div>
										<div className="col-sm-6">
											<div className="form-group">
                                            <label>Google Map Share Link</label>
                                            <input type="text" className="form-control" placeholder="Google ..." name="google" value={google}  onChange={e => onChange(e)} />
											<input type="hidden" className="form-control" name="instagram_id" value={instagram_id} onChange={e => onChange(e)} />
											<input type="hidden" className="form-control" name="facebook_id" value={facebook_id} onChange={e => onChange(e)} />
											<input type="hidden" className="form-control" name="twitter_id" value={twitter_id} onChange={e => onChange(e)} />
											<input type="hidden" className="form-control" name="youtube_id" value={youtube_id} onChange={e => onChange(e)} />
											<input type="hidden" className="form-control" name="website_id" value={website_id} onChange={e => onChange(e)} />
											<input type="hidden" className="form-control" name="google_id" value={google_id} onChange={e => onChange(e)} />
											</div>
										</div>
									</div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                            <label>Latitude</label>
                                            <input type="text" className="form-control" placeholder="Latitude ..." name="latitude" value={latitude} onChange={e => onChange(e)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                            <label>Longitude</label>
                                            <input type="text" className="form-control" placeholder="Longitude ..." name="longitude" value={longitude} onChange={e => onChange(e)} />
                                            </div>
                                        </div>
                                        </div>


                                    <div className="col-sm-12">
                                        <div className="form-group">
                                        <label>Gambar Pemimpin Gereja</label>
                                        <div className="imgPreviewUpload">
                                        <img className="imgPreview" src={imagePreviewUrl} />
                                        <div >
                                        <input type="file" placeholder="Pemimpin Gereja ..." name="namafile_image"  onChange={fileOnChangePemimpin} />
                                        </div>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                            <label>Sejarah</label>


                                            <div style={{position: 'relative'}}>
                                                <div style={{zIndex: 1}}>
                                                    <SunEditor
                                                        autoFocus={true}
                                                        lang="en"
                                                        width='100%'
                                                        height='450px'
                                                        setOptions={{
                                                        minWidth : '450px',
                                                        showPathLabel: false,
                                                        placeholder: "Enter your text here!!!",
                                                        plugins: [
                                                            align,
                                                            font,
                                                            fontColor,
                                                            fontSize,
                                                            formatBlock,
                                                            hiliteColor,
                                                            horizontalRule,
                                                            lineHeight,
                                                            list,
                                                            paragraphStyle,
                                                            table,
                                                            template,
                                                            textStyle,
                                                            image,
                                                            link
                                                        ],
                                                        buttonList: [
                                                            ["undo", "redo"],
                                                            ["font", "fontSize", "formatBlock"],
                                                            ["paragraphStyle"],
                                                            [
                                                            "bold",
                                                            "underline",
                                                            "italic",
                                                            "strike",
                                                            "subscript",
                                                            "superscript"
                                                            ],
                                                            ["fontColor", "hiliteColor"],
                                                            ["removeFormat"],
                                                            "/", // Line break
                                                            ["outdent", "indent"],
                                                            ["align", "horizontalRule", "list", "lineHeight"],
                                                            ["table", "link", "image"]
                                                        ],
                                                        formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                                        font: [
                                                            "Arial",
                                                            "Calibri",
                                                            "Comic Sans",
                                                            "Courier",
                                                            "Garamond",
                                                            "Georgia",
                                                            "Impact",
                                                            "Lucida Console",
                                                            "Palatino Linotype",
                                                            "Segoe UI",
                                                            "Tahoma",
                                                            "Times New Roman",
                                                            "Trebuchet MS"
                                                        ]
                                                        }}
                                                        onImageUploadBefore={handleImageUploadBefore}
                                                        setContents={value}
                                                        onChange={setValue}

                                                    />

                                                </div>

                                                <div style={{zIndex: -999, position: 'absolute'}}>
                                                    <SunEditorAtom initialContent={value}/>
                                                </div>
                                            </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* /.card-body */}
                                <div className="card-footer">
                                {
                                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                                  :
                                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                                }
                                </div>
                                {/* /.card-footer*/}
                            </form>
                        </div>
                        {/* /.card */}
                    </div>
                </section>
                {/* /.content */}

            </div>
            {/* /.content-wrapper */}
            <Footer />
        </Fragment>
    );
};

EditGereja2.propTypes = {
    updateGereja: PropTypes.func.isRequired,
    getJadwal: PropTypes.func.isRequired,
    jadwal: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    jadwal: state.jadwal,
    auth: state.auth
});

export default connect(mapStateToProps, { updateGereja, getJadwal })(withRouter(EditGereja2));
