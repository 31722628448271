import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage900.png";
import { createBranchesSlider } from "../../../actions/gerejaSliderAction";

const CreateBranchesSlider = ({ createBranchesSlider, auth: { user }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [formData, setFormData] = useState({
        kode_gereja: '',
        urutan:'',
    });
    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        setNamaGereja(decodeString.split(';')[1]);
        setFormData({kode_gereja: decodeString.split(';')[0]});

    }, [id]);

    // useEffect(() => {
    //     let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
    //     user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0) ?
        
    //     setFormData({kode_gereja: decodeString2.split(';')[0]}) : user && (setFormData({kode_gereja: user.gereja[0].kode_gereja}))
    //   }, [user, id]);

    const {
        kode_gereja,
        urutan,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        data.append("namafile", file);
        data.append("tautan", API_URL_IMAGE + '/gereja/');
        data.append("kode_gereja", formData.kode_gereja);
        data.append("urutan", formData.urutan);
        console.log(file);
        createBranchesSlider(data, history,id);
    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to={`/pipka/branches/slider/${id}`} className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardPIPKA">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <Link to="/pipka/branches">Branches</Link>
                    </li>
                    <li className="breadcrumb-item active">Branches Slider</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create Branches Slider : {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Urutan</label>
                      <select className="form-control" required name="urutan" value={urutan} onChange={e => onChange(e)}>
                      <option value="">--Pilih--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Image slider</label>
                            <div className="imgPreviewUpload">
                             <img className="imgPreview" src={imagePreviewUrl} />
                             <div >
                             <input type="hidden" className="form-control" name="kode_gereja" value={kode_gereja} onChange={e => onChange(e)} />
                            <input type="file" className="form-control" placeholder="Image slider ..." name="namafile"  onChange={fileOnChange} />
                             </div>
                             </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreateBranchesSlider.propTypes = {
    createBranchesSlider: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { createBranchesSlider })(withRouter(CreateBranchesSlider));
