import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link, useParams }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

const KaryaProduk = ({ auth: { user, token } }) => {
    const {id} = useParams();
    const [dataKaryaProduk, setKaryaProduk] = useState([]);
    const [namaToko, setNamaToko] = useState();
    const [linkCreate, setLinkCreate] = useState();
    const [status, setStatus] = useState(true);
    useEffect(() =>{
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        let kodeToko = decodeString.split(';')[0];
        let codeChurch = user && (user.gereja[0].kode_gereja);
        let codeMasterChurch = user && (user.gereja[0].kode_gereja_induk);
        console.log('PROFILE: ' + codeChurch + " - " + codeMasterChurch);
        let link='';
        let codeMasterChurch2 = 0;
        link = `https://gkmi.one:8080/api/karya/tokoproduk/${kodeToko}`;
        fetch(`${link}`, {
            headers: {
                'x-auth-token': token
            }
        })
          .then((response) => {
            setStatus(response.ok);
            return response.json();
          })
          .then((res) => {
            console.log("DATA: " + res[0].product);
            const data = {
              columns: [
                {
                    label: "No.",
                    field: "no",
                    sort: "asc",
                    width: 10,
                },
                {
                    label: "Gambar",
                    field: "image_produk",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Nama Produk",
                    field: "nama_produk",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Detail Produk",
                    field: "detail_produk",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Minimum Pembelian",
                    field: "pembelian_minimum",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Pengiriman",
                    field: "pengiriman",
                    sort: "asc",
                    width: 150,
                },
                // {
                //     label: "Tautan",
                //     field: "produk_tautan",
                //     sort: "asc",
                //     width: 150,
                // },
                {
                    label: "Endorser",
                    field: "endorser",
                    sort: "asc",
                    width: 150,
                },
                {
                  label: "Active",
                  field: "status",
                  sort: "asc",
                  width: 150,
              },
                {
                    label: "Action",
                    field: "action",
                    width: 150,
                },
              ],
              rows: res[0].product.map((apiData, i) => {
                  //let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
                  let base64EncodeString = Buffer.from(`${id};${apiData.id}`).toString('base64');
                  var status;
              if(apiData.status==1){
                status="Active"
                  }else{
                  status="Inactive"
                  }
                  return({
                    no: (i+1),
                     image_produk:  <center><img src={(apiData.image_produk[1].avatar ? (apiData.image_produk[1].avatar !== undefined ? apiData.image_produk[1].tautan + '/' + apiData.image_produk[1].avatar : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')} className="profile-user-img img-fluid img-circle" alt="Avatar" /><br/></center>,
                    nama_produk: <span>{apiData.nama_produk}</span>,
                    detail_produk: <span>Ukuran: {apiData.ukuran_produk} <br/>Berat: {apiData.berat_produk} <br/>Kondisi: {apiData.kondisi_produk}</span>,
                    pembelian_minimum: <span>{apiData.pembelian_minimum}</span>,
                    pengiriman: <span>{apiData.pengiriman}</span>,

                    endorser: apiData.product_endorser ? <center><img src={(apiData.product_endorser.pemimpin_image ? (apiData.product_endorser.pemimpin_image !== undefined ? apiData.product_endorser.pemimpin_tautan + '/' + apiData.product_endorser.pemimpin_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')} className="profile-user-img img-fluid img-circle" alt="Avatar" /><br/><span>{apiData.product_endorser.nama_pemimpin}</span></center> : '',
                    status:   <span className="btn btn-block btn-outline-success btn-sm">{status}</span>,
                    action: <span><Link className="btn btn-block bg-gradient-primary" to={`/karya/toko/produk/edit-produk/${base64EncodeString}`}>Ubah</Link></span>
                  });

              }),

            };
            setKaryaProduk(data);
          });
    }, [user]);

    useEffect(() =>{
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
        setNamaToko(decodeString2.split(';')[1]);
        setLinkCreate(`/karya/toko/produk/${id}/create-produk`);
    }, [id]);

    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={dataKaryaProduk} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <div className="container-fluid">
                                <div className="row mb-4">
                                    <Link to='/karya/toko' className="btn btn-block bg-gradient-danger">Back</Link>
                                    <Link to={linkCreate} className="btn btn-block bg-gradient-green  ">Tambah Data Produk</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Produk Karya</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita Category */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Produk Toko {namaToko}</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(KaryaProduk);
