import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';

const BeritaCategory = ({ auth: { user, token } }) => {
    const [beritaCategory, setBeritaCategory] = useState([]);
    const [status, setStatus] = useState(true);

    useEffect(() =>{
         fetchData();
    }, []);

    const fetchData = async () => {
      let codeChurch = user && (user.gereja[0].kode_gereja);
      let codeMasterChurch = user && (user.gereja[0].kode_gereja_induk);
      console.log('PROFILE: ' + codeChurch + " - " + codeMasterChurch);
      let link='';
      let codeMasterChurch2 = 0;
      link = 'https://gkmi.one:8080/api/berita/backend/beritakategori/kategori';
      const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
      setBeritaCategory(response.data);
    }

    const deleteData = (id) => {
      let url2 = `https://gkmi.one:8080/api/berita/backend/beritakategori/${id}`
      axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
          const del = beritaCategory.filter(tableRow => id !== tableRow._id)
          setBeritaCategory(del);
      })
    }

    const data = {
        columns: [
          {
            label: "No.",
            field: "no",
            sort: "asc",
            width: 10,
          },
          {
              label: "Nama Kategori",
              field: "nama_kategori",
              sort: "asc",
              width: 150,
          },
          {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },

        ],
        rows: beritaCategory.map((apiData, i) => {
          let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
          return({
              no: (i+1),
              nama_kategori: <span>{apiData.nama_kategori}</span>,
              action: <span><Link className="btn btn-block bg-gradient-primary" to={`/berita/category/edit-category/${base64EncodeString}`}>Ubah</Link>
              <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData._id) } }>Hapus</a></span>
        });
      }),
    };



    function cekStatus(){
        if(status){

          return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <Link to='/berita/category/create-category' className="btn btn-block bg-gradient-green  ">Tambah Data Kategori</Link>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Kategori Berita</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita Category */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Kategori Berita</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(BeritaCategory);
