import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import {API_URL_IMAGE} from "../../config/apiUrl";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import store from "../../store";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import parse from 'html-react-parser';

import { getHomeSlider } from "../../actions/homeSliderAction";

const HomeSlider = ({homeSlider, auth: { user, token }}) => {
    const [dataHomeSlider, setHomeSlider] = useState([]);
    const [status, setStatus] = useState(true);

    const deleteData = (id) => {
        let url2 = `https://gkmi.one:8080/api/slider/home-slider/${id}`
        axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
            const del = dataHomeSlider.filter(tableRow => id !== tableRow.id)
            setHomeSlider(del);
        })
      }
    useEffect(() =>{
        fetchData();
   }, [user]);

   const fetchData = async () => {

     let link='';
     link = `https://gkmi.one:8080/api/slider/home-slider`;
   const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
   setHomeSlider(response.data);

}

        const data = {
           columns: [
             {
                 label: "No.",
                 field: "no",
                 sort: "asc",
                 width: 10,
             },
             {
                 label: "Image",
                 field: "namafile",
                 sort: "asc",
                 width: 150,
             },
             {
                label: "Urutan",
                field: "urutan",
                sort: "asc",
                width: 10,
            },
             {
                 label: "Action",
                 field: "action",
                 width: 100,
             },
           ],
          rows: dataHomeSlider.map((apiData, i) => {


             let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
             return({
               no: (i+1),
               namafile:<center>
               <img style={{border: "3px solid #adb5bd", padding: "3px"}} src={apiData.tautan + '/' + (apiData.namafile !== '' ? '/' + apiData.namafile : 'avatar.jpg')}
                   className="img-fluid mb-3"
                   alt="Image"
               />
               </center>,
              urutan: <center>{apiData.urutan}</center>,
            //    <center><img src={(apiData.nama_image !== "" ? (apiData.nama_image !== undefined ? apiData.tautan + apiData.nama_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
            //    className="profile-user-img img-fluid img-circle"
            //    alt="Image" /></center>,

               action: <span><Link className="btn btn-block bg-gradient-primary" to={`/sliderhome/edit-homeslider/${base64EncodeString}`}>Ubah</Link>
               <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData.id) } }>Hapus</a></span>

             });

}),
};



      function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                        <Link to='/sliderhome/create-homeslider/' className="btn btn-block bg-gradient-success">Tambah Gambar Slider Home</Link>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Home Slider</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Firman */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Home Slider</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
//     return(
//         <Fragment>
//             <Header />
//             <Navbar />
//             <Fragment>
//                 {/* Content Wrapper. Contains page content */}
//                 <div className="content-wrapper">
//                     {/* Content Header (Page header) */}
//                     <section className="content-header">
//                     <div className="container-fluid">
//                         <div className="row mb-2">

//                             <div className="col-sm-12">
//                             <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
//                                 <li className="breadcrumb-item">
//                                 <Link to="/dashboard">Home</Link>
//                                 </li>
//                                 <li className="breadcrumb-item active">Home Slider</li>
//                             </ol>
//                             </div>
//                         </div>
//                         </div>
//                     </section>
//                     {/* /.content */}

//                     <section className="content">
//                     <div className="container-fluid">
//                         {/* Default box */}
//                         <div className="card">

//                         <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
//                             <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Home Slider </h3>
//                             <div className="card-tools">
//                             <button
//                                 type="button"
//                                 className="btn btn-tool"
//                                 data-card-widget="collapse"
//                                 data-toggle="tooltip"
//                                 title="Collapse">
//                                 <i className="fas fa-minus" />
//                             </button>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <Alert />
//                             <div className="col-sm-5" style={{marginLeft: "-15px", marginBottom: "10px"}}>
//                                 <Link to='/sliderhome/create-homeslider/' className="btn btn-block bg-gradient-success">Tambah Gambar Slider Home</Link>
//                             </div>
//                             <table id="example1" className="table table-bordered table-striped">
//                                 <thead>
//                                     <tr>
//                                         <td>No.</td>
//                                         <td><center>Image</center></td>
//                                         {/* <td><center>Urutan</center></td> */}
//                                         <td><center>Action</center></td>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                 {
//                                     homeSlider.homeSlider && homeSlider.homeSlider.length > 0 ?
//                                     homeSlider.homeSlider.map((sld, index) => {
//                                         let base64EncodeString = Buffer.from(`${sld.id}`).toString('base64');
//                                         return(
//                                             <tr key={sld.id}>
//                                                 <td width="2%"><center>{index+1}</center></td>
//                                                 <td>
//                                                     <center>
//                                                     <img style={{border: "3px solid #adb5bd", padding: "3px"}} src={sld.tautan + '/' + (sld.namafile !== '' ? '/' + sld.namafile : 'avatar.jpg')}
//                                                         className="img-fluid mb-3"
//                                                         alt="Gereja"
//                                                     />
//                                                     </center>
//                                                 </td>
//                                                 {/* { <td width="2%"><center>{sld.namafile}</center></td> } */}
//                                                 <td>
//                                                 <span> <Link className="btn btn-block bg-gradient-primary" to={`/gereja/gereja-slider/edit-slider/${base64EncodeString}`}>Ubah</Link>
//                                                     <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(sld.id) } }>Hapus</a></span>

//                                                 </td>
//                                             </tr>
//                                         );
//                                     }): <tr><td colSpan={3}><center></center></td></tr>
//                                 }

//                                 </tbody>
//                             </table>
//                         </div>
//                         </div>
//                         {/* /.card */}

//                         </div>
//                     </section>
//                     {/* /.content */}

//                     </div>
//                     {/* /.content-wrapper */}
//             </Fragment>
//             <Footer />
//         </Fragment>
//     )
 }

// HomeSlider.propTypes = {
//     //gereja: PropTypes.object.isRequired,
//     getHomeSlider: PropTypes.func.isRequired,
//     auth: PropTypes.object.isRequired
//   }

//   const mapStateToProps = state => ({
//     //gereja: state.gereja,
//     homeSlider: state.homeSlider,
//     auth: state.auth
//   });

// export default connect(mapStateToProps, { getHomeSlider })(HomeSlider);
const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(HomeSlider);
