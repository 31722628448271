import React, { Fragment, useEffect,useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import axios from 'axios';
import { getCountUser } from "../../actions/dashboardAction";

const Dashboard = ({ dashboard, getCountUser, auth: { user,token } }) => {
  const [beritaTop, setBeritaTop] = useState([]);
  const [firmanTop, setFirmanTop] = useState([]);
  useEffect(() => {
    getCountUser();
    fetchDataBeritatop();
    fetchDataFirmantop();
  }, user);
  const fetchDataBeritatop = async () => {

    let link='';
      link = 'https://gkmi.one:8080/api/dashboard/dashboardGerejaBeritatop';

    const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
      });

      setBeritaTop(response.data);
  }
  const dataBeritaTop = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 10,
    },
      {
          label: "Judul Berita",
          field: "nama",
          sort: "asc",
          width: 150,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 100,
    },

  {
    label: "View",
    field: "view",
    sort: "asc",
    width: 100,
  },



    ],
    rows: beritaTop.map((apiData, i) => {



      return({
        no: (i+1),
          nama: <span>{apiData.judul_berita}</span>,
          kategori:<span>{apiData.kategori}</span>,
          view:<span>{apiData.view}</span>,
    });
  }),
  };
  const fetchDataFirmantop = async () => {

    let link='';
      link = 'https://gkmi.one:8080/api/dashboard/dashboardGerejaFirmantop';

    const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
      });

      setFirmanTop(response.data);
  }
  const dataFirmanTop = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 10,
    },
      {
          label: "Judul Firman",
          field: "nama",
          sort: "asc",
          width: 100,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 100,
    },
    {
      label: "Kontibutor",
      field: "kontibutor",
      sort: "asc",
      width: 100,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 100,
  },
  {
    label: "View",
    field: "view",
    sort: "asc",
    width: 100,
  },
    ],
    rows: firmanTop.map((apiData, i) => {
      var status;
      if(apiData.status==1){
          status="Tulisan";

              }else{
                  status="Youtube";

              }
      return({
        no: (i+1),
          nama: <span>{apiData.judul_firman}</span>,
          kategori:<span>{apiData.kategori}</span>,
          kontibutor:<span>{apiData.kontibutor}</span>,
          status:<span>{status}</span>,
          view:<span>{apiData.view}</span>,
    });
  }),
  };
  return (
    <Fragment>
      <Header />
      <Navbar />

      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        {/* Main content */}
        <section className="content">

          <div class="container-fluid">

            <div class="row">
              <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.anggota)}</h3>
                    <p style={{color: 'white'}}>Anggota</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.simpatisan)}</h3>
                    <p style={{color: 'white'}}>Simpatisan</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.guest)}</h3>
                    <p style={{color: 'white'}}>Guest</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.gereja_cabang)}</h3>
                    <p style={{color: 'white'}}>Gereja Cabang</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-building"></i>
                  </div>
                  <a href="/dashboard/gereja-cabang" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.berita)}</h3>
                    <p style={{color: 'white'}}>Berita</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-newspaper-o"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.firman_tulisan)}</h3>
                    <p style={{color: 'white'}}>Firman Tulisan</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-book"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.firmman_video)}</h3>
                    <p style={{color: 'white'}}>Firman Video</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-book"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>


            </div>
            <div class="row">

<div className="col-sm-12">
<div className="card">
      <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
      <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Berita Top 10</h3>
      <div className="card-tools">
      </div>
      </div>
      <div className="card-body">
      <MDBDataTable responsive striped bordered hover data={dataBeritaTop} />
  </div>
</div>
</div>
</div>
<div class="row">

<div className="col-sm-12">
<div className="card">
      <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
      <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Firman Top 10</h3>
      <div className="card-tools">
      </div>
      </div>
      <div className="card-body">
      <MDBDataTable responsive striped bordered hover data={dataFirmanTop} />
  </div>
</div>
</div>
</div>
          </div>

        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </Fragment>
  );
};


Dashboard.propTypes = {
  dashboard: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  auth: state.auth
});

export default connect(mapStateToProps, { getCountUser })(Dashboard);
