import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import { updateBranchesSlider, getGerejaSliderById } from "../../../actions/gerejaSliderAction";

const EditBranchesSlider = ({ gerejaSlider: { loading, gerejaSlider }, getGerejaSliderById, updateBranchesSlider, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [idValues, setIdValues] = useState();
    
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [formData, setFormData] = useState({
        kode_gereja: '',
        urutan:"",
        tautan: API_URL_IMAGE + '/gereja/',
    });

    useEffect(() => {
        let decodeString3 = Buffer.from(id, 'base64').toString('ascii');
        setFormData({
            kode_gereja: decodeString3.split(';')[1],
            urutan:decodeString3.split(';')[4]
        });
        setFileName(decodeString3.split(';')[3]);
        setImagePreviewUrl(API_URL_IMAGE + '/gereja/'+fileName);
      }, [loading, gerejaSlider, id]);

      useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        getGerejaSliderById(decodeString.split(';')[0]);
        setNamaGereja(decodeString.split(';')[2]);
        setIdValues(decodeString.split(';')[5]);
      }, [getGerejaSliderById, id]);


    const {
        kode_gereja,
        urutan,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
        const data = new FormData();
        data.append("namafile", file);
        data.append("kode_gereja", formData.kode_gereja);
        data.append("urutan", formData.urutan);
        data.append("image_name", fileName);
        console.log(file);
        updateBranchesSlider(data, decodeString2.split(';')[0], history,idValues);

    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to={`/pipka/branches/slider/${idValues}`} className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardPIPKA">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <Link to="">Branches</Link>
                    </li>
                    <li className="breadcrumb-item active">Branches Slider</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Branches Slider : {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Urutan</label>
                      <select className="form-control" required name="urutan" value={urutan} onChange={e => onChange(e)}>
                      <option value="">--Pilih--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>

                      </select>
                    </div>
                  </div>
                </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <center>
                            <img style={{border: "3px solid #adb5bd", padding: "3px"}} src={imagePreviewUrl}
                                className="img-fluid mb-3"
                                alt="Gereja"
                            />
                            </center>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Image slider</label>
                            <input type="hidden" className="form-control" name="kode_gereja" value={kode_gereja} onChange={e => onChange(e)} />
                            <input type="file" className="form-control" placeholder="Image slider ..." name="namafile" onChange={fileOnChange} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditBranchesSlider.propTypes = {
    updateBranchesSlider: PropTypes.func.isRequired,
    getGerejaSliderById: PropTypes.func.isRequired,
    gerejaSlider: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    gerejaSlider: state.gerejaSlider
});

export default connect(mapStateToProps, { updateBranchesSlider, getGerejaSliderById })(withRouter(EditBranchesSlider));
