import axios from "../util/http-common";
import { setAlert } from "./alertAction";
import { GET_GEREJA_JADWAL_IBADAH,  GEREJA_JADWAL_IBADAH_ERROR } from "./types";

// Get Gereja Jadwal Ibadah by Id
export const getJadwalIbadah = id => async dispatch => {
    try{
        const res = await axios.get(`/api/gereja/jadual/ibadah/${id}`);
        dispatch({
            type: GET_GEREJA_JADWAL_IBADAH,
            payload: res.data[0],
        });

    }catch(err){
        dispatch({
            type: GET_GEREJA_JADWAL_IBADAH,
            payload: null,
        });
        dispatch({
            type: GEREJA_JADWAL_IBADAH_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Get Gereja Jadwal Ibadah by kode gereja
export const getJadwalIbadahByKodeGereja = kode_gereja => async dispatch => {
    try{
        const res = await axios.get(`/api/gereja/jadual/ibadah2/${kode_gereja}`);
        dispatch({
            type: GET_GEREJA_JADWAL_IBADAH,
            payload: res.data[0],
        });

    }catch(err){
        dispatch({
            type: GET_GEREJA_JADWAL_IBADAH,
            payload: null,
        });
        dispatch({
            type: GEREJA_JADWAL_IBADAH_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Get Gereja Jadwal Ibadah by kode gereja
export const getJadwalIbadahByKodeGerejaJadwal = (kode_gereja, jadwal_id) => async dispatch => {
    try{
        const res = await axios.get(`/api/gereja/jadual/ibadah3/${kode_gereja}/${jadwal_id}`);
        dispatch({
            type: GET_GEREJA_JADWAL_IBADAH,
            payload: res.data[0],
        });

    }catch(err){
        dispatch({
            type: GET_GEREJA_JADWAL_IBADAH,
            payload: null,
        });
        dispatch({
            type: GEREJA_JADWAL_IBADAH_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create Jadual Ibadah
export const createIbadah = (formData, history, path) => async dispatch => {
    try{
		const res = await axios.post(`/api/gereja/jadual/ibadah`, formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }
        history.push(`/jadual/ibadah/${path}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_JADWAL_IBADAH_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Gereja Jadual Ibadah Berdasarkan ID
export const updateJadwalIbadah = (formData, history, id, link) => async dispatch => {
    try{
        const res = await axios.put(`/api/gereja/jadual/ibadah/${id}`, formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push(`/jadual/ibadah/${link}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }

        dispatch({
            type: GEREJA_JADWAL_IBADAH_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });

    }
}

// Create Jadual Ibadah
export const createBranchesIbadah = (formData, history, path) => async dispatch => {
    try{
		const res = await axios.post(`/api/gereja/jadual/ibadah`, formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }
        history.push(`/pipka/branches/kegiatan/ibadah/${path}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_JADWAL_IBADAH_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Update Gereja Jadual Ibadah Berdasarkan ID
export const updateBranchesIbadah = (formData, history, id, link) => async dispatch => {
    try{
        const res = await axios.put(`/api/gereja/jadual/ibadah/${id}`, formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }


        history.push(`/pipka/branches/kegiatan/ibadah/${link}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }

        dispatch({
            type: GEREJA_JADWAL_IBADAH_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });

    }
}
