import axios from "../util/http-common";
import { setAlert } from "./alertAction";
import { GET_HOME_SLIDER, HOME_SLIDER_ERROR } from "./types";

// Get Gereja Slider
export const getHomeSlider = ()  => async dispatch => {
    try{
        const res = await axios.get(`/api/slider/home-slider`);
        console.log('MASUK: ' + JSON.stringify(res.data));
        dispatch({
            type: GET_HOME_SLIDER,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: HOME_SLIDER_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Get Gereja Slider Berdasarkan ID
export const getHomeSliderById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/slider/home-slider/${id}`);
        console.log('MASUK: ' + JSON.stringify(res.data));
        dispatch({
            type: GET_HOME_SLIDER,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: HOME_SLIDER_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Create Gereja Slider
export const createHomeSlider = (formData, history) => async dispatch => {
    try{
		const res = await axios.post(`/api/slider/home-slider`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }
        history.push('/sliderhome');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: HOME_SLIDER_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Gereja Slider Berdasarkan ID
export const updateHomeSlider = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/slider/home-slider/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }

		history.push('/sliderhome');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: HOME_SLIDER_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
