import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { updateBeritaCategory } from "../../../actions/beritaAction";
import { getBeritaCategoryById } from "../../../actions/beritaAction";

const EditBeritaCategory = ({ berita: { loading, beritaCategory }, getBeritaCategoryById, updateBeritaCategory, auth: { user }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [loadings, setLoadings] = useState(false);
    const [formData, setFormData] = useState({
        nama_kategori:'',
    });

    useEffect(() => {
        setFormData({
            nama_kategori: beritaCategory && (beritaCategory.nama_kategori ? beritaCategory.nama_kategori : ''),
        });
    }, [loading, beritaCategory]);

    useEffect(() => {
        getBeritaCategoryById(Buffer.from(id, 'base64').toString('ascii'));
      }, [getBeritaCategoryById, id]);

    const {
        nama_kategori,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        updateBeritaCategory(formData, Buffer.from(id, 'base64').toString('ascii'), history);

    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/berita/category" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Kategori Berita</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Kategori Berita {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Nama Kategori</label>
                            <input type="text" className="form-control" placeholder="Nama Kategori ..." required name="nama_kategori" value={nama_kategori} onChange={e => onChange(e)} />
                            </div>
                        </div>
                    </div>

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditBeritaCategory.propTypes = {
    updateBeritaCategory: PropTypes.func.isRequired,
    getBeritaCategoryById: PropTypes.func.isRequired,
    berita: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    berita: state.berita
});

export default connect(mapStateToProps, { updateBeritaCategory, getBeritaCategoryById })(withRouter(EditBeritaCategory));
