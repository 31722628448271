import { combineReducers } from "redux";
import alert from "./alertReducer";
import auth from "./authReducer";
import gereja from './gerejaReducer';
import pemimpin from './pemimpinReducer';
import profile from './profileReducer';
import jadwal from './gerejaJadwalReducer';
import jadwalKegiatan from './gerejaJadwalKegiatanReducer';
import jadwalIbadah from './gerejaJadwalIbadahReducer';
import pembicara from './pembicaraReducer';
import gerejaSlider from './gerejaSliderReducer';
import jemaat from './jemaatReducer';
import berita from './beritaReducer';
import beritaCategory from './beritaCategoryReducer';
import firman from './firmanReducer';
import firmanCategory from './firmanCategoryReducer';
import karya from './karyaReducer';
import karyaCategory from './karyaCategoryReducer';
import action from './actionReducer';
import beritaGkmi from './beritaGkmiReducer';
import beritaGkmiData from './beritaGkmiDataReducer';
import pipka from './pipkaReducer';
import homeSlider from './homeSliderReducer';
import dashboard from './dashboardReducer';
import connect from './connectReducer';
import sinode from './sinodeReducer';

export default combineReducers({
  alert,
  auth,
  gereja,
  pemimpin,
  profile,
  jadwal,
  jadwalKegiatan,
  jadwalIbadah,
  pembicara,
  gerejaSlider,
  jemaat,
  berita,
  beritaCategory,
  firman,
  firmanCategory,
  karya,
  karyaCategory,
  action,
  beritaGkmi,
  beritaGkmiData,
  pipka,
  sinode,
  homeSlider,
  dashboard,
  connect
});
