import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link, useParams }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

const ActionKegiatan = ({ auth: { user, token } }) => {
    const {id} = useParams();
    const [dataKaryaProduk, setKaryaProduk] = useState([]);
    const [namaToko, setNamaToko] = useState();
    const [linkCreate, setLinkCreate] = useState();
    const [status, setStatus] = useState(true);
    const { selected, toggleSelected } = useState();
    useEffect(() =>{
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        let kodeToko = decodeString.split(';')[0];
        // console.log('PROFILE: ' + kodeToko + " - " );
        // console.log('PROFILE apppp : ' + id + " - " );
        let link='';
        let codeMasterChurch2 = 0;
        link = `https://gkmi.one:8080/api/backendaction/kegiatan/${kodeToko}`;

        fetch(`${link}`, {
            headers: {
                'x-auth-token': token
            }
        })
          .then((response) => {
            setStatus(response.ok);
            return response.json();
          })
          .then((res) => {
          //  console.log("DATA: " + res);
            const data = {
              columns: [
                {
                    label: "No.",
                    field: "no",
                    sort: "asc",
                    width: 3,
                },
                // {
                //     label: "Gambar",
                //     field: "image_produk",
                //     sort: "asc",
                //     width: 150,
                // },
                {
                    label: "Kegiatan",
                    field: "nama",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Keterangan",
                    field: "keterangan",
                    sort: "asc",
                    width: 150,
                },

                {
                    label: "Kegiatan",
                    field: "kegiatan",
                    sort: "asc",
                    width: 150,
                },
                // {
                //     label: "Tautan",
                //     field: "produk_tautan",
                //     sort: "asc",
                //     width: 150,
                // },
                // {
                //     label: "Endorser",
                //     field: "endorser",
                //     sort: "asc",
                //     width: 150,
                // },
                // {
                //     label: "Active",
                //     field: "status",
                //     sort: "asc",
                //     width: 150,
                // },
                {
                    label: "Action",
                    field: "action",
                    width: 150,
                },
              ],
              rows: res.map((apiData, i) => {
                 // let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');

                  let base64EncodeString = Buffer.from(`${id};${apiData._id}`).toString('base64');
                  console.log('base:::ss: ' + apiData._id + " - " );
                //   console.log("base:::ss " + apiData.id);


                  return({
                    no: (i+1),
                    // image_produk: apiData.image_produk.map((apiData2, j) => {
                    //     return(
                    //         <center><img src={(apiData2.avatar ? (apiData2.avatar !== undefined ? apiData2.tautan + '/' + apiData2.avatar : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')} className="profile-user-img img-fluid img-circle" alt="Avatar" /><br/></center>
                    //     )
                    // }),
                    nama: <span>{apiData.nama_categori}</span>,
                //     detail_produk: <span>Ukuran: {apiData.ukuran_produk} <br/>Berat: {apiData.berat_produk} <br/>Kondisi: {apiData.kondisi_produk}</span>,

                keterangan: <span>{apiData.keterangan}</span>,
                tanggal: <span>{apiData.tanggal}</span>,
                kegiatan: <span>Tanggal : {apiData.tanggal} <br/>Mulai : {apiData.jam_mulai} <br/>Selesai : {apiData.jam_selesai}</span>,
                //     produk_tautan: apiData.product_tautan.map((apiData3, k) => {
                //         return(
                //             <span><Link className="btn btn-block btn-outline-success btn-sm" to={apiData3.tautan} target="_blank">{apiData3.nama_tautan}</Link><br/></span>
                //         )
                //     }),
                //     endorser: apiData.product_endorser ? <center><img src={(apiData.product_endorser.pemimpin_image ? (apiData.product_endorser.pemimpin_image !== undefined ? apiData.product_endorser.pemimpin_tautan + '/' + apiData.product_endorser.pemimpin_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')} className="profile-user-img img-fluid img-circle" alt="Avatar" /><br/><span>{apiData.product_endorser.nama_pemimpin}</span></center> : '',
                //     status:  <div className="toggle-container" onClick={toggleSelected}>
                //     <div className={`dialog-button ${selected ? "" : "disabled"}`}>
                //       {selected ? "YES" : "NO"}
                //     </div>
                //   </div>,
                   action: <span><Link className="btn btn-block bg-gradient-primary" to={`/action/kegiatan/edit-actionkegiatan/${base64EncodeString}`}>Ubah</Link>

                    <Link className="btn btn-block bg-gradient-green" to={`/action/kegiatan/absensi/${base64EncodeString}`}>Absen</Link>

                    </span>
                  });

              }),

            };
            setKaryaProduk(data);
          });
    }, [user]);

    useEffect(() =>{
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
        setNamaToko(decodeString2.split(';')[1]);
       setLinkCreate(`/action/kegiatan/${id}/create-actionkegiatan`);
    }, [id]);

    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={dataKaryaProduk} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }
    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <div className="container-fluid">
                                <div className="row mb-4">
                                    <Link to='/action' className="btn btn-block bg-gradient-danger">Back</Link>
                                    <Link to={linkCreate} className="btn btn-block bg-gradient-green  ">Tambah Action Kegiatan</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Action Kegiatan</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita Category */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Action Kegiatan </h3>
                    <div className="card-tools">
                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(ActionKegiatan);
