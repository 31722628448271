import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { GET_BERITA_CATEGORY, GET_BERITA_CATEGORY2, GET_BERITA_CATEGORY3,  BERITA_CATEGORY_ERROR, GET_BERITA, BERITA_ERROR } from "./types";

// Get Berita Category
export const getBeritaCategory = () => async dispatch => {
    try{
        const res = await axios.get(`/api/berita/backend/beritakategori/kategori`);
        console.log('MASUK SINI: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_BERITA_CATEGORY2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: BERITA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Get Berita Category 2
export const getBeritaCategory2 = () => async dispatch => {
    try{
        const res = await axios.get(`/api/berita/backend/beritakategori/kategori`);
        console.log('MASUK SINI 1: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_BERITA_CATEGORY3,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: BERITA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Get Berita Category by Id
export const getBeritaCategoryById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/berita/backend/beritakategori/kategori/${id}`);

        dispatch({
            type: GET_BERITA_CATEGORY,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: BERITA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Create Berita Categori
export const createBeritaCategory = (formData, history) => async dispatch => {
    try{
		const res = await axios.post(`/api/berita/backend/beritakategori`, formData);
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            try{
                const res2 = await axios.get("/api/berita/backend/beritakategori/kategori");
                dispatch({
                    type: GET_BERITA_CATEGORY,
                    payload: res2.data[0],
                });
            }catch(err2){
                dispatch({
                    type: BERITA_CATEGORY_ERROR,
					payload: { msg: err2.message, status: err2 }

                });
            }
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/berita/category');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Berita Category Berdasarkan ID
export const updateBeritaCategory = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/berita/backend/beritakategori/${id}`, formData);

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            try{
                const res2 = await axios.get("/api/berita/backend/beritakategori/kategori");
                dispatch({
                    type: GET_BERITA_CATEGORY,
                    payload: res2.data[0],
                });
            }catch(err2){
                dispatch({
                    type: BERITA_CATEGORY_ERROR,
                    //payload: { msg: err2.response.statusText, status: err2.response.status }
					payload: { msg: err2.message, status: err2 }

                });
            }
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/berita/category');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Get Berita by Id
export const getBeritaById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/berita/backend/berita/edit/${id}`);

        dispatch({
            type: GET_BERITA,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: BERITA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Create Berita
export const createBerita = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/berita/backend/berita`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/berita');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Berita Berdasarkan ID
export const updateBerita = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/berita/backend/berita/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/berita');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
