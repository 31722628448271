import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {API_URL_IMAGE} from "../../../config/apiUrl";
import { MDBDataTable } from "mdbreact";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import store from "../../../store";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import { getGereja } from "../../../actions/gerejaAction";
import { getPembicara } from "../../../actions/pembicaraAction";

const Pembicara = ({ auth: { user,token }}) => {
    const [dataPembicara, setPembicara] = useState([]);
 const [title, setTitle] = useState();
    const [status, setStatus] = useState(true);
const [formData, setFormData] = useState({
        kode_gereja: ''
    });

    const {
        kode_gereja
      } = formData;
    
    const onChange = e => {
        console.log('ADA DATA: ' + e.target.options[e.target.selectedIndex].text);
        setTitle(e.target.options[e.target.selectedIndex].text);
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const deleteData = (id) => {
        let url2 = `https://gkmi.one:8080/api/pembicara/delete/${id}`
        axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
            const del = dataPembicara.filter(tableRow => id !== tableRow._id)
            setPembicara(del);
        })
      }
      useEffect(() =>{
        fetchData();
   }, [user]);

   const fetchData = async () => {

    let codeChurch = '';
        if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
            codeChurch = user && (user.kode_gereja);
        }else{
            codeChurch = user && (user.gereja[0].kode_gereja);
        }
        console.log('AA: ' + codeChurch);
        let link='';
        let codeMasterChurch2 = 0;
        link = `https://gkmi.one:8080/api/pembicara/search/${codeChurch}`;
        
   const response = await axios.get(link, {
    headers: {
        'x-auth-token': token
    }
  });
 // console.log('MASUK: ' + JSON.stringify(response.data[0].gerejaSlider));
 setPembicara(response.data[0].pembicara);

}

const data = {
    columns: [
      {
          label: "No.",
          field: "no",
          sort: "asc",
          width: 10,
      },
      {
          label: "Photo",
          field: "namafile_image",
          sort: "asc",
          width: 150,
      },
      {
          label: "Nama",
          field: "nama",
          sort: "asc",
          width: 150,
      },
      {
          label: "Gelar",
          field: "gelar",
          sort: "asc",
          width: 150,
      },
      {
          label: "No. Handphone",
          field: "phone",
          sort: "asc",
          width: 150,
      },
      {
          label: "Keterangan",
          field: "keterangan",
          sort: "asc",
          width: 150,
      },
      {
          label: "Action",
          field: "action",
          width: 150,
      },
    ],

    
   rows: dataPembicara.map((apiData, i) => {
    let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
    //let base64EncodeString = Buffer.from(`${apiData._id};${formData.kode_gereja};${title}`).toString('base64');
     // let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
     return({
        no: (i+1),
        namafile_image: <center><img src={(apiData.namafile_image ? (apiData.namafile_image !== undefined ? API_URL_IMAGE  + '/user/' + apiData.namafile_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')} className="profile-user-img img-fluid img-circle" alt="Avatar" /></center>,
        nama: <span>{apiData.nama}</span>,
        gelar: <span>{apiData.gelar}</span>,
        phone: <span>{apiData.no_handphone}</span>,
        keterangan: <span>{apiData.keterangan}</span>,
        action: <span> <Link className="btn btn-block bg-gradient-primary" to={`/jadual/pembicara/edit-pembicara/${base64EncodeString}`}>Ubah</Link>
        <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData._id) } }>Hapus</a></span>
      });

}),
};

function cekStatus(){
    if(status){
        return(
            <Fragment>
                <MDBDataTable responsive striped bordered hover data={data} />
            </Fragment>
        );
    }else{
        return(
            <Fragment>
                <span>Data tidak ada</span>
            </Fragment>
        )
    }
}

return(
    <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-4">
                    <Link to={`/jadual/pembicara/create-pembicara/${user && (Buffer.from(`${user.gereja[0].kode_gereja};${user && (user.gereja[0].nama)}`).toString('base64'))}`} className="btn btn-block bg-gradient-success">Tambah Data Pembicara</Link>
                    </div>
                    <div className="col-sm-8">
                        <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Pembicara</li>
                        </ol>
                    </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Display Berita Category */}
            <div className="card">
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Pembicara</h3>
                <div className="card-tools">


                </div>
                </div>
                <div className="card-body">
                <Alert />
                {cekStatus()}
                </div>
                {/* /.card-body */}

            </div>
            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
    </Fragment>
)
};
   // const [title, setTitle] = useState();
    // const [formData, setFormData] = useState({
    //     kode_gereja: ''
    // });

    // const {
    //     kode_gereja
    //   } = formData;
    
    // const onChange = e => {
    //     console.log('ADA DATA: ' + e.target.options[e.target.selectedIndex].text);
    //     setTitle(e.target.options[e.target.selectedIndex].text);
    //     setFormData({...formData, [e.target.name]: e.target.value});
    // };
    
     
    // useEffect(() =>{
      
    //     let code = "0";//formData.kode_gereja;
    //     let codeChurch = user && (user.gereja[0].kode_gereja);
    //     if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
    //         console.log('USER1: ' + user.level_user + " - " + user.gereja[0].kode_gereja);
    //         if(formData.kode_gereja !== ""){
    //             code = formData.kode_gereja;
    //         }
    //     }else{
    //         code = codeChurch;
    //         console.log('USER: ' + codeChurch);
    //     }
    //     store.dispatch(getPembicara(code));

    //     console.log('KODE: ' + code);
    // }, [user, formData.kode_gereja]);

    
    // useEffect(() =>{
    //     const script = document.createElement("script");
    //     script.src = "dist/js/datatableContent.js";
    //     script.async = true;
    //     document.body.appendChild(script);

    //     store.dispatch(getGereja());        
    // }, []);

    // function cekLevelAkses(){
    //     if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
    //       return (
    //         <Fragment>
    //             {/* Content Wrapper. Contains page content */}
    //             <div className="content-wrapper">
    //             {/* Content Header (Page header) */}
    //             <section className="content-header">
    //             <div className="container-fluid">
    //                 <div className="row mb-2">
                        
    //                     <div className="col-sm-12">
    //                     <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
    //                         <li className="breadcrumb-item">
    //                         <Link to="/dashboard">Home</Link>
    //                         </li>
    //                         <li className="breadcrumb-item active">
    //                             <Link to="/jadual/kegiatan">Jadual</Link>
    //                         </li>
    //                         <li className="breadcrumb-item active">Pembicara</li>
    //                     </ol>
    //                     </div>
    //                 </div>
    //                 </div>
    //             </section>
    //             {/* Main content */}
    //             <section className="content">
    //             <div className="container-fluid">
    //                 {/* Default box */}
    //                 <div className="card">
    //                 <form className="form-horizontal">
    //                 <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
    //                     <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Cari Gereja</h3>
    //                     <div className="card-tools">
    //                     <button
    //                         type="button"
    //                         className="btn btn-tool"
    //                         data-card-widget="collapse"
    //                         data-toggle="tooltip"
    //                         title="Collapse"
    //                     >
    //                         <i className="fas fa-minus" />
    //                     </button>
                        
    //                     </div>
    //                 </div>
    //                 <div className="card-body">
    //                     <div className="form-group row">
    //                         <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">Nama Gereja</label>
    //                         <div className="col-sm-9">
    //                             <select className="form-control" required name="kode_gereja" value={kode_gereja} onChange={e => onChange(e)}>
    //                                 <option value="">--Pilih--</option>
    //                                     {
    //                                         gereja && gereja.gereja.map((grj, index) => <option key={grj._id} value={grj.kode_gereja}>{grj.nama}</option>)
    //                                     }
    //                             </select>
    //                         </div>
    //                     </div>
                        
    //                 </div>
    //                 {/* /.card-body */}
                   
    //                 </form>
    //                 </div>
    //                 {/* /.card */}

    //                 </div>
    //             </section>
    //             {/* /.content */}

    //             <section className="content">
    //             <div className="container-fluid">
    //                 {/* Default box */}
    //                 <div className="card">
                    
    //                 <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
    //                     <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Data Pembicara {title === "--Pilih--" ? "" : title}</h3>
    //                     <div className="card-tools">
    //                     <button
    //                         type="button"
    //                         className="btn btn-tool"
    //                         data-card-widget="collapse"
    //                         data-toggle="tooltip"
    //                         title="Collapse"
    //                     >
    //                         <i className="fas fa-minus" />
    //                     </button>
                        
    //                     </div>
    //                 </div>
    //                 <div className="card-body">
    //                     <Alert />
    //                     {/*<ListKegiatan user={user} kode={formData.kode_gereja} />*/}
    //                     {
    //                         formData.kode_gereja !== "" ?
    //                         <div className="col-sm-5" style={{marginLeft: "-15px", marginBottom: "10px"}}>
    //                             <Link to={`/jadual/pembicara/create-pembicara/${Buffer.from(`${formData.kode_gereja};${title}`).toString('base64')}`} className="btn btn-block bg-gradient-success">Create Pembicara</Link>
    //                         </div>
    //                         : ''
    //                     }
    //                     <table id="example1" className="table table-bordered table-striped">
    //                         <thead>
    //                             <tr>
    //                                 <td>No.</td>
    //                                 <td><center>Photo</center></td>
    //                                 <td>Gelar</td>
    //                                 <td>Nama Pembicara</td>
    //                                 <td>No. Handphone</td>
    //                                 <td>Keterangan</td>
    //                                 <td>Action</td>
    //                             </tr>
    //                         </thead>
    //                         <tbody>
    //                             {
    //                                 pembicara.pembicara && pembicara.pembicara.length > 0 ?
    //                                 pembicara.pembicara.map((pmb, index) => {
    //                                     let base64EncodeString = Buffer.from(`${pmb._id};${formData.kode_gereja};${title}`).toString('base64');
    //                                     return(
    //                                         <tr key={pmb._id}>
    //                                             <td width="2%"><center>{index+1}</center></td>
    //                                             <td>
    //                                                 <center>
    //                                                 <img src={API_URL_IMAGE + '/' + (pmb.namafile_image !== '' ? 'user/' + pmb.namafile_image : 'avatar.jpg')}
    //                                                     className="profile-user-img img-fluid img-circle"
    //                                                     alt="User"
    //                                                 />
    //                                                 </center>
    //                                             </td>
    //                                             <td>{pmb.gelar}</td>
    //                                             <td>{pmb.nama}</td>
    //                                             <td>{pmb.no_handphone}</td>
    //                                             <td>{pmb.keterangan}</td>
    //                                             <td>
    //                                                 <Link className="btn btn-block bg-gradient-primary" to={`/jadual/pembicara/edit-pembicara/${base64EncodeString}`}>Ubah</Link>
    //                                             </td>
    //                                         </tr>
    //                                     );
    //                                 }): <tr><td colSpan={7}><center>Data tidak ditemukan</center></td></tr>
    //                             }
    //                         </tbody>
    //                     </table>
    //                 </div>
    //                 </div>
    //                 {/* /.card */}

    //                 </div>
    //             </section>
    //             {/* /.content */}

    //             </div>
    //             {/* /.content-wrapper */}
    //         </Fragment>
    //       );
    //     }else{
    //         return(
    //         <Fragment>
    //             {/* Content Wrapper. Contains page content */}
    //             <div className="content-wrapper">
    //                 {/* Content Header (Page header) */}
    //                 <section className="content-header">
    //                 <div className="container-fluid">
    //                     <div className="row mb-2">
                            
    //                         <div className="col-sm-12">
    //                         <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
    //                             <li className="breadcrumb-item">
    //                             <Link to="/dashboard">Home</Link>
    //                             </li>
    //                             <li className="breadcrumb-item active">
    //                                 <Link to="/jadual/kegiatan">Jadual</Link>
    //                             </li>
    //                             <li className="breadcrumb-item active">Pembicara</li>
    //                         </ol>
    //                         </div>
    //                     </div>
    //                     </div>
    //                 </section>                
    //                 {/* /.content */}

    //                 <section className="content">
    //                 <div className="container-fluid">
    //                     {/* Default box */}
    //                     <div className="card">
                        
    //                     <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
    //                         <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Data Pembicara {title === "--Pilih--" ? "" : title}</h3>
    //                         <div className="card-tools">
    //                         <button
    //                             type="button"
    //                             className="btn btn-tool"
    //                             data-card-widget="collapse"
    //                             data-toggle="tooltip"
    //                             title="Collapse"
    //                         >
    //                             <i className="fas fa-minus" />
    //                         </button>
                            
    //                         </div>
    //                     </div>
    //                     <div className="card-body">
    //                         <Alert />                            
    //                         <div className="col-sm-5" style={{marginLeft: "-15px", marginBottom: "10px"}}>
    //                             <Link to={`/jadual/pembicara/create-pembicara/${user && (Buffer.from(`${user.gereja[0].kode_gereja};${user && (user.gereja[0].nama)}`).toString('base64'))}`} className="btn btn-block bg-gradient-success">Tambah Data Pembicara</Link>
    //                         </div>                                
    //                         <table id="example1" className="table table-bordered table-striped">
    //                             <thead>
    //                                 <tr>
    //                                     <td>No.</td>
    //                                     <td><center>Photo</center></td>
    //                                     <td>Gelar</td>
    //                                     <td>Nama Pembicara</td>
    //                                     <td>No. Handphone</td>
    //                                     <td>Keterangan</td>
    //                                     <td>Action</td>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                             {
    //                                 pembicara.pembicara && pembicara.pembicara.length > 0 ?
    //                                 pembicara.pembicara.map((pmb, index) => {
    //                                     let base64EncodeString = Buffer.from(`${pmb._id};${user && (user.gereja[0].kode_gereja)};${user && (user.gereja[0].nama)}`).toString('base64');
    //                                     return(
    //                                         <tr key={pmb._id}>
    //                                             <td width="2%"><center>{index+1}</center></td>
    //                                             <td>
    //                                                 <center>
    //                                                 <img src={API_URL_IMAGE + '/' + (pmb.namafile_image !== '' ? 'user/' + pmb.namafile_image : 'avatar.jpg')}
    //                                                     className="profile-user-img img-fluid img-circle"
    //                                                     alt="User"
    //                                                 />
    //                                                 </center>
    //                                             </td>
    //                                             <td>{pmb.gelar}</td>
    //                                             <td>{pmb.nama}</td>
    //                                             <td>{pmb.no_handphone}</td>
    //                                             <td>{pmb.keterangan}</td>
    //                                             <td>
    //                                                 <Link className="btn btn-block bg-gradient-primary" to={`/jadual/pembicara/edit-pembicara/${base64EncodeString}`}>Ubah</Link>
    //                                             </td>
    //                                         </tr>
    //                                     );
    //                                 }): <tr><td colSpan={7}><center></center></td></tr>
    //                             }
                                   
    //                             </tbody>
    //                         </table>
    //                     </div>
    //                     </div>
    //                     {/* /.card */}

    //                     </div>
    //                 </section>
    //                 {/* /.content */}

    //                 </div>
    //                 {/* /.content-wrapper */}
    //         </Fragment>
    //         );
    //    }
    //   }
    

//     return(
//         <Fragment>
//             <Header />
//             <Navbar />
//             {cekLevelAkses()}  
//             <Footer />
//         </Fragment>
//     )
// }

// Pembicara.propTypes = {
//     gereja: PropTypes.object.isRequired,
//     getPembicara: PropTypes.func.isRequired,
//     auth: PropTypes.object.isRequired
//   }
  
//   const mapStateToProps = state => ({
//     gereja: state.gereja,
//     pembicara: state.pembicara,
//     auth: state.auth
//   });

// export default connect(mapStateToProps, { getGereja, getPembicara })(Pembicara);

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(Pembicara);