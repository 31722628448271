import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {useParams, Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import moment from "moment";

const DataDatabase = ({ auth: { user, token } }) => {
    const [dataDatabase, setDataDatabase] = useState([]);
    const [status, setStatus] = useState(true);
    const {id} = useParams();
    const [persentaseValue, setPersentase] = useState('');
    useEffect(() =>{
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        let kode_project = decodeString.split(';')[0];
        setPersentase(decodeString.split(';')[1])
        fetchData(kode_project);
   }, [user,id]);

   const fetchData = async (kode_project) => {
    //   
      let link='';
      link = `https://gkmi.one:8080/api/sinodebackend/database/data/${kode_project}`;
      const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
        setDataDatabase(response.data);
    }

    

    const data = {
        columns: [
          {
            label: "No.",
            field: "no",
            sort: "asc",
            width: 10,
          },
         
         
          {
              label: "Nama",
              field: "nama",
              sort: "asc",
              width: 200,
          },
          {
            label: "Data 1",
            field: "data1",
            sort: "asc",
            width: 20,
        },
       

        {
            label: "Data 2",
            field: "data2",
            sort: "asc",
            width: 20,
        },
        {
            label: "Data 3",
            field: "data3",
            sort: "asc",
            width: 20,
        },
        {
            label: "Data 4",
            field: "data4",
            sort: "asc",
            width: 20,
        },
        {
            label: "Data 5",
            field: "data5",
            sort: "asc",
            width: 20,
        },
         
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 30,
        },
          {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 70,
            },

        ],
        rows: dataDatabase.map((apiData, i) => {
        //   let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
          let base64EncodeString = Buffer.from(`${apiData._id};${id}`).toString('base64');
          var active;
                  var classStatus;
                  if(apiData.active==1){
                    active="Active";
                    classStatus="badge bg-gray"
                        }else{
                            active="In Active";
                            classStatus="badge badge-danger"
                        }
                        var persentase1="";
                        var persentase2="";
                        var persentase3="";
                        var persentase3="";
                        var persentase4="";
                        var persentase5="";
                        if(persentaseValue=="yes"){
                        if(!apiData.field1 == ""){
                            persentase1="%"
                        }
                         if(!apiData.field2 ==""){
                            persentase2="%"
                        }
                         if(!apiData.field3 == ""){
                            persentase3="%"
                        }
                         if(!apiData.field4 ==""){
                            persentase4="%"
                        }
                         if(!apiData.field5 ==""){
                            persentase5="%"
                        }
                    }
                        
                        
                        

          return({
              no: (i+1),
            
              nama: <span>{apiData.nama}</span>,
              data1: <span>{apiData.field1} {persentase1}</span>,
              data2: <span>{apiData.field2} {persentase2}</span>,
              data3: <span>{apiData.field3} {persentase3}</span>,
              data4: <span>{apiData.field4} {persentase4}</span>,
              data5: <span>{apiData.field5} {persentase5}</span>,
              status:<span class={classStatus}>{active}</span>,
              action: <span><Link className="btn btn-block bg-gradient-primary" to={`/database/datadatabase/edit-datadatabase/${base64EncodeString}`}>Ubah</Link>
            
             </span>
        });
      }),
    };



    function cekStatus(){
        if(status){

          return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-2">
                    <Link to={`/database`} className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                        <div className="col-sm-3">
                           <Link  to={`/database/datadatabase/create-datadatabase/${id}`} className="btn btn-block bg-gradient-green  ">Tambah Data</Link>
                            
                        </div>
                        <div className="col-sm-7">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardSinode">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Data Database</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="content">
                <div className="container-fluid">
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Data </h3>
                    <div className="card-tools">
                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(DataDatabase);
