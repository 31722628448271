import React, { Fragment, useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from 'axios';
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getDashbordBeritaGkmi } from "../../actions/dashboardAction";

const DashboardBeritaGkmi = ({ dashboard, getDashbordBeritaGkmi, auth: { user,token } }) => {
const [langganan, setLangganan] = useState([]);
const [beritaKetegory, setBeritaKetegory] = useState([]);
const [beritaTop, setBeritaTop] = useState([]);
  useEffect(() => {
    getDashbordBeritaGkmi();
     fetchData();
     fetchDataBerita();
     fetchDataBeritatop();
  }, user);
  const fetchData = async () => {

      let link='';
      // link = 'https://gkmi.one:8080/api/beritagkmibackend/langganan';
       link = 'https://gkmi.one:8080/api/dashboard/dashboardlangganan';
      const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
        setLangganan(response.data);
    }
    const data = {
      columns: [

        {
            label: "Nama",
            field: "nama",
            sort: "asc",
            width: 150,
        },


      {
          label: "Member",
          field: "user",
          sort: "asc",
          width: 150,
      },
      {
          label: "Total",
          field: "harga",
          sort: "asc",
          width: 150,
      },


      ],
      rows: langganan.map((apiData, i) => {

        let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');

        return({

            nama: <span>{apiData.nama}</span>,


            user: <span>{apiData.user_langganan}</span>,
            harga: <span>Rp. {apiData.total}</span>,


      });
    }),
  };
  const fetchDataBerita = async () => {

    let link='';
    //  link = 'https://gkmi.one:8080/api/beritagkmibackend/langganan';
     link = 'https://gkmi.one:8080/api/dashboard/dashboardBerita';
    const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
      });
      setBeritaKetegory(response.data);
  }
  const dataBerita = {
    columns: [

      {
          label: "Nama Kategori",
          field: "nama",
          sort: "asc",
          width: 150,
      },

    {
        label: "Publish",
        field: "release",
        sort: "asc",
        width: 150,
    },
    {
        label: "View",
        field: "viewCategory",
        sort: "asc",
        width: 150,
    },


    ],
    rows: beritaKetegory.map((apiData, i) => {

      let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');

      return({

          nama: <span>{apiData.nama}</span>,


          release: <span>{apiData.release}</span>,
            viewCategory: <span>{apiData.viewCategory}</span>,



    });
  }),
  };
  const fetchDataBeritatop = async () => {

  let link='';
    link = 'https://gkmi.one:8080/api/dashboard/dashboardBeritatop';
  //  link = 'http://localhost:8080/api/dashboard/dashboardBerita';
  const response = await axios.get(link, {
        headers: {
            'x-auth-token': token
        }
    });

    setBeritaTop(response.data);
}
const dataBeritaTop = {
  columns: [
    {
      label: "No.",
      field: "no",
      sort: "asc",
      width: 10,
  },
    {
        label: "Berita",
        field: "nama",
        sort: "asc",
        width: 150,
    },
    {
      label: "Kategori",
      field: "kategori",
      sort: "asc",
      width: 100,
  },
  {
    label: "Edisi",
    field: "edisi",
    sort: "asc",
    width: 100,
},
{
  label: "Status",
  field: "status",
  sort: "asc",
  width: 100,
},

  {
      label: "View",
      field: "view",
      sort: "asc",
      width: 150,
  },


  ],
  rows: beritaTop.map((apiData, i) => {

    let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');

    return({
      no: (i+1),
        nama: <span>{apiData.judul_berita}</span>,
        kategori:<span>{apiData.kategori}</span>,
        edisi:<span>{apiData.edisi}</span>,
        status:<span>{apiData.status}</span>,
        view: <span>{apiData.view}</span>,



  });
}),
};
  return (
    <Fragment>
      <Header />
      <Navbar />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        {/* Main content */}
        <section className="content">

          <div class="container-fluid">

            <div class="row">
              <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.anggota)}</h3>
                    <p style={{color: 'white'}}>User Terdaftar</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.langganan)}</h3>
                    <p style={{color: 'white'}}>Langganan</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-shopping-cart"></i>
                  </div>
                 <a href="/dashboardBeritaGkmi/langganan" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.berita)}</h3>
                    <p style={{color: 'white'}}>Berita</p>
                  </div>
                  <div class="icon">
                    <i class="far fa-bookmark"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.kontibutor)}</h3>
                    <p style={{color: 'white'}}>Kontributor</p>
                  </div>
                  <div class="icon">
                    <i class="far fa-bookmark"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>
            </div>

            <div class="row">
            <div className="col-sm-6">
            <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Langganan Berita</h3>
                    <div className="card-tools">
                    </div>
                    </div>
                    <div className="card-body">
                    <MDBDataTable responsive striped bordered hover data={data} />
                </div>
              </div>
              </div>
              <div className="col-sm-6">
              <div className="card">
                   <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                   <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Berita Publish</h3>
                   <div className="card-tools">
                   </div>
                   </div>
                   <div className="card-body">
                   <MDBDataTable responsive striped bordered hover data={dataBerita} />
               </div>
             </div>
             </div>
              </div>

              <div class="row">
              <div className="col-sm-12">
                <div className="card">
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Berita Top 10</h3>
                <div className="card-tools">
                </div>
                </div>
                <div className="card-body">
                <MDBDataTable responsive striped bordered hover data={dataBeritaTop} />
                </div>
                </div>
                </div>
                </div>



          </div>

        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </Fragment>
  );
};


DashboardBeritaGkmi.propTypes = {
  dashboard: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  auth: state.auth
});

export default connect(mapStateToProps, { getDashbordBeritaGkmi })(DashboardBeritaGkmi);
