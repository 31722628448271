import React, { Fragment, useState, useEffect,useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import DatePicker from "react-datepicker";
import moment from "moment";
import { updateProjectProgress } from "../../../actions/pipkaAction";
import { getProjectProgressById } from "../../../actions/pipkaAction";

import { SunEditorAtom } from '../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditProjectProgress = ({ pipka: { projectProgress, loading }, getProjectProgressById, updateProjectProgress, auth: { user, token }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [idValue, setIdValue] = useState('');
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [isi, setIsi] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl]  = useState(defaultImage);
    const editor = useRef();
    const [values, setValues] = useState({
      status: '1',
    });
    const [formData, setFormData] = useState({
   tanggal: new Date(),
      judul_project:'',
      progress_project:'',
      dana_terkumpul:'',
        images_id:'',
    });
    const [isiProject, setProject] = useState();


    const {
      judul_project,
      progress_project,
      dana_terkumpul,
        images_id,
        tanggal
    } = formData;

    useEffect(() => {


      if(projectProgress && projectProgress.images[0]){
            console.log('KESINI KAN');
            setFormData({
              judul_project: projectProgress && (projectProgress.judul_project ? projectProgress.judul_project : ''),

              dana_terkumpul: projectProgress && (projectProgress.dana_terkumpul ? projectProgress.dana_terkumpul : ''),
            tanggal:  projectProgress && (projectProgress.tanggal  ?  new Date(projectProgress.tanggal) : ''),
                images_id: projectProgress && (projectProgress.images[0].id ? projectProgress.images[0].id : '')
            });
        }else{
            setFormData({
              judul_project: projectProgress && (projectProgress.judul_project ? projectProgress.judul_project : ''),

              dana_terkumpul: projectProgress && (projectProgress.dana_terkumpul ? projectProgress.dana_terkumpul : ''),
            tanggal:  projectProgress && (projectProgress.tanggal  ?  new Date(projectProgress.tanggal) : ''),
                images_id: '0',
            });
        }

        setProject((projectProgress && (projectProgress.progress_project ? projectProgress.progress_project : '')));
        setValues({ status: projectProgress && (projectProgress.active.toString()) });

	}, [loading, projectProgress]);

    useEffect(() => {
      if(projectProgress && (projectProgress.images[0])){
            setFileName(projectProgress && (projectProgress.images[0].nama_image ? projectProgress.images[0].nama_image : ''));
            console.log('NAMA: ' + fileName);
            setImagePreviewUrl((projectProgress && (projectProgress.images[0].tautan ? projectProgress.images[0].tautan : ''))+fileName);
        }else{
            setImagePreviewUrl(defaultImage);
        }

    }, [loading, projectProgress, fileName]);

    useEffect(() => {
      let decodeString = Buffer.from(id, 'base64').toString('ascii');
      setIdValue(decodeString.split(';')[1])
      getProjectProgressById(decodeString.split(';')[0]);
    }, [getProjectProgressById, id]);



    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const setStatus = (event) => {
      //console.log(event.target.value);
      setValues({ ...values, status: event.target.value });
      //console.log(values);
    };
      function uploadImageCallBack(file, uploadHandler) {
        const uploadFile = file[0];
        console.log('KESINI: ' + uploadFile);
        return new Promise(
          (resolve, reject) => {
            const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
            xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
            xhr.setRequestHeader('x-auth-token', token);
            const data = new FormData(); // eslint-disable-line no-undef
            data.append('file', uploadFile);
            data.append("tautan", API_URL_IMAGE + '/ckeditor/')
            xhr.send(data);
            xhr.addEventListener('load', () => {
              const response = JSON.parse(xhr.responseText);
              console.log('OK: ' + response);
              uploadHandler(response);
              resolve(response);
            });
            xhr.addEventListener('error', () => {
              const error = JSON.parse(xhr.responseText);
              reject(error);
            });
          },
        );
      }
      const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;

      };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
        const data = new FormData();
        data.append("images_project", file);
        data.append("judul_project", formData.judul_project);
        data.append("dana_terkumpul", formData.dana_terkumpul);
        data.append("images_id", formData.images_id);
        data.append("progress_project", isiProject);
        data.append("active", values.status);
        data.append("tanggal", moment(formData.tanggal).format('YYYY-MM-DD'));
        updateProjectProgress(data, decodeString2.split(';')[0], history,decodeString2.split(';')[1]);
    }

    function handleImageUploadBefore(files, info, uploadHandler){
        // uploadHandler is a function
      console.log('UPLOAD: ' + files, info);
      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString());
      }
    }
    const handleDateChange = e => {
        setFormData({ ...formData,
            tanggal: new Date(e)
        });
      }
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to={`/pipka/project/progress/${idValue}`}  className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardPIPKA">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Progress Project Pipka</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Progress Project {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                    <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Judul Project</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Judul Project ..." required name="judul_project" value={judul_project} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>



                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Kebutuhan dana</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="number" className="form-control" placeholder="Dana Terkumpul ..." required name="dana_terkumpul" value={dana_terkumpul} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>

                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Progress Project</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <SunEditor
                          autoFocus={true}
                          lang="en"
                          width='100%'
                          height='450px'
                          setOptions={{
                            minWidth : '450px',
                            showPathLabel: false,
                            placeholder: "Enter your text here!!!",
                            plugins: [
                              align,
                              font,
                              fontColor,
                              fontSize,
                              formatBlock,
                              hiliteColor,
                              horizontalRule,
                              lineHeight,
                              list,
                              paragraphStyle,
                              table,
                              template,
                              textStyle,
                              image,
                              link
                            ],
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize", "formatBlock"],
                              ["paragraphStyle"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                              ],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              "/", // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["table", "link", "image"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                              "Arial",
                              "Calibri",
                              "Comic Sans",
                              "Courier",
                              "Garamond",
                              "Georgia",
                              "Impact",
                              "Lucida Console",
                              "Palatino Linotype",
                              "Segoe UI",
                              "Tahoma",
                              "Times New Roman",
                              "Trebuchet MS"
                            ]
                          }}
                          getSunEditorInstance={getSunEditorInstance}
                          onImageUploadBefore={handleImageUploadBefore}
                          setContents={isiProject}
                          onChange={setProject}


                        />
                   </div>
                   <div style={{zIndex: -999, position: 'absolute'}}>
                        <SunEditorAtom initialContent={isiProject}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Tanggal</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal"
                                    dateFormat={moment(tanggal).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal}
                                    onChange={handleDateChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Gambar</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div className="imgPreviewUpload">
                          <img className="imgPreview" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                   </div>
                  </div>
                </div>



                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditProjectProgress.propTypes = {
  updateProjectProgress: PropTypes.func.isRequired,
  getProjectProgressById: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({
    auth: state.auth,
    pipka: state.pipka,
});

export default connect(mapStateToProps, { updateProjectProgress, getProjectProgressById })(withRouter(EditProjectProgress));
