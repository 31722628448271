import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {API_URL_IMAGE} from '../../config/apiUrl'

const NavbarGkmione = ({ auth: { user } }) => {
  const location = useLocation();
  const [gerejaClass, setGerejaClass] = useState("nav-item has-treeview");
  const [jadualClass, setJadualClass] = useState("nav-item has-treeview");
  const [firmanTreeClass, setFirmanTreeClass] = useState("nav-item has-treeview");
  const [beritaTreeClass, setBeritaTreeClass] = useState("nav-item has-treeview");
  const [karyaTreeClass, setKaryaTreeClass] = useState("nav-item has-treeview");
  const [actionTreeClass, setActionTreeClass] = useState("nav-item has-treeview");
  const [connectTreeClass, setConnectTreeClass] = useState("nav-item has-treeview");

  const homeClass = location.pathname === "/dashboard" ? "nav-link active" : "nav-link";

  const gerejaDataClass = location.pathname === "/gereja/gereja-data" ? "nav-link active" : "nav-link";
  const gerejaSliderClass = location.pathname === "/gereja/slider" ? "nav-link active" : "nav-link";
  const gerejaPemimpinClass = location.pathname === "/gereja/pemimpin" ? "nav-link active" : "nav-link";

  const jadualKegiatanClass = location.pathname === "/jadual/kegiatan" ? "nav-link active" : "nav-link";
  const jadualPembicaraClass = location.pathname === "/jadual/pembicara" ? "nav-link active" : "nav-link";

  const jemaatClass = location.pathname === "/jemaat" ? "nav-link active" : "nav-link";

  const notifikasiClass = location.pathname === "/notifikasi" ? "nav-link active" : "nav-link";

  const firmanCategoryClass = location.pathname === "/firman/category" ? "nav-link active" : "nav-link";
  const firmanClass = location.pathname === "/firman" ? "nav-link active" : "nav-link";

  const beritaCategoryClass = location.pathname === "/berita/category" ? "nav-link active" : "nav-link";
  const beritaClass = location.pathname === "/berita" ? "nav-link active" : "nav-link";

  const karyaCategoryClass = location.pathname === "/karya/category" ? "nav-link active" : "nav-link";
  const karyaTokoClass = location.pathname === "/karya/toko" ? "nav-link active" : "nav-link";

  const actionClass = location.pathname === "/action" ? "nav-link active" : "nav-link";
  const connectTopicClass = location.pathname === "/connect/topic" ? "nav-link active" : "nav-link";
  const connectClass = location.pathname === "/connect" ? "nav-link active" : "nav-link";
  const homeSliderClass = location.pathname === "/sliderhome" ? "nav-link active" : "nav-link";

  const handleOnClick = (menu) => {
    console.log("MENU: " + menu); // remove the curly braces

    if(menu !=="Dashboard")
      document.title = `GKMI ONE - ${menu}`;
    else
     document.title = `GKMI ONE`;
  };

  const handleClick = (e) => {
	e.preventDefault();
  }

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
  }, []);

  useEffect(() => {
	if(location.pathname === "/gereja"){
      setGerejaClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/gereja/gereja-slider"){
      setGerejaClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/gereja/pemimpin"){
      setGerejaClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/jadual/kegiatan"){
      setJadualClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/jadual/pembicara"){
      setJadualClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/firman/category"){
      setFirmanTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/firman"){
      setFirmanTreeClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/berita/category"){
      setBeritaTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/berita"){
      setBeritaTreeClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/karya/category"){
      setKaryaTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/karya/toko"){
      setKaryaTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/connect/topic"){
      setConnectTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/connect"){
      setConnectTreeClass("nav-item has-treeview menu-open");
    }
  }, [location.pathname]);

  function cekLevelAkses(){
    if (user && (user.level_user === 7)) {
      return (
        <Fragment>
          <li className={firmanTreeClass}>
              <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                <i className="nav-icon far fa fa-2x fa-book"></i>
                <p className="linkcolor">
                  Firman
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
          <li className="nav-item">
                  <Link to="/firman/category" className={firmanCategoryClass} onClick={() => handleOnClick("Kategori Firman")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Kategori</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/firman" className={firmanClass} onClick={() => handleOnClick("Firman")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Firman</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li className={beritaTreeClass}>
              <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                <i className="nav-icon far fa-newspaper"></i>
                <p className="linkcolor">
                  Berita
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
				<li className="nav-item">
                  <Link to="/berita/category" className={beritaCategoryClass} onClick={() => handleOnClick("Kategori Berita")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Kategori</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/berita" className={beritaClass} onClick={() => handleOnClick("Berita")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Berita</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={karyaTreeClass}>
                  <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                    <i className="nav-icon fas fa-store"></i>
                    <p className="linkcolor">
                      Karya
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/karya/category" className={karyaCategoryClass} onClick={() => handleOnClick("Kategori Karya")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p className="linkcolor">Kategori</p>
                      </Link>
                    </li>
                  </ul>
              </li>
              <li className="nav-item">
                 <Link
                   to="/notifikasi"
                   className={notifikasiClass}
                   onClick={() => handleOnClick("Notifikasi")}
                 >
                   <i className="nav-icon fas fa-upload" />
                   <p className="linkcolor">
                   Notifikasi
                     <i className="right" />
                   </p>
                 </Link>
               </li>
              <li className="nav-item">
              <Link to="/sliderhome" className={homeSliderClass} onClick={() => handleOnClick("Gereja Slider")}>
                  <i className="nav-icon fas fa-upload" />
                  <p className="linkcolor">
                  Home Slider
                    <i className="right" />
                  </p>
                </Link>
              </li>
        </Fragment>
      );
    }else if(user && (user.kode_gereja === "gr_0000067")){
      return(
        <Fragment>
          <li className={jadualClass}>
              <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                <i className="nav-icon far fa-calendar-alt"></i>
                <p className="linkcolor">
                  Ibadah/Persekutuan
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
        <li className="nav-item">
                  <Link to="/jadual/pembicara" className={jadualPembicaraClass} onClick={() => handleOnClick("Jadual Pembicara")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Pembicara</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/jadual/kegiatan" className={jadualKegiatanClass} onClick={() => handleOnClick("Jadual Kegiatan")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Kegiatan Ibadah</p>
                  </Link>
                </li>
              </ul>
            </li>

      <li className="nav-item">
                <Link
                  to="/jemaat"
                  className={jemaatClass}
                  onClick={() => handleOnClick("Jemaat")}
                >
                  <i className="nav-icon fas fa-user" />
                  <p className="linkcolor">
                    Jemaat
                    <i className="right" />
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/notifikasi"
                  className={notifikasiClass}
                  onClick={() => handleOnClick("Notifikasi")}
                >
                  <i className="nav-icon fas fa-upload" />
                  <p className="linkcolor">
                  Notifikasi
                    <i className="right" />
                  </p>
                </Link>
              </li>
          <li className="nav-item">
                <Link
                  to="/firman"
                  className={firmanClass}
                  onClick={() => handleOnClick("Firman")}
                >
                  <i className="nav-icon far fa fa-2x fa-book" />
                  <p className="linkcolor">
                  Firman
                    <i className="right" />
                  </p>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/berita"
                  className={beritaClass}
                  onClick={() => handleOnClick("Berita")}
                >
                  <i className="nav-icon far fas fa-newspaper" />
                  <p className="linkcolor">
                  Berita
                    <i className="right" />
                  </p>
                </Link>
              </li>
              <li className={karyaTreeClass}>
                  <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                    <i className="nav-icon fas fa-store"></i>
                    <p className="linkcolor">
                      Karya
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/karya/toko" className={karyaTokoClass} onClick={() => handleOnClick("Toko Karya")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p className="linkcolor">Toko</p>
                      </Link>
                    </li>
                  </ul>
              </li>
              <li className={actionTreeClass}>
                  <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                    <i className="nav-icon fas fa-store"></i>
                    <p className="linkcolor">
                      Action
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/action" className={actionClass} onClick={() => handleOnClick("Action")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p className="linkcolor">Kegiatan</p>
                      </Link>
                    </li>
                  </ul>
              </li>
        </Fragment>

      );

    }else if(user && (user.level_user === 8)){
      return(
        <Fragment>
          <li className="nav-item">
                <Link
                  to="/firman"
                  className={firmanClass}
                  onClick={() => handleOnClick("Firman")}
                >
                  <i className="nav-icon far fa fa-2x fa-book" />
                  <p className="linkcolor">
                  Firman
                    <i className="right" />
                  </p>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/berita"
                  className={beritaClass}
                  onClick={() => handleOnClick("Berita")}
                >
                  <i className="nav-icon far fas fa-newspaper" />
                  <p className="linkcolor">
                  Berita
                    <i className="right" />
                  </p>
                </Link>
              </li>
        </Fragment>
      );
   }else{
     // if(user.kode_gereja==="gr_0000067"){
       return(
         <Fragment>
           <li className={jadualClass}>
               <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                 <i className="nav-icon far fa-calendar-alt"></i>
                 <p className="linkcolor">
                   Ibadah/Persekutuan
                   <i className="right fas fa-angle-left"></i>
                 </p>
               </a>
               <ul className="nav nav-treeview">
         <li className="nav-item">
                   <Link to="/jadual/pembicara" className={jadualPembicaraClass} onClick={() => handleOnClick("Jadual Pembicara")}>
                     <i className="far fa-circle nav-icon"></i>
                     <p className="linkcolor">Pembicara</p>
                   </Link>
                 </li>
                 <li className="nav-item">
                   <Link to="/jadual/kegiatan" className={jadualKegiatanClass} onClick={() => handleOnClick("Jadual Kegiatan")}>
                     <i className="far fa-circle nav-icon"></i>
                     <p className="linkcolor">Kegiatan Ibadah</p>
                   </Link>
                 </li>
               </ul>
             </li>
             <li className={connectTreeClass}>
                            <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                              <i className="nav-icon far fa-calendar-alt"></i>
                              <p className="linkcolor">
                                Connect
                                <i className="right fas fa-angle-left"></i>
                              </p>
                            </a>
                            <ul className="nav nav-treeview">
                      <li className="nav-item">
                                <Link to="/connect/topic" className={connectTopicClass} onClick={() => handleOnClick("Topic Connect")}>
                                  <i className="far fa-circle nav-icon"></i>
                                  <p className="linkcolor">Topic</p>
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link to="/connect" className={connectClass} onClick={() => handleOnClick("Connect")}>
                                  <i className="far fa-circle nav-icon"></i>
                                  <p className="linkcolor">Connect</p>
                                </Link>
                              </li>
                            </ul>
                          </li>
                         
       <li className="nav-item">
                 <Link
                   to="/jemaat"
                   className={jemaatClass}
                   onClick={() => handleOnClick("Jemaat")}
                 >
                   <i className="nav-icon fas fa-user" />
                   <p className="linkcolor">
                     Jemaat
                     <i className="right" />
                   </p>
                 </Link>
               </li>
               <li className="nav-item">
                 <Link
                   to="/notifikasi"
                   className={notifikasiClass}
                   onClick={() => handleOnClick("Notifikasi")}
                 >
                   <i className="nav-icon fas fa-upload" />
                   <p className="linkcolor">
                   Notifikasi
                     <i className="right" />
                   </p>
                 </Link>
               </li>
           <li className="nav-item">
                 <Link
                   to="/firman"
                   className={firmanClass}
                   onClick={() => handleOnClick("Firman")}
                 >
                   <i className="nav-icon far fa fa-2x fa-book" />
                   <p className="linkcolor">
                   Firman
                     <i className="right" />
                   </p>
                 </Link>
               </li>

               <li className="nav-item">
                 <Link
                   to="/berita"
                   className={beritaClass}
                   onClick={() => handleOnClick("Berita")}
                 >
                   <i className="nav-icon far fas fa-newspaper" />
                   <p className="linkcolor">
                   Berita
                     <i className="right" />
                   </p>
                 </Link>
               </li>
               {/* <li className={karyaTreeClass}>
                   <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                     <i className="nav-icon fas fa-store"></i>
                     <p className="linkcolor">
                       Karya
                       <i className="right fas fa-angle-left"></i>
                     </p>
                   </a>
                   <ul className="nav nav-treeview">
                     <li className="nav-item">
                       <Link to="/karya/toko" className={karyaTokoClass} onClick={() => handleOnClick("Toko Karya")}>
                         <i className="far fa-circle nav-icon"></i>
                         <p className="linkcolor">Toko</p>
                       </Link>
                     </li>
                   </ul>
               </li> */}
         </Fragment>

       );

   }
 }


  return (
    <Fragment>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/dashboard" className="brand-link navbar-danger" style={{lineHeight: "2.1"}}>
          <img
            src={process.env.PUBLIC_URL + '/logo-gkmi.png'}
            alt="GKMI ONE"
            className="brand-image img-circle"
            style={{ opacity: ".8", height: "58px", maxHeight: "58px", marginTop: "-12px", marginLeft: "1px" }}
          />
          <span className="brand-text font-weight-light">GKMI ONE</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar" style={{backgroundColor: "#3c8dbc"}}>
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                style={{ width: "2.8rem", marginTop: "6px", marginLeft: "-8px" }}
                src={API_URL_IMAGE + '/' + (user && (user.gereja[0].logo !== '' ? 'gereja/' + user.gereja[0].logo : 'avatar.jpg'))}
                className="img-circle elevation-2"
                alt="User"
              />
            </div>
            <div className="info">
              <div className="d-block" style={{ color: "#ffffff" }}>
                {user && user.gereja[0].nama}
                <br />
                <span style={{ fontSize: "12px" }}>{user && user.role}</span>
              </div>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-legacy"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  className={homeClass}
                  onClick={() => handleOnClick("Dashboard")}
                >
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p className="linkcolor">
                    Dashboard
                    <i className="right" />
                  </p>
                </Link>
              </li>

              <li className={gerejaClass}>
              <a href="#!" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p className="linkcolor">
                  Gereja
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/gereja" className={gerejaDataClass} onClick={() => handleOnClick("Gereja")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Gereja</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/gereja/gereja-slider" className={gerejaSliderClass} onClick={() => handleOnClick("Gereja Slider")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Slider</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/gereja/pemimpin" className={gerejaPemimpinClass} onClick={() => handleOnClick("Gereja Pemimpin")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Pemimpin</p>
                  </Link>
                </li>
              </ul>
            </li>

              {cekLevelAkses()}

              {/* {cekLevelAksesKarya()} */}

            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </Fragment>
  );
};

NavbarGkmione.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(NavbarGkmione);
