import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import {GET_PIPKA,GET_PIPKA_SLIDER,GET_DONASI,GET_SETTINGS,GET_REGION,GET_REGION2,GET_EVENT,GET_MENU,GET_PROJECT,GET_PROJECT_PROGRESS,GET_PROJECT_DONASI,GET_KATEGORI_MISSION,GET_KATEGORI_MISSION2,GET_MISSION_HOME,GET_GALLERY_VIDEO,GET_GALLERY_FOTO,
    PIPKA_ERROR,HOME_PIPKA_SLIDER_ERROR ,DONASI_ERROR,BERITA_PIPKA_ERROR ,SETTINGS_ERROR,REGION_ERROR,REGION_ERROR2,EVENT_ERROR,MENU_ERROR,PROJECT_ERROR,PROJECT_PROGRESS_ERROR,PROJECT_DONASI_ERROR,KATEGORI_MISSION_ERROR,KATEGORI_MISSION_ERROR2,MISSION_HOME_ERROR,GALLERY_VIDEO_ERROR,GALLERY_FOTO_ERROR,FIRMAN_ERROR} from "./types";


    export const createPipkaHomeSlider = (formData, history) => async dispatch => {
        try{
             const res = await axios.post(`/api/pipkabackend/home-slider`, formData, {
            //  const res = await axios.post(`http://localhost:8080/api/pipkabackend/home-slider`, formData, {
                 headers: {
                     'Content-Type': 'multipart/form-data'
                 }
             });

             if(res.status === 200){
                 console.log("success",res.data[0].msg);
                 dispatch(setAlert(res.data[0].msg, 'success', 5000));
             }

             history.push('/pipka/pipka-slider');

         }catch(err){
             const errors = err.response;
             if (errors) {
                 const errors2 = errors.data[0].errors;
                 errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
             }
             dispatch({
                 type: HOME_PIPKA_SLIDER_ERROR,
                 //payload: { msg: err.response.statusText, status: err.response.status }
                 payload: { msg: err.message, status: err }
             });
         }
    }

    export const getPipkaHomeSliderById = id => async dispatch => {
         try{
             const res = await axios.get(`/api/pipkabackend/home-slider/${id}`);

             dispatch({
                 type: GET_PIPKA_SLIDER,
                 payload: res.data[0],
             });
         }catch(err){
             dispatch({
                 type: HOME_PIPKA_SLIDER_ERROR,
                 //payload: { msg: err.response.statusText, status: err.response.status }
                 payload: { msg: err.message, status: err }
             });
         }
     }
     // Update Gereja Slider Berdasarkan ID
 export const updateHomeSlider = (formData, id, history) => async dispatch => {
     try{
         const res = await axios.put(`/api/pipkabackend/home-slider/${id}`, formData, {
             headers: {
                 'Content-Type': 'multipart/form-data'
               }
         });

         if(res.status === 200){
             console.log("success",res.data[0].msg);

             dispatch(setAlert(res.data[0].msg, 'success', 3000));
         }

 		history.push('/pipka/pipka-slider');

     }catch(err){
         const errors = err.response;
         if (errors) {
             const errors2 = errors.data[0].errors;
             errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
         }
         dispatch({
             type: HOME_PIPKA_SLIDER_ERROR,
             //payload: { msg: err.response.statusText, status: err.response.status }
 			payload: { msg: err.message, status: err }
         });
     }
 }
    export const getDonasiId = id => async dispatch => {
        try{
            // const res = await axios.get(`http://localhost:8080/api/pipkabackend/donasi/${id}`);
             const res = await axios.get(`/api/pipkabackend/donasi/${id}`);

            dispatch({
                type: GET_DONASI,
                payload: res.data[0],
            });
        }catch(err){
            dispatch({
                type: DONASI_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const updateDonasi = (formData, id, history) => async dispatch => {

        try{
             const res = await axios.put(`/api/pipkabackend/donasi/${id}`, formData, {
                // const res = await axios.put(`http://localhost:8080/api/pipkabackend/donasi/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push('/pipka/donasi');

        }catch(err){
            const errors = err.response;
            if (errors) {
               const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: DONASI_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    // Create Berita PIpka
    // Create Berita
export const createBeritaPipka = (formData, history) => async dispatch => {
    try{
        // const res = await axios.put(`http://localhost:8080/api/pipkabackend/berita`, formData, {
         const res = await axios.post(`/api/pipkabackend/berita`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/berita');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_PIPKA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Update Berita Berdasarkan ID
export const updateBeritaPipka = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/berita/backend/berita/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/pipka/berita');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_PIPKA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
export const getSettings = id => async dispatch => {
    try{
         const res = await axios.get(`/api/pipkabackend/settings`);
        //  const res = await axios.get(`/api/pipkabackend/donasi/${id}`);

        dispatch({
            type: GET_SETTINGS,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: SETTINGS_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}
// Update Berita Berdasarkan ID
export const updateSettings = (formData, id, history) => async dispatch => {
    try{

        // const res = await axios.put(`/api/berita/backend/berita/${id}`, formData, {
            const res = await axios.put(`/api/pipkabackend/settings/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/dashboardPIPKA');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: SETTINGS_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create Berita
export const createRegion = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/pipkabackend/region`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/region');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: REGION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

  // Get Berita by Id
  export const getRegionById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/region/${id}`);

        dispatch({
            type: GET_REGION,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: REGION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateRegion = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/pipkabackend/region/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/region');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: REGION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Get Berita Category
export const getRegion2 = () => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/region`);

        dispatch({
            type: GET_REGION2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: REGION_ERROR2,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}


// Create Berita
export const createEvent = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/pipkabackend/event`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/event');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: EVENT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getEventById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/event/${id}`);
        dispatch({
            type: GET_EVENT,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: EVENT_ERROR,
            payload: { msg: err.message, status: err }
        });
    }
}
export const updateEvent = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/pipkabackend/event/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/event');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: EVENT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create Berita
export const createHomeMenu = (formData, history) => async dispatch => {
    try{

            const res = await axios.post(`/api/pipkabackend/menu`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/menu');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: MENU_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getHomeMenuById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/menu/${id}`);

        dispatch({
            type: GET_MENU,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: MENU_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateHomeMenu = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/pipkabackend/menu/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/menu');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: MENU_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const createProject = (formData, history) => async dispatch => {
    try{

            const res = await axios.post(`/api/pipkabackend/project`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/project');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PROJECT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
export const getProjectById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/project/${id}`);

        dispatch({
            type: GET_PROJECT,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: PROJECT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateProject = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/pipkabackend/project/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/project');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PROJECT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}


// Create Berita
export const createProjectProgress = (formData, history,id) => async dispatch => {
    try{

            const res = await axios.post(`/api/pipkabackend/project/progress`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push(`/pipka/project/progress/${id}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PROJECT_PROGRESS_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getProjectProgressById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/project/progressEdit/${id}`);

        dispatch({
            type: GET_PROJECT_PROGRESS,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: PROJECT_PROGRESS_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateProjectProgress = (formData, id, history,path) => async dispatch => {

    try{
        const res = await axios.put(`/api/pipkabackend/project/progressEdit/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push(`/pipka/project/progress/${path}`);


    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PROJECT_PROGRESS_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getProjectDonasiById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/project/donasi/${id}`);

        dispatch({
            type: GET_PROJECT_DONASI,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: PROJECT_DONASI_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateProjectDonasi = (formData, id, history) => async dispatch => {
    // console.log("Masuk sini kah updateProjectDonasi" );
    try{
        const res = await axios.put(`/api/pipkabackend/project/donasiProject/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
        console.log("Masuk sini kah updateProjectDonasi "+res.data[0].msg );
        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push("/pipka/project");


    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PROJECT_DONASI_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const createKategoriMission = (formData, history) => async dispatch => {
    try{

            const res = await axios.post(`/api/pipkabackend/kategori`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push(`/pipka/mission/kategori`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: KATEGORI_MISSION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getKategoriMossionById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/kategori/${id}`);

        dispatch({
            type: GET_KATEGORI_MISSION,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: KATEGORI_MISSION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateKategoriMossion = (formData, id, history) => async dispatch => {
    // console.log("Masuk sini kah updateProjectDonasi" );
    try{
        const res = await axios.put(`/api/pipkabackend/kategori/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
        console.log("Masuk sini kah updateProjectDonasi "+res.data[0].msg );
        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push("/pipka/mission/kategori");


    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: KATEGORI_MISSION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Get Berita Category
export const getKategoriMission2 = () => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/kategoriMission`);

        dispatch({
            type: GET_KATEGORI_MISSION2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: KATEGORI_MISSION_ERROR2,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

export const createMissionHome = (formData, history) => async dispatch => {
    try{
            const res = await axios.post(`/api/pipkabackend/missionhome`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push(`/pipka/missionhome`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: MISSION_HOME_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Get Berita Category
export const getMissionHomeById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/missionhome/${id}`);
        dispatch({
            type: GET_MISSION_HOME,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: MISSION_HOME_ERROR,
            payload: { msg: err.message, status: err }
        });
    }
}


export const updateMissionHome = (formData, id, history) => async dispatch => {
    // console.log("Masuk sini kah updateProjectDonasi" );
    try{
        const res = await axios.put(`/api/pipkabackend/missionhome/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
        console.log("Masuk sini kah updateProjectDonasi "+res.data[0].msg );
        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push("/pipka/missionhome");


    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: MISSION_HOME_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const createGalleryVideo = (formData, history) => async dispatch => {
    try{
            const res = await axios.post(`/api/pipkabackend/galleryvideo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push(`/pipka/galleryvideo`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GALLERY_VIDEO_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
export const getGalleryVideoById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/galleryvideo/${id}`);
        dispatch({
            type: GET_GALLERY_VIDEO,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: GALLERY_VIDEO_ERROR,
            payload: { msg: err.message, status: err }
        });
    }
}


export const updateGalleryVideo = (formData, id, history) => async dispatch => {
    // console.log("Masuk sini kah updateProjectDonasi" );
    try{
        const res = await axios.put(`/api/pipkabackend/galleryvideo/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
        console.log("Masuk sini kah updateProjectDonasi "+res.data[0].msg );
        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push("/pipka/galleryvideo");


    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GALLERY_VIDEO_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const createPipkaFirman = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/firman/firman`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/firman');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: FIRMAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Firman Berdasarkan ID
export const updatePipkaFirman = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/firman/firman/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/pipka/firman');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: FIRMAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
