import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Header from "../../../layouts/Header";
import Navbar from "../../../layouts/Navbar";
import Footer from "../../../layouts/Footer";
import Alert from "../../../alert/Alert";
import { updateJadwalKegiatan } from "../../../../actions/gerejaJadwalKegiatanAction";
import { getJadwalKegiatanById } from "../../../../actions/gerejaJadwalKegiatanAction";

const EditKegiatan = ({ jadwalKegiatan: { loading, jadwalKegiatan }, getJadwalKegiatanById, updateJadwalKegiatan, history }) => {
    const {id} = useParams();
    const [loadings, setLoadings] = useState(false);
    const [formData, setFormData] = useState({
        nama_kegiatan: '',
        hari: '',
        jam_mulai: '',
        jam_selesai: '',
    });

    useEffect(() => {
        setFormData({
          nama_kegiatan:  jadwalKegiatan && (jadwalKegiatan.nama_kegiatan ? jadwalKegiatan.nama_kegiatan : ''),
          hari:  jadwalKegiatan && (jadwalKegiatan.hari ? jadwalKegiatan.hari : ''),
          jam_mulai:  jadwalKegiatan && (jadwalKegiatan.jam_mulai ? jadwalKegiatan.jam_mulai : ''),
          jam_selesai:  jadwalKegiatan && (jadwalKegiatan.jam_selesai ? jadwalKegiatan.jam_selesai : ''),
        });
      }, [loading, jadwalKegiatan]);

      useEffect(() => {
        getJadwalKegiatanById(Buffer.from(id, 'base64').toString('ascii'));
      }, [getJadwalKegiatanById, id]);


    const {
        nama_kegiatan,
        hari,
        jam_mulai,
        jam_selesai
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        updateJadwalKegiatan(formData, Buffer.from(id, 'base64').toString('ascii'), history);

    }



    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/jadual/kegiatan" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Jadual</li>
                    <li className="breadcrumb-item active">Kegiatan</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Jadual Kegiatan</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                        <label>Nama Kegiatan</label>
                        <input type="text" className="form-control" placeholder="Nama Kegiatan ..." required name="nama_kegiatan" value={nama_kegiatan} onChange={e => onChange(e)} />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                        <label>Hari</label>
                        <input type="text" className="form-control" placeholder="Hari ..." name="hari" value={hari} onChange={e => onChange(e)} />
                        </div>
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                        <label>Jam Mulai</label>
                        <input type="text" className="form-control" placeholder="Jam Mulai ..." name="jam_mulai" value={jam_mulai} onChange={e => onChange(e)} />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                        <label>Jam Selesai</label>
                        <input type="text" className="form-control" placeholder="Jam Selesai ..." name="jam_selesai" value={jam_selesai} onChange={e => onChange(e)} />
                        </div>
                    </div>
                    </div>



                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditKegiatan.propTypes = {
    updateJadwalKegiatan: PropTypes.func.isRequired,
    getJadwalKegiatanById: PropTypes.func.isRequired,
    jadwalKegiatan: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    jadwalKegiatan: state.jadwalKegiatan
});

export default connect(mapStateToProps, { updateJadwalKegiatan, getJadwalKegiatanById })(withRouter(EditKegiatan));
