import axios from "../util/http-common";
import { setAlert } from "./alertAction";


import {GET_CONNECT,GET_CONNECT_TOPIC,GET_CONNECT_LEADER,GET_CONNECT_LAGU,CONNECT_TOPIC_ERROR,CONNECT_LEADER_ERROR,CONNECT_ERROR,CONNECT_LAGU_ERROR } from "./types";


// Create Berita
export const createConnectTopic = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/connectbackend/topic`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/connect/topic');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: CONNECT_TOPIC_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Get Berita by Id
export const getTopicById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/connectbackend/topic/${id}`);
        dispatch({
            type: GET_CONNECT_TOPIC,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: CONNECT_TOPIC_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateTopic = (formData, id, history) => async dispatch => {
    console.log("Masuk sini kah updateProjectDonasi" );
    try{
         const res = await axios.put(`/api/connectbackend/topic/${id}`, formData, {

            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/connect/topic');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: CONNECT_TOPIC_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const getLeader = () => async dispatch => {
    try{
        const res = await axios.get(`/api/connectbackend/connect_leader`);
        dispatch({
            type: GET_CONNECT_LEADER,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: CONNECT_LEADER_ERROR,
			payload: { msg: err.message, status: err }

        });
    }
}
export const createConnect = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/connectbackend/connect`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/connect');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: CONNECT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
export const getConnectId = id => async dispatch => {
    try{
        console.log(id);
        const res = await axios.get(`/api/connectbackend/connect/${id}`);
        console.log(res);
        dispatch({
            type: GET_CONNECT,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: CONNECT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Update Berita Berdasarkan ID
export const updateConnect = (formData, id, history) => async dispatch => {
    try{
       // console.log();
        const res = await axios.put(`/api/connectbackend/connect/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/connect');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: CONNECT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create Berita
export const createConnectLagu = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/connectbackend/pujian`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/connect/lagu');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: CONNECT_LAGU_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Get Berita by Id
export const getLaguById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/connectbackend/pujian/${id}`);
        dispatch({
            type: GET_CONNECT_LAGU,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: CONNECT_LAGU_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}
// Update Berita Berdasarkan ID
export const updateLagu = (formData, id, history) => async dispatch => {
    try{
       // console.log();
        const res = await axios.put(`/api/connectbackend/pujian/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/connect/lagu');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: CONNECT_LAGU_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
