import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "../app/components/auth/Login";
import Dashboard from "../app/components/dashboard/Dashboard";
import DashboardBeritaGkmi from "../app/components/dashboard/DashboardBeritaGkmi";
import DashboardPIPKA from "../app/components/dashboard/DashboardPIPKA";
import DashboardBerlangganan from "../app/components/dashboard/DashboardBerlangganan";
import DashboardSinode from "../app/components/dashboard/DashboardSinode";
import DashboardFirman from "../app/components/dashboard/DashboardFirman";
import DashboardConnect from "../app/components/dashboard/DashboardConnect";
import DashboardKarya from "../app/components/dashboard/DashboardKarya";
import ViewFirman from "../app/components/dashboard/ViewFirman";
import GerejaDewasa from "../app/components/dashboard/GerejaDewasa";
import GerejaCabang from "../app/components/dashboard/GerejaCabang";
import Gereja from "../app/components/gereja/gereja/Gereja";
import CreateGereja from "../app/components/gereja/gereja/gereja-forms/CreateGereja"
import EditGereja from "../app/components/gereja/gereja/gereja-forms/EditGereja";
import EditGereja2 from "../app/components/gereja/gereja/gereja-forms/EditGereja2";
import GerejaSlider from "../app/components/gereja/slider/GerejaSlider";
import CreateGerejaSlider from "../app/components/gereja/slider/slider-forms/CreateGerejaSlider";
import EditGerejaSlider from "../app/components/gereja/slider/slider-forms/EditGerejaSlider";
import Pemimpin from "../app/components/gereja/pemimpin/Pemimpin";
import CreatePemimpin from "../app/components/gereja/pemimpin/pemimpin-forms/CreatePemimpin";
import EditPemimpin from "../app/components/gereja/pemimpin/pemimpin-forms/EditPemimpin";
import Pembicara from "../app/components/jadual/pembicara/Pembicara";
import CreatePembicara from "../app/components/jadual/pembicara/pembicara-forms/CreatePembicara";
import EditPembicara from "../app/components/jadual/pembicara/pembicara-forms/EditPembicara";
import JadualKegiatan from "../app/components/jadual/kegiatan/Kegiatan";
import CreateJadualKegiatan from "../app/components/jadual/kegiatan/kegiatan-forms/CreateKegiatan";
import EditJadualKegiatan from "../app/components/jadual/kegiatan/kegiatan-forms/EditKegiatan";
import JadualIbadah from "../app/components/jadual/ibadah/ListIbadah";
import CreateJadualIbadah from "../app/components/jadual/ibadah/ibadah-forms/CreateIbadah";
import EditJadualIbadah from "../app/components/jadual/ibadah/ibadah-forms/EditIbadah";
import JemaatJadualIbadah from "../app/components/jadual/ibadah/IbadahJemaat";
import Jemaat from "./components/jemaat/Jemaat";
import CreateJemaat from "../app/components/jemaat/jemaat-forms/CreateJemaat";
import EditJemaat from "../app/components/jemaat/jemaat-forms/EditJemaat";
import SendNotification from "./components/notifikasi/SendNotification";

import FirmanCategory from "../app/components/firman/FirmanCategory";
import Firman from "../app/components/firman/Firman";
import CreateFirmanCategory from "../app/components/firman/firman-forms/CreateFirmanCategory";
import EditFirmanCategory from "../app/components/firman/firman-forms/EditFirmanCategory";
import CreateFirman from "../app/components/firman/firman-forms/CreateFirman";
import EditFirman from "../app/components/firman/firman-forms/EditFirman";

import BeritaCategory from "../app/components/berita/BeritaCategory";
import Berita from "../app/components/berita/Berita";
import CreateBeritaCategory from "../app/components/berita/berita-forms/CreateBeritaCategory";
import EditBeritaCategory from "../app/components/berita/berita-forms/EditBeritaCategory";
import CreateBerita from "../app/components/berita/berita-forms/CreateBerita";
import EditBerita from "../app/components/berita/berita-forms/EditBerita";

import KaryaCategory from "../app/components/karya/KaryaCategory";
import CreateKaryaCategory from "../app/components/karya/karya-forms/CreateKaryaCategory";
import EditKaryaCategory from "../app/components/karya/karya-forms/EditKaryaCategory";

import KaryaToko from "../app/components/karya/KaryaToko";
import CreateKaryaToko from "../app/components/karya/karya-forms/CreateKaryaToko";
import EditKaryaToko from "../app/components/karya/karya-forms/EditKaryaToko";

import KaryaProduk from "../app/components/karya/KaryaProduk";
import CreateKaryaTokoProduct from "../app/components/karya/karya-forms/CreateKaryaTokoProduct";
import EditKaryaTokoProduct from "../app/components/karya/karya-forms/EditKaryaTokoProduct";

import Action from "./components/action/Action";
import CreateAction from "./components/action/action-forms/CreateAction";
import EditAction from "./components/action/action-forms/EditAction";

import ActionKegiatan from "./components/action/ActionKegiatan";
import CreateActionKegiatan from "./components/action/action-forms/CreateActionKegiatan";
import EditActionKegiatan from "./components/action/action-forms/EditActionKegiatan";

import ActionPeserta from "./components/action/ActionPeserta";
import ActionKegiatanAbsensi from "./components/action/ActionKegiatanAbsensi";

import BeritaGKMIKategori from "./components/beritaGKMI/BeritaGKMIKategori";
import BeritaGKMIEdisi from "./components/beritaGKMI/BeritaGKMIEdisi";
import BeritaGKMI from "./components/beritaGKMI/BeritaGKMI";
import BeritaGKMIKontributor from "./components/beritaGKMI/BeritaGKMIKontributor";
import CreateBeritaGkmi from "../app/components/beritaGKMI/beritaGkmi-forms/CreateBeritaGkmi";
import CreateBeritaGkmiKategori from "../app/components/beritaGKMI/beritaGkmi-forms/CreateBeritaGkmiKategori";
import CreateBeritaGkmiEdisi from "../app/components/beritaGKMI/beritaGkmi-forms/CreateBeritaGkmiEdisi";
import CreateBeritaGkmiKontributor from "../app/components/beritaGKMI/beritaGkmi-forms/CreateBeritaGkmiKontributor";
import EditBeritaGkmiKategori from "../app/components/beritaGKMI/beritaGkmi-forms/EditBeritaGkmiKategori";
import EditBeritaGkmiEdisi from "../app/components/beritaGKMI/beritaGkmi-forms/EditBeritaGkmiEdisi";
import EditBeritaGkmi from "../app/components/beritaGKMI/beritaGkmi-forms/EditBeritaGkmi";
import EditBeritaGkmiKontributor from "../app/components/beritaGKMI/beritaGkmi-forms/EditBeritaGkmiKontributor";
import Langganan from "./components/beritaGKMI/Langganan";
import CreateLangganan from "../app/components/beritaGKMI/beritaGkmi-forms/CreateLangganan";
import EditLangganan from "../app/components/beritaGKMI/beritaGkmi-forms/EditLangganan";
import UserRole from "./components/beritaGKMI/UserRole";
import CreateUserRole from "../app/components/beritaGKMI/beritaGkmi-forms/CreateUserRole";
import EditUserRole from "../app/components/beritaGKMI/beritaGkmi-forms/EditUserRole";
import BeritaGKMIIklan from "./components/beritaGKMI/BeritaGKMIIklan";
import CreateBeritaGkmiIklan from "../app/components/beritaGKMI/beritaGkmi-forms/CreateBeritaGkmiIklan";
import EditBeritaGkmiIklan from "../app/components/beritaGKMI/beritaGkmi-forms/EditBeritaGkmiIklan";
import LanggananOrder from "./components/beritaGKMI/LanggananOrder";
import KonfirmasiOrderLangganan from "../app/components/beritaGKMI/beritaGkmi-forms/KonfirmasiOrderLangganan";
import CaraPembayaran from "./components/beritaGKMI/CaraPembayaran";
import EditBeritaGkmiPembayaran from "./components/beritaGKMI/beritaGkmi-forms/EditBeritaGkmiPembayaran";

import Branches from "../app/components/pipka/Branches";
import EditBranches from "../app/components/pipka/pipka-form/EditBranches";
import CreateBranches from "../app/components/pipka/pipka-form/CreateBranches";
import BranchesSlider from "../app/components/pipka/BranchesSlider";
import CreateBranchesSlider from "../app/components/pipka/pipka-form/CreateBranchesSlider";
import EditBranchesSlider from "../app/components/pipka/pipka-form/EditBranchesSlider";
import BranchesPemimpin from "../app/components/pipka/BranchesPemimpin";
import CreatePemimpinBranches from "./components/pipka/pipka-form/CreateBranchesPemimpin";
import EditBranchesPemimpin from "./components/pipka/pipka-form/EditBranchesPemimpin";

import BranchesKegiatan from "../app/components/pipka/BranchesKegiatan";
import CreateBranchesKegiatan from "../app/components/pipka/pipka-form/CreateBranchesKegiatan";
import EditBranchesKegiatan from "../app/components/pipka/pipka-form/EditBranchesKegiatan";
import ListBranchesIbadah from "../app/components/pipka/ListBranchesIbadah";
import CreateBranchesIbadah from "../app/components/pipka/pipka-form/CreateBranchesIbadah";
import EditIBranchesbadah from "./components/pipka/pipka-form/EditIBranchesIbadah";

import BranchesPembicara from "../app/components/pipka/BranchesPembicara";
import CreateBranchesPembicara from "../app/components/pipka/pipka-form/CreateBranchesPembicara";
import EditBranchesPembicara from "../app/components/pipka/pipka-form/EditBranchesPembicara";


import PipkaSlider from "../app/components/pipka/PipkaSlider";
import CreatePipkaHomeSlider from "../app/components/pipka/pipka-form/CreateHomeSlider";
import EditPipkaHomeSlider from "../app/components/pipka/pipka-form/EditHomeSlider";
import Donasi from "./components/pipka/Donasi";
import EditDonasi from "./components/pipka/pipka-form/EditDonasi";
import BeritaPipka from "../app/components/pipka/BeritaPipka";
import CreateBeritaPipka from "../app/components/pipka/pipka-form/CreateBeritaPipka";
import EditBeritaPipka from "../app/components/pipka/pipka-form/EditBeritaPipka";
import Settings from "./components/pipka/Settings";
import Region from "./components/pipka/Region";
import CreateRegion from "../app/components/pipka/pipka-form/CreateRegion";
import EditRegion from "../app/components/pipka/pipka-form/EditRegion";
import PipkaMenu from "../app/components/pipka/PipkaMenu";
import CreateMenuHome from "../app/components/pipka/pipka-form/CreateMenuHome";
import EditMenuHome from "./components/pipka/pipka-form/EditMenuHome";
import EventPipka from "../app/components/pipka/EventPipka";
import CreateEvent from "../app/components/pipka/pipka-form/CreateEvent";
import EditEvent from "../app/components/pipka/pipka-form/EditEvent";
import ProjectPipka from "../app/components/pipka/ProjectPipka";
import CreateProject from "../app/components/pipka/pipka-form/CreateProject";
import EditProject from "../app/components/pipka/pipka-form/EditProject";
import ProjectProgressPipka from "../app/components/pipka/ProjectProgressPipka";
import CreateProjectProgress from "../app/components/pipka/pipka-form/CreateProjectProgress";
import EditProjectProgress from "../app/components/pipka/pipka-form/EditProjectProgress";
import ProjectDonasi from "../app/components/pipka/ProjectDonasi";
import KategoriMissionHome from "../app/components/pipka/KategoriMissionHome";
import CreateKategoriMissionHome from "../app/components/pipka/pipka-form/CreateKategoriMissionHome";
import EditKategoriMissionHome from "../app/components/pipka/pipka-form/EditKategoriMissionHome";
import MissionHome from "../app/components/pipka/MissionHome";
import CreateMissionHome from "../app/components/pipka/pipka-form/CreateMissionHome";
import EditMissionHome from "../app/components/pipka/pipka-form/EditMissionHome";
import GalleryFoto from "../app/components/pipka/GalleryFoto";

import GalleryVideo from "../app/components/pipka/GalleryVideo";
import CreateGalleryVideo from "../app/components/pipka/pipka-form/CreateGalleryVideo";
import EditGalleryVideo from "../app/components/pipka/pipka-form/EditGalleryVideo";

import FirmanPipka from "../app/components/pipka/FirmanPipka";
import CreatePipkaFirman from "../app/components/pipka/pipka-form/CreatePipkaFirman";
import EditPipkaFirman from "../app/components/pipka/pipka-form/EditPipkaFirman";

import ConnectTopic from "../app/components/connect/ConnectTopic";
import CreateConnectTopic from "../app/components/connect/connect-forms/CreateConnectTopic";
import EditConnectTopic from "../app/components/connect/connect-forms/EditConnectTopic";
import Connect from "../app/components/connect/Connect";
import ConnectOperation from "../app/components/connect/ConnectOperation";
import CreateConnect from "../app/components/connect/connect-forms/CreateConnect";
import EditConnect from "../app/components/connect/connect-forms/EditConnect";
import ConnectLagu from "../app/components/connect/ConnectLagu";
import CreateConnectLagu from "../app/components/connect/connect-forms/CreateConnectLagu";
import EditConnectLagu from "../app/components/connect/connect-forms/EditConnectLagu";
import OutVision from "./components/sinode/OutVision";
import History from "./components/sinode/History";
import Letters from "./components/sinode/Letters";
import CreateLetters from "../app/components/sinode/sinode-forms/CreateLetters";
import EditLetters from "../app/components/sinode/sinode-forms/EditLetters";
import Database from "./components/sinode/Database";
import CreateDatabase from "../app/components/sinode/sinode-forms/CreateDatabase";
import EditDatabase from "../app/components/sinode/sinode-forms/EditDatabase";
import DataDatabase from "./components/sinode/DataDatabase";
import CreateDataDatabase from "../app/components/sinode/sinode-forms/CreateDataDatabase";
import EditDataDatabase from "../app/components/sinode/sinode-forms/EditDataDatabase";
import TataGereja from "./components/sinode/TataGereja";
import TataGerejaParent from "./components/sinode/TataGerejaParent";
import TataGerejaParentChild from "./components/sinode/TataGerejaParentChild";
import CreateTataGereja from "../app/components/sinode/sinode-forms/CreateTataGereja";
import CreateTataGerejaParent from "../app/components/sinode/sinode-forms/CreateTataGerejaParent";
import CreateTataGerejaChild from "../app/components/sinode/sinode-forms/CreateTataGerejaChild";
import EditTataGereja from "../app/components/sinode/sinode-forms/EditTataGereja";
import EditTataGerejaParent from "../app/components/sinode/sinode-forms/EditTataGerejaParent";
import EditTataGerejaChild from "../app/components/sinode/sinode-forms/EditTataGerejaChild";
import CreateTataGerejaDocument from "../app/components/sinode/sinode-forms/CreateTataGerejaDocument";
import CreateTataGerejaDocumentParent from "../app/components/sinode/sinode-forms/CreateTataGerejaDocumentParent";
import CreateTataGerejaDocumentChild from "../app/components/sinode/sinode-forms/CreateTataGerejaDocumentChild";
import EditTataGerejaDocument from "../app/components/sinode/sinode-forms/EditTataGerejaDocument";
import EditTataGerejaDocumentParent from "../app/components/sinode/sinode-forms/EditTataGerejaDocumentParent";
import EditTataGerejaDocumentChild from "../app/components/sinode/sinode-forms/EditTataGerejaDocumentChild";
import ProjectSinode from "./components/sinode/ProjectSinode";
import CreateProjectSinode from "../app/components/sinode/sinode-forms/CreateProjectSinode";
import ProjectProgressSinode from "./components/sinode/ProjectProgressSinode";
import CreateProjectProgressSinode from "../app/components/sinode/sinode-forms/CreateProjectProgressSinode";
import EditProjectSinode from "../app/components/sinode/sinode-forms/EditProjectSinode";
import EditProjectProgressSinode from "../app/components/sinode/sinode-forms/EditProjectProgressSinode";
import ProjectDonasiSinode from "../app/components/sinode/ProjectDonasiSinode";
import GalleryFotoSinode from "../app/components/sinode/GalleryFotoSinode";
import GalleryVideoSinode from "../app/components/sinode/GalleryVideoSinode";
import CreateGalleryVideoSinode from "../app/components/sinode/sinode-forms/CreateGalleryVideoSinode";
import EditGalleryVideoSinode from "../app/components/sinode/sinode-forms/EditGalleryVideoSinode";
import EksekutifMenu from "./components/sinode/EksekutifMenu";
import CreateEksekutifMenu from "../app/components/sinode/sinode-forms/CreateEksekutifMenu";
import EditEksekutifMenu from "../app/components/sinode/sinode-forms/EditEksekutifMenu";
 import EksekutifData from "./components/sinode/EksekutifData";
// import CreateDataDatabase from "../app/components/sinode/sinode-forms/CreateDataDatabase";
// import EditDataDatabase from "../app/components/sinode/sinode-forms/EditDataDatabase";
import UserRoleSinode from "./components/sinode/UserRoleSinode";
 import CreateUserRoleSinode from "../app/components/sinode/sinode-forms/CreateUserRoleSinode";
// import EditUserRole from "../app/components/beritaGKMI/beritaGkmi-forms/EditUserRole";
import ScheduleSinode from "./components/sinode/ScheduleSinode";
import CreateScheduleSinode from "../app/components/sinode/sinode-forms/CreateScheduleSinode";
import EditScheduleSinode from "../app/components/sinode/sinode-forms/EditScheduleSinode";

import HomeSlider from "../app/components/sliderhome/HomeSlider";
import CreateHomeSlider from "../app/components/sliderhome/homeslider-forms/CreateHomeSlider";
import EditHomeSlider from "../app/components/sliderhome/homeslider-forms/EditHomeSlider";

import Policy from "../app/components/policy/Policy";

import ChangePassword from "../app/components/auth/ChangePassword";

import PrivateRoute from "../app/components/routing/PrivateRoute";
import setAuthToken from "../app/util/setAuthToken";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "../app/actions/authAction";

//import "./App.css";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Route exact path="/" component={Login} />
		  <Route exact path="/privacy-policy" component={Policy} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/dashboardBeritaGkmi" component={DashboardBeritaGkmi} />
          <PrivateRoute exact path="/dashboardPIPKA" component={DashboardPIPKA} />
          <PrivateRoute exact path="/dashboardSinode" component={DashboardSinode} />
          <PrivateRoute exact path="/dashboardFirman" component={DashboardFirman} />
          <PrivateRoute exact path="/dashboardFirman/view-firman" component={ViewFirman} />
          <PrivateRoute exact path="/dashboardSinode/gereja-dewasa" component={GerejaDewasa} />
          <PrivateRoute exact path="/dashboard/gereja-cabang" component={GerejaCabang} />
          <PrivateRoute exact path="/dashboardBeritaGkmi/langganan" component={DashboardBerlangganan} />
          <PrivateRoute exact path="/dashboardSinode/connect" component={DashboardConnect} />
          <PrivateRoute exact path="/dashboardSinode/karya" component={DashboardKarya} />
          <PrivateRoute exact path="/gereja" component={Gereja} />
          <PrivateRoute exact path="/gereja/create-gereja" component={CreateGereja} />
		      <PrivateRoute exact path="/gereja/edit-gereja" component={EditGereja} />
		      <PrivateRoute exact path="/gereja/edit-gereja/:kode_gereja" component={EditGereja2} />
		      <PrivateRoute exact path="/gereja/gereja-slider" component={GerejaSlider} />
          <PrivateRoute exact path="/gereja/gereja-slider/create-slider/:id" component={CreateGerejaSlider} />
          <PrivateRoute exact path="/gereja/gereja-slider/edit-slider/:id" component={EditGerejaSlider} />
          <PrivateRoute exact path="/gereja/pemimpin" component={Pemimpin} />
          <PrivateRoute exact path="/gereja/pemimpin/create-pemimpin" component={CreatePemimpin} />
          <PrivateRoute exact path="/gereja/pemimpin/edit-pemimpin/:id" component={EditPemimpin} />
		      <PrivateRoute exact path="/jadual/pembicara" component={Pembicara} />
          <PrivateRoute exact path="/jadual/pembicara/create-pembicara/:id" component={CreatePembicara} />
          <PrivateRoute exact path="/jadual/pembicara/edit-pembicara/:id" component={EditPembicara} />
		      <PrivateRoute exact path="/jadual/kegiatan" component={JadualKegiatan} />
		      <PrivateRoute exact path="/jadual/kegiatan/create-kegiatan/:id" component={CreateJadualKegiatan} />
		      <PrivateRoute exact path="/jadual/kegiatan/edit-kegiatan/:id" component={EditJadualKegiatan} />
		      <PrivateRoute exact path="/jadual/ibadah/:id" component={JadualIbadah} />
          <PrivateRoute exact path="/jadual/ibadah/create-ibadah/:id" component={CreateJadualIbadah} />
          <PrivateRoute exact path="/jadual/ibadah/edit-ibadah/:id" component={EditJadualIbadah} />
		      <PrivateRoute exact path="/jadual/ibadah/jemaat-ibadah/:id" component={JemaatJadualIbadah} />
		      <PrivateRoute exact path="/jemaat" component={Jemaat} />
		      <PrivateRoute exact path="/jemaat/create-jemaat" component={CreateJemaat} />
          <PrivateRoute exact path="/jemaat/edit-jemaat/:id" component={EditJemaat} />
          <PrivateRoute exact path="/notifikasi" component={SendNotification} />
          <PrivateRoute exact path="/firman/category" component={FirmanCategory} />
          <PrivateRoute exact path="/firman" component={Firman} />
          <PrivateRoute exact path="/firman/category/create-category" component={CreateFirmanCategory} />
          <PrivateRoute exact path="/firman/category/edit-category/:id" component={EditFirmanCategory} />
          <PrivateRoute exact path="/firman/create-firman" component={CreateFirman} />
          <PrivateRoute exact path="/firman/edit-firman/:id" component={EditFirman} />
          <PrivateRoute exact path="/berita/category" component={BeritaCategory} />
          <PrivateRoute exact path="/berita" component={Berita} />
          <PrivateRoute exact path="/berita/category/create-category" component={CreateBeritaCategory} />
          <PrivateRoute exact path="/berita/category/edit-category/:id" component={EditBeritaCategory} />
          <PrivateRoute exact path="/berita/create-berita" component={CreateBerita} />
          <PrivateRoute exact path="/berita/edit-berita/:id" component={EditBerita} />
          <PrivateRoute exact path="/karya/category" component={KaryaCategory} />
          <PrivateRoute exact path="/karya/category/create-category" component={CreateKaryaCategory} />
          <PrivateRoute exact path="/karya/category/edit-category/:id" component={EditKaryaCategory} />
          <PrivateRoute exact path="/karya/toko" component={KaryaToko} />
          <PrivateRoute exact path="/karya/toko/create-toko" component={CreateKaryaToko} />
          <PrivateRoute exact path="/karya/toko/edit-toko/:id" component={EditKaryaToko} />
          <PrivateRoute exact path="/karya/toko/produk/:id" component={KaryaProduk} />
          <PrivateRoute exact path="/karya/toko/produk/:id/create-produk" component={CreateKaryaTokoProduct} />
          <PrivateRoute exact path="/karya/toko/produk/edit-produk/:id" component={EditKaryaTokoProduct} />
          <PrivateRoute exact path="/action" component={Action} />
          <PrivateRoute exact path="/action/create-action" component={CreateAction} />
          <PrivateRoute exact path="/action/edit-action/:id" component={EditAction} />
          <PrivateRoute exact path="/action/kegiatan/:id" component={ActionKegiatan} />
          <PrivateRoute exact path="/action/kegiatan/:id/create-actionkegiatan" component={CreateActionKegiatan} />
          <PrivateRoute exact path="/action/kegiatan/edit-actionkegiatan/:id" component={EditActionKegiatan} />
          <PrivateRoute exact path="/action/kegiatan/absensi/:id" component={ActionKegiatanAbsensi} />
          <PrivateRoute exact path="/action/peserta/:id" component={ActionPeserta} />
          <PrivateRoute exact path="/beritaGKMI/kategori" component={BeritaGKMIKategori} />
          <PrivateRoute exact path="/beritaGKMI/edisi" component={BeritaGKMIEdisi} />
          <PrivateRoute exact path="/beritaGKMI" component={BeritaGKMI} />
          <PrivateRoute exact path="/beritaGKMI/kontributor" component={BeritaGKMIKontributor} />
          <PrivateRoute exact path="/beritaGKMI/create-beritaGkmi" component={CreateBeritaGkmi} />
          <PrivateRoute exact path="/beritaGKMI/create-kategori" component={CreateBeritaGkmiKategori} />
          <PrivateRoute exact path="/beritaGKMI/create-edisi" component={CreateBeritaGkmiEdisi} />
          <PrivateRoute exact path="/beritaGKMI/create-kontributor" component={CreateBeritaGkmiKontributor} />
          <PrivateRoute exact path="/beritaGKMI/kategori/edit-kategori/:id" component={EditBeritaGkmiKategori} />
          <PrivateRoute exact path="/beritaGKMI/edisi/edit-edisi/:id" component={EditBeritaGkmiEdisi} />
          <PrivateRoute exact path="/beritaGKMI/edit-berita/:id" component={EditBeritaGkmi} />
          <PrivateRoute exact path="/beritaGKMI/kontributor/edit-kontributor/:id" component={EditBeritaGkmiKontributor} />
          <PrivateRoute exact path="/beritaGKMI/langganan" component={Langganan} />
          <PrivateRoute exact path="/beritaGKMI/create-langganan" component={CreateLangganan} />
          <PrivateRoute exact path="/beritaGKMI/langganan/edit-langganan/:id" component={EditLangganan} />
          <PrivateRoute exact path="/beritaGKMI/user" component={UserRole} />
          <PrivateRoute exact path="/beritaGKMI/create-user-role" component={CreateUserRole} />
          <PrivateRoute exact path="/beritaGKMI/edit-user-role/:id" component={EditUserRole} />
          <PrivateRoute exact path="/beritaGKMI/iklan" component={BeritaGKMIIklan} />
          <PrivateRoute exact path="/beritaGKMI/create-iklan" component={CreateBeritaGkmiIklan} />
          <PrivateRoute exact path="/beritaGKMI/iklan/edit-iklan/:id" component={EditBeritaGkmiIklan} />
          <PrivateRoute exact path="/beritaGKMI/order" component={LanggananOrder} />
          <PrivateRoute exact path="/beritaGKMI/order/konfirmasi-order/:id" component={KonfirmasiOrderLangganan} />
          <PrivateRoute exact path="/beritaGKMI/pembayaran" component={CaraPembayaran} />
          <PrivateRoute exact path="/beritaGKMI/pembayaran/edit-pembayaran/:id" component={EditBeritaGkmiPembayaran} />
          <PrivateRoute exact path="/pipka/branches" component={Branches} />
                   <PrivateRoute exact path="/pipka/branches/edit-branches/:kode_gereja" component={EditBranches} />
                   <PrivateRoute exact path="/pipka/branches/create-branches" component={CreateBranches} />
                   <PrivateRoute exact path="/pipka/branches/slider/:id" component={BranchesSlider} />
                   <PrivateRoute exact path="/pipka/branches/slider/create-slider/:id" component={CreateBranchesSlider} />
                   <PrivateRoute exact path="/pipka/branches/slider/edit-slider/:id" component={EditBranchesSlider} />
                   <PrivateRoute exact path="/pipka/branches/pemimpin/:id" component={BranchesPemimpin} />
                   <PrivateRoute exact path="/pipka/branches/pemimpin/create-pemimpin/:id" component={CreatePemimpinBranches} />
                   <PrivateRoute exact path="/pipka/branches/pemimpin/edit-pemimpin/:id" component={EditBranchesPemimpin} />
                   <PrivateRoute exact path="/pipka/branches/kegiatan/:id" component={BranchesKegiatan} />
                   <PrivateRoute exact path="/pipka/branches/kegiatan/create-kegiatan/:id" component={CreateBranchesKegiatan} />
                   <PrivateRoute exact path="/pipka/branches/kegiatan/edit-kegiatan/:id" component={EditBranchesKegiatan} />
                   <PrivateRoute exact path="/pipka/branches/kegiatan/ibadah/:id" component={ListBranchesIbadah} />
                   <PrivateRoute exact path="/pipka/branches/kegiatan/ibadah/create-ibadah/:id" component={CreateBranchesIbadah} />
                   <PrivateRoute exact path="/pipka/branches/kegiatan/ibadah/edit-ibadah/:id" component={EditIBranchesbadah} />
                   <PrivateRoute exact path="/pipka/branches/pembicara/:id" component={BranchesPembicara} />
                   <PrivateRoute exact path="/pipka/branches/pembicara/create-pembicara/:id" component={CreateBranchesPembicara} />
                   <PrivateRoute exact path="/pipka/branches/pembicara/edit-pembicara/:id" component={EditBranchesPembicara} />
                   <PrivateRoute exact path="/pipka/pipka-slider" component={PipkaSlider} />
                   <PrivateRoute exact path="/pipka/create-pipkaslider" component={CreatePipkaHomeSlider} />
                   <PrivateRoute exact path="/pipka/edit-pipkaslider/:id" component={EditPipkaHomeSlider} />
                   <PrivateRoute exact path="/pipka/donasi" component={Donasi} />
                   <PrivateRoute exact path="/pipka/donasi/edit-donasi/:id" component={EditDonasi} />
                   <PrivateRoute exact path="/pipka/berita" component={BeritaPipka} />
                   <PrivateRoute exact path="/pipka/berita/create-berita" component={CreateBeritaPipka} />
                   <PrivateRoute exact path="/pipka/berita/edit-berita/:id" component={EditBeritaPipka} />
                   <PrivateRoute exact path="/pipka/setting" component={Settings} />
                   <PrivateRoute exact path="/pipka/region" component={Region} />
                   <PrivateRoute exact path="/pipka/region/create-region" component={CreateRegion} />
                   <PrivateRoute exact path="/pipka/region/edit-region/:id" component={EditRegion} />
                   <PrivateRoute exact path="/pipka/menu" component={PipkaMenu} />
                   <PrivateRoute exact path="/pipka/menu/create-menu" component={CreateMenuHome} />
                   <PrivateRoute exact path="/pipka/menu/edit-menu/:id" component={EditMenuHome} />
                   <PrivateRoute exact path="/pipka/event" component={EventPipka} />
                   <PrivateRoute exact path="/pipka/event/create-event" component={CreateEvent} />
                   <PrivateRoute exact path="/pipka/event/edit-event/:id" component={EditEvent} />
                   <PrivateRoute exact path="/pipka/project" component={ProjectPipka} />
                   <PrivateRoute exact path="/pipka/project/create-project" component={CreateProject} />
                   <PrivateRoute exact path="/pipka/project/edit-project/:id" component={EditProject} />
                   <PrivateRoute exact path="/pipka/project/progress/:id" component={ProjectProgressPipka} />
                   <PrivateRoute exact path="/pipka/project/progress/create-progress/:id" component={CreateProjectProgress} />
                   <PrivateRoute exact path="/pipka/project/progress/edit-progress/:id" component={EditProjectProgress} />
                   <PrivateRoute exact path="/pipka/project/donasi/:id" component={ProjectDonasi} />
                   <PrivateRoute exact path="/pipka/mission/kategori" component={KategoriMissionHome} />
                   <PrivateRoute exact path="/pipka/mission/kategori/create-kategori" component={CreateKategoriMissionHome} />
                   <PrivateRoute exact path="/pipka/mission/kategori/edit-kategori/:id" component={EditKategoriMissionHome} />
                   <PrivateRoute exact path="/pipka/missionhome" component={MissionHome} />
                   <PrivateRoute exact path="/pipka/missionhome/create-missionhome" component={CreateMissionHome} />
                   <PrivateRoute exact path="/pipka/missionhome/edit-missionhome/:id" component={EditMissionHome} />
                   <PrivateRoute exact path="/pipka/galleryfoto" component={GalleryFoto} />
                   <PrivateRoute exact path="/pipka/galleryvideo" component={GalleryVideo} />
                   <PrivateRoute exact path="/pipka/galleryvideo/create-galleryvideo" component={CreateGalleryVideo} />
                   <PrivateRoute exact path="/pipka/galleryvideo/edit-galleryvideo/:id" component={EditGalleryVideo} />
                   <PrivateRoute exact path="/pipka/firman" component={FirmanPipka} />
                   <PrivateRoute exact path="/pipka/firman/create-firman" component={CreatePipkaFirman} />
                    <PrivateRoute exact path="/pipka/firman/edit-firman/:id" component={EditPipkaFirman} />
                    <PrivateRoute exact path="/connect/topic" component={ConnectTopic} />
                    <PrivateRoute exact path="/connect/topic/create-topic" component={CreateConnectTopic} />
                    <PrivateRoute exact path="/connect/topic/edit-topic/:id" component={EditConnectTopic} />
                    <PrivateRoute exact path="/connect" component={Connect} />
                    <PrivateRoute exact path="/connect/create-connect" component={CreateConnect} />
                    <PrivateRoute exact path="/connect/edit-connect/:id" component={EditConnect} />
                    <PrivateRoute exact path="/connect/operation/:id" component={ConnectOperation} />
                    <PrivateRoute exact path="/connect/lagu" component={ConnectLagu} />
                    <PrivateRoute exact path="/connect/lagu/create-lagu" component={CreateConnectLagu} />
                    <PrivateRoute exact path="/connect/lagu/edit-lagu/:id" component={EditConnectLagu} />
                    <PrivateRoute exact path="/outvision" component={OutVision} />
                    <PrivateRoute exact path="/history" component={History} />
                    <PrivateRoute exact path="/letters" component={Letters} />
            <PrivateRoute exact path="/letters/create-letters" component={CreateLetters} />
            <PrivateRoute exact path="/letters/edit-letters/:id" component={EditLetters} />
            <PrivateRoute exact path="/database" component={Database} />
            <PrivateRoute exact path="/database/create-database" component={CreateDatabase} />
            <PrivateRoute exact path="/database/edit-database/:id" component={EditDatabase} />
            <PrivateRoute exact path="/database/data/:id" component={DataDatabase} />
            <PrivateRoute exact path="/database/datadatabase/create-datadatabase/:id" component={CreateDataDatabase} />
            <PrivateRoute exact path="/database/datadatabase/edit-datadatabase/:id" component={EditDataDatabase} />
            <PrivateRoute exact path="/tatagereja" component={TataGereja} />
         <PrivateRoute exact path="/tatagereja/parent/:id" component={TataGerejaParent} />
         <PrivateRoute exact path="/tatagereja/parent/child/:id" component={TataGerejaParentChild} />
         <PrivateRoute exact path="/tatagereja/create-tatagereja/" component={CreateTataGereja} />
         <PrivateRoute exact path="/tatagereja/parent/create-tatagerejaparent/:id" component={CreateTataGerejaParent} />
         <PrivateRoute exact path="/tatagereja/parent/create-tatagerejachild/:id" component={CreateTataGerejaChild} />
         <PrivateRoute exact path="/tatagereja/edit-tatagereja/:id" component={EditTataGereja} />
         <PrivateRoute exact path="/tatagereja/parent/edit-tatagerejaparent/:id" component={EditTataGerejaParent} />
         <PrivateRoute exact path="/tatagereja/parent/edit-tatagerejachild/:id" component={EditTataGerejaChild} />
         <PrivateRoute exact path="/tatagereja/document/create-document/:id" component={CreateTataGerejaDocument} />
         <PrivateRoute exact path="/tatagereja/document/create-documentparent/:id" component={CreateTataGerejaDocumentParent} />
         <PrivateRoute exact path="/tatagereja/document/create-documentchild/:id" component={CreateTataGerejaDocumentChild} />
         <PrivateRoute exact path="/tatagereja/document/edit-document/:id" component={EditTataGerejaDocument} />
         <PrivateRoute exact path="/tatagereja/document/edit-documentparent/:id" component={EditTataGerejaDocumentParent} />
         <PrivateRoute exact path="/tatagereja/document/edit-documentchild/:id" component={EditTataGerejaDocumentChild} />
         <PrivateRoute exact path="/project" component={ProjectSinode} />
        <PrivateRoute exact path="/project/create-project" component={CreateProjectSinode} />
        <PrivateRoute exact path="/project/edit-project/:id" component={EditProjectSinode} />
        <PrivateRoute exact path="/projectsinode/progress/:id" component={ProjectProgressSinode} />
        <PrivateRoute exact path="/projectsinode/progress/create-progressproject/:id" component={CreateProjectProgressSinode} />
        <PrivateRoute exact path="/projectsinode/progress/edit-progressproject/:id" component={EditProjectProgressSinode} />
        <PrivateRoute exact path="/project/donasi/:id" component={ProjectDonasiSinode} />
        <PrivateRoute exact path="/galleryfoto" component={GalleryFotoSinode} />
        {/* <PrivateRoute exact path="/pipka/galleryfoto/create-galleryfoto" component={CreateGalleryFoto} /> */}
        <PrivateRoute exact path="/galleryvideo" component={GalleryVideoSinode} />
        <PrivateRoute exact path="/galleryvideo/create-galleryvideosinode" component={CreateGalleryVideoSinode} />
        <PrivateRoute exact path="/galleryvideo/edit-galleryvideosinode/:id" component={EditGalleryVideoSinode} />
        <PrivateRoute exact path="/user" component={UserRoleSinode} />
        <PrivateRoute exact path="/user/create-user-role" component={CreateUserRoleSinode} />
        <PrivateRoute exact path="/user/edit-user-role/:id" component={EditUserRole} />
        <PrivateRoute exact path="/eksekutif" component={EksekutifMenu} />
        <PrivateRoute exact path="/eksekutif/create-eksekutifmenu" component={CreateEksekutifMenu} />
        <PrivateRoute exact path="/eksekutif/edit-eksekutifmenu/:id" component={EditEksekutifMenu} />
        <PrivateRoute exact path="/eksekutif/data/:id" component={EksekutifData} />
        {/*<PrivateRoute exact path="/database/datadatabase/create-datadatabase/:id" component={CreateDataDatabase} />
        <PrivateRoute exact path="/database/datadatabase/edit-datadatabase/:id" component={EditDataDatabase} /> */}
        <PrivateRoute exact path="/schedule" component={ScheduleSinode} />
         <PrivateRoute exact path="/schedule/create-schedule" component={CreateScheduleSinode} />
         <PrivateRoute exact path="/schedule/edit-schedule/:id" component={EditScheduleSinode} />
          <PrivateRoute exact path="/sliderhome" component={HomeSlider} />
          <PrivateRoute exact path="/sliderhome/create-homeslider" component={CreateHomeSlider} />
          <PrivateRoute exact path="/sliderhome/edit-homeslider/:id" component={EditHomeSlider} />
          <PrivateRoute exact path="/change-password" component={ChangePassword} />
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
