import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { useParams,Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import moment from "moment";

const TataGerejaParent = ({ auth: { user, token } }) => {
    const [menuHome, setMenuHome] = useState([]);
    const [status, setStatus] = useState(true);
    const [kodeParent, setKodeParent] = useState();
    const {id} = useParams();
    useEffect(() =>{
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        let kode_project = decodeString.split(';')[0];
         setKodeParent(decodeString.split(';')[0])
        fetchData(kode_project);
   }, [user,id]);

    const fetchData = async (kode_project) => {
    //
      let link='';
      link = `https://gkmi.one:8080/api/sinodebackend/tatagereja/parent/${kode_project}`;

      const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
        setMenuHome(response.data);

    }


    const data = {
        columns: [
          {
            label: "No.",
            field: "no",
            sort: "asc",
            width: 10,
          },


          {
              label: "Nama",
              field: "nama",
              sort: "asc",

          },

          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 30,
        },
          {
                label: "Action",
                field: "action",
                sort: "asc",

            },

        ],
        rows: menuHome.map((apiData, i) => {
          let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
            let database64EncodeString = Buffer.from(`${kodeParent};${apiData._id}`).toString('base64');
            let dataParent64EncodeString = Buffer.from(`${id};${apiData._id}`).toString('base64');

          var active;
                  var classStatus;
                  if(apiData.active==1){
                    active="Active";
                    classStatus="badge bg-gray"
                        }else{
                            active="In Active";
                            classStatus="badge badge-danger"
                        }
// var asas=apiData.relation.length();
//                         console.log("data "+asas);

          return({
              no: (i+1),

              nama: <span>{apiData.nama}</span>,
              status:<span class={classStatus}>{active}</span>,
              action:
                        <div class="">
                            {
                            apiData.relation.length >0 ?
                            <div class="">
                            <button type="button" class="btn btn-success btn-sm" >
                            <Link  to={`/tatagereja/parent/edit-tatagerejaparent/${dataParent64EncodeString}`}>Ubah</Link>
                           </button> 	&nbsp;
                            <button type="button" class="btn btn-success btn-sm" >
                            <Link  to={`/tatagereja/parent/child/${database64EncodeString}`}>Child</Link>
                            </button>
                            </div>
                                : apiData.document > 0 ?
                                apiData.document_active > 1 ?
                                  <div class="">
                                  <button type="button" class="btn btn-success btn-sm" >
                                  <Link  to={`/tatagereja/document/edit-documentparent/${database64EncodeString}`}>Document Edit</Link>
                              </button> &nbsp;
                              <button type="button" class="btn btn-success btn-sm" >
                              <Link  to={`/tatagereja/parent/edit-tatagerejaparent/${dataParent64EncodeString}`}>Ubah</Link>
                                          </button> 	&nbsp;
                                          <button type="button" class="btn btn-success btn-sm" >
                              <Link  to={`/tatagereja/parent/child/${database64EncodeString}`}>Child</Link>
                              </button>
                              </div>
                              :<div class="">
                              <button type="button" class="btn btn-success btn-sm" >
                              <Link  to={`/tatagereja/document/edit-documentparent/${database64EncodeString}`}>Document Edit</Link>
                          </button> &nbsp;
                          <button type="button" class="btn btn-success btn-sm" >
                          <Link  to={`/tatagereja/parent/edit-tatagerejaparent/${dataParent64EncodeString}`}>Ubah</Link>
                                      </button> 	&nbsp;
                          </div>
                            :
                            <div class="">
                            <button type="button" class="btn btn-success btn-sm" >
                            <Link  to={`/tatagereja/document/create-documentparent/${dataParent64EncodeString}`}>Document Add</Link>
                            </button> &nbsp;
                            <button type="button" class="btn btn-success btn-sm" >
                            <Link  to={`/tatagereja/parent/edit-tatagerejaparent/${dataParent64EncodeString}`}>Ubah</Link>
                            </button> 	&nbsp;
                            <button type="button" class="btn btn-success btn-sm" >
                            <Link  to={`/tatagereja/parent/child/${database64EncodeString}`}>Child</Link>
                            </button>
                            </div>
                            }
                            </div>

        });
      }),
    };




    function cekStatus(){
        if(status){

          return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-2">
                    <Link to={`/tatagereja`} className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-3">

                           <Link  to={`/tatagereja/parent/create-tatagerejaparent/${id}`}  className="btn btn-block bg-gradient-green  ">Tambah Menu Parent</Link>
                        </div>
                        <div className="col-sm-7">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardSinode">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Menu Parent</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="content">
                <div className="container-fluid">
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Menu</h3>
                    <div className="card-tools">
                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>


                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(TataGerejaParent);
