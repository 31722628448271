import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import DatePicker from "react-datepicker";
import moment from "moment";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { updateJemaat } from "../../../actions/jemaatAction";
import { getJemaatById } from "../../../actions/jemaatAction";

const EditUserRole = ({ jemaat: {  jemaat, loading  }, getJemaatById, updateJemaat, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [status, setStatus] = useState();
    const [role2, setRole2] = useState('');
    const [fileName, setFileName] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [values, setValues] = useState({
      gender: 'Pria',
    });
    const [formData, setFormData] = useState({
        nama: '',
        email: '',
        no_telepon: '',
        alamat: '',
        tanggal_lahir:'',
        tempat_lahir:'',
        role:'',
        level_user: ''
    });

    useEffect(() => {

        setFormData({
          nama: jemaat && (jemaat.nama ? jemaat.nama : ''),
          no_telepon: jemaat && (jemaat.no_telepon ? jemaat.no_telepon : ''),
          email: jemaat && (jemaat.email ? jemaat.email : ''),
          tempat_lahir: jemaat && (jemaat.tempat_lahir ? jemaat.tempat_lahir : ''),
          //tanggal_lahir: jemaat && (jemaat.tanggal_lahir ? moment(jemaat.tanggal_lahir).format('YYYY-MM-DD') : ''),
          tanggal_lahir: jemaat && (jemaat.tanggal_lahir ? moment(jemaat.tanggal_lahir, 'DD-MM-YYYY').format('YYYY-MM-DD') : ''),
          gender: jemaat && (jemaat.jenis_kelamin ? jemaat.jenis_kelamin : ''),
          alamat: jemaat && (jemaat.alamat ? jemaat.alamat : ''),

        });
        setValues({ gender: jemaat && (jemaat.jenis_kelamin ? jemaat.jenis_kelamin : '') });
        setRole2(jemaat && (jemaat.role ? jemaat.role : ''));
    }, [loading, jemaat]);

    // useEffect(() => {
    //     setFileName(firmanCategory && (firmanCategory.nama_image ? firmanCategory.nama_image : ''));
    //     console.log('NAMA: ' + fileName);
    //     setImagePreviewUrl((firmanCategory && (firmanCategory.tautan ? firmanCategory.tautan : ''))+fileName);
    // }, [loading, firmanCategory, fileName]);

    useEffect(() => {
        getJemaatById(Buffer.from(id, 'base64').toString('ascii'));
    }, [getJemaatById, id]);

    const setGender = (event) => {
      setValues({ ...values, gender: event.target.value });
    };

    useEffect(() => {
      console.log("VAL: " + values.gender);

    }, [values]);

    const {
        nama,
        email,
        no_telepon,
        alamat,
        tanggal_lahir,
        tempat_lahir,
        role
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const selectOnChange = e => {
        setStatus(parseInt(e.target.value));
        console.log("TARGET:" + e.target.value);
    }



    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }
    const handleDateChange = e => {
      setFormData({ ...formData,
        tanggal_lahir: new Date(e)
      });
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
         const data = new FormData();
         let newRole;
         if(typeof formData.role === "undefined"){

           newRole=role2;
           console.log("TARGET undefined  :" + newRole);
         }else{
           newRole= formData.role;
           console.log("TARGET   :" + newRole);
         }
         if(typeof formData.role !== 'undefined'){
          //data.append("nama", formData.role);
          console.log("TARGET masuk  :" + newRole);
        }
         let level = "2";
          // if(formData.role === "Guest"){
          //   level = "1";
          // }else if(formData.role === "Simpatisan"){
          //   level = "11";
          // }else if(formData.role === "Member"){
          //   level = "2";
          // }else if(formData.role === "Volunteer"){
          //   level = "3";
          // }else if(formData.role === "Leader"){
          //   level = "4";
          // }else if(formData.role === "Church Administrator"){
          //   level = "5";
          // }

          if(newRole === "Guest"){
            level = "1";
          }else if(newRole === "Simpatisan"){
            level = "11";
          }else if(newRole === "Member"){
            level = "2";
          }else if(newRole === "Volunteer"){
            level = "3";
          }else if(newRole === "Leader"){
            level = "4";
          }else if(newRole === "Church Administrator"){
            level = "5";
          }
         data.append("jenis_kelamin", values.gender);
         data.append("tanggal_lahir", moment(tanggal_lahir).format('YYYY-MM-DD'));
         data.append("nama", formData.nama);
         data.append("email", formData.email);
         data.append("tempat_lahir", formData.tempat_lahir);
         data.append("no_telepon", formData.no_telepon);
         data.append("alamat", formData.alamat);
         data.append("role", newRole);
         data.append("level_user", level);

       updateJemaat(data, Buffer.from(id, 'base64').toString('ascii'), history);

    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI/user" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">User Role</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Data User Role {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                        <label>Status</label>
                        <select name="role" required className="form-control" id="role" onChange={e => onChange(e)}>
                            <option value="">--Pilih--</option>

                            <option value="Church Administrator" selected = {role2 === "Church Administrator"}>Administrator</option>
                        </select>
                        </div>
                      </div>
                      </div>
                    <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Nama</label>
                      <input type="text" className="form-control" placeholder="Nama ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>No. Telepon</label>
                      <input type="text" className="form-control" placeholder="No Telpone ..." name="no_telepon" value={no_telepon} onChange={e => onChange(e)} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input type="text" className="form-control" placeholder="Email ..." name="email" value={email} onChange={e => onChange(e)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Tempat Lahir</label>
                      <input type="text" className="form-control" placeholder="Tempat Lahir ..." name="tempat_lahir" value={tempat_lahir} onChange={e => onChange(e)} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Tgl Lahir</label>
                      <div className="customDatePickerWidth">
                                                        <DatePicker
                                                        name="tanggal"
                                                        dateFormat="dd-MM-yyyy"
                                                        className="form-control"
                                                        selected={tanggal_lahir ? new Date(tanggal_lahir) : new Date()}
                                                        onChange={handleDateChange}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        onFocus={e => e.target.blur()}/>
                                                    </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Jenis Kelamin</label>
                      <div onChange={setGender}>
                          <div class="form-check "><input class="form-check-input" type="radio" value="Pria" name="gender" checked = {values.gender === 'Pria'}/><label class="form-check-label">Pria</label></div>
                          <div class="form-check "><input class="form-check-input" type="radio" value="Wanita" name="gender" checked = {values.gender === 'Wanita'}/><label class="form-check-label">Wanita</label></div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                        <label>Alamat</label>
                      <textarea className="form-control" rows="3" placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                        </div>
                      </div>
                      </div>




                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditUserRole.propTypes = {
    updateJemaat: PropTypes.func.isRequired,
    getJemaatById: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    jemaat: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
   // auth: state.auth,
   profile: state.profile,
   jemaat: state.jemaat
});

export default connect(mapStateToProps, { updateJemaat, getJemaatById })(withRouter(EditUserRole));
