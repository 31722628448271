import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";

import { updateDataDatabase } from "../../../actions/sinodeAction";
import { getDataDatabaseById } from "../../../actions/sinodeAction";

const EditDataDatabase = ({ sinode: { loading, datadatabase }, getDataDatabaseById, updateDataDatabase, auth: { user }, history }) => {
    const {id} = useParams();
    const [loadings, setLoadings] = useState(false);
    const [idValue, setIdValue] = useState('');
    const [idData, setIdData] = useState('');

    const [values, setValues] = useState({
        status: '1',
      });

    const [formData, setFormData] = useState({
      nama:'',
      data1:'',
      data2:'',
      data3:'',
      data4:'',
      data5:'',
    });

    useEffect(() => {
        setFormData({
          nama: datadatabase && (datadatabase.nama ? datadatabase.nama : ''),
          data1: datadatabase && (datadatabase.field1 ? datadatabase.field1 : ''),
          data2: datadatabase && (datadatabase.field2 ? datadatabase.field2 : ''),
          data3: datadatabase && (datadatabase.field3 ? datadatabase.field3 : ''),
          data4: datadatabase && (datadatabase.field4 ? datadatabase.field4 : ''),
          data5: datadatabase && (datadatabase.field5 ? datadatabase.field5 : ''),
            status: datadatabase && (datadatabase.active ? datadatabase.active : ''),
        });
        setValues({ status: datadatabase && (datadatabase.active.toString()) });
     
    }, [loading, datadatabase]);



    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        setIdValue(decodeString.split(';')[1])
        setIdData(decodeString.split(';')[0])
        getDataDatabaseById( decodeString.split(';')[0]);
      }, [getDataDatabaseById, id]);

     

    const {
      nama,
      data1,
      data2,
      data3,
      data4,
      data5,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        data.append("nama", formData.nama);
        data.append("field1", formData.data1);
        data.append("field2", formData.data2  );
        data.append("field3", formData.data3 );
        data.append("field4", formData.data4);
        data.append("field5", formData.data5);
        data.append("active", values.status);
        updateDataDatabase(data,  idData, history,idValue);
     
    }
    const setStatus = (event) => {   
        setValues({ ...values, status: event.target.value });
      };

     
    
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                <Link to={`/database/data/${idValue}`}  className="btn btn-block bg-gradient-danger">Back</Link>
                  
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardSinode">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Data</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Data</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-2">
                        <div className="form-group">
                          <label>Status *</label>
                         
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                     
                      <div className="row">
                <div className="col-sm-2"> 
                    <div className="form-group">
                    <label>Nama *</label>
                    </div>
                  </div>
                <div className="col-sm-4">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Nama ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
               
                
               
                <div className="row">
                <div className="col-sm-2"> 
                    <div className="form-group">
                    <label>Data 1 *</label>
                    </div>
                  </div>
                <div className="col-sm-4">
                <div className="form-group">
                <input type="number" className="form-control" placeholder="Data 1 ..." required name="data1" value={data1} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-2"> 
                    <div className="form-group">
                    <label>Data 2</label>
                    </div>
                  </div>
                <div className="col-sm-4">
                <div className="form-group">
                <input type="number" className="form-control" placeholder="Data 2 ..."  name="data2" value={data2} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-2"> 
                    <div className="form-group">
                    <label>Data 3</label>
                    </div>
                  </div>
                <div className="col-sm-4">
                <div className="form-group">
                <input type="number" className="form-control" placeholder="Data3 ..."  name="data3" value={data3} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-2"> 
                    <div className="form-group">
                    <label>Data 4</label>
                    </div>
                  </div>
                <div className="col-sm-4">
                <div className="form-group">
                <input type="number" className="form-control" placeholder="Data4 ..."  name="data4" value={data4} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-2"> 
                    <div className="form-group">
                    <label>Data 5</label>
                    </div>
                  </div>
                <div className="col-sm-4">
                <div className="form-group">
                <input type="number" className="form-control" placeholder="Data5 ..."  name="data5" value={data5} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditDataDatabase.propTypes = {
  updateDataDatabase: PropTypes.func.isRequired,
  getDataDatabaseById: PropTypes.func.isRequired,
  
}
const mapStateToProps = state => ({
    auth: state.auth,
    sinode: state.sinode
});

export default connect(mapStateToProps, { updateDataDatabase, getDataDatabaseById })(withRouter(EditDataDatabase));
