import { GET_PEMBICARA, PEMBICARA_ERROR } from "../actions/types";

const initialState = {
    pembicara: [],
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_PEMBICARA:
            return{
                ...state,
                pembicara: payload,
                loading: false
            };
        case PEMBICARA_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}