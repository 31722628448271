import { GET_KARYA_CATEGORY3, KARYA_CATEGORY_ERROR } from "../actions/types";

const initialState = {
    karyaCategory3: [],
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_KARYA_CATEGORY3:
            return{
                ...state,
                karyaCategory3: payload,
                loading: false
            };
        case KARYA_CATEGORY_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}