import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';
import axios from 'axios';

import { createTataGereja } from "../../../actions/sinodeAction";


const CreateTataGereja = ({ createTataGereja, auth: { user, token }, history }) => {
    const editor = useRef();
    const [value, setValue] = useState('');
    const [loadings, setLoadings] = useState(false);
    const [formData, setFormData] = useState({
     
      nama:'',
    });

    const {
      nama,
      ayat,
    } = formData;
    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    


    const onSubmit = async (e) => {
        e.preventDefault();
        //setLoadings(true);
      
        const data = new FormData();
        data.append("nama", formData.nama);
        createTataGereja(data, history);
         
      
    }

   
    
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content fileHeader (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/tatagereja" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardSinode">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Tata Gereja</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create Menu Tata Gereja</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>
                </div>
                </div>
                <div className="card-body">
                    <Alert />
                <div className="row">
                <div className="col-sm-3"> 
                    <div className="form-group">
                    <label>Nama</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Nama Menu ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
               
                
                
                
                
                
                

                

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                  {
                    loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                    :
                    <input type="submit" className="btn btn-primary pull-right" value="Save" />
                  }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreateTataGereja.propTypes = {
  createTataGereja: PropTypes.func.isRequired,
  
    
}
const mapStateToProps = state => ({
    auth: state.auth,
    sinode: state.sinode
});

export default connect(mapStateToProps, { createTataGereja})(withRouter(CreateTataGereja));
