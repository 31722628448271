import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { useParams, Link }  from "react-router-dom";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { connect } from 'react-redux';


const IbadahJemaat = ({ auth: { user, token } }) => {
    const {id} = useParams();
    const [dataIbadahJemaat, setDataIbadahJemaat] = useState([]);
    const [title, setTitle] = useState();
    const [kegiatan, setKegiatan] = useState();
    useEffect(() =>{
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        setTitle(decodeString.split(';')[2]);
        setKegiatan(decodeString.split(';')[3]);
        let jadwal_id = decodeString.split(';')[4];
        let kode_gereja = decodeString.split(';')[1];
        let link = `https://gkmi.one:8080/api/ibadah/registration/${kode_gereja}/${jadwal_id}`;

        console.log('LINK: ' + link);
        console.log('DECODE: ' + decodeString);

        fetch(`${link}`, {
            headers: {
                'x-auth-token': token
            }
        })
          .then((response) => {
            return response.json();
          })
          .then((res) => {

            const data = {
              columns: [
                {
                    label: "No.",
                    field: "no",
                    sort: "asc",
                    width: 10,
                },
                {
                    label: "Nama.",
                    field: "nama",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "No. Telepon",
                    field: "no_telepon",
                    sort: "asc",
                    width: 50,
                },
                {
                    label: "Jumlah",
                    field: "jumlah",
                    sort: "asc",
                    width: 10,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 50,
                },
                {
                    label: "Keterangan",
                    field: "keterangan",
                    sort: "asc",
                    width: 150,
                },

              ],
              rows: res.map((apiData, i) => ({
                  no: (i+1),
                  nama: apiData.nama,
                  no_telepon: apiData.no_telepon,
                  jumlah: apiData.jumlah,
                  status: apiData.status,
                  keterangan: apiData.keterangan
              })),

            };
            setDataIbadahJemaat(data);
          });
    }, [id]);



    return(
        <Fragment>
        <Header />
        <Navbar />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-2">
                            <Link to={`/jadual/ibadah/${id}`} className="btn btn-block bg-gradient-danger">Back</Link>
                        </div>
                        <div className="col-sm-10">
                        <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                            <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/jadual/kegiatan">Jadual</Link>
                            </li>
                            <li className="breadcrumb-item active">Ibadah</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content">
                <div className="container-fluid">
                    {/* Default box */}
                    <div className="card">

                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                        <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Data Jemaat {title} {kegiatan}</h3>
                        <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                        >
                            <i className="fas fa-minus" />
                        </button>

                        </div>
                    </div>
                    <div className="card-body">
                    <MDBDataTable responsive striped bordered hover data={dataIbadahJemaat} />
                    </div>
                    </div>
                    {/* /.card */}

                    </div>
                </section>
                {/* /.content */}

                </div>
                {/* /.content-wrapper */}
        <Footer/>
        </Fragment>

    )
};

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(IbadahJemaat);
