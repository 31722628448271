import { GET_GEREJA_JADWAL_IBADAH,  GEREJA_JADWAL_IBADAH_ERROR } from "../actions/types";

const initialState = {
    jadwalIbadah: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_GEREJA_JADWAL_IBADAH:
            return{
                ...state,
                jadwalIbadah: payload,
                loading: false
            };
        case GEREJA_JADWAL_IBADAH_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}