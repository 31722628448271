import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';

const FirmanCategory = ({ auth: { user, token } }) => {
    const [dataFirmanCategory, setFirmanCategory] = useState([]);
    const [status, setStatus] = useState(true);




    // useEffect(() =>{
    //     let codeChurch = user && (user.gereja[0].kode_gereja);
    //     let codeMasterChurch = user && (user.gereja[0].kode_gereja_induk);
    //     console.log('PROFILE: ' + codeChurch + " - " + codeMasterChurch);
    //     let link='';
    //     let codeMasterChurch2 = 0;
    //     if (user && (user.level_user === 7)) {
    //         link = 'https://gkmi.one:8080/api/firman/kategori/0';
    //     }else{
    //         if(codeMasterChurch !== ''){
    //             codeMasterChurch2 = codeMasterChurch;
    //         }
    //         link = `https://gkmi.one:8080/api/firman/kategori/1`;
    //         console.log('LINK: ' + link);
    //     }
    //     fetch(`${link}`)
    //       .then((response) => {
    //         console.log('AAA 1: ' + response.ok);
    //         setStatus(response.ok);
    //         return response.json();
    //       })
    //       .then((res) => {
    //         console.log(res, "data");
    //         const data = {
    //           columns: [
    //             {
    //                 label: "No.",
    //                 field: "no",
    //                 sort: "asc",
    //                 width: 10,
    //             },
    //             {
    //                 label: "Image",
    //                 field: "nama_image",
    //                 sort: "asc",
    //                 width: 150,
    //             },
    //             {
    //                 label: "Nama Kategori",
    //                 field: "nama_kategori",
    //                 sort: "asc",
    //                 width: 150,
    //             },
    //             {
    //                 label: "Status",
    //                 field: "status",
    //                 sort: "asc",
    //                 width: 150,
    //             },
    //             {
    //                 label: "Urutan",
    //                 field: "urutan",
    //                 sort: "asc",
    //                 width: 150,
    //             },
    //             {
    //                 label: "Action",
    //                 field: "action",
    //                 width: 150,
    //             },
    //           ],
    //           rows: res.map((apiData, i) => {
    //               let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
    //               return({
    //                 no: (i+1),
    //                 nama_image: <center><img src={(apiData.nama_image !== "" ? (apiData.nama_image !== undefined ? apiData.tautan + apiData.nama_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
    //                 className="profile-user-img img-fluid img-circle"
    //                 alt="Image" /></center>,
    //                 nama_kategori: <span>{apiData.nama_kategori}</span>,
    //                 status: <span>{apiData.status === 1 ? "Umum" : "Khusus"}</span>,
    //                 urutan: apiData.urutan,
    //                 action: <span><Link className="btn btn-block bg-gradient-primary" to={`/firman/category/edit-category/${base64EncodeString}`}>Ubah</Link>
    //                 <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData._id) } }>Hapus</a></span>
    //                 // action: <Link className="btn btn-block bg-gradient-primary" to={`/firman/category/edit-category/${base64EncodeString}`}>Ubah</Link>
    //               });
    //          }),

    //         };
    //         setFirmanCategory(data);
    //       });
    // }, [user]);



    useEffect(() =>{
        fetchData();
   }, [user]);

   const fetchData = async () => {
    let codeChurch = user && (user.gereja[0].kode_gereja);
    let codeMasterChurch = user && (user.gereja[0].kode_gereja_induk);
    console.log('PROFILE: ' + codeChurch + " - " + codeMasterChurch);
    let link='';
    let codeMasterChurch2 = 0;
    if (user && (user.level_user === 7)) {
        link = 'https://gkmi.one:8080/api/firman/kategori/0';
        const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });

        setFirmanCategory(response.data);
    }else{
        if(codeMasterChurch !== ''){
            codeMasterChurch2 = codeMasterChurch;

        }
        link = `https://gkmi.one:8080/api/firman/kategori/1`;
        console.log('LINK: ' + link);
        const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });

        setFirmanCategory(response.data);
    }


   }

   const deleteData = (id) => {
    let url2 = `https://gkmi.one:8080/api/firman/kategorifirman/${id}`
    axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
        const del = dataFirmanCategory.filter(tableRow => id !== tableRow._id)
        setFirmanCategory(del);
    })
  }
        const data = {
              columns: [
                {
                    label: "No.",
                    field: "no",
                    sort: "asc",
                    width: 10,
                },
                {
                    label: "Image",
                    field: "nama_image",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Nama Kategori",
                    field: "nama_kategori",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Urutan",
                    field: "urutan",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Action",
                    field: "action",
                    width: 150,
                },
              ],
              rows: dataFirmanCategory.map((apiData, i) => {
                  let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
                  return({
                    no: (i+1),
                    nama_image: <center><img src={(apiData.nama_image !== "" ? (apiData.nama_image !== undefined ? apiData.tautan + apiData.nama_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
                    className="profile-user-img img-fluid img-circle"
                    alt="Image" /></center>,
                    nama_kategori: <span>{apiData.nama_kategori}</span>,
                    status: <span>{apiData.status === 1 ? "Umum" : "Khusus"}</span>,
                    urutan: apiData.urutan,
                    action: <span><Link className="btn btn-block bg-gradient-primary" to={`/firman/category/edit-category/${base64EncodeString}`}>Ubah</Link>
                    <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData._id) } }>Hapus</a></span>
                    // action: <Link className="btn btn-block bg-gradient-primary" to={`/firman/category/edit-category/${base64EncodeString}`}>Ubah</Link>
                  });
             }),

            };


    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <Link to='/firman/category/create-category' className="btn btn-block bg-gradient-green  ">Tambah Data Kategori</Link>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Kategori Firman</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Firman Category */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Kategori Firman</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(FirmanCategory);
