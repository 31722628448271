import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import axios from 'axios';
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';
import { default as ReactSelect } from "react-select";
import {stateToHTML} from 'draft-js-export-html';
import draftToHtml from 'draftjs-to-html';

import { createBeritaGkmi } from "../../../actions/beritaGkmiAction";
import { getBeritaGkmiKategori } from "../../../actions/beritaGkmiAction";
import { getBeritaGkmiEdisi } from "../../../actions/beritaGkmiAction";
import { getBeritaGkmiKontributor } from "../../../actions/beritaGkmiAction";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  video,
  link
} from "suneditor/src/plugins";

const CreateBeritaGkmi = ({ createBeritaGkmi, beritaGkmi, getBeritaGkmiKategori,getBeritaGkmiEdisi,getBeritaGkmiKontributor, auth: { user, token }, history }) => {
    const editor = useRef();
    const [value, setValue] = useState('');

    const [loadings, setLoadings] = useState(false);
    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [valuep, setPayment] = useState({
      payment: '1',
    });
    const [selectdata, setSelect] = useState();
    const [selectBerita, setSelectBerita] = useState();
    const [valpay, setValPay] = useState('free');
    const [formData, setFormData] = useState({
        judul_berita:'',
        kode_kategori:'',
        kode_edisi:'',
        kode_kontributor:'',
    });

    const {
        judul_berita,
        kode_kategori,
        kode_edisi,
        kode_kontributor,
        deskripsi,
        deskripsi_foto,
        selectOptions,
        selectBeritaTerkait
    } = formData;

    useEffect(() => {
      getBeritaGkmiKategori();
      getBeritaGkmiEdisi();
      getBeritaGkmiKontributor();
    }, [getBeritaGkmiKategori,getBeritaGkmiEdisi,getBeritaGkmiKontributor]);
    useEffect(() => {
          setSelect( beritaGkmi && beritaGkmi.beritaGkmiKontributor2.map(d => ({
            value : d._id,
            label : d.nama
          })));
        }, [beritaGkmi]);
    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const setBeritaByArtikel =  async (event) => {
            let link='';
            console.log(event.target.options[event.target.selectedIndex].value);

          link = 'https://gkmi.one:8080/api/beritagkmibackend/beritaByArtikel/'+event.target.options[event.target.selectedIndex].value;
          console.log(link);

          const response = await axios.get(link, {
                headers: {
                    'x-auth-token': token
                }
            });

            setSelectBerita( response.data.map(d => ({
              value : d.id,
              label : d.judul_berita
            })))


          };
    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const handleCategoryChange = e => {
        setFormData({ ...formData,
            kode_kategori: e.target.options[e.target.selectedIndex].value,
            nama_kategori: e.target.options[e.target.selectedIndex].text
        });
      }
      const handleEdisiChange = e => {

      setBeritaByArtikel(e);
        setFormData({ ...formData,
            kode_edisi: e.target.options[e.target.selectedIndex].value,
            nama_edisi: e.target.options[e.target.selectedIndex].text,
            selectBeritaTerkait:[]
        });
      }
      const handleKontributorChange = e => {
        setFormData({ ...formData,
            kode_kontributor: e.target.options[e.target.selectedIndex].value,
            nama_kontributor: e.target.options[e.target.selectedIndex].text
        });
      }
      const handleEdisiMultipleChange = e => {
             setFormData({ ...formData,
               selectBeritaTerkait:e
             });

           }
    function uploadImageCallBack(file, uploadHandler) {
        const uploadFile = file[0];
        return new Promise(
          (resolve, reject) => {
            const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
            xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
            xhr.setRequestHeader('x-auth-token', token);
            const data = new FormData(); // eslint-disable-line no-undef
            data.append('file', uploadFile);
            data.append("tautan", API_URL_IMAGE + '/ckeditor/')
            xhr.send(data);
            xhr.addEventListener('load', () => {
              const response = JSON.parse(xhr.responseText);
              console.log('OK: ' + response);
              uploadHandler(response);
              resolve(response);
            });
            xhr.addEventListener('error', () => {
              const error = JSON.parse(xhr.responseText);
              reject(error);
            });
          },
        );
      }

    const onSubmit = async (e) => {
        e.preventDefault();
        //setLoadings(true);
var varpayment;
        if(valuep.payment>1){
          varpayment="pay";
        }else{
          varpayment="free";
        }
        const data = new FormData();
        data.append("images", file);
        data.append("kode_kategori", formData.kode_kategori);
        data.append("kode_edisi", formData.kode_edisi);
        data.append("judul_berita", formData.judul_berita);
        data.append("deskripsi", formData.deskripsi);
        data.append("isi_berita", value);
        data.append("kontributor", formData.kode_kontributor);
        data.append("status", valuep.payment);
        data.append("status_name", varpayment);
        data.append("deskripsi_foto", formData.deskripsi_foto);
         data.append("multi_kontributor", JSON.stringify(selectOptions));
        data.append("berita_terkait", JSON.stringify(selectBeritaTerkait));
         // if(file){
         //  createBeritaGkmi(data, history);
         // }else{
         //  alert('Image Berita Tidak Boleh Kosong')
         //  console.log('NO UPLOAD: ' );
         //
         // }
         if(!file){
           //createBeritaGkmi(data, history);
           alert('Image Berita Tidak Boleh Kosong')
          }else if(!selectOptions){
           alert('Penulis Tidak Boleh kosong')
          // console.log('NO UPLOAD: ' );

          }else{
           createBeritaGkmi(data, history);
          }
    }

    function handleImageUploadBefore(files, info, uploadHandler){
      // uploadHandler is a function
      console.log('UPLOAD: ' + files, info);

      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString());
      }
    }

    const getSunEditorInstance = (sunEditor) => {
      editor.current = sunEditor;

    };
    const setPaymentStatus = (event) => {
      //console.log(event.target.value);
      setPayment({ ...valuep, payment: event.target.value });
      //console.log(values);
      // setValPay('free')
    };
    const handleImageChange = (e) => {
      // console.log(e.target.files[])
      if (e.target.files) {
        const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));

        console.log("filesArray: ", filesArray);

        setSelectedFiles((prevImages) => prevImages.concat(filesArray));
        Array.from(e.target.files).map(
          (file) => URL.revokeObjectURL(file) // avoid memory leak
        );
      }
    };

    const handleKontributorMultipleChange = e => {
      setFormData({ ...formData,
        selectOptions:e
          // kode_kontributor: e.target.options[e.target.selectedIndex].value,
          // nama_kontributor: e.target.options[e.target.selectedIndex].text
          // selectOptions:e

      });
      //setPaymentrr({ ...valueprr, id_kontributor: e.target.value });
    //  console.log("VAL: " + e.target.value);
      console.log("VAL: " + JSON.stringify(e));
    }
    const renderPhotos= (source) => {
      console.log('source: ', source);
      return source.map((photo) => {
        return <label for="imgInp1" className="imgPreviewUpload2">
        <img className="imgPreview100" src={imagePreviewUrl} />
        <div >
        <input type="file"  id="imgInp1" className="hidden"  placeholder="Gambar Utama ..." name="nama_image"  onChange={fileOnChange} />
        <button className="btn btn-danger">Remove</button>
        </div>
        </label>


        //<img src={photo} alt="" key={photo} />;
       // console.log('source: ', photo.name);
        return <label>asassas</label>
      });
    };
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Berita</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create Berita {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />

                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                      <label>Status Berita </label>
                      <div class="jss213-small">Geratis/Bayar</div>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div onChange={setPaymentStatus}>
                      <input type="radio" value="1" name="payment" checked = {valuep.payment === '1'}/>&nbsp;Gratis&nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio" value="2" name="payment" checked = {valuep.payment === '2'}/>&nbsp;Bayar &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                      <label>Kategori </label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <select className="form-control" required name="kode_kategori" value={kode_kategori} onChange={handleCategoryChange}>
                   <option value="">--Pilih--</option>
                                        {
                                            beritaGkmi && beritaGkmi.beritaGkmiKategori2.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama_kategori}</option>)
                                        }
                                </select>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                      <label>Edisi </label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <select className="form-control" required name="kode_edisi" value={kode_edisi} onChange={handleEdisiChange}>
                   <option value="">--Pilih--</option>
                                        {
                                            beritaGkmi && beritaGkmi.beritaGkmiEdisi2.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama}</option>)
                                        }
                                </select>
                   </div>
                  </div>
                </div>
                {/* <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Judul Berita</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Judul Berita ..." required name="judul_berita" value={judul_berita} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                  <label>Materi Kegiatan</label>
                  <div class="jss213-small">Materi penunjang Kegiatan</div>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <div>
				<input type="file" id="file" multiple onChange={handleImageChange} />
				<div className="label-holder">
					<label htmlFor="file" className="label">
						<i className="material-icons">add_a_photo</i>
					</label>
				</div>
				<div className="result">{renderPhotos(selectedFiles)}</div>
			</div>


                </div>
                </div>
              </div> */}

                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Judul Berita</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Judul Berita ..." required name="judul_berita" value={judul_berita} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Deskription Berita</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <textarea className="form-control" rows="3" required placeholder="Deskripsi ..." name="deskripsi" value={deskripsi} onChange={e => onChange(e)}></textarea>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Isi Berita</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <SunEditor
                          autoFocus={true}
                          lang="en"
                          width='100%'
                          height='450px'
                          setOptions={{
                            minWidth : '450px',
                            showPathLabel: false,
                            placeholder: "Enter your text here!!!",
                            plugins: [
                              align,
                              font,
                              fontColor,
                              fontSize,
                              formatBlock,
                              hiliteColor,
                              horizontalRule,
                              lineHeight,
                              list,
                              paragraphStyle,
                              table,
                              template,
                              textStyle,
                              image,
                              video,
                              link
                            ],
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize", "formatBlock"],
                              ["paragraphStyle"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                              ],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              "/", // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["table", "link", "image","video"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                              "Arial",
                              "Calibri",
                              "Comic Sans",
                              "Courier",
                              "Garamond",
                              "Georgia",
                              "Impact",
                              "Lucida Console",
                              "Palatino Linotype",
                              "Segoe UI",
                              "Tahoma",
                              "Times New Roman",
                              "Trebuchet MS"
                            ]
                          }}
                          getSunEditorInstance={getSunEditorInstance}
                          onImageUploadBefore={handleImageUploadBefore}
                          setContents={value}
                          onChange={setValue}

                        />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Foto</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div className="imgPreviewUpload">
                          <img className="imgPreview" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Deskripsi Foto</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Deskripsi Foto ..."  name="deskripsi_foto" value={deskripsi_foto} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                {/*  <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Penulis</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <select className="form-control" required name="kode_kontributor" value={kode_kontributor} onChange={handleKontributorChange}>
                                    <option value="">--Pilih--</option>
                                        {
                                            beritaGkmi && beritaGkmi.beritaGkmiKontributor2.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama}</option>)
                                        }
                                </select>
                   </div>
                  </div>
                </div>*/}
                <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                    <label>Penulis</label>
                                    </div>
                                  </div>
                                <div className="col-sm-9">
                                <div className="form-group">

                       <ReactSelect options={selectdata} onChange={handleKontributorMultipleChange} isMulti/>
                                   </div>
                                  </div>
                                </div>
                                <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                    <label>Berita Terkait</label>
                                                    </div>
                                                  </div>
                                                <div className="col-sm-9">
                                                <div className="form-group">

                                       <ReactSelect options={selectBerita} value={selectBeritaTerkait} selectedValue={selectBeritaTerkait} onChange={handleEdisiMultipleChange} isMulti/>
                                                   </div>
                                                  </div>
                                                </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                  {
                    loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                    :
                    <input type="submit" className="btn btn-primary pull-right" value="Save" />
                  }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreateBeritaGkmi.propTypes = {
    createBeritaGkmi: PropTypes.func.isRequired,
    getBeritaGkmiKategori: PropTypes.func.isRequired,
    getBeritaGkmiEdisi: PropTypes.func.isRequired,
    getBeritaGkmiKontributor: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({
    auth: state.auth,
    beritaGkmi: state.beritaGkmi
});

export default connect(mapStateToProps, { createBeritaGkmi, getBeritaGkmiKategori,getBeritaGkmiEdisi,getBeritaGkmiKontributor })(withRouter(CreateBeritaGkmi));
