import axios from "../util/http-common";
import { setAlert } from "./alertAction";


import {GET_OUTVISION,OUTVISION_ERROR,GET_HISTORY,GET_LETTERS,GET_DATABASE,GET_DATA_DATABASE,GET_TATA_GEREJA,GET_TATA_GEREJA_PARENT,GET_TATA_GEREJA_CHILD,GET_PROJECT_SINODE,GET_PROJECT_PROGRESS_SINODE,GET_PROJECT_DONASI_SINODE,GET_GALLERY_VIDEO,GET_GALLERY_FOTO,GET_SCHEDULE,
    HISTORY_ERROR,LETTERS_ERROR,DATABASE_ERROR,DATA_DATABASE_ERROR,TATA_GEREJA_ERROR,TATA_GEREJA_PARENT_ERROR,TATA_GEREJA_CHILD_ERROR,GET_TATA_GEREJA_DOCUMENT,TATA_GEREJA_DOCUMENT_ERROR,PROJECT_SINODE_ERROR,PROJECT_PROGRESS_SINODE_ERROR,PROJECT_DONASI_SINODE_ERROR,GALLERY_VIDEO_ERROR,GALLERY_FOTO_ERROR,SCHEDULE_ERROR,  } from "./types";




export const getOutVision = id => async dispatch => {
    try{
         const res = await axios.get(`/api/sinodebackend/outvision`);

        dispatch({
            type: GET_OUTVISION,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: OUTVISION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Update Berita Berdasarkan ID
export const updateOutVision = (formData, id, history) => async dispatch => {
    try{

            const res = await axios.put(`api/sinodebackend/outvision/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/dashboardSinode');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: OUTVISION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getHistory = id => async dispatch => {
    try{
         const res = await axios.get(`/api/sinodebackend/history`);

        dispatch({
            type: GET_HISTORY,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: HISTORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateHistory = (formData, id,history) => async dispatch => {
    try{

            const res = await axios.put(`api/sinodebackend/history/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/dashboardSinode');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: HISTORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
export const createLetters = (formData, history) => async dispatch => {
    try{

         const res = await axios.post(`/api/sinodebackend/letters`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/letters');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: LETTERS_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}
export const getLettersById = id => async dispatch => {
      try{
          const res = await axios.get(`/api/sinodebackend/letters/${id}`);
          dispatch({
              type: GET_LETTERS,
              payload: res.data[0],
          });
      }catch(err){
          dispatch({
              type: LETTERS_ERROR,
              //payload: { msg: err.response.statusText, status: err.response.status }
              payload: { msg: err.message, status: err }
          });
      }
  }
  export const updateLetters = (formData, id,history) => async dispatch => {
      try{

              const res = await axios.put(`api/sinodebackend/letters/${id}`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          if(res.status === 200){
              console.log("success",res.data[0].msg);

              dispatch(setAlert(res.data[0].msg, 'success', 5000));
          }

          history.push('/letters');

      }catch(err){
          const errors = err.response;
          if (errors) {
              const errors2 = errors.data[0].errors;
              errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
          }
          dispatch({
              type: LETTERS_ERROR,
              //payload: { msg: err.response.statusText, status: err.response.status }
              payload: { msg: err.message, status: err }
          });
      }
  }

  export const createMenuDatabase = (formData, history) => async dispatch => {
      try{

           const res = await axios.post(`/api/sinodebackend/database`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          if(res.status === 200){
              console.log("success",res.data[0].msg);
              dispatch(setAlert(res.data[0].msg, 'success', 5000));
          }

          history.push('/database');

      }catch(err){
          const errors = err.response;
          if (errors) {
              const errors2 = errors.data[0].errors;
              errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
          }
          dispatch({
              type: DATABASE_ERROR,
              //payload: { msg: err.response.statusText, status: err.response.status }
              payload: { msg: err.message, status: err }
          });
      }
  }

  export const getDatabaseById = id => async dispatch => {
      try{
          const res = await axios.get(`/api/sinodebackend/database/${id}`);

          dispatch({
              type: GET_DATABASE,
              payload: res.data[0],
          });
      }catch(err){
          dispatch({
              type: DATABASE_ERROR,
              //payload: { msg: err.response.statusText, status: err.response.status }
              payload: { msg: err.message, status: err }
          });
      }
  }

  export const updateDatabase = (formData, id, history) => async dispatch => {

      try{
          const res = await axios.put(`/api/sinodebackend/database/${id}`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          if(res.status === 200){
              dispatch(setAlert(res.data[0].msg, 'success', 5000));
          }

          history.push('/database');

      }catch(err){
          const errors = err.response;
          if (errors) {
             const errors2 = errors.data[0].errors;
              errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
          }
          dispatch({
              type: DATABASE_ERROR,
              //payload: { msg: err.response.statusText, status: err.response.status }
              payload: { msg: err.message, status: err }
          });
      }
  }



  export const createDataDatabase = (formData, history,id) => async dispatch => {
      try{

           const res = await axios.post(`/api/sinodebackend/database/data`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          if(res.status === 200){
              console.log("success",res.data[0].msg);
              dispatch(setAlert(res.data[0].msg, 'success', 5000));
          }
          history.push(`/database/data/${id}`);

      }catch(err){
          const errors = err.response;
          if (errors) {
              const errors2 = errors.data[0].errors;
              errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
          }
          dispatch({
              type: DATA_DATABASE_ERROR,
              //payload: { msg: err.response.statusText, status: err.response.status }
              payload: { msg: err.message, status: err }
          });
      }
  }


  export const getDataDatabaseById = id => async dispatch => {
      try{
          const res = await axios.get(`/api/sinodebackend/database/datadatabase/${id}`);

          dispatch({
              type: GET_DATA_DATABASE,
              payload: res.data[0],
          });
      }catch(err){
          dispatch({
              type: DATA_DATABASE_ERROR,
              //payload: { msg: err.response.statusText, status: err.response.status }
              payload: { msg: err.message, status: err }
          });
      }
  }

  export const updateDataDatabase = (formData, id, history,idValue) => async dispatch => {
      console.log("masuk sini ga "+id);

      try{
          const res = await axios.put(`/api/sinodebackend/database/datadatabase/${id}`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          if(res.status === 200){
              dispatch(setAlert(res.data[0].msg, 'success', 5000));
          }

          history.push(`/database/data/${idValue}`);

      }catch(err){
          const errors = err.response;
          if (errors) {
             const errors2 = errors.data[0].errors;
              errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
          }
          dispatch({
              type: DATA_DATABASE_ERROR,
              //payload: { msg: err.response.statusText, status: err.response.status }
              payload: { msg: err.message, status: err }
          });
      }
  }

  export const createTataGereja = (formData, history,id) => async dispatch => {
        try{

             const res = await axios.post(`/api/sinodebackend/tatagereja`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                console.log("success",res.data[0].msg);
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }
            history.push(`/tatagereja`);

        }catch(err){
            const errors = err.response;
            if (errors) {
                const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const getTataGerejaById = id => async dispatch => {
        try{
            const res = await axios.get(`/api/sinodebackend/tatagereja/${id}`);

            dispatch({
                type: GET_TATA_GEREJA,
                payload: res.data[0],
            });
        }catch(err){
            dispatch({
                type: TATA_GEREJA_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const updateTataGereja = (formData, id, history) => async dispatch => {

        try{
            const res = await axios.put(`/api/sinodebackend/tatagereja/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push('/tatagereja');

        }catch(err){
            const errors = err.response;
            if (errors) {
               const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: DATABASE_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }
    export const createTataGerejaParent = (formData, history,id) => async dispatch => {
        try{

             const res = await axios.post(`/api/sinodebackend/tatagereja/parent`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                console.log("success",res.data[0].msg);
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push(`/tatagereja/parent/${id}`);
        }catch(err){
            const errors = err.response;
            if (errors) {
                const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_PARENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const getTataGerejaParentById = id => async dispatch => {
        try{
            const res = await axios.get(`/api/sinodebackend/tatagereja/parentedit/${id}`);

            dispatch({
                type: GET_TATA_GEREJA_PARENT,
                payload: res.data[0],
            });
        }catch(err){
            dispatch({
                type: TATA_GEREJA_PARENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const updateTataGerejaParent = (formData, id, history,idParent) => async dispatch => {

        try{
            const res = await axios.put(`/api/sinodebackend/tatagereja/parentedit/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push(`/tatagereja/parent/${idParent}`);

        }catch(err){
            const errors = err.response;
            if (errors) {
               const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_PARENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }
    export const createTataGerejaChild = (formData, history,id) => async dispatch => {
        try{


             const res = await axios.post(`/api/sinodebackend/tatagereja/parent/child`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                console.log("success",res.data[0].msg);
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push(`/tatagereja/parent/child/${id}`);
        }catch(err){
            const errors = err.response;
            if (errors) {
                const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_PARENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const getTataGerejaChildById = id => async dispatch => {
        try{
            const res = await axios.get(`/api/sinodebackend/tatagereja/parent/child/childedit/${id}`);

            dispatch({
                type: GET_TATA_GEREJA_CHILD,
                payload: res.data[0],
            });
        }catch(err){
            dispatch({
                type: TATA_GEREJA_CHILD_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const updateTataGerejaChild = (formData, id, history,idParent) => async dispatch => {

        try{
            const res = await axios.put(`/api/sinodebackend/tatagereja/parent/child/childedit/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push(`/tatagereja/parent/child/${idParent}`);

        }catch(err){
            const errors = err.response;
            if (errors) {
               const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_PARENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const createDocument = (formData, history) => async dispatch => {
        try{

             const res = await axios.post(`/api/sinodebackend/tatagereja/document`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                console.log("success",res.data[0].msg);
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push(`/tatagereja`);

        }catch(err){
            const errors = err.response;
            if (errors) {
                const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_DOCUMENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const createDocumentParent = (formData, history,patch) => async dispatch => {
        try{

             const res = await axios.post(`/api/sinodebackend/tatagereja/document`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                console.log("success",res.data[0].msg);
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

           history.push(`/tatagereja/parent/${patch}`);
        }catch(err){
            const errors = err.response;
            if (errors) {
                const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_DOCUMENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }
    export const createDocumentChild = (formData, history,patch) => async dispatch => {
        try{

             const res = await axios.post(`/api/sinodebackend/tatagereja/document`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                console.log("success",res.data[0].msg);
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

           history.push(`/tatagereja/parent/child/${patch}`);
        }catch(err){
            const errors = err.response;
            if (errors) {
                const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_DOCUMENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }
    export const getDocumentById = id => async dispatch => {
        try{
            const res = await axios.get(`/api/sinodebackend/tatagereja/document/${id}`);

            dispatch({
                type: GET_TATA_GEREJA_DOCUMENT,
                payload: res.data[0],
            });
        }catch(err){
            dispatch({
                type: TATA_GEREJA_DOCUMENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }
    export const updateDocument = (formData, id, history) => async dispatch => {

        try{
            const res = await axios.put(`/api/sinodebackend/tatagereja/document/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push(`/tatagereja`);

        }catch(err){
            const errors = err.response;
            if (errors) {
               const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_DOCUMENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const updateDocumentParent = (formData, id, history,idParent) => async dispatch => {

        try{
            const res = await axios.put(`/api/sinodebackend/tatagereja/document/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push(`/tatagereja/parent/${idParent}`);

        }catch(err){
            const errors = err.response;
            if (errors) {
               const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_DOCUMENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }

    export const updateDocumentChild = (formData, id, history,idParent) => async dispatch => {

        try{
            const res = await axios.put(`/api/sinodebackend/tatagereja/document/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(res.status === 200){
                dispatch(setAlert(res.data[0].msg, 'success', 5000));
            }

            history.push(`/tatagereja/parent/child/${idParent}`);

        }catch(err){
            const errors = err.response;
            if (errors) {
               const errors2 = errors.data[0].errors;
                errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
            }
            dispatch({
                type: TATA_GEREJA_DOCUMENT_ERROR,
                //payload: { msg: err.response.statusText, status: err.response.status }
                payload: { msg: err.message, status: err }
            });
        }
    }


    // Create Berita
export const createProjectSinode = (formData, history) => async dispatch => {
    try{
        console.log("Masuk sinikah gggg");
            const res = await axios.post(`/api/sinodebackend/projectsinode`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
//console.log("Masuk sinikah "+res);
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/project');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PROJECT_SINODE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getProjectById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/project/${id}`);

        dispatch({
            type: GET_PROJECT_SINODE,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: PROJECT_SINODE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateProject = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/pipkabackend/project/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/project');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PROJECT_SINODE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}


export const getProjectProgressById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/project/progressEdit/${id}`);

        dispatch({
            type: GET_PROJECT_PROGRESS_SINODE,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: PROJECT_PROGRESS_SINODE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateProjectProgress = (formData, id, history,path) => async dispatch => {

    try{
        const res = await axios.put(`/api/pipkabackend/project/progressEdit/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push(`/projectsinode/progress/${path}`);


    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PROJECT_PROGRESS_SINODE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}


export const getProjectDonasiById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/project/donasi/${id}`);

        dispatch({
            type: GET_PROJECT_DONASI_SINODE,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: PROJECT_DONASI_SINODE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateProjectDonasi = (formData, id, history) => async dispatch => {
    // console.log("Masuk sini kah updateProjectDonasi" );
    try{
        const res = await axios.put(`/api/pipkabackend/project/donasiProject/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
      //  console.log("Masuk sini kah updateProjectDonasi "+res.data[0].msg );
        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push("/project");


    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PROJECT_DONASI_SINODE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const createGalleryVideo = (formData, history) => async dispatch => {
    try{
            const res = await axios.post(`/api/sinodebackend/galleryvideo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push(`/galleryvideo`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GALLERY_VIDEO_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getGalleryVideoById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pipkabackend/galleryvideo/${id}`);
        dispatch({
            type: GET_GALLERY_VIDEO,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: GALLERY_VIDEO_ERROR,
            payload: { msg: err.message, status: err }
        });
    }
}


export const updateGalleryVideo = (formData, id, history) => async dispatch => {
    // console.log("Masuk sini kah updateProjectDonasi" );
    try{
        const res = await axios.put(`/api/pipkabackend/galleryvideo/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
        console.log("Masuk sini kah updateProjectDonasi "+res.data[0].msg );
        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push("/galleryvideo");


    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GALLERY_VIDEO_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const createMenuEksekutif = (formData, history) => async dispatch => {
    try{

         const res = await axios.post(`/api/sinodebackend/eksekutif`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/eksekutif');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: DATABASE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const getEksekutifById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/sinodebackend/database/${id}`);

        dispatch({
            type: GET_DATABASE,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: DATABASE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateEksekutif = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/sinodebackend/database/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/eksekutif');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: DATABASE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const getEksekutifDataById = id => async dispatch => {
    try{
         const res = await axios.get(`/api/sinodebackend/eksekutif/data/${id}`);
        dispatch({
            type: GET_OUTVISION,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: OUTVISION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}


// Update Berita Berdasarkan ID
export const updateEksekutifData = (formData, id, history) => async dispatch => {
    try{

            const res = await axios.put(`api/sinodebackend/eksekutif/data/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/eksekutif');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: OUTVISION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create Berita
export const createSchedule = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/sinodebackend/schedule`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/schedule');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: SCHEDULE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getScheduleById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/sinodebackend/schedule/${id}`);
        dispatch({
            type: GET_SCHEDULE,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: SCHEDULE_ERROR,
            payload: { msg: err.message, status: err }
        });
    }
}
export const updateSchedule = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/sinodebackend/schedule/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/schedule');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: SCHEDULE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
