import React, { Fragment, useState, useEffect, } from "react";
import { MDBDataTable } from "mdbreact";
import {useParams, Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import parse from 'html-react-parser';

const ProjectProgressSinode = ({ auth: { user, token } }) => {
    const [dataProject, setProject] = useState([]);
    const [status, setStatus] = useState(true);
    const {id} = useParams();
    useEffect(() =>{
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        let kode_project = decodeString.split(';')[0];
        fetchData(kode_project);
   }, [user,id]);

   const fetchData = async (kode_project) => {
       
        let link='';
        
         link = `https://gkmi.one:8080/api/sinodebackend/projectsinode/progress/${kode_project}`;
         console.log("success",link);
      
     const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
        setProject(response.data);
   }

   

   const data = {
    columns: [
                    {
                        label: "No.",
                        field: "no",
                        sort: "asc",
                        width: 10,
                    },
                    {
                        label: "Image",
                        field: "nama_image",
                        sort: "asc",
                        width: 150,
                    },
                    
                    {
                        label: "Judul Project",
                        field: "judul",
                        sort: "asc",
                        width: 150,
                    },
                    {
                        label: "Dana Terkumpul",
                        field: "dana",
                        sort: "asc",
                        width: 150,
                    },
                    {
                        label: "Status",
                        field: "status",
                        sort: "asc",
                        width: 30,
                    },
                    {
                        label: "Action",
                        field: "action",
                        width: 150,
                    },
                  ],
              rows: dataProject.map((apiData, i) => {
                // var content = apiData.isi_berita;
                // var fixedHTML = "";
                // if(content !== undefined){
                //     fixedHTML = parse(content);
                // }
                let base64EncodeString = Buffer.from(`${apiData._id};${id}`).toString('base64');
                var active;
                  var classStatus;
                  if(apiData.active==1){
                    active="Active";
                    classStatus="badge bg-gray"
                        }else{
                            active="In Active";
                            classStatus="badge badge-danger"
                        }

                return({
                  no: (i+1),
                  nama_image: <center><img src={(apiData.images[0] !== "" ? (apiData.images[0] !== undefined ? apiData.images[0].tautan + apiData.images[0].nama_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
                  className="profile-user-img img-fluid img-circle"
                  alt="Image" /></center>,
                
                  judul: <span>{apiData.judul_project}</span>,
                  dana: <span>{apiData.dana_terkumpul}</span>,
                  status:<span class={classStatus}>{active}</span>,
                  action: <span><Link className="btn btn-block bg-gradient-primary" to={`/projectsinode/progress/edit-progressproject/${base64EncodeString}`}>Ubah</Link>
         
          </span>
    });
  }),
};
    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-2">
                    <Link to={`/project`} className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                        <div className="col-sm-3">
                       
                            <Link  to={`/projectsinode/progress/create-progressproject/${id}`}  className="btn btn-block bg-gradient-green  ">Tambah Data Project Progress</Link>
                        </div>
                        <div className="col-sm-7">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardSinode">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Project Progress</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Project Progress</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(ProjectProgressSinode);
