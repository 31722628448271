import { GET_BERITA_GKMI_CATEGORY,GET_BERITA_GKMI_CATEGORY2,GET_BERITA_GKMI_EDISI,GET_BERITA_GKMI_EDISI2,GET_BERITA_GKMI_PEMBAYARAN,GET_BERITA_GKMI_KONTRIBUTOR,GET_BERITA_GKMI_KONTRIBUTOR2,GET_BERITA_GKMI,GET_LANGGANAN,GET_BERITA_GKMI_IKLAN,GET_BERITA_GKMI_ORDER,  BERITA_GKMI_CATEGORY_ERROR,BERITA_GKMI_CATEGORY_ERROR2,BERITA_GKMI_EDISI_ERROR,BERITA_GKMI_EDISI_ERROR2,BERITA_GKMI_KONTRIBUTOR_ERROR2,BERITA_GKMI_KONTRIBUTOR_ERROR,BERITA_GKMI_ERROR,LANGGANAN_ERROR,BERITA_GKMI_IKLAN_ERROR,BERITA_GKMI_ORDER_ERROR,BERITA_GKMI_PEMBAYARAN_ERROR } from "../actions/types";

const initialState = {

    beritaGkmiKategori2: [],
    beritaGkmiEdisi2:[],
    beritaGkmiKontributor2:[],
    beritaGkmiKategori: null,
    beritaGkmiEdisi: null,
    beritaGkmiKontributor: null,
    langganan: null,
    beritaGkmiIklan: null,
    beritaGkmiOrder: null,
    beritaGkmiPembayaran: null,
    beritaGkmi:"",
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_BERITA_GKMI_CATEGORY:
            return{
                ...state,
                beritaGkmiKategori: payload,
                loading: false
            };
            case GET_BERITA_GKMI_CATEGORY2:
            return{
                ...state,
                beritaGkmiKategori2: payload,
                loading: false
            };
            case GET_BERITA_GKMI_EDISI:
            return{
                ...state,
                beritaGkmiEdisi: payload,
                loading: false
            };
            case GET_BERITA_GKMI_EDISI2:
            return{
                ...state,
                beritaGkmiEdisi2: payload,
                loading: false
            };
            case GET_BERITA_GKMI_KONTRIBUTOR:
            return{
                ...state,
                beritaGkmiKontributor: payload,
                loading: false
            };
            case GET_BERITA_GKMI_KONTRIBUTOR2:
            return{
                ...state,
                beritaGkmiKontributor2: payload,
                loading: false
            };
            case GET_BERITA_GKMI:
                return{
                    ...state,
                    beritaGkmi: payload,
                    loading: false
                };
                case GET_LANGGANAN:
                  return{
                      ...state,
                      langganan: payload,
                      loading: false
                  };
                  case GET_BERITA_GKMI_IKLAN:
                    return{
                        ...state,
                        beritaGkmiIklan: payload,
                        loading: false
                    };
                    case GET_BERITA_GKMI_ORDER:
                   return{
                       ...state,
                       beritaGkmiOrder: payload,
                       loading: false
                   };
                   case GET_BERITA_GKMI_PEMBAYARAN:
                       return{
                           ...state,
                           beritaGkmiPembayaran: payload,
                           loading: false
                       };
        case BERITA_GKMI_CATEGORY_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
            case BERITA_GKMI_CATEGORY_ERROR2:
                return{
                    ...state,
                    error: payload,
                    loading: false
                };
                case BERITA_GKMI_EDISI_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
            case BERITA_GKMI_EDISI_ERROR2:
            return{
                ...state,
                error: payload,
                loading: false
            };
            case BERITA_GKMI_KONTRIBUTOR_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
            case BERITA_GKMI_KONTRIBUTOR_ERROR2:
            return{
                ...state,
                error: payload,
                loading: false
            };
            case BERITA_GKMI_ERROR:
                return{
                    ...state,
                    error: payload,
                    loading: false
                };
                case LANGGANAN_ERROR:
                    return{
                        ...state,
                        error: payload,
                        loading: false
                    };
                    case BERITA_GKMI_IKLAN_ERROR:
                   return{
                       ...state,
                       error: payload,
                       loading: false
                   };
                   case BERITA_GKMI_ORDER_ERROR:
                        return{
                            ...state,
                            error: payload,
                            loading: false
                        };
                        case BERITA_GKMI_PEMBAYARAN_ERROR:
                       return{
                           ...state,
                           error: payload,
                           loading: false
                       };
        default:
            return state;
    }

}
