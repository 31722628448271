import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/img_store.png";

import DatePicker from "react-datepicker";
import moment from "moment";
import { updateAction } from "../../../actions/actionKegiatan";
import { getActionId } from "../../../actions/actionKegiatan";

  const EditAction = ({ action: { actionacara, loading }, updateAction,getActionId, auth: { user, token }, history }) => {


const {id} = useParams();

const [idToko, setIdToko] = useState();
    const [file, setFile] = useState();
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [fileDat1, setFileDat1] = useState(0);
    const [fileDat2, setFileDat2] = useState(0);
    const [fileDat3, setFileDat3] = useState(0);

    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [imagePreviewUrl1, setImagePreviewUrl1] = useState(defaultImage);
    const [imagePreviewUrl2, setImagePreviewUrl2] = useState(defaultImage);
    const [values, setValues] = useState({
      status: "0",
  });
  const [value, setOnOff] = useState({
    onOff: '1',
  });
  const [valuec, setValuec] = useState({
    acti: '1',
  });
  const [loadings, setLoadings] = useState(false);

     const [formData, setFormData] = useState({
      tanggal: new Date(),
      tanggal_pendaftaran: new Date(),
      tanggal_ahir_pendaftaran: new Date(),
      nama_action:'',
      youtube_url:'',
      facebook_url:'',
      instagram_url:'',
      twitter_url:'',
      tempat:'',
      alamat:'',
      map_url:'',

    });

    const {
      tanggal,tanggal_pendaftaran,tanggal_ahir_pendaftaran,nama_action,youtube_url,facebook_url,instagram_url,twitter_url,deskripsi,peserta,tempat,alamat,map_url
    } = formData;


    useEffect(() => {
      setFormData({
        nama_action:  actionacara && (actionacara.nama_action ? actionacara.nama_action : ''),
        tanggal:  actionacara && (actionacara.tanggal_action  ?  new Date(actionacara.tanggal_action) : ''),

         tanggal_pendaftaran:  actionacara && (actionacara.tanggal_daftar_awal ? new Date( actionacara.tanggal_daftar_awal) : ''),
        tanggal_ahir_pendaftaran:  actionacara && (actionacara.tanggal_daftar_akhir ?  new Date(actionacara.tanggal_daftar_akhir) : ''),

         peserta:  actionacara && (actionacara.max_jumlah_group_pergereja ? actionacara.max_jumlah_group_pergereja : ''),
        deskripsi:  actionacara && (actionacara.keterangan ? actionacara.keterangan : ''),
        tempat:  actionacara && (actionacara.tempat ? actionacara.tempat : ''),
        map_url:  actionacara && (actionacara.map_url ? actionacara.map_url : ''),
        alamat:  actionacara && (actionacara.address ? actionacara.address : ''),

        youtube_url: actionacara && (actionacara.youtube_url ? actionacara.youtube_url : ''),
        facebook_url: actionacara && (actionacara.facebook_url ? actionacara.facebook_url : ''),
        instagram_url: actionacara && (actionacara.instagram_url ? actionacara.instagram_url : ''),
        twitter_url: actionacara && (actionacara.twitter_url ? actionacara.twitter_url : ''),
        status: actionacara && (actionacara.offline_online ? actionacara.offline_online : ''),
        onOff: actionacara && (actionacara.type_peserta ? actionacara.type_peserta : ''),
        acti: actionacara && (actionacara.active ? actionacara.active : ''),

      });
      // console.log("ID tokopedia_url wewe: ", actionacara);

    setValues({ status: actionacara && (actionacara.offline_online.toString()) });
    setOnOff({ onOff: actionacara && (actionacara.type_peserta.toString()) });
    setValuec({ acti: actionacara && (actionacara.active.toString()) });

    console.log("ID tokopedia_url wewe: ", actionacara && (actionacara.tanggal_action ? actionacara.tanggal_action : ''));
    // console.log('STATUS: ' + (actionacara && (actionacara.offline_online ? actionacara.offline_online : '')));
    // console.log('STATUS: ' + (actionacara && (actionacara.type_peserta ? actionacara.type_peserta : '')));

  }, [loading, actionacara]);


useEffect(() => {


  setImagePreviewUrl( actionacara && (actionacara.image_name1 ? actionacara.image_name1 : defaultImage));
  setImagePreviewUrl1( actionacara && (actionacara.image_name2 ? actionacara.image_name2 : defaultImage));
  setImagePreviewUrl2( actionacara && (actionacara.image_name3 ? actionacara.image_name3 : defaultImage));

}, [loading, actionacara]);

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl);
        setFileDat1(1)
    }
    const fileOnChange1 = e => {
      setFile1(e.target.files[0]);
      const objectUrl1= URL.createObjectURL(e.target.files[0])
      setImagePreviewUrl1(objectUrl1);
      setFileDat2(1)
  }
  const fileOnChange2 = e => {
    setFile2(e.target.files[0]);
    const objectUrl2= URL.createObjectURL(e.target.files[0])
    setImagePreviewUrl2(objectUrl2);
    setFileDat3(1)
}


useEffect(() => {
  let decodeString = Buffer.from(id, 'base64').toString('ascii');

  // console.log("base:::" + decodeString.split(';')[0]);

  // console.log("base:::" + decodeString.split(';')[1]);
  getActionId( decodeString.split(';')[0]);
}, [getActionId, id]);
    const onSubmit = async (e) => {
        e.preventDefault();
        //setLoadings(true);
        const data = new FormData();
        if (fileDat1>0){
           data.append("nama_image[]", file);
        }
       // if(file=)
       if (fileDat2>0){
         data.append("nama_image[]", file1);
      }
      if (fileDat3>0){
        data.append("nama_image[]", file2);
      }

        data.append("nama_action", formData.nama_action);
        data.append("tanggal_action", moment(formData.tanggal).format('YYYY-MM-DD'));
        data.append("tanggal_daftar_awal", moment(formData.tanggal_pendaftaran).format('YYYY-MM-DD'));
        data.append("tanggal_daftar_akhir", moment(formData.tanggal_ahir_pendaftaran).format('YYYY-MM-DD'));
        data.append("max_jumlah_group_pergereja", formData.peserta);
        data.append("keterangan", formData.deskripsi);
        data.append("youtube_url", formData.youtube_url);
        data.append("facebook_url", formData.facebook_url);
        data.append("instagram_url", formData.instagram_url);
        data.append("twitter_url", formData.twitter_url);
        data.append("offline_online", value.onOff);
        data.append("type_peserta", values.status);
        data.append("active", valuec.acti);
        data.append("tempat", formData.tempat);
        data.append("map_url", formData.map_url);
        data.append("address", formData.alamat);

        updateAction(data, Buffer.from(id, 'base64').toString('ascii'), history);
      //  updateAction(data, history,id);
    }

    const handleDateChange = e => {
      setFormData({ ...formData,
        tanggal: new Date(e)
      });
    }
    const handleDatePendaftaranChange = e => {
      setFormData({ ...formData,
        tanggal_pendaftaran: new Date(e)
      });
    }
    const handleDateAhirPendaftaranChange = e => {
      setFormData({ ...formData,
        tanggal_ahir_pendaftaran: new Date(e)
      });
    }
    const setStatus = (event) => {
      //console.log(event.target.value);
      setValues({ ...values, status: event.target.value });
      //console.log("VAL: " + values.status);
    };

    const setOnOffStatus = (event) => {
      //console.log(event.target.value);
      setOnOff({ ...value, onOff: event.target.value });
      //console.log(values);
    };
    const setActiveStatus = (event) => {
      //console.log(event.target.value);
      setValuec({ ...valuec, acti: event.target.value });
      //console.log(values);
    };

    useEffect(() => {
      //console.log("VAL: " + values.status);
   //   console.log("VAL ff : " + value.onOff);

  }, [values]);
  useEffect(() => {

   // console.log("VAL ff : " + value.onOff);

}, [value]);
useEffect(() => {

  console.log("VAL ff : " + valuec.acti);

}, [valuec]);

  function cekStatus(){
   // console.log("ID tokopedia_url wewe: ", values.status );
    if(values.status === "0"){ // ini isi firman
        return (
          <Fragment>
                    <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tempat</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Tempat ..." name="tempat" value={tempat} onChange={e => onChange(e)} />
                      </div>
                      </div>
                      </div>
                      <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Alamat</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                    <textarea className="form-control" rows="5"  placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Google Map Url</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Google Map ..." name="map_url" value={map_url} onChange={e => onChange(e)} />
                      </div>
                      </div>
                      </div>
      </Fragment>
        );
    }
}
return(
  <Fragment>
  <Header />
  <Navbar />
  {/* Content Wrapper. Contains page content */}
  <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
      <div className="container-fluid">
          <div className="row mb-2">
          <div className="col-sm-2">

              <Link to={`/action`} className="btn btn-block bg-gradient-danger">Back</Link>
          </div>
          <div className="col-sm-10">
              <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
              <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">Action</li>
              </ol>
          </div>
          </div>
          </div>
      </section>
      {/* Main content */}
      <section className="content">
      <div className="container-fluid">
      {/* Default box */}
      <div className="card">
      <form onSubmit={(e) => onSubmit(e)}>
          <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
          <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Action</h3>
          <div className="card-tools">
              <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
              data-toggle="tooltip"
              title="Collapse"
              >
              <i className="fas fa-minus" />
              </button>

          </div>
          </div>
          <div className="card-body">
              <Alert />
            <div className="card">
              <div className="card-body">
              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Status</label>
                    <div class="jss213-small">Status Active InActive</div>
                  </div>
                </div>
              <div className="col-sm-9">
              <div onChange={setActiveStatus}>
                          <input type="radio" value="1" name="acti" checked = {valuec.acti === '1'}/>&nbsp;Active&nbsp;&nbsp;&nbsp;&nbsp;
                          <input type="radio" value="2" name="acti" checked = {valuec.acti === '2'}/>&nbsp;In Active
                      </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Image Action *</label>
                    <div class="jss213-small">Format gambar .jpg .jpeg .png dan ukuran minimum 640 x 340px  </div>
                  </div>
                </div>
              <div className="col-sm-2">
                  <div className="form-group">
                  <label for="imgInp1" className="imgPreviewUpload2">
                    <img className="imgPreview100" src={imagePreviewUrl} />
                    <div >
                    <input type="file"  id="imgInp1" className="hidden"  placeholder="Gambar Utama ..." name="nama_image"  onChange={fileOnChange} />
                    </div>
                    </label>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="form-group">
                  <label for="imgInp2" className="imgPreviewUpload2">
                    <img className="imgPreview100" src={imagePreviewUrl1} />
                    <div >
                    <input type="file"  id="imgInp2" className="hidden" placeholder="Gambar Utama ..." name="nama_image1"  onChange={fileOnChange1} />
                    </div>
                    </label>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="form-group">
                  <label for="imgInp3" className="imgPreviewUpload2">
                    <img className="imgPreview100" src={imagePreviewUrl2} />
                    <div >
                    <input type="file"  id="imgInp3" className="hidden" placeholder="Gambar Utama ..." name="nama_image2"  onChange={fileOnChange2} />
                    </div>
                    </label>
                  </div>
                </div>
              </div>



              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Nama Action *</label>


                  </div>
                </div>
              <div className="col-sm-9">
                  <div className="form-group">
                  <input type="text" className="form-control" required placeholder="Nama Action ..." name="nama_action" value={nama_action} onChange={e => onChange(e)} />
                  </div>
                </div>
              </div>

              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Tanggal Kegiatan</label>
                    <div class="jss213-small">Tanggal Kegiatan  </div>
                  </div>
                </div>
              <div className="col-sm-3">
                  <div className="form-group">
                  <div className="customDatePickerWidth">
                              <DatePicker
                              name="tanggal"
                              dateFormat={moment(tanggal).format('DD-MM-YYYY')}
                              className="form-control"
                              selected={tanggal}
                              onChange={handleDateChange}
                              onFocus={e => e.target.blur()}/>
                          </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Tanggal Pendaftaran</label>
                    <div class="jss213-small">Tanggal dimulainya pendaftaran  </div>
                  </div>
                </div>
              <div className="col-sm-3">
                  <div className="form-group">
                  <div className="customDatePickerWidth">
                              <DatePicker
                              name="tanggal_pendaftaran"
                              dateFormat={moment(tanggal_pendaftaran).format('DD-MM-YYYY')}
                              className="form-control"
                              selected={tanggal_pendaftaran}
                              onChange={handleDatePendaftaranChange}
                              onFocus={e => e.target.blur()}/>
                          </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Tanggal Batas Ahir Pendaftaran</label>
                    <div class="jss213-small">Batas Ahir Pendaftaran  </div>
                  </div>
                </div>
              <div className="col-sm-3">
                  <div className="form-group">
                  <div className="customDatePickerWidth">
                              <DatePicker
                              name="tanggal_ahir_pendaftaran"
                              dateFormat={moment(tanggal_ahir_pendaftaran).format('DD-MM-YYYY')}
                              className="form-control"
                              selected={tanggal_ahir_pendaftaran}
                              onChange={handleDateAhirPendaftaranChange}
                              onFocus={e => e.target.blur()}/>
                          </div>
                  </div>
                </div>
              </div>

              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Status Kegiatan</label>
                    <div class="jss213-small">Kegiatan Offline atau Online</div>
                  </div>
                </div>
              <div className="col-sm-9">
              <div onChange={setStatus}>
                          <input type="radio" value="0" name="status" checked = {values.status === '0'}/>&nbsp;Offline (Onsite)&nbsp;&nbsp;&nbsp;&nbsp;
                          <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;Online
                      </div>
                </div>
              </div>

              {cekStatus()}
              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Status Peserta Kegiatan</label>
                    <div class="jss213-small">Kegiatan Offline atau Online</div>
                  </div>
                </div>
              <div className="col-sm-9">
              <div onChange={setOnOffStatus}>
                          <input type="radio" value="1" name="onOff" checked = {value.onOff === '1'}/>&nbsp;Per Orangan&nbsp;&nbsp;&nbsp;&nbsp;
                          <input type="radio" value="2" name="onOff" checked = {value.onOff === '2'}/>&nbsp;Group &nbsp;&nbsp;&nbsp;&nbsp;
                          <input type="radio" value="3" name="onOff" checked = {value.onOff === '3'}/>&nbsp;Gereja
                      </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Max Jumlah Peserta</label>
                    <div class="jss213-small">Max Jumlah Peserta Kegiatan </div>
                  </div>
                </div>
              <div className="col-sm-9">
                <input type="number"  className="form-control"  placeholder="Max Jumlah Peserta ..." name="peserta" value={peserta} onChange={e => onChange(e)} />
                </div>
              </div>
              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                    <label>Keterangan</label>

                  </div>
                </div>
              <div className="col-sm-9">
              <textarea className="form-control" rows="8"  placeholder="Deskripsi ..." name="deskripsi" value={deskripsi} onChange={e => onChange(e)}></textarea>
                </div>
              </div>

              </div>
             </div>





             <div className="card">
              <div className="card-body">
              <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Sosial Media Promosi</label>
                    </div>
                  </div>
                  </div>
                  <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                  <label>Youtube</label>

                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
                <input type="text"  className="form-control"  placeholder=" Link Url Youtube..." name="youtube_url" value={youtube_url} onChange={e => onChange(e)} />
                </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                  <label>Facebook</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
                <input type="text"  className="form-control"  placeholder="Link Url Facebook ..." name="facebook_url" value={facebook_url} onChange={e => onChange(e)} />
                </div>
              </div>
              </div>


              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                  <label>Instagram</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
                <input type="text"  className="form-control"  placeholder="Link Url Instagram ..." name="instagram_url" value={instagram_url} onChange={e => onChange(e)} />
                </div>
              </div>
              </div>

              <div className="row">
              <div className="col-sm-3">
                  <div className="form-group">
                  <label>Twitter</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
                <input type="text"  className="form-control"  placeholder="Link Url Twitter ..." name="twitter_url" value={twitter_url} onChange={e => onChange(e)} />
                </div>
                </div>
              </div>

              </div>
              </div>










          </div>
          {/* /.card-body */}
          <div className="card-footer">
          {
            loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
            :
            <input type="submit" className="btn btn-primary pull-right" value="Save" />
          }
          </div>
          {/* /.card-footer*/}
          </form>
      </div>
      {/* /.card */}

      </div>
      </section>
      {/* /.content */}
  </div>
  {/* /.content-wrapper */}

  <Footer />
  </Fragment>
)
};

EditAction.propTypes = {
  updateAction: PropTypes.func.isRequired,
  getActionId: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({
    auth: state.auth,
    action: state.action,

});

export default connect(mapStateToProps, { updateAction,getActionId })(withRouter(EditAction));
