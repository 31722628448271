export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const GET_GEREJA = "GET_GEREJA";
export const GEREJA_ERROR = "GEREJA_ERROR";

export const GET_GEREJA_SLIDER = "GET_GEREJA_SLIDER";
export const GEREJA_SLIDER_ERROR = "GEREJA_SLIDER_ERROR";

export const GET_PEMIMPIN = "GET_PEMIMPIN";
export const GET_PEMIMPIN2 = "GET_PEMIMPIN2";
export const PEMIMPIN_ERROR = "PEMIMPIN_ERROR";

export const GET_GEREJA_JADWAL = "GET_GEREJA_JADWAL";
export const GEREJA_JADWAL_ERROR = "GEREJA_JADWAL_ERROR";

export const GET_GEREJA_JADWAL_KEGIATAN = "GET_GEREJA_JADWAL_KEGIATAN";
export const GEREJA_JADWAL_KEGIATAN_ERROR = "GEREJA_JADWAL_KEGIATAN_ERROR";

export const GET_GEREJA_JADWAL_IBADAH = "GET_GEREJA_JADWAL_IBADAH";
export const GEREJA_JADWAL_IBADAH_ERROR = "GEREJA_JADWAL_IBADAH_ERROR";

export const GET_PEMBICARA = "GET_PEMBICARA";
export const PEMBICARA_ERROR = "PEMBICARA_ERROR";

export const GET_JEMAAT = "GET_JEMAAT";
export const GET_JEMAAT2 = "GET_JEMAAT2";
export const JEMAAT_ERROR = "JEMAAT_ERROR";

export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";

export const GET_FIRMAN_CATEGORY = "GET_FIRMAN_CATEGORY";
export const GET_FIRMAN_CATEGORY2 = "GET_FIRMAN_CATEGORY2";
export const GET_FIRMAN_CATEGORY3 = "GET_FIRMAN_CATEGORY3";
export const FIRMAN_CATEGORY_ERROR = "FIRMAN_CATEGORY_ERROR";
export const GET_FIRMAN = "GET_FIRMAN";
export const FIRMAN_ERROR = "FIRMAN_ERROR";

export const GET_BERITA_CATEGORY = "GET_BERITA_CATEGORY";
export const GET_BERITA_CATEGORY2 = "GET_BERITA_CATEGORY2";
export const GET_BERITA_CATEGORY3 = "GET_BERITA_CATEGORY3";
export const BERITA_CATEGORY_ERROR = "BERITA_CATEGORY_ERROR";
export const GET_BERITA = "GET_BERITA";
export const BERITA_ERROR = "BERITA_ERROR";

export const GET_KARYA_CATEGORY = "GET_KARYA_CATEGORY";
export const GET_KARYA_CATEGORY2 = "GET_KARYA_CATEGORY2";
export const GET_KARYA_CATEGORY3 = "GET_KARYA_CATEGORY3";
export const KARYA_CATEGORY_ERROR = "KARYA_CATEGORY_ERROR";

export const GET_KARYA_TOKO= "GET_KARYA_TOKO";
export const KARYA_TOKO_ERROR = "KARYA_TOKO_ERROR";

export const GET_KARYA_PRODUK= "GET_KARYA_PRODUK";
export const GET_KARYA_PRODUK_ERROR = "GET_KARYA_PRODUK_ERROR";

export const GET_ACTION= "GET_ACTION";
export const GET_ACTION_KEGIATAN= "GET_ACTION_KEGIATAN";
export const ACTION_ERROR= "ACTION_ERROR";
export const ACTION_KEGIATAN_ERROR = "ACTION_KEGIATAN_ERROR";

export const GET_BERITA_GKMI_CATEGORY= "GET_BERITA_GKMI_CATEGORY";
export const GET_BERITA_GKMI_CATEGORY2= "GET_BERITA_GKMI_CATEGORY2";
export const GET_BERITA_GKMI_CATEGORY3= "GET_BERITA_GKMI_CATEGORY3";
export const GET_BERITA_GKMI_EDISI= "GET_BERITA_GKMI_EDISI";
export const GET_BERITA_GKMI_EDISI2= "GET_BERITA_GKMI_EDISI2";
export const GET_BERITA_GKMI_EDISI3= "GET_BERITA_GKMI_EDISI3";
export const GET_BERITA_GKMI_KONTRIBUTOR= "GET_BERITA_GKMI_KONTRIBUTOR";
export const GET_BERITA_GKMI_KONTRIBUTOR2= "GET_BERITA_GKMI_KONTRIBUTOR2";
export const GET_BERITA_GKMI_KONTRIBUTOR3= "GET_BERITA_GKMI_KONTRIBUTOR3";
export const GET_BERITA_GKMI= "GET_BERITA_GKMI";
export const GET_LANGGANAN= "GET_LANGGANAN";
export const GET_BERITA_GKMI_PEMBAYARAN= "GET_BERITA_GKMI_PEMBAYARAN";
export const GET_BERITA_GKMI_IKLAN= "GET_BERITA_GKMI_IKLAN";
export const GET_BERITA_GKMI_ORDER= "GET_BERITA_GKMI_ORDER";
export const BERITA_GKMI_CATEGORY_ERROR= "BERITA_GKMI_CATEGORY_ERROR";
export const BERITA_GKMI_CATEGORY_ERROR2= "BERITA_GKMI_CATEGORY_ERROR2";
export const BERITA_GKMI_CATEGORY_ERROR3= "BERITA_GKMI_CATEGORY_ERROR3";
export const BERITA_GKMI_EDISI_ERROR= "BERITA_GKMI_EDISI_ERROR";
export const BERITA_GKMI_EDISI_ERROR2= "BERITA_GKMI_EDISI_ERROR2";
export const BERITA_GKMI_EDISI_ERROR3= "BERITA_GKMI_EDISI_ERROR3";
export const BERITA_GKMI_KONTRIBUTOR_ERROR= "BERITA_GKMI_KONTRIBUTOR_ERROR";
export const BERITA_GKMI_KONTRIBUTOR_ERROR2= "BERITA_GKMI_KONTRIBUTOR_ERROR2";
export const BERITA_GKMI_KONTRIBUTOR_ERROR3= "BERITA_GKMI_KONTRIBUTOR_ERROR3";
export const BERITA_GKMI_ERROR= "BERITA_GKMI_ERROR";
export const LANGGANAN_ERROR= "LANGGANAN_ERROR";
export const BERITA_GKMI_ORDER_ERROR= " BERITA_GKMI_ORDER_ERROR";
export const BERITA_GKMI_IKLAN_ERROR= " BERITA_GKMI_IKLAN_ERROR";
export const BERITA_GKMI_PEMBAYARAN_ERROR= " BERITA_GKMI_PEMBAYARAN_ERROR";

export const GET_PIPKA_SLIDER = "GET_PIPKA_SLIDER";
export const HOME_PIPKA_SLIDER_ERROR = "HOME_PIPKA_SLIDER_ERROR";
export const GET_DONASI = "GET_DONASI";
export const DONASI_ERROR = "DONASI_ERROR";
export const GET_PIPKA = "GET_PIPKA";
export const PIPKA_ERROR = "PIPKA_ERROR";
export const BERITA_PIPKA_ERROR = "BERITA_PIPKA_ERROR";
export const GET_SETTINGS = "GET_SETTINGS";
export const SETTINGS_ERROR = "SETTINGS_ERROR";
export const GET_REGION = "GET_REGION";
export const REGION_ERROR = "REGION_ERROR";
export const GET_REGION2= "GET_REGION2";
export const REGION_ERROR2 = "REGION_ERROR2";
export const GET_EVENT= "GET_EVENT";
export const EVENT_ERROR = "EVENT_ERROR";
export const GET_MENU= "GET_MENU";
export const MENU_ERROR = "MENU_ERROR";
export const GET_PROJECT= "GET_PROJECT";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const GET_PROJECT_PROGRESS= "GET_PROJECT_PROGRESS";
export const PROJECT_PROGRESS_ERROR = "PROJECT_PROGRESS_ERROR";
export const GET_PROJECT_DONASI= "GET_PROJECT_DONASI";
export const PROJECT_DONASI_ERROR = "PROJECT_DONASI_ERROR";
export const GET_KATEGORI_MISSION= "GET_KATEGORI_MISSION";
export const KATEGORI_MISSION_ERROR = "KATEGORI_MISSION_ERROR";
export const GET_KATEGORI_MISSION2= "GET_KATEGORI_MISSION2";
export const KATEGORI_MISSION_ERROR2 = "KATEGORI_MISSION_ERROR2";
export const GET_MISSION_HOME= "GET_MISSION_HOME";
export const MISSION_HOME_ERROR = "MISSION_HOME_ERROR";
export const GET_GALLERY_VIDEO= "GET_GALLERY_VIDEO";
export const GALLERY_VIDEO_ERROR = "GALLERY_VIDEO_ERROR";
export const GET_GALLERY_FOTO= "GET_GALLERY_FOTO";
export const GALLERY_FOTO_ERROR = "GALLERY_FOTO_ERROR";

export const CONNECT_TOPIC_ERROR = "CONNECT_TOPIC_ERROR";
export const GET_CONNECT_TOPIC = "GET_CONNECT_TOPIC";
export const GET_CONNECT = "GET_CONNECT";
export const CONNECT_ERROR = "CONNECT_ERROR";
export const GET_CONNECT_LEADER = "GET_CONNECT_LEADER";
export const CONNECT_LEADER_ERROR = "CONNECT_LEADER_ERROR";
export const GET_CONNECT_LAGU = "GET_CONNECT_LAGU";
export const CONNECT_LAGU_ERROR = "CONNECT_LAGU_ERROR";
export const GET_OUTVISION = "GET_OUTVISION";
export const OUTVISION_ERROR = "OUTVISION_ERROR";
export const GET_HISTORY = "GET_HISTORY";
export const HISTORY_ERROR = "HISTORY_ERROR";
export const GET_LETTERS = "GET_LETTERS";
export const LETTERS_ERROR = "LETTERS_ERROR";
export const GET_DATABASE = "GET_DATABASE";
export const DATABASE_ERROR = "DATABASE_ERROR";
export const GET_DATA_DATABASE = "GET_DATA_DATABASE";
export const DATA_DATABASE_ERROR = "DATA_DATABASE_ERROR";
export const GET_TATA_GEREJA = "GET_TATA_GEREJA";
export const TATA_GEREJA_ERROR = "TATA_GEREJA_ERROR";
export const GET_TATA_GEREJA_PARENT = "GET_TATA_GEREJA_PARENT";
export const TATA_GEREJA_PARENT_ERROR = "TATA_GEREJA_PARENT_ERROR";
export const GET_TATA_GEREJA_CHILD = "GET_TATA_GEREJA_CHILD";
export const TATA_GEREJA_CHILD_ERROR = "TATA_GEREJA_CHILD_ERROR";
export const GET_TATA_GEREJA_DOCUMENT = "GET_TATA_GEREJA_DOCUMENT";
export const TATA_GEREJA_DOCUMENT_ERROR = "TATA_GEREJA_DOCUMENT_ERROR";
export const GET_PROJECT_SINODE = "GET_PROJECT_SINODE";
export const PROJECT_SINODE_ERROR = "PROJECT_SINODE_ERROR";
export const GET_PROJECT_PROGRESS_SINODE = "GET_PROJECT_PROGRESS_SINODE";
export const PROJECT_PROGRESS_SINODE_ERROR = "PROJECT_PROGRESS_SINODE_ERROR";
export const GET_PROJECT_DONASI_SINODE = "GET_PROJECT_DONASI_SINODE";
export const PROJECT_DONASI_SINODE_ERROR = "PROJECT_DONASI_SINODE_ERROR";
export const GET_SCHEDULE = "GET_SCHEDULE";
export const SCHEDULE_ERROR = "SCHEDULE_ERROR";

export const GET_HOME_SLIDER = "GET_HOME_SLIDER";
export const HOME_SLIDER_ERROR = "HOME_SLIDER_ERROR";

export const GET_COUNT_USER = "GET_COUNT_USER";
export const COUNT_USER_ERROR = "COUNT_USER_ERROR";
