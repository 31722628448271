import { GET_PIPKA,GET_PIPKA_SLIDER,GET_DONASI,GET_SETTINGS,GET_REGION,GET_REGION2,GET_EVENT,GET_MENU,GET_PROJECT,GET_PROJECT_PROGRESS,GET_PROJECT_DONASI,GET_KATEGORI_MISSION,GET_KATEGORI_MISSION2,GET_MISSION_HOME,GET_GALLERY_VIDEO,GET_GALLERY_FOTO,
    PIPKA_ERROR,HOME_PIPKA_SLIDER_ERROR,DONASI_ERROR,SETTINGS_ERROR,REGION_ERROR,REGION_ERROR2,EVENT_ERROR,MENU_ERROR,PROJECT_ERROR,PROJECT_PROGRESS_ERROR,PROJECT_DONASI_ERROR,KATEGORI_MISSION_ERROR,KATEGORI_MISSION_ERROR2,MISSION_HOME_ERROR,GALLERY_VIDEO_ERROR,GALLERY_FOTO_ERROR} from "../actions/types";

const initialState = {
    region2: [],
    kategoriMission2: [],
    pipkaHomeSlider: null,
    donasi: null,
    settings: null,
    region: null,
    event: null,
    menu: null,
    project: null,
    projectProgress: null,
    projectDonasi: null,
    kategoriMission: null,
    missionHome: null,
    galleryVideo:null,
    galleryFoto:null,
    pipka: "",
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_PIPKA_SLIDER:
            return{
                ...state,
                pipkaHomeSlider: payload,
                loading: false
            };
            case GET_DONASI:
                return{
                    ...state,
                    donasi: payload,
                    loading: false
                };
            case GET_PIPKA:
                return{
                    ...state,
                    pipka: payload,
                    loading: false
                };
                case GET_SETTINGS:
                   return{
                       ...state,
                       settings: payload,
                       loading: false
                   };
                   case GET_REGION:
                    return{
                        ...state,
                        region: payload,
                        loading: false
                    };
                    case GET_REGION2:
                   return{
                       ...state,
                       region2: payload,
                       loading: false
                   };
                   case GET_EVENT:
                        return{
                            ...state,
                            event: payload,
                            loading: false
                        };
                        case GET_MENU:
                        return{
                            ...state,
                            menu: payload,
                            loading: false
                        };
                        case GET_PROJECT:
                        return{
                            ...state,
                            project: payload,
                            loading: false
                        };
                        case GET_PROJECT_PROGRESS:
                        return{
                            ...state,
                            projectProgress: payload,
                            loading: false
                        };
                        case GET_PROJECT_DONASI:
                            return{
                                ...state,
                                projectDonasi: payload,
                                loading: false
                            };
                            case GET_KATEGORI_MISSION:
                                return{
                                    ...state,
                                    kategoriMission: payload,
                                    loading: false
                                };
                                case GET_MISSION_HOME:
                                    return{
                                        ...state,
                                        missionHome: payload,
                                        loading: false
                                    };
                                    case GET_KATEGORI_MISSION2:
                                        return{
                                            ...state,
                                            kategoriMission2: payload,
                                            loading: false
                                        };
                                        case GET_GALLERY_VIDEO:
                                            return{
                                                ...state,
                                             galleryVideo: payload,
                                                loading: false
                                            };
                                            case GET_GALLERY_FOTO:
                                            return{
                                                ...state,
                                             galleryFoto: payload,
                                                loading: false
                                            };
        case HOME_PIPKA_SLIDER_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
            case DONASI_ERROR:
                return{
                    ...state,
                    error: payload,
                    loading: false
                };
            case PIPKA_ERROR:
                return{
                    ...state,
                    error: payload,
                    loading: false
                };
                case SETTINGS_ERROR:
                   return{
                       ...state,
                       error: payload,
                       loading: false
                   };
                   case REGION_ERROR:
                        return{
                            ...state,
                            error: payload,
                            loading: false
                        };
                        case REGION_ERROR2:
                        return{
                            ...state,
                            error: payload,
                            loading: false
                        };
                        case EVENT_ERROR:
                        return{
                            ...state,
                            error: payload,
                            loading: false
                        };
                        case MENU_ERROR:
                        return{
                            ...state,
                            error: payload,
                            loading: false
                        };
                        case PROJECT_ERROR:
                        return{
                            ...state,
                            error: payload,
                            loading: false
                        };
                        case PROJECT_PROGRESS_ERROR:
                        return{
                            ...state,
                            error: payload,
                            loading: false
                        };
                        case PROJECT_DONASI_ERROR:
                            return{
                                ...state,
                                error: payload,
                                loading: false
                            };
                            case KATEGORI_MISSION_ERROR:
                            return{
                                ...state,
                                error: payload,
                                loading: false
                            };
                            case MISSION_HOME_ERROR:
                            return{
                                ...state,
                                error: payload,
                                loading: false
                            };
                            case KATEGORI_MISSION_ERROR2:
                            return{
                                ...state,
                                error: payload,
                                loading: false
                            };
                            case GALLERY_VIDEO_ERROR:
                                return{
                                    ...state,
                                    error: payload,
                                    loading: false
                                };
                                case GALLERY_FOTO_ERROR:
                                return{
                                    ...state,
                                    error: payload,
                                    loading: false
                                };

        default:
            return state;
    }

}
