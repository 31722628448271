import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { NOTIFICATION_ERROR } from "./types";

// Send Notification to Mobile
export const sendNotification = (formData, history) => async dispatch => {
    try{
		const res = await axios.post(`/api/notifikasi`, formData);
        if(res.status === 200){
            console.log("success",res.data);
            dispatch(setAlert(res.data, 'success', 5000));
        }
        history.push('/notifikasi');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: NOTIFICATION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
