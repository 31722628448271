import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { createLangganan } from "../../../actions/beritaGkmiAction";

const CreateLangganan = ({ createLangganan, auth: { user }, history }) => {
    const [namaGereja, setNamaGereja] = useState();
    const [loadings, setLoadings] = useState(false);
    const [formData, setFormData] = useState({
        nama:'',
    });

    const {
        nama,
        keterangan,
        harga,
        slot,
        duration
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async (e) => {
        e.preventDefault();
        //setLoadings(true);
        //console.log("waktu : "+formData.duration)
        createLangganan(formData, history);

    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI/langganan" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Langganan Berita</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create Langganan Berita {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                        <label>Nama</label>
                                        </div>
                                      </div>
                                    <div className="col-sm-9">
                                    <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Nama ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                                       </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                        <label>Durasi</label>
                                        </div>
                                      </div>
                                    <div className="col-sm-9">
                                    <div className="form-group">
                                    <select className="form-control" required name="duration" value={duration} onChange={e => onChange(e)}>
                                              <option value="">--Pilih--</option>
                                              <option value="1">1 Bulan</option>
                                                <option value="2">2 Bulan</option>
                                                <option value="3">3 Bulan</option>
                                                <option value="4">4 Bulan</option>
                                                <option value="5">5 Bulan</option>
                                                <option value="6">6 Bulan</option>
                                                <option value="7">7 Bulan</option>
                                                <option value="8">8 Bulan</option>
                                                <option value="9">9 Bulan</option>
                                                <option value="10">10 Bulan</option>
                                                <option value="11">11 Bulan</option>
                                                <option value="12">12 Bulan</option>
                                              </select>
                                       </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                        <label>Jumlah User</label>
                                        </div>
                                      </div>
                                    <div className="col-sm-9">
                                    <div className="form-group">
                                    <select className="form-control" required name="slot" value={slot} onChange={e => onChange(e)}>
                                              <option value="">--Pilih--</option>
                                              <option value="1">1 User</option>
                                                <option value="2">2 User</option>
                                                <option value="3">3 User</option>
                                                <option value="4">4 User</option>
                                                <option value="5">5 User</option>

                                              </select>
                                       </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                        <label>Keterangan</label>
                                        </div>
                                      </div>
                                    <div className="col-sm-9">
                                    <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Keterangan ..."  name="keterangan" value={keterangan} onChange={e => onChange(e)} />
                                       </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                        <label>Harga</label>
                                        </div>
                                      </div>
                                    <div className="col-sm-9">
                                    <div className="form-group">
                                    <input type="number" className="form-control" placeholder="Harga ..." required name="harga" value={harga} onChange={e => onChange(e)} />
                                       </div>
                                      </div>
                                    </div>

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreateLangganan.propTypes = {
    createLangganan: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { createLangganan })(withRouter(CreateLangganan));
