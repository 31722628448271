import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {API_URL_IMAGE} from '../../config/apiUrl'
import NavbarFirman from './NavbarFirman';
import NavbarGkmione from './NavbarGkmione';
import NavbarBeritaGKMI from './NavbarBeritaGKMI';
import NavbarPIPKA from './NavbarPIPKA';
import NavbarSinode from './NavbarSinode';

const Navbar = ({ auth: { user } }) => {


  function cekLevelAkses(){
    if (user && (user.level_user === 7)) {
         return (
           <Fragment>
              <NavbarSinode />
           </Fragment>
         );

  }else if (user && (user.level_user === 10)) {
      return (
        <Fragment>
           <NavbarFirman />
        </Fragment>
      );
    }else if(user && (user.level_user === 8)){
      return (
        <Fragment>
           <NavbarPIPKA />
        </Fragment>
      );
    }else{
      if(user && (user.kode_gereja === "uk_0000002")){
        return (
          <Fragment>
             <NavbarBeritaGKMI />
          </Fragment>
        );
      }else{
      return(
        <Fragment>
          <NavbarGkmione />
        </Fragment>
      );
    }
    }
  }


  return (
    <Fragment>
      {cekLevelAkses()}
    </Fragment>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Navbar);
