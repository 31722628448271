import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Fragment>
      {/* Main Footer */}
      <footer className="main-footer text-sm">
        <strong>
          Copyright © 2020 <Link to="#!">GKMI ONE</Link>
        </strong>
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 1.0.0
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
