import React, { Fragment, useEffect,useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import axios from 'axios';
import { getDashbordPipka } from "../../actions/dashboardAction";

const DashboardPipka = ({ dashboard, getDashbordPipka, auth: { user,token }  }) => {
  const [beritaTop, setBeritaTop] = useState([]);
  useEffect(() => {
    getDashbordPipka();
    fetchDataBeritatop();
  }, user);
  const fetchDataBeritatop = async () => {

    let link='';
      link = 'https://gkmi.one:8080/api/dashboard/dashboardBranchesArea';
    const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
      });
      console.log("mana :"+response.data)
      setBeritaTop(response.data);
  }
  const dataBeritaTop = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 10,
    },
      {
          label: "Regional",
          field: "nama",
          sort: "asc",
          width: 150,
      },
      {
        label: "Branches",
        field: "branches",
        sort: "asc",
        width: 100,
    },





    ],
    rows: beritaTop.map((apiData, i) => {

      let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');

      return({
        no: (i+1),
          nama: <span>{apiData.nama_region}</span>,
          branches:<span>{apiData.branches}</span>,

    });
  }),
  };
  return (
    <Fragment>
      <Header />
      <Navbar />

      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        {/* Main content */}
        <section className="content">

          <div class="container-fluid">

            <div class="row">
            <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.branches)}</h3>
                    <p style={{color: 'white'}}>Branches</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-home"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.simpatisan)}</h3>
                    <p style={{color: 'white'}}>Hamba Tuhan</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.project)}</h3>
                    <p style={{color: 'white'}}>Project</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.event)}</h3>
                    <p style={{color: 'white'}}>Event</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

            </div>
            <div class="row">
            <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.mission)}</h3>
                    <p style={{color: 'white'}}>Home Mission</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                  {/* <a href="/dashboardSinode/gereja-dewasa" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> */}
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.firman)}</h3>
                    <p style={{color: 'white'}}>Firman</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.berita)}</h3>
                    <p style={{color: 'white'}}>Berita</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>

              {/* <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.guest)}</h3>
                    <p style={{color: 'white'}}>Guest</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{dashboard.userCount && (dashboard.userCount.guest)}</h3>
                    <p style={{color: 'white'}}>Guest</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="small-box-footer">&nbsp;</div>
                </div>
              </div> */}

            </div>

            <div class="row">

            <div className="col-sm-12">
          <div className="card">
                  <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                  <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Regional Branches</h3>
                  <div className="card-tools">
                  </div>
                  </div>
                  <div className="card-body">
                  <MDBDataTable responsive striped bordered hover data={dataBeritaTop} />
              </div>
            </div>
            </div>
            </div>


          </div>

        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </Fragment>
  );
};


DashboardPipka.propTypes = {
  dashboard: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  auth: state.auth
});

export default connect(mapStateToProps, { getDashbordPipka })(DashboardPipka);
