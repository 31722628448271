import { GET_GEREJA_SLIDER, GEREJA_SLIDER_ERROR } from "../actions/types";

const initialState = {
    gerejaSlider: [],
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_GEREJA_SLIDER:
            return{
                ...state,
                gerejaSlider: payload,
                loading: false
            };
        case GEREJA_SLIDER_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}