import axios from "../util/http-common";
import { setAlert } from "./alertAction";
import { GET_PEMBICARA, PEMBICARA_ERROR } from "./types";

// Get Pembicara
export const getPembicara = kode_gereja  => async dispatch => {
    try{
        const res = await axios.get(`/api/pembicara/search/${kode_gereja}`);

        dispatch({
            type: GET_PEMBICARA,
            payload: res.data[0].pembicara,
        });
    }catch(err){
        dispatch({
            type: PEMBICARA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Get Pembicara Berdasarkan ID
export const getPembicaraById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/pembicara/search2/${id}`);

        dispatch({
            type: GET_PEMBICARA,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: PEMBICARA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Create Pembicara
export const createPembicara = (formData, history) => async dispatch => {
    try{
		const res = await axios.post(`/api/pembicara`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }
        history.push('/jadual/pembicara');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PEMBICARA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Pembicara Berdasarkan ID
export const updatePembicara = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/pembicara/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }

		history.push('/jadual/pembicara');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PEMBICARA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create Pembicara
export const createBranchesPembicara = (formData, history,path) => async dispatch => {
    try{
		const res = await axios.post(`/api/pembicara`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }
        history.push(`/pipka/branches/pembicara/${path}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PEMBICARA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Pembicara Berdasarkan ID
export const updateBranchesPembicara = (formData, id, history,path) => async dispatch => {
    try{
        const res = await axios.put(`/api/pembicara/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }

        history.push(`/pipka/branches/pembicara/${path}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: PEMBICARA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
