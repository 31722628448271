import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { GET_KARYA_CATEGORY, GET_KARYA_CATEGORY2, GET_KARYA_CATEGORY3, GET_KARYA_TOKO,GET_KARYA_PRODUK,KARYA_CATEGORY_ERROR,KARYA_TOKO_ERROR ,GET_KARYA_PRODUK_ERROR} from "./types";

// Get Karya Category
export const getKaryaCategory = () => async dispatch => {
    try{
        const res = await axios.get(`/api/karya/kategory`);
        console.log('MASUK SINI karya: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_KARYA_CATEGORY2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: KARYA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Get Karya Category 2
export const getKaryaCategory2 = () => async dispatch => {
    try{
        const res = await axios.get(`/api/karya/kategory`);
        console.log('MASUK SINI 1: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_KARYA_CATEGORY3,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: KARYA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Get Karya Category by Id
export const getKaryaCategoryById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/karya/kategory/edit/${id}`);

        dispatch({
            type: GET_KARYA_CATEGORY,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: KARYA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Create Karya Category
export const createKaryaCategory = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/karya/kategory`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/karya/category');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: KARYA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Karya Category Berdasarkan ID
export const updateKaryaCategory = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/karya/kategory/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/karya/category');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: KARYA_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
    // Create Karya Toko
export const createKaryaToko = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/karyatoko`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/karya/toko');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: KARYA_TOKO_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }

}
// Get Karya Toko by Id
export const getKaryaTokoById = id => async dispatch => {
    try{
        console.log("getKaryaTokoById id "+id);
        const res = await axios.get(`/api/karyatoko/backand/${id}`);

        dispatch({
            type: GET_KARYA_TOKO,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: KARYA_TOKO_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}
// Update Karya Toko by Id
export const updateKaryaToko = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/karyatoko/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/karya/toko');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: KARYA_TOKO_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
   // Create Karya Toko
   export const createKaryaTokoProduct = (formData, history,path) => async dispatch => {
    try{

        const res = await axios.post(`/api/karya/produk/backand`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push(`/karya/toko/produk/${path}`);
    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GET_KARYA_PRODUK_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Get Karya Toko by Id
export const getKaryaProdukById = id => async dispatch => {
    console.log("id "+id);
    try{
        const res = await axios.get(`/api/karya/produk/backand/edit/${id}`);

        dispatch({
            type: GET_KARYA_PRODUK,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: GET_KARYA_PRODUK_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Update Karya Toko by Id
export const updateKaryaProduk = (formData, idProduk,idToko, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/karya/produk/backand/${idProduk}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push(`/karya/toko/produk/${idToko}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GET_KARYA_PRODUK_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
