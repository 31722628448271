import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {useParams, Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import axios from 'axios';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

const BranchesPemimpin = ({ auth: { user, token } }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [kodeBranch, setKodeBranch] = useState();
    const [dataPemimpin, setPemimpin] = useState([]);
    const [status, setStatus] = useState(true);

    const deleteData = (id) => {
        let url2 = `https://gkmi.one:8080/api/gereja/pemimpingereja/${id}`
        axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {
            const del = dataPemimpin.filter(tableRow => id !== tableRow.id)
            setPemimpin(del);
        })
      }
      useEffect(() =>{
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        setKodeBranch(decodeString.split(';')[0]);
        setNamaGereja(decodeString.split(';')[1]);
        fetchData(decodeString.split(';')[0]);
        console.log('PROFILE: '  + " - " + decodeString.split(';')[0]);
       
   }, [user]);
    const fetchData = async (codeChurch) => {

        // let codeChurch = user && (user.gereja[0].kode_gereja);
        // let codeMasterChurch = user && (user.gereja[0].kode_gereja_induk);
        // console.log('PROFILE: ' + codeChurch + " - " + codeMasterChurch);
        let link='';
        let codeMasterChurch2 = 0;
        link = `https://gkmi.one:8080/api/gereja/pemimpinbranches/${codeChurch}`;
       const response = await axios.get(link, {
        headers: {
            'x-auth-token': token
        }
      });
     // console.log('MASUK: ' + JSON.stringify(response.data[0].gerejaSlider));
     setPemimpin(response.data);
    
    }
    
    const data = {
        columns: [
          {
              label: "No.",
              field: "no",
              sort: "asc",
              width: 10,
          },
          {
              label: "Avatar",
              field: "namafile_image",
              sort: "asc",
              width: 150,
          },
          {
              label: "Nama",
              field: "nama",
              sort: "asc",
              width: 150,
          },
          {
              label: "Jabatan",
              field: "jabatan",
              sort: "asc",
              width: 150,
          },
          {
              label: "Gelar",
              field: "gelar",
              sort: "asc",
              width: 150,
          },
          
          {
              label: "Action",
              field: "action",
              width: 150,
          },
        ],
       rows: dataPemimpin.map((apiData, i) => {
        let base64EncodeString = Buffer.from(`${apiData.id};${id}`).toString('base64');
    
         // let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
         return({
            no: (i+1),
            namafile_image: <center><img src={(apiData.namafile_image ? (apiData.namafile_image !== undefined ? apiData.tautan + '/' + apiData.namafile_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')} className="profile-user-img img-fluid img-circle" alt="Avatar" /></center>,
            nama: <span>{apiData.nama}</span>,
            jabatan: <span>{apiData.jabatan}</span>,
            gelar: <span>{apiData.gelar}</span>,
            action: <span><Link className="btn btn-block bg-gradient-primary" to={`/pipka/branches/pemimpin/edit-pemimpin/${base64EncodeString}`}>Ubah</Link>
            <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData.id) } }>Hapus</a></span>
          });
    
    }),
    };
    






   

    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-2">
                    <Link to={`/pipka/branches`} className="btn btn-block bg-gradient-danger">Back</Link>
                       </div>
                        <div className="col-sm-3">
                            <Link to={`/pipka/branches/pemimpin/create-pemimpin/${ (Buffer.from(`${kodeBranch};${namaGereja}`).toString('base64'))}`} className="btn btn-block bg-gradient-green  ">Tambah Pemimpin</Link>
                          
                        </div>
                        <div className="col-sm-7">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardPIPKA">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Pemimpin</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita Category */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Pemimpin</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(BranchesPemimpin);
