import React, { Fragment, useState, useEffect,useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import DatePicker from "react-datepicker";
import moment from "moment";
import { updateLagu } from "../../../actions/connectAction";
import { getLaguById } from "../../../actions/connectAction";

import { SunEditorAtom } from '../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditConnectLagu = ({ connect: { lagu, loading }, getLaguById, updateLagu, auth: { user, token }, history }) => {
    const {id} = useParams();const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [isi, setIsi] = useState();
    const [loadings, setLoadings] = useState(false);
    const editor = useRef();
    const [values, setValues] = useState({
      status: '1',
    });
    const [formData, setFormData] = useState({
     
      nama_lagu:'',
    });
    const [isiConten, setConten] = useState();
    

    const {
      nama_lagu,
    } = formData;

    useEffect(() => {


    
            setFormData({
              nama_lagu: lagu && (lagu.nama_lagu ? lagu.nama_lagu : ''),
             
            });
        

        setConten((lagu && (lagu.lagu ? lagu.lagu : '')));
        setValues({ status: lagu && (lagu.active.toString()) });

	}, [loading, lagu]);

  
    useEffect(() => {
      getLaguById(Buffer.from(id, 'base64').toString('ascii'));
    }, [getLaguById, id]);

   

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});


    const setStatus = (event) => {
      //console.log(event.target.value);
      setValues({ ...values, status: event.target.value });
      //console.log(values);
    };
      function uploadImageCallBack(file, uploadHandler) {
        const uploadFile = file[0];
        console.log('KESINI: ' + uploadFile);
        return new Promise(
          (resolve, reject) => {
            const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
            xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
            xhr.setRequestHeader('x-auth-token', token);
            const data = new FormData(); // eslint-disable-line no-undef
            data.append('file', uploadFile);
            data.append("tautan", API_URL_IMAGE + '/ckeditor/')
            xhr.send(data);
            xhr.addEventListener('load', () => {
              const response = JSON.parse(xhr.responseText);
              console.log('OK: ' + response);
              uploadHandler(response);
              resolve(response);
            });
            xhr.addEventListener('error', () => {
              const error = JSON.parse(xhr.responseText);
              reject(error);
            });
          },
        );
      }
      const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
  
      };
     
    const onSubmit = async (e) => {
        e.preventDefault();
         setLoadings(true);
        const data = new FormData();
       
         data.append("nama_lagu", formData.nama_lagu);
       
         data.append("isi_lagu", isiConten);
  
        data.append("active", values.status);
        updateLagu(data, Buffer.from(id, 'base64').toString('ascii'), history);
    }

    function handleImageUploadBefore(files, info, uploadHandler){
        // uploadHandler is a function
      console.log('UPLOAD: ' + files, info);
      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString());
      }
    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/connect/lagu" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardSinode">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Connect Pujian</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Pujian {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>
                         
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="0" name="status" checked = {values.status === '0'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                    <div className="row">
                <div className="col-sm-3"> 
                    <div className="form-group">
                    <label>Judul Lagu</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Judul Lagu ..." required name="nama_lagu" value={nama_lagu} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
               
                
                
               
                <div className="row">
                <div className="col-sm-3"> 
                    <div className="form-group">
                    <label>Lirik Lagu</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <SunEditor
                          autoFocus={true}
                          lang="en"
                          width='100%'
                          height='450px'
                          setOptions={{
                            minWidth : '450px',
                            showPathLabel: false,
                            placeholder: "Enter your text here!!!",
                            plugins: [
                              align,
                              font,
                              fontColor,
                              fontSize,
                              formatBlock,
                              hiliteColor,
                              horizontalRule,
                              lineHeight,
                              list,
                              paragraphStyle,
                              table,
                              template,
                              textStyle,
                              image,
                              link
                            ],
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize", "formatBlock"],
                              ["paragraphStyle"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                              ],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              "/", // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["table", "link", "image"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                              "Arial",
                              "Calibri",
                              "Comic Sans",
                              "Courier",
                              "Garamond",
                              "Georgia",
                              "Impact",
                              "Lucida Console",
                              "Palatino Linotype",
                              "Segoe UI",
                              "Tahoma",
                              "Times New Roman",
                              "Trebuchet MS"
                            ]
                          }}
                          getSunEditorInstance={getSunEditorInstance}
                          onImageUploadBefore={handleImageUploadBefore}
                          setContents={isiConten}
                          onChange={setConten}

                          
                        />
                   </div>
                   <div style={{zIndex: -999, position: 'absolute'}}>
                        <SunEditorAtom initialContent={isiConten}/>
                    </div>
                  </div>
                </div>
                


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditConnectLagu.propTypes = {
  updateLagu: PropTypes.func.isRequired,
  getLaguById: PropTypes.func.isRequired,
   
}
const mapStateToProps = state => ({
    auth: state.auth,
    connect: state.connect,
});

export default connect(mapStateToProps, { updateLagu, getLaguById })(withRouter(EditConnectLagu));
