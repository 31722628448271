import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { GET_BERITA_GKMI_CATEGORY,GET_BERITA_GKMI_CATEGORY2,GET_BERITA_GKMI_PEMBAYARAN,GET_BERITA_GKMI_CATEGORY3,GET_BERITA_GKMI_EDISI,GET_BERITA_GKMI_EDISI2,GET_BERITA_GKMI_EDISI3,GET_BERITA_GKMI_KONTRIBUTOR,GET_BERITA_GKMI_KONTRIBUTOR2,GET_BERITA_GKMI_KONTRIBUTOR3,GET_BERITA_GKMI,GET_LANGGANAN,GET_BERITA_GKMI_IKLAN,GET_BERITA_GKMI_ORDER,BERITA_GKMI_CATEGORY_ERROR,BERITA_GKMI_CATEGORY_ERROR2,BERITA_GKMI_CATEGORY_ERROR3,BERITA_GKMI_EDISI_ERROR,BERITA_GKMI_EDISI_ERROR2,BERITA_GKMI_EDISI_ERROR3,BERITA_GKMI_KONTRIBUTOR_ERROR,BERITA_GKMI_KONTRIBUTOR_ERROR2,BERITA_GKMI_KONTRIBUTOR_ERROR3,BERITA_GKMI_ERROR,LANGGANAN_ERROR ,JEMAAT_ERROR,BERITA_GKMI_IKLAN_ERROR,BERITA_GKMI_ORDER_ERROR,BERITA_GKMI_PEMBAYARAN_ERROR} from "./types";

// Get Berita Category
export const getBeritaGkmiKategori = () => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/kategori`);
        //console.log('MASUK SINI: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_BERITA_GKMI_CATEGORY2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_CATEGORY_ERROR2,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}
// Get Berita Category
export const getBeritaGkmiKategori2 = () => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/kategori`);
        //console.log('MASUK SINI: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_BERITA_GKMI_CATEGORY3,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_CATEGORY_ERROR3,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}
// Get Berita Edisi
export const getBeritaGkmiEdisi = () => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/categoryedisi`);
       // console.log('MASUK SINI: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_BERITA_GKMI_EDISI2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_EDISI_ERROR2,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}
// Get Berita Edisi
export const getBeritaGkmiEdisi2 = () => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/edisi`);
       // console.log('MASUK SINI: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_BERITA_GKMI_EDISI3,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_EDISI_ERROR3,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Get Berita Edisi
export const getBeritaGkmiKontributor = () => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/kontributor`);

       // console.log('MASUK SINI: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_BERITA_GKMI_KONTRIBUTOR2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_KONTRIBUTOR_ERROR2,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}
// Get Berita Edisi
export const getBeritaGkmiKontributor2 = () => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/kontributor`);

       // console.log('MASUK SINI: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_BERITA_GKMI_KONTRIBUTOR3,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_KONTRIBUTOR_ERROR3,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}
// Create Berita
export const createBeritaGkmiKategori = (formData, history) => async dispatch => {
    try{
       // const res = await axios.post(`/api/berita/backend/berita`, formData, {
        const res = await axios.post(`/api/beritagkmibackend/kategori`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/kategori');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Create Berita
export const createBeritaGkmiEdisi = (formData, history) => async dispatch => {
    try{
       // const res = await axios.post(`/api/berita/backend/berita`, formData, {
        const res = await axios.post(`/api/beritagkmibackend/edisi`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/edisi');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_EDISI_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Create Berita
export const createBeritaGkmikontributor = (formData, history) => async dispatch => {
    try{
       // const res = await axios.post(`/api/berita/backend/berita`, formData, {
        const res = await axios.post(`/api/beritagkmibackend/kontributor`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/kontributor');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_KONTRIBUTOR_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Create Berita
export const createBeritaGkmi = (formData, history) => async dispatch => {
    try{
       // const res = await axios.post(`/api/berita/backend/berita`, formData, {
        const res = await axios.post(`/api/beritagkmibackend`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
    // Get Berita by Id
export const getBeritaGkmiKategoriById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/kategori/${id}`);

        dispatch({
            type: GET_BERITA_GKMI_CATEGORY,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateBeritaGkmiKategori = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/beritagkmibackend/kategori/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/kategori');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getBeritaGkmiEdisiById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/edisi/${id}`);

        dispatch({
            type: GET_BERITA_GKMI_EDISI,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_EDISI_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateBeritaGkmiEdisi = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/beritagkmibackend/edisi/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/edisi');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_EDISI_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
export const getBeritaGkmiById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/berita/${id}`);

        dispatch({
            type: GET_BERITA_GKMI,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateBeritaGkmi = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/beritagkmibackend/berita/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getBeritaGkmikontributorById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/kontributor/${id}`);

        dispatch({
            type: GET_BERITA_GKMI_KONTRIBUTOR,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_KONTRIBUTOR_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateBeritaGkmiKontributor = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/beritagkmibackend/kontributor/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/kontributor');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_KONTRIBUTOR_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Create Berita
export const createLangganan = (formData, history) => async dispatch => {
    try{
       // const res = await axios.post(`/api/berita/backend/berita`, formData, {
        const res = await axios.post(`/api/beritagkmibackend/langganan`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/langganan');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getLanggananById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/langganan/${id}`);

        dispatch({
            type: GET_LANGGANAN,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: LANGGANAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateLangganan = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/beritagkmibackend/langganan/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/langganan');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: LANGGANAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create User Role
export const createUserRole = (formData, history) => async dispatch => {
    try{
		const res = await axios.post(`/api/profile/jemaat`, formData);
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push('/beritaGKMI/user');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: JEMAAT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const createBeritaGkmiIklan = (formData, history) => async dispatch => {
    try{
        // const res = await axios.post(`/api/berita/backend/berita`, formData, {
         const res = await axios.post(`/api/beritagkmibackend/iklan`, formData, {
             headers: {
                 'Content-Type': 'multipart/form-data'
             }
         });

         if(res.status === 200){
             console.log("success",res.data[0].msg);
             dispatch(setAlert(res.data[0].msg, 'success', 5000));
         }

         history.push('/beritaGKMI/iklan');

     }catch(err){
         const errors = err.response;
         if (errors) {
             const errors2 = errors.data[0].errors;
             errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
         }
         dispatch({
             type: BERITA_GKMI_IKLAN_ERROR,
             //payload: { msg: err.response.statusText, status: err.response.status }
             payload: { msg: err.message, status: err }
         });
     }
}

export const getBeritaGkmiIklanById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/beritagkmibackend/iklan/${id}`);

        dispatch({
            type: GET_BERITA_GKMI_IKLAN,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_IKLAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateBeritaGkmiIklan = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/beritagkmibackend/iklan/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/iklan');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_IKLAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

export const getOrderLanggananById = id => async dispatch => {
    try{
       // const res = await axios.get(`http://localhost:8080/api/beritagkmibackend/langgananOrder/${id}`);
        const res = await axios.get(`/api/beritagkmibackend/langgananOrder/${id}`);

        dispatch({
            type: GET_BERITA_GKMI_ORDER,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_ORDER_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateBeritaGkmiOrder = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/beritagkmibackend/konfirmasi/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/order');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_ORDER_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}


export const getPembayaran = () => async dispatch => {
    try{
       // const res = await axios.get(`http://localhost:8080/api/beritagkmibackend/langgananOrder/${id}`);
        const res = await axios.get(`/api/beritagkmibackend/settings`);

        dispatch({
            type: GET_BERITA_GKMI_PEMBAYARAN,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: BERITA_GKMI_PEMBAYARAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

export const updateCaraPembayaran = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/beritagkmibackend/carapembayaran/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/beritaGKMI/pembayaran');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: BERITA_GKMI_PEMBAYARAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
