import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {API_URL_IMAGE} from '../../config/apiUrl'

const NavbarFirman = ({ auth: { user } }) => {
  const location = useLocation();
  const [firmanTreeClass, setFirmanTreeClass] = useState("nav-item has-treeview");

  const homeClass = location.pathname === "/dashboardFirman" ? "nav-link active" : "nav-link";

  const firmanClass = location.pathname === "/firman" ? "nav-link active" : "nav-link";

  const handleOnClick = (menu) => {
    console.log("MENU: " + menu); // remove the curly braces

    if(menu !=="DashboardFirman")
      document.title = `GKMI ONE - ${menu}`;
    else
     document.title = `GKMI ONE`;
  };

  const handleClick = (e) => {
	e.preventDefault();
  }

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
  }, []);

  useEffect(() => {

    if(location.pathname === "/firman"){
      setFirmanTreeClass("nav-item has-treeview menu-open");
    }
  }, [location.pathname]);

  return (
    <Fragment>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/dashboardFirman" className="brand-link navbar-danger" style={{lineHeight: "2.1"}}>
          <img
            src={process.env.PUBLIC_URL + '/logo-gkmi.png'}
            alt="GKMI ONE"
            className="brand-image img-circle"
            style={{ opacity: ".8", height: "58px", maxHeight: "58px", marginTop: "-12px", marginLeft: "1px" }}
          />
          <span className="brand-text font-weight-light">GKMI ONE</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar" style={{backgroundColor: "#3c8dbc"}}>
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">

          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-legacy"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item">
                <Link
                  to="/dashboardFirman"
                  className={homeClass}
                  onClick={() => handleOnClick("Dashboard")}
                >
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p className="linkcolor">
                    Dashboard
                    <i className="right" />
                  </p>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/firman"
                  className={firmanClass}
                  onClick={() => handleOnClick("Firman")}
                >
                  <i className="nav-icon far fa fa-2x fa-book" />
                  <p className="linkcolor">
                  Firman
                    <i className="right" />
                  </p>
                </Link>
              </li>


            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </Fragment>
  );
};

NavbarFirman.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(NavbarFirman);
