import React, { Fragment, useState, useEffect, useRef } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DatePicker from "react-datepicker";
import moment from "moment";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link, withRouter } from "react-router-dom";
import Alert from "../../../components/alert/Alert";
import { API_URL_IMAGE } from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createBranches } from "../../../actions/gerejaAction"
import { getRegion2 } from "../../../actions/pipkaAction";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import draftToHtml from 'draftjs-to-html';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const CreateBranches = ({ createBranches, pipka,getRegion2,history, auth: { user, token } }) => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const onEditorStateChange = (editorState) => {
    console.log('OKK: ' + stateToHTML(editorState.getCurrentContent()));
    //setSejarah(editorState);
    setEditorState(editorState);
  }

  const editor = useRef();
  const [value, setValue] = useState('');
  const [loadings, setLoadings] = useState(false);

  const [file, setFile] = useState();
  const [kodeGereja, setKodeGereja] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
  const [sejarah, setSejarah] = useState();
  const [formData, setFormData] = useState({
    nama: '',
    kota: '',
    email: '',
    telp: '',
    tagline: '',
    status: '',
    visi: '',
    misi: '',
    latitude: '',
    longitude: '',
    alamat: '',
	instagram: '',
    facebook: '',
    twitter: '',
    youtube: '',
    website: '',
    google: '',
    tgl_pendewasaan: "",
    pgmw: '',
    nama_user: '',
    email_user: '',
    no_telepon: '',
    username: '',
    password: '',
    confirmPassword: '',
    kode_region:'',
  });

  const {
    nama,
    kota,
    email,
    telp,
    tagline,
    status,
    visi,
    misi,
    latitude,
    longitude,
    alamat,
	instagram,
    facebook,
    twitter,
    youtube,
    website,
    google,
    tgl_pendewasaan,
    pgmw,
    no_telepon,
    nama_user,
    email_user,
    username,
    password,
    confirmPassword,
    kode_region
  } = formData;

  const handleDateChange = e => {
    setFormData({ ...formData,
      tgl_pendewasaan: new Date(e)
    });
  }

  const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

  const fileOnChange = e => {
    setFile(e.target.files[0]);
  }

  const fileOnChangePemimpin = e => {
    console.log(e);
  setFile(e.target.files[0]);
  const objectUrl = URL.createObjectURL(e.target.files[0])
  setImagePreviewUrl(objectUrl)
}

  const sejarahOnChange = (e, editor) => {
    const data = editor.getData();
    console.log('EDITOR: ' + data);
    setSejarah(data);
  }
  const handleRegionChange = e => {
    setFormData({ ...formData,
        kode_region: e.target.options[e.target.selectedIndex].value,
        nama_region: e.target.options[e.target.selectedIndex].text
    });
  }

  function uploadImageCallBack(file, uploadHandler) {
    const uploadFile = file[0];
    console.log('KESINI: ' + uploadFile);
    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
        xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
        xhr.setRequestHeader('x-auth-token', token);
        const data = new FormData(); // eslint-disable-line no-undef
        data.append('file', uploadFile);
        data.append("tautan", API_URL_IMAGE + '/ckeditor/')
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText);
          console.log('OK: ' + response);
          uploadHandler(response);
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      },
    );
  }

  useEffect(() => {

    let code = "0";
    let codeChurch = user && (user.gereja[0].kode_gereja);
    if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
        console.log('USER1: ' + user.level_user + " - " + user.gereja[0].kode_gereja);
        setKodeGereja(codeChurch);
        setFormData({
          tgl_pendewasaan: '',//moment(new Date()).format('YYYY-MM-DD'),
        });
    }else{
        code = codeChurch;
        console.log('USER: ' + codeChurch);
        setKodeGereja(codeChurch);
        setFormData({
          tgl_pendewasaan: '',
        });
    }
    getRegion2();
    console.log('KODE: ' + kodeGereja);
  }, [user,getRegion2]);

  const onSubmit = async (e) => {
      e.preventDefault();
      setLoadings(true);
      const data = new FormData();
      data.append("image_pemimpin", file);
      //data.append("nama", formData.nama);
      //data.append("kota", formData.kota);
      //data.append("email", formData.email);
      //data.append("telp", formData.telp);
      //data.append("tagline", formData.tagline);
      data.append("status", formData.status);
      //data.append("visi", formData.visi);
      //data.append("misi", formData.misi);
      //data.append("alamat", formData.alamat);
      //data.append("sejarah", draftToHtml(convertToRaw(editorState.getCurrentContent())));
      data.append("sejarah", value);
      if(typeof formData.nama !== 'undefined'){
        data.append("nama", formData.nama);
      }
      if(typeof formData.kota !== 'undefined'){
        data.append("kota", formData.kota);
      }
      if(typeof formData.email !== 'undefined'){
        data.append("email", formData.email);
      }
      if(typeof formData.telp !== 'undefined'){
        data.append("telp", formData.telp);
      }
      if(typeof formData.tagline !== 'undefined'){
        data.append("tagline", formData.tagline);
      }
      if(typeof formData.visi !== 'undefined'){
        data.append("visi", formData.visi);
      }
      if(typeof formData.misi !== 'undefined'){
        data.append("misi", formData.misi);
      }
      if(typeof formData.latitude !== 'undefined'){
        data.append("latitude", formData.latitude);
      }
      if(typeof formData.longitude !== 'undefined'){
        data.append("longitude", formData.longitude);
      }
      if(typeof formData.alamat !== 'undefined'){
        data.append("alamat", formData.alamat);
      }
      if(typeof formData.instagram !== 'undefined'){
        data.append("instagram", formData.instagram);
      }
	    if(typeof formData.facebook !== 'undefined'){
        data.append("facebook", formData.facebook);
      }
      if(typeof formData.twitter !== 'undefined'){
        data.append("twitter", formData.twitter);
      }
      if(typeof formData.youtube !== 'undefined'){
        data.append("youtube", formData.youtube);
      }
      if(typeof formData.website !== 'undefined'){
        data.append("website", formData.website);
      }
      if(typeof formData.google !== 'undefined'){
        data.append("google", formData.google);
      }
      data.append("pgmw", "");
      data.append("kode_gereja_induk", kodeGereja);
      data.append("tgl_pendewasaan", '');
      data.append("tautan", API_URL_IMAGE + '/gereja/');

      if (password !== confirmPassword) {
        alert("Password tidak sama");
        return;
      }
      data.append("nama_user", formData.nama_user);
      data.append("email_user", formData.email_user);
      data.append("no_telepon", formData.no_telepon);
      data.append("username", formData.username);
      data.append("password", formData.password);
      data.append("kode_region", formData.kode_region);

      console.log(file);
      createBranches(data, history);

	  //createGereja(formData, history);
  }

  function handleImageUploadBefore(files, info, uploadHandler){
    try {
      uploadImageCallBack(files, uploadHandler);
    } catch (err) {
        uploadHandler(err.toString())
    }
  }

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;

  };

  function cekLevelAkses(){
    
      return (
        <Fragment>
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-2">
                    <Link to="/pipka/branches" className="btn btn-block bg-gradient-danger">Back</Link>
                  </div>
                  <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                      <li className="breadcrumb-item">
                        <Link to="/dashboardPIPKA">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Branches</li>
                    </ol>
                  </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
              {/* Default box */}
              <div className="card">
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                  <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Registrasi Branches</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>

                  </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                        <label>Region</label>
                        <select className="form-control" required name="kode_region" value={kode_region} onChange={handleRegionChange}>
                             <option value="">--Pilih--</option>
                                        {
                                            pipka && pipka.region2.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama_region}</option>)
                                        }
                                </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Nama </label>
                          <input type="text" className="form-control" placeholder="Nama ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Kota</label>
                          <input type="text" className="form-control" required placeholder="Kota ..." name="kota" value={kota} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Email</label>
                          <input type="text" className="form-control" placeholder="Email ..." name="email" value={email} onChange={e => onChange(e)} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Telepon</label>
                          <input type="text" className="form-control" placeholder="Telepon ..." name="telp" value={telp} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Tagline</label>
                          <input type="text" className="form-control" placeholder="Tagline ..." name="tagline" value={tagline} onChange={e => onChange(e)} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Status Branches</label>
                          <select className="form-control" required name="status" value={status} onChange={e => onChange(e)}>
                          <option value="">--Pilih--</option>
                            
                            <option value="cabang">Cabang</option>
                            <option value="pos pi ">Pos PI</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Visi</label>
                          <input type="text" className="form-control" placeholder="Visi ..." name="visi" value={visi} onChange={e => onChange(e)} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Misi</label>
                          <input type="text" className="form-control" placeholder="Misi ..." name="misi" value={misi} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                   

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Sejarah</label>
                          <SunEditor
                              autoFocus={true}
                              lang="en"
                              width='100%'
                              height='450px'
                              setOptions={{
                                minWidth : '450px',
                                showPathLabel: false,
                                placeholder: "Enter your text here!!!",
                                plugins: [
                                  align,
                                  font,
                                  fontColor,
                                  fontSize,
                                  formatBlock,
                                  hiliteColor,
                                  horizontalRule,
                                  lineHeight,
                                  list,
                                  paragraphStyle,
                                  table,
                                  template,
                                  textStyle,
                                  image,
                                  link
                                ],
                                buttonList: [
                                  ["undo", "redo"],
                                  ["font", "fontSize", "formatBlock"],
                                  ["paragraphStyle"],
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript"
                                  ],
                                  ["fontColor", "hiliteColor"],
                                  ["removeFormat"],
                                  "/", // Line break
                                  ["outdent", "indent"],
                                  ["align", "horizontalRule", "list", "lineHeight"],
                                  ["table", "link", "image"]
                                ],
                                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                font: [
                                  "Arial",
                                  "Calibri",
                                  "Comic Sans",
                                  "Courier",
                                  "Garamond",
                                  "Georgia",
                                  "Impact",
                                  "Lucida Console",
                                  "Palatino Linotype",
                                  "Segoe UI",
                                  "Tahoma",
                                  "Times New Roman",
                                  "Trebuchet MS"
                                ]
                              }}
                              getSunEditorInstance={getSunEditorInstance}
                              onImageUploadBefore={handleImageUploadBefore}
                              setContents={value}
                              onChange={setValue}
                            />

                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Instagram</label>
                          <input type="text" className="form-control" placeholder="Instagram ..." name="instagram" value={instagram} onChange={e => onChange(e)} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Facebook</label>
                          <input type="text" className="form-control" placeholder="Facebook ..." name="facebook" value={facebook} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Twitter</label>
                          <input type="text" className="form-control" placeholder="Twitter ..." name="twitter" value={twitter} onChange={e => onChange(e)} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Youtube</label>
                          <input type="text" className="form-control" placeholder="Youtube ..." name="youtube" value={youtube} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Website</label>
                          <input type="text" className="form-control" placeholder="Website ..." name="website" value={website} onChange={e => onChange(e)} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Google</label>
                          <input type="text" className="form-control" placeholder="Google ..." name="google" value={google} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Latitude</label>
                          <input type="text" className="form-control" placeholder="Latitude ..." name="latitude" value={latitude} onChange={e => onChange(e)} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Longitude</label>
                          <input type="text" className="form-control" placeholder="Longitude ..." name="longitude" value={longitude} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                        <label>Gambar Pemimpin Gereja</label>
                          <div className="imgPreviewUpload">
                          <img className="imgPreview" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Pemimpin Gereja ..." name="namafile_image"  onChange={fileOnChangePemimpin} />
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Alamat</label>
                          <textarea className="form-control" rows="5" placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Nama</label>
                          <input type="text" className="form-control" required={true} placeholder="Nama ..." name="nama_user" value={nama_user} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Email</label>
                            <input type="text" className="form-control" placeholder="Email ..." name="email_user" value={email_user} onChange={e => onChange(e)} />
                          </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>No. Telepon</label>
                            <input type="text" className="form-control" placeholder="No. Telepon ..." name="no_telepon" value={no_telepon} onChange={e => onChange(e)} />
                          </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Username</label>
                            <input type="text" className="form-control" required={true} placeholder="Username ..." name="username" value={username} onChange={e => onChange(e)} />
                          </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" required={true} placeholder="Password ..." name="password" value={password} onChange={e => onChange(e)} />
                          </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Konfirmasi Password</label>
                            <input type="password" className="form-control" required={true} placeholder="Konfirmasi Password ..." name="confirmPassword" value={confirmPassword} onChange={e => onChange(e)} />
                          </div>
                        </div>
                    </div>

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
              </div>
              {/* /.card */}

              </div>
            </section>
            {/* /.content */}
          </div>
          {/* /.content-wrapper */}

        </Fragment>
      );
    
  }

  return (
    <Fragment>
        <Header />
        <Navbar />
        {cekLevelAkses()}
        <Footer />
    </Fragment>
  );
};

CreateBranches.propTypes = {
  createBranches: PropTypes.func.isRequired,
  getRegion2: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
  pipka:state.pipka
});


export default connect(mapStateToProps, { createBranches,getRegion2 })(withRouter(CreateBranches));
