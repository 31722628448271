import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import moment from "moment";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { updateBeritaGkmiOrder } from "../../../actions/beritaGkmiAction";
import { getOrderLanggananById } from "../../../actions/beritaGkmiAction";

const KonfirmasiOrderLangganan = ({ beritaGkmi: { loading, beritaGkmiOrder }, getOrderLanggananById, updateBeritaGkmiOrder, auth: { user }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [loadings, setLoadings] = useState(false);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [values, setValues] = useState({
        status: '0',
      });
    const [formData, setFormData] = useState({
        nama:'',

    });

    useEffect(() => {
        setFormData({
            nama: beritaGkmiOrder && (beritaGkmiOrder.user_name ? beritaGkmiOrder.user_name : ''),
            status: beritaGkmiOrder && (beritaGkmiOrder.status_payment ? beritaGkmiOrder.status_payment : ''),
            alamat: beritaGkmiOrder && (beritaGkmiOrder.alamat ? beritaGkmiOrder.alamat : ''),
            email: beritaGkmiOrder && (beritaGkmiOrder.email ? beritaGkmiOrder.email : ''),
            telepon: beritaGkmiOrder && (beritaGkmiOrder.no_telepon ? beritaGkmiOrder.no_telepon : ''),
            langganan_name: beritaGkmiOrder && (beritaGkmiOrder.langganan_name ? beritaGkmiOrder.langganan_name : ''),
            duration: beritaGkmiOrder && (beritaGkmiOrder.duration ? beritaGkmiOrder.duration : ''),
            harga: beritaGkmiOrder && (beritaGkmiOrder.harga ? beritaGkmiOrder.harga : ''),
            slot_langganan: beritaGkmiOrder && (beritaGkmiOrder.slot_langganan ? beritaGkmiOrder.slot_langganan : ''),
            keterangan: beritaGkmiOrder && (beritaGkmiOrder.order_no ? beritaGkmiOrder.keterangan : ''),
            order_no: beritaGkmiOrder && (beritaGkmiOrder.order_no ? beritaGkmiOrder.order_no : ''),
            payment:beritaGkmiOrder && (beritaGkmiOrder.payment ? beritaGkmiOrder.payment : ''),
            date:beritaGkmiOrder && (beritaGkmiOrder.date ?  moment(beritaGkmiOrder.date).format('DD/MM/YYYY') : ''),
            deskription:beritaGkmiOrder && (beritaGkmiOrder.keteranganOrder ? beritaGkmiOrder.keteranganOrder : ''),
            start_date:beritaGkmiOrder && (beritaGkmiOrder.start_date ?  moment(beritaGkmiOrder.start_date).format('DD-MMM-YYYY') : '-'),
            end_date:beritaGkmiOrder && (beritaGkmiOrder.end_date ?  moment(beritaGkmiOrder.end_date).format('DD-MMM-YYYY') : '-'),
            propinsi: beritaGkmiOrder && (beritaGkmiOrder.propinsi ? beritaGkmiOrder.propinsi : ''),
            kabupatenKota: beritaGkmiOrder && (beritaGkmiOrder.kabupatenKota ? beritaGkmiOrder.kabupatenKota : ''),
            kecamatan: beritaGkmiOrder && (beritaGkmiOrder.kecamatan ? beritaGkmiOrder.kecamatan : ''),
            kelurahan: beritaGkmiOrder && (beritaGkmiOrder.kelurahan ? beritaGkmiOrder.kelurahan : ''),
            kodepos: beritaGkmiOrder && (beritaGkmiOrder.kodepos ? beritaGkmiOrder.kodepos : ''),

        });

        setValues({ status: beritaGkmiOrder && (beritaGkmiOrder.status_payment.toString()) });
    }, [loading, beritaGkmiOrder]);
    useEffect(() => {
        setFileName( beritaGkmiOrder && (beritaGkmiOrder.nama_image ? beritaGkmiOrder.nama_image : ''));

        setImagePreviewUrl( beritaGkmiOrder && (beritaGkmiOrder.tautan ? beritaGkmiOrder.tautan + '/' +fileName : ''));
      }, [loading, beritaGkmiOrder, fileName]);


    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
      console.log("id "+decodeString.split(';')[0])
      getOrderLanggananById( decodeString.split(';')[0]);
      }, [getOrderLanggananById, id]);

    const {
        nama,
        keterangan,
        langganan_name,
        alamat,
        telepon,
        email,
        duration,
        harga,
        slot_langganan,
        order_no,
        payment,
        date,
        deskription,
        start_date,
        end_date,
        propinsi,
        kabupatenKota,
        kecamatan,
        kelurahan,
        kodepos


    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);

        const data = new FormData();
         data.append("image_konfirmasi", file);
        data.append("duration", formData.duration);
        data.append("active", values.status);
        data.append("deskription", formData.deskription);
        updateBeritaGkmiOrder(data,  Buffer.from(id, 'base64').toString('ascii'), history);

    }
    const setStatus = (event) => {
        //console.log(event.target.value);
        setValues({ ...values, status: event.target.value });
        //console.log(values);
      };
      const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI/order" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Konfirmasi Langganan</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Konfirmasi Langganan</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />

                  {/*  <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>

                        </div>
                      </div>


                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>*/}
                      <div className="row">
                                          <div className="col-sm-3">
                                              <div className="form-group">
                                                <label>Status Pembayaran *</label>

                                              </div>
                                            </div>
                                          <div className="col-sm-9">
                                              <div className="form-group">
                                              <div onChange={setStatus}>
                                                      <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Sudah Bayar &nbsp;&nbsp;&nbsp;&nbsp;
                                                      <input type="radio" value="0" name="status" checked = {values.status === '0'}/>&nbsp;&nbsp;Belum Bayar &nbsp;&nbsp;
                                                  </div>
                                              </div>
                                            </div>
                                            </div>
                      <div class="invoice p-3 mb-3">
                        <div class="row">
                          <div class="col-12">
                          <div className="form-group">
                            <h4>
                              <i class="fas "></i> Berita GKMI
                              <small class="float-right">Date : {date}</small>
                            </h4>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                <div className="col-sm-4">
                    <div className="form-group">
                    <label>To </label>
                    <label> {nama} </label>
                    <div class="jss213-normal">Alamat : {alamat} </div>
                    <div class="jss213-normal">Propinsi : {propinsi} </div>
                    <div class="jss213-normal">Kota : {kabupatenKota} </div>
                    <div class="jss213-normal">Kecamatan : {kecamatan} </div>
                    <div class="jss213-normal">Kelurahan : {kelurahan} </div>
                    <div class="jss213-normal">Kode Pos  : {kodepos} </div>
                    <div class="jss213-normal">Phone :  {telepon} </div>
                    <div class="jss213-normal">Email :  {email} </div>
                    </div>
                  </div>
                <div className="col-sm-4">
                <div className="form-group">
                <label> Langganan: </label>
                <label> {langganan_name} </label>
                <div class="jss213-normal">{keterangan} </div>
                <div class="jss213-normal">Harga : {harga} </div>
                <div class="jss213-normal">User : {slot_langganan} User</div>
                <div class="jss213-normal">Durasi : {duration} Bulan </div>
                   </div>
                  </div>
                  <div className="col-sm-4">
                <div className="form-group">
                <label> Order : </label>
                <label> No : {order_no} </label>
                <div class="jss213-normal">Status  : <label class="badge badge-danger"> {payment}</label> </div>
                <div class="jss213-normal">Mulai : {start_date} </div>
                <div class="jss213-normal">Berahir : {end_date} </div>

                   </div>
                  </div>

                </div>
                </div>


                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Keterangan</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <textarea className="form-control" rows="3"  placeholder="Keterangan ..." name="deskription" value={deskription} onChange={e => onChange(e)}></textarea>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Bukti Pembayaran</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div className="imgPreviewUpload">
                          <img className="imgPreview300_400" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                   </div>
                  </div>
                </div>


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

KonfirmasiOrderLangganan.propTypes = {
  updateBeritaGkmiOrder: PropTypes.func.isRequired,
  getOrderLanggananById: PropTypes.func.isRequired,
    // beritaGkmi: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    beritaGkmi: state.beritaGkmi
});

export default connect(mapStateToProps, { updateBeritaGkmiOrder, getOrderLanggananById })(withRouter(KonfirmasiOrderLangganan));
