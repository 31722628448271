import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { getGereja } from "../../actions/gerejaAction";
import { connect } from 'react-redux';
import axios from 'axios';

const ListBranches = ({ getGereja, gerejaData, auth: { user, token } }) => {
    const [dataGereja, setDataGereja] = useState([]);
    const [status, setStatus] = useState(true);
    const [kode, setKode] = useState();
    const deleteData = (id) => {
        let url2 = `https://gkmi.one:8080/api/gereja/${id}`
        axios.delete(url2, {
            headers: {
                'x-auth-token': token
            }
        }).then(res => {

            //if(res.data[0].status === "failed"){
              // alert(res.data[0].msg);
            //}else{
                const del = dataGereja.filter(tableRow => id !== tableRow._id)
                setDataGereja(del);
            //}

        })
      }

    useEffect(() => {
        fetchData();
    }, [user]);

    const fetchData = async () => {

        let codeChurch = user && (user.gereja[0].kode_gereja);
        let codeMasterChurch = user && (user.gereja[0].kode_gereja_induk);
        console.log('PROFILE: ' + codeChurch + " - " + codeMasterChurch);
        let link='';
        setKode(codeChurch);
        let codeMasterChurch2 = 0;

            link = `https://gkmi.one:8080/api/gereja/branches_search/${codeChurch}/${codeMasterChurch2}`;


         const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
        });

       setDataGereja(response.data[0].gereja);

      }



    const data = {
        columns: [
            {
                label: "No.",
                field: "no",
                sort: "asc",
                width: 10,
            },
            {
                label: "Nama",
                field: "nama",
                sort: "asc",
                width: 150,
            },
            // {
            //     label: "Email",
            //     field: "email",
            //     sort: "asc",
            //     width: 150,
            // },
            // {
            //     label: "Telepon",
            //     field: "telp",
            //     sort: "asc",
            //     width: 150,
            // },
            // {
            //     label: "Alamat",
            //     field: "alamat",
            //     sort: "asc",
            //     width: 150,
            // },
            {
                label: "Action",
                field: "action",
                width: 150,
            },
        ],

        rows: dataGereja.map((apiData, i) => {
            let base64EncodeString = Buffer.from(`${apiData.kode_gereja};${apiData.nama}`).toString('base64');
           return({
            no: (i+1),
            image_pemimpin: <center><img src={(apiData.image_pemimpin !== "" ? (apiData.image_pemimpin !== undefined ? apiData.tautan + apiData.image_pemimpin : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
            className="profile-user-img img-fluid img-circle"
            alt="Gereja" /></center>,
            kode_gereja: apiData.kode_gereja,
            nama: <span>{apiData.nama}</span>,

            action:

        //     <span> {
        //       apiData.status === "dewasa" ?

        //         <Link className="btn btn-block bg-gradient-primary" to={`/gereja/edit-gereja/${apiData.kode_gereja}`}>Ubah</Link>
        //         : apiData.kode_gereja !== kode ?
        //         <span> <Link className="btn btn-block bg-gradient-primary" to={`/gereja/edit-gereja/${apiData.kode_gereja}`}>Ubah</Link>
        //     <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData._id) } }>Hapus</a></span>
        // :
        <div class="">

      <button type="button" class="btn btn-success btn-sm" >

      <Link  to={`/pipka/branches/edit-branches/${apiData.kode_gereja}`}><i class="fas fa-edit"></i></Link>
    </button> 	&nbsp;
    <button type="button" class="btn btn-success btn-sm" >
 <i class="fas fa-trash"><Link  to=''></Link></i>
      
    </button> 	&nbsp;

        <button type="button" class="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" data-offset="-52">
         <i class="fas fa-bars"><Link  to=''></Link></i>
        </button>
        <div class="dropdown-menu" role="menu">
         <Link class="dropdown-item" to={`/pipka/branches/slider/${base64EncodeString}`}>Slider Branches</Link>
         <Link class="dropdown-item" to={`/pipka/branches/pemimpin/${base64EncodeString}`}>Hamba Tuhan</Link>
          <div class="dropdown-divider"></div>
          <Link class="dropdown-item" to={`/pipka/branches/pembicara/${base64EncodeString}`}>Pembicara</Link>
          <Link class="dropdown-item" to={`/pipka/branches/kegiatan/${base64EncodeString}`}>Jadwal Ibadah</Link>

        </div>
        {/* {`/pipka/branches/slider/ Buffer.from(`${kodeBranch};${namaGereja}`).toString('base64'))`} */}

    </div>
         });
       }),

    //     rows: dataGereja ? dataGereja.map((apiData, i) => ({

    //         no: (i+1),
    //         image_pemimpin: <center><img src={(apiData.image_pemimpin !== "" ? (apiData.image_pemimpin !== undefined ? apiData.tautan + apiData.image_pemimpin : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
    //         className="profile-user-img img-fluid img-circle"
    //         alt="Gereja" /></center>,
    //         kode_gereja: apiData.kode_gereja,
    //         nama: <span>{apiData.nama}</span>,
    //         // email: apiData.email,
    //         // telp: apiData.telp,
    //         // tagline: apiData.tagline,
    //         // visi: apiData.visi,
    //         // misi: apiData.misi,
    //         // alamat: apiData.alamat,

    //         action:

    //     //     <span> {
    //     //       apiData.status === "dewasa" ?

    //     //         <Link className="btn btn-block bg-gradient-primary" to={`/gereja/edit-gereja/${apiData.kode_gereja}`}>Ubah</Link>
    //     //         : apiData.kode_gereja !== kode ?
    //     //         <span> <Link className="btn btn-block bg-gradient-primary" to={`/gereja/edit-gereja/${apiData.kode_gereja}`}>Ubah</Link>
    //     //     <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData._id) } }>Hapus</a></span>
    //     // :
    //     <div class="">

    //   <button type="button" class="btn btn-success btn-sm" >

    //   <Link  to={`/pipka/branches/edit-branches/${apiData.kode_gereja}`}><i class="fas fa-edit"></i></Link>
    // </button>
    // <button type="button" class="btn btn-success btn-sm" >

    //   <i class="fas fa-trash"></i>
    // </button>

    //     <button type="button" class="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" data-offset="-52">
    //       <i class="fas fa-bars"></i>
    //     </button>
    //     <div class="dropdown-menu" role="menu">
    //      <Link class="dropdown-item" to={`/gereja/edit-gereja/${apiData.kode_gereja}`}>Slider Branches</Link>
    //       <a href="#" class="dropdown-item">Clear events</a>
    //       <div class="dropdown-divider"></div>
    //       <a href="#" class="dropdown-item">View calendar</a>
    //     </div>


    // </div>
    //     // <span> <Link className="btn btn-block bg-gradient-primary" to={`/gereja/edit-gereja/${apiData.kode_gereja}`}>Ubah</Link></span>
    //     // }
    //     // </span>

    //     })) : '',
        //rows: res[0].gereja.map((apiData, i) => <tr> <td> <a href={`/item/${apiData.nama}`}> {apiData.nama} </a> </td> <td> {apiData.nama} </td> </tr>),


    };

      return(
        <Fragment>
            <MDBDataTable responsive striped bordered hover data={data} />
        </Fragment>
    );

};

ListBranches.propTypes = {
    getGereja: PropTypes.func.isRequired,
    gerejaData: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, {getGereja})(ListBranches);
