import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { createFirmanCategory } from "../../../actions/firmanAction";

const CreateFirmanCategory = ({ createFirmanCategory, auth: { user }, history }) => {
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [status, setStatus] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [formData, setFormData] = useState({
        nama: '',
        username: '',
        email: '',
        no_telepon: '',
        password: '',
        confirmPassword: '',
        nama_kategori:'',
    });

    const {
        nama,
        username,
        email,
        no_telepon,
        password,
        confirmPassword,
        nama_kategori,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const selectOnChange = e => {
        setStatus(e.target.value );
        console.log("TARGET:" + e.target.value);
    }

    function cekStatus(){
      if(status === "2"){ // ini ada username dan password
          return (
              <Fragment>
                  <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Nama</label>
                          <input type="text" className="form-control" required={true} placeholder="Nama ..." name="nama" value={nama} onChange={e => onChange(e)} />
                        </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input type="text" className="form-control" placeholder="Email ..." name="email" value={email} onChange={e => onChange(e)} />
                        </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>No. Telepon</label>
                          <input type="text" className="form-control" placeholder="No. Telepon ..." name="no_telepon" value={no_telepon} onChange={e => onChange(e)} />
                        </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Username</label>
                          <input type="text" className="form-control" required={true} placeholder="Username ..." name="username" value={username} onChange={e => onChange(e)} />
                        </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Password</label>
                          <input type="password" className="form-control" required={true} placeholder="Password ..." name="password" value={password} onChange={e => onChange(e)} />
                        </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Konfirmasi Password</label>
                          <input type="password" className="form-control" required={true} placeholder="Konfirmasi Password ..." name="confirmPassword" value={confirmPassword} onChange={e => onChange(e)} />
                        </div>
                      </div>
                  </div>
              </Fragment>
          );
      }
    }

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        data.append("nama_image", file);
        data.append("status", status);
        data.append("nama_kategori", formData.nama_kategori);
        if(status === "2"){
          if (password !== confirmPassword) {
            alert("Password tidak sama");
            return;
          }
          data.append("nama", formData.nama);
          data.append("email", formData.email);
          data.append("no_telepon", formData.no_telepon);
          data.append("username", formData.username);
          data.append("password", formData.password);
        }
        createFirmanCategory(data, history);
    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/firman/category" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Kategori Firman</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create Kategori Firman {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Status</label>
                          <select className="form-control" required name="status" value={status} onChange={selectOnChange}>
                          <option value="">--Pilih--</option>
                            <option value="1">Umum</option>
                            <option value="2">Khusus</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Nama Kategori</label>
                          <textarea className="form-control" rows="3" placeholder="Nama Kategori ..." name="nama_kategori" value={nama_kategori} onChange={e => onChange(e)}></textarea>
                          {/* <input type="text" className="form-control" required placeholder="Nama Kategori ..." name="nama_kategori" value={nama_kategori} onChange={e => onChange(e)} /> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                        <label>Gambar Kategori</label>
                          <div className="imgPreviewUpload">
                          <img className="imgPreview" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar Kategori ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {cekStatus()}

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreateFirmanCategory.propTypes = {
    createFirmanCategory: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { createFirmanCategory })(withRouter(CreateFirmanCategory));
