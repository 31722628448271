import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/img_store.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { updateKaryaProduk } from "../../../actions/karyaAction";
import { getKaryaProdukById } from "../../../actions/karyaAction";
import { getKaryaCategory } from "../../../actions/karyaAction";
import { getPemimpin } from "../../../actions/pemimpinAction";
import SunEditor from 'suneditor-react';
import { SunEditorAtom } from '../../SunEditorAtom';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";
  const EditKaryaTokoProduct = ({ karya: { karyaproduk, loading }, updateKaryaProduk, getKaryaProdukById,  karya,pemimpin , getKaryaCategory, getPemimpin, auth: { user, token }, history }) => {
  //   const [valueActive, setValueActive] = useState({
  //     status: '1',
  // });
    const editor = useRef();
const {id} = useParams();
//const {idToko} = useParams();
const [idToko, setIdToko] = useState();
const [idProduk, setIdProduk] = useState();
    const [file, setFile] = useState();
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [fileDat1, setFileDat1] = useState(0);
    const [fileDat2, setFileDat2] = useState(0);
    const [fileDat3, setFileDat3] = useState(0);
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [imagePreviewUrl1, setImagePreviewUrl1] = useState(defaultImage);
    const [imagePreviewUrl2, setImagePreviewUrl2] = useState(defaultImage);
    const [values, setValues] = useState({
     status: '1',
   });
    const [deskripsi, setDeskripsi] = useState();


    const [formData, setFormData] = useState({
        nama_produk:'',
        kode_kategori:'',
        kode_pemimpin:'',
        shopee_url:'',
        tokopedia_url:'',
        blibli_url:'',
        lazada_url:'',
        bukalapak_url:'',
    });

    const {
      nama_produk,bukalapak_url,shopee_url,tokopedia_url,blibli_url,lazada_url,ukuran_produk,harga_produk,berat_produk,kondisi_produk,pembelian_minimum, pengiriman,deskripsi_endorser,kode_kategori,kode_pemimpin
    } = formData;

    useEffect(() => {
      setFormData({
        nama_produk:  karyaproduk && (karyaproduk.nama_produk ? karyaproduk.nama_produk : ''),
        ukuran_produk:  karyaproduk && (karyaproduk.ukuran_produk ? karyaproduk.ukuran_produk : ''),
        harga_produk:  karyaproduk && (karyaproduk.harga_produk ? karyaproduk.harga_produk : ''),
        berat_produk:  karyaproduk && (karyaproduk.berat_produk ? karyaproduk.berat_produk : ''),
        kondisi_produk:  karyaproduk && (karyaproduk.kondisi_produk ? karyaproduk.kondisi_produk : ''),
        pembelian_minimum:  karyaproduk && (karyaproduk.pembelian_minimum ? karyaproduk.pembelian_minimum : ''),
        pengiriman:  karyaproduk && (karyaproduk.pengiriman ? karyaproduk.pengiriman : ''),

        kode_pemimpin: karyaproduk && (karyaproduk.kode_pemimpin ? karyaproduk.kode_pemimpin : ''),
        kode_kategori: karyaproduk && (karyaproduk.kode_kategori ? karyaproduk.kode_kategori : ''),

        deskripsi_endorser: karyaproduk && (karyaproduk.deskripsi ? karyaproduk.deskripsi : ''),
        tokopedia_url: karyaproduk && (karyaproduk.tokopedia_url ? karyaproduk.tokopedia_url : ''),
        shopee_url: karyaproduk && (karyaproduk.shopee_url ? karyaproduk.shopee_url : ''),
        blibli_url: karyaproduk && (karyaproduk.blibli_url ? karyaproduk.blibli_url : ''),
        lazada_url: karyaproduk && (karyaproduk.lazada_url ? karyaproduk.lazada_url : ''),
        bukalapak_url: karyaproduk && (karyaproduk.bukalapak_url ? karyaproduk.bukalapak_url : ''),
         bukalapak_url: karyaproduk && (karyaproduk.bukalapak_url ? karyaproduk.bukalapak_url : ''),

      });

    setValues({ status: karyaproduk && (karyaproduk.status ? karyaproduk.status : '') });
     setDeskripsi((karyaproduk && (karyaproduk.deskripsi_produk ? karyaproduk.deskripsi_produk : '')));
  }, [loading, karyaproduk]);

  useEffect(() => {
    setImagePreviewUrl( karyaproduk && (karyaproduk.image_name1 ? karyaproduk.image_name1 : defaultImage));
    setImagePreviewUrl1( karyaproduk && (karyaproduk.image_name2 ? karyaproduk.image_name2 : defaultImage));
    setImagePreviewUrl2( karyaproduk && (karyaproduk.image_name3 ? karyaproduk.image_name3 : defaultImage));

  }, [loading, karyaproduk]);
//   useEffect(() => {
//     setValueActive({ status: karyaproduk && (karyaproduk.status ? karyaproduk.status : '') });
// }, [loading, karyaproduk, valueActive]);

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl);
        setFileDat1(1)
    }
    const fileOnChange1 = e => {
      setFile1(e.target.files[0]);
      const objectUrl1= URL.createObjectURL(e.target.files[0])
      setImagePreviewUrl1(objectUrl1);
      setFileDat2(1)
  }
  const fileOnChange2 = e => {
    setFile2(e.target.files[0]);
    const objectUrl2= URL.createObjectURL(e.target.files[0])
    setImagePreviewUrl2(objectUrl2);
    setFileDat3(1)
}


useEffect(() => {
  let decodeString = Buffer.from(id, 'base64').toString('ascii');
  getKaryaProdukById( decodeString.split(';')[1]);
    setIdToko(decodeString.split(';')[0]);
    setIdProduk(decodeString.split(';')[1]);
    }, [getKaryaProdukById, idToko,idProduk]);


useEffect(() => {
  getKaryaCategory();
}, [getKaryaCategory]);



useEffect(() => {
  getPemimpin();
}, [getPemimpin]);

//  const setStatusActive = (event) => {
//         //console.log(event.target.value);
//         setValueActive({ ...valueActive, status: event.target.value });
//         //console.log(values);
//     };
//     useEffect(() => {
//         console.log("VAL: " + valueActive.status);

//     }, [valueActive]);
// useEffect(() => {
//   let decodeString3 = Buffer.from(idToko, 'base64').toString('ascii');
//  // setIdToko(decodeString3.split(';')[0]);
// }, [idToko]);

function uploadImageCallBack(file, uploadHandler) {
  const uploadFile = file[0];
  return new Promise(
    (resolve, reject) => {
      const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
      xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
      xhr.setRequestHeader('x-auth-token', token);
      const data = new FormData(); // eslint-disable-line no-undef
      data.append('file', uploadFile);
      data.append("tautan", API_URL_IMAGE + '/ckeditor/')
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        console.log('OK: ' + response);
        uploadHandler(response);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    },
  );
}

const setStatus = (event) => {
  //console.log(event.target.value);
  setValues({ ...values, status: event.target.value });
  //console.log(values);
};

    const onSubmit = async (e) => {
        e.preventDefault();
        //setLoadings(true);
        const data = new FormData();
        if (fileDat1>0){
           data.append("nama_image[]", file);
        }
       // if(file=)
       if (fileDat2>0){
         data.append("nama_image[]", file1);
      }
      if (fileDat3>0){
        data.append("nama_image[]", file2);
      }

      console.log("ID tokopedia_url", formData.kode_pemimpin);
        data.append("nama_produk", formData.nama_produk);
        data.append("kode_kategori", formData.kode_kategori);
        data.append("kode_toko", idToko);
        data.append("ukuran_produk", formData.ukuran_produk );
        data.append("harga_produk", formData.harga_produk);
        data.append("berat_produk", formData.berat_produk);
        data.append("kondisi_produk", formData.kondisi_produk);
        data.append("pembelian_minimum", formData.pembelian_minimum);
        data.append("deskripsi_produk", deskripsi);
        data.append("tokopedia_url", formData.tokopedia_url);
        data.append("bukalapak_url", formData.bukalapak_url);
        data.append("blibli_url", formData.blibli_url);
        data.append("lazada_url", formData.lazada_url);
        data.append("shopee_url", formData.shopee_url);
        data.append("pengiriman", formData.pengiriman);
        data.append("kode_pemimpin", formData.kode_pemimpin);
        data.append("deskripsi_endorser", formData.deskripsi_endorser);
        data.append("status",  values.status);
          updateKaryaProduk(data,idProduk,idToko,history);
    }

  function handleImageUploadBefore(files, info, uploadHandler){
    // uploadHandler is a function
    console.log('UPLOAD: ' + files, info);

    try {
      uploadImageCallBack(files, uploadHandler);
    } catch (err) {
        uploadHandler(err.toString());
    }
  }

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;

  };
  const handleCategoryChange = e => {
    setFormData({ ...formData,
        kode_kategori: e.target.options[e.target.selectedIndex].value,
        nama_kategori: e.target.options[e.target.selectedIndex].text
    });
  }
  const handlePemimpinChange = e => {
    setFormData({ ...formData,
        kode_pemimpin: e.target.options[e.target.selectedIndex].value,
        nama: e.target.options[e.target.selectedIndex].text,
       // deskripsi_endorser:""
    });
    //console.log("ID tokopedia_url", e.target.options[e.target.selectedIndex].value);

  }
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                <Link to={`/karya/toko/produk/${idToko}`}  className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Karya Produk</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Tambah Produk</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />

                  <div className="card">
                    <div className="card-body">
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status </label>
                          <div class="jss213-small">Produk Active Tidak Active </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">

                        <div onChange={setStatus}>
                                  <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                  <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                              </div>
                        </div>
                      </div>
                      </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Foto Produk *</label>
                          <div class="jss213-small">Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px  </div>
                        </div>
                      </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                        <label for="imgInp1" className="imgPreviewUpload2">
                          <img className="imgPreview100" src={imagePreviewUrl} />
                          <div >
                          <input type="file"  id="imgInp1" className="hidden"  placeholder="Gambar Utama ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="form-group">
                        <label for="imgInp2" className="imgPreviewUpload2">
                          <img className="imgPreview100" src={imagePreviewUrl1} />
                          <div >
                          <input type="file"  id="imgInp2" className="hidden" placeholder="Gambar Utama ..." name="nama_image1"  onChange={fileOnChange1} />
                          </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="form-group">
                        <label for="imgInp3" className="imgPreviewUpload2">
                          <img className="imgPreview100" src={imagePreviewUrl2} />
                          <div >
                          <input type="file"  id="imgInp3" className="hidden" placeholder="Gambar Utama ..." name="nama_image2"  onChange={fileOnChange2} />
                          </div>
                          </label>
                        </div>
                      </div>
                    </div>



                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Nama Produk *</label>
                          <div class="jss213-small">agar produk semakin menarik cantumkan spesifikasinya </div>

                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <input type="text" className="form-control" required placeholder="Nama Produk ..." name="nama_produk" value={nama_produk} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Kategori *</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <select className="form-control" required name="kode_kategori" value={kode_kategori} onChange={handleCategoryChange}>
                                  <option value="">--Pilih--</option>
                                      {
                                          karya && karya.karyaCategory2.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama_kategori}</option>)
                                      }
                              </select>
                        </div>
                      </div>
                    </div>


                    </div>
                   </div>

                   <div className="card">
                    <div className="card-body">
                  <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Kondisi Produk</label>
                          <div class="jss213-small">Baru atau Bekas </div>
                        </div>
                      </div>
                  <div className="col-sm-9">
                        <div className="form-group">
                        <input type="text" className="form-control"  placeholder="Kondisi Produk ..." name="kondisi_produk" value={kondisi_produk} onChange={e => onChange(e)} />
                            {/* <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === 1}/>&nbsp;Baru&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === 2}/>&nbsp;&nbsp;&nbsp;Link Youtube
                            </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Deskripsi *</label>
                          <div class="jss213-small">Pastikan deskripsi produk memuat spesifikasi semakin mudah dimengerti dan dipahami pembeli </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <SunEditor
                          autoFocus={true}
                          lang="en"
                          width='100%'
                          height='300px'
                          setOptions={{
                            minWidth : '450px',
                            showPathLabel: false,
                            placeholder: "Enter your text here!!!",
                            plugins: [
                              align,
                              font,
                              fontColor,
                              fontSize,
                              formatBlock,
                              hiliteColor,
                              horizontalRule,
                              lineHeight,
                              list,
                              paragraphStyle,
                              table,
                              template,
                              textStyle,
                              image,
                              link
                            ],
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize", "formatBlock"],
                              ["paragraphStyle"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                              ],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              "/", // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["table", "link", "image"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                              "Arial",
                              "Calibri",
                              "Comic Sans",
                              "Courier",
                              "Garamond",
                              "Georgia",
                              "Impact",
                              "Lucida Console",
                              "Palatino Linotype",
                              "Segoe UI",
                              "Tahoma",
                              "Times New Roman",
                              "Trebuchet MS"
                            ]
                          }}
                          getSunEditorInstance={getSunEditorInstance}
                          onImageUploadBefore={handleImageUploadBefore}
                          setContents={deskripsi}
                         onChange={setDeskripsi}

                        />
                        </div>
                       <div style={{zIndex: -999, position: 'absolute'}}>
                       <SunEditorAtom initialContent={deskripsi}/>
                   
                        </div>
                      </div>
                    </div>



                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Harga Satuan *</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Rp</span>
                      </div>
                      <input type="text" className="form-control" required placeholder="0.0" name="harga_produk" value={harga_produk} onChange={e => onChange(e)} />
                      </div>
                      </div>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Berat Produk</label>
                          <div class="jss213-small">Berat Kilogram/Gram ex: 1Kg atau 100gram  </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                      <input type="text"  className="form-control"  placeholder="Berat Produk ..." name="berat_produk" value={berat_produk} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Ukuran Produk</label>
                          <div class="jss213-small">Ukuran centimeter/meter ex: 5cm </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                      <input type="text"  className="form-control"  placeholder="Ukuran Produk ..." name="ukuran_produk" value={ukuran_produk} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Minimum Pemesanan</label>
                          <div class="jss213-small">Minimum pembelian untuk produk ini </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                      <input type="text"  className="form-control"  placeholder="pembelian Minimum ..." name="pembelian_minimum" value={pembelian_minimum} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Pengiriman</label>
                          <div class="jss213-small">Dalam kota, luar kota</div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                      <input type="text"  className="form-control"  placeholder="Pengiriman ..." name="pengiriman" value={pengiriman} onChange={e => onChange(e)} />
                      </div>
                    </div>


                    </div>
                   </div>

                   <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Penjualan Produk di toko Online</label>
                          </div>
                        </div>
                        </div>
                        <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Link Tokopedia</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Tokopedia..." name="tokopedia_url" value={tokopedia_url} onChange={e => onChange(e)} />
                      </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Link Shoope</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Shoope ..." name="shopee_url" value={shopee_url} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    </div>


                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Link Bukalapak</label>
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Bukalapak ..." name="bukalapak_url" value={bukalapak_url} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Link Blibli.com</label>
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Blibli ..." name="blibli_url" value={blibli_url} onChange={e => onChange(e)} />
                      </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Link Lazada</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Lazada ..." name="lazada_url" value={lazada_url} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Endorser</label>
                          </div>
                        </div>
                        </div>
                        <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Pemimpin Gereja</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">


                  <select className="form-control"  name="kode_pemimpin" value={kode_pemimpin} onChange={handlePemimpinChange}>
                                  <option value="">--Pilih--</option>
                                      {
                                          pemimpin && pemimpin.pemimpin2.map((pem, index) => <option key={pem.kode_pemimpin} value={pem.kode_pemimpin}>{pem.nama}</option>)
                                      }
                              </select>
                      </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Deskripsi</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <textarea className="form-control" rows="3"  placeholder="Deskripsi Endorser ..." name="deskripsi_endorser" value={deskripsi_endorser} onChange={e => onChange(e)}></textarea>
                      </div>
                    </div>


                    </div>
                    </div>








                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditKaryaTokoProduct.propTypes = {
updateKaryaProduk: PropTypes.func.isRequired,
  getKaryaProdukById: PropTypes.func.isRequired,
  getKaryaCategory: PropTypes.func.isRequired,
  getPemimpin: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    karya: state.karya,
    pemimpin:state.pemimpin

});

export default connect(mapStateToProps, { updateKaryaProduk,getKaryaProdukById,getKaryaCategory,getPemimpin })(withRouter(EditKaryaTokoProduct));
