import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../../config/apiUrl";
import Header from "../../../layouts/Header";
import Navbar from "../../../layouts/Navbar";
import Footer from "../../../layouts/Footer";
import Alert from "../../../alert/Alert";
import { createPembicara } from "../../../../actions/pembicaraAction";
import defaultImage from "../../../auth/styles/images/noImage200.png";
import "../../../auth/styles/css/main.css";

const CreatePembicara = ({ createPembicara, auth: { user }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [formData, setFormData] = useState({
        kode_gereja: '',
        nama: '',
        gelar: '',
        no_handphone: '',
        namafile_image: '',
        tautan: API_URL_IMAGE + '/user/',
        keterangan: '',
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
    });


    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        setNamaGereja(decodeString.split(';')[1]);
    }, [id]);

    useEffect(() => {
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
        user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0) ? setFormData({kode_gereja: decodeString2.split(';')[0]}) : user && (setFormData({kode_gereja: user.gereja[0].kode_gereja}))
      }, [user, id]);

    const {
        kode_gereja,
        nama,
        gelar,
        no_handphone,
        keterangan,
        instagram,
        facebook,
        twitter,
        youtube,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        data.append("namafile_image", file);
        data.append("kode_gereja", formData.kode_gereja);
        data.append("nama", formData.nama);
        data.append("gelar", formData.gelar);
        data.append("no_handphone", formData.no_handphone);
        if(typeof formData.keterangan !== 'undefined'){
            data.append("keterangan", formData.keterangan);
        }
        if(typeof formData.instagram !== 'undefined'){
            data.append("instagram", formData.instagram);
        }
        if(typeof formData.facebook !== 'undefined'){
            data.append("facebook", formData.facebook);
        }
        if(typeof formData.twitter !== 'undefined'){
            data.append("twitter", formData.twitter);
        }
        if(typeof formData.youtube !== 'undefined'){
            data.append("youtube", formData.youtube);
        }

        console.log('KETERANGAN: ' + formData.keterangan);


        createPembicara(data, history);
    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/jadual/Pembicara" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <Link to="/jadual/kegiatan">Jadual</Link>
                    </li>
                    <li className="breadcrumb-item active">Pembicara</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create Pembicara {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Nama Pembicara</label>
                            <input type="hidden" className="form-control" name="kode_gereja" value={kode_gereja} onChange={e => onChange(e)} />
                            <input type="text" className="form-control" placeholder="Nama Pembicara ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                            <label>Gelar</label>
                            <input type="text" className="form-control" placeholder="Gelar ..." name="gelar" value={gelar} onChange={e => onChange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                            <label>No. Handphone</label>
                            <input type="text" className="form-control" placeholder="No. Handphone ..." name="no_handphone" value={no_handphone} onChange={e => onChange(e)} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Photo</label>
                            <div className="imgPreviewUpload">
                      <img className="imgPreview200" src={imagePreviewUrl} />
                      <div >
                      <input type="file"  placeholder="Photo ..." name="namafile_image"  onChange={fileOnChange} />
                      </div>
                      </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Keterangan</label>
                            {/* <input type="text" className="form-control" placeholder="Keterangan ..." name="keterangan" value={keterangan} onChange={e => onChange(e)} /> */}
                            <textarea className="form-control" rows="5" placeholder="Keterangan ..." name="keterangan" value={keterangan} onChange={e => onChange(e)}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Instagram</label>
                      <input type="text" className="form-control" placeholder="Instagram ..." name="instagram" value={instagram} onChange={e => onChange(e)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Facebook</label>
                      <input type="text" className="form-control" placeholder="Facebook ..." name="facebook" value={facebook} onChange={e => onChange(e)} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Twitter</label>
                      <input type="text" className="form-control" placeholder="Twitter ..." name="twitter" value={twitter} onChange={e => onChange(e)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Youtube</label>
                      <input type="text" className="form-control" placeholder="Youtube ..." name="youtube" value={youtube} onChange={e => onChange(e)} />
                    </div>
                  </div>
                </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreatePembicara.propTypes = {
    createPembicara: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { createPembicara })(withRouter(CreatePembicara));
