import React, { Fragment } from "react";

import { Link, Redirect } from "react-router-dom";


import "../auth/styles/vendor/bootstrap/css/bootstrap.min.css";
import "../auth/styles/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "../auth/styles/fonts/Linearicons-Free-v1.0.0/icon-font.min.css";
import "../auth/styles/vendor/animate/animate.css";
import "../auth/styles/vendor/css-hamburgers/hamburgers.min.css";
import "../auth/styles/vendor/animsition/css/animsition.min.css";
import "../auth/styles/vendor/select2/select2.min.css";
import "../auth/styles/vendor/daterangepicker/daterangepicker.css";
import "../auth/styles/css/util.css";
import "../auth/styles/css/main.css";
import logo from "../auth/styles/images/logo-gkmi.png";

const Policy = () => {
  

  const imgContainer = {
    textAlign: "center",
    margin: "10px 0 0 0",
  };

  const imgAvatar = {
    width: "38%",
  };

  const labelStyle = {
    display: "inline-block",
    marginBottom: ".5rem",
  };



  return (
    <Fragment>
      <div style={{ backgroundColor: "#666666" }}>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form validate-form"
                style={{ paddingTop: "15px" }}

              >
                <div style={imgContainer}>
                  <img src={logo} alt="GKMI" style={imgAvatar} />
                </div>
                <blockquote
                  className="blockquote"
                  style={{
                    fontSize: "17px",
                    borderLeft: "3px solid #dc241c",
                    borderRight: "3px solid #dc241c",
                    padding: "5px 5px",
                    fontWeight: "bold",
                    backgroundColor: "#005da3",
                  }}
                >
                  <center>
                    <span style={{ color: "#ffffff" }}>GKMI ONE Privacy Policy</span>
                  </center>
                </blockquote>



                <div className="container-login100-form-btn">
                    <div style={{ overflowY: 'auto', height: '275px' }}>

                        <p>Your privacy is important to us. It is GKMI One policy to respect your privacy regarding any information we may collect from you through our app, GKMI One.</p>
                        <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
                        <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
                        <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
                        <p>Our app may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
                        <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
                        <p>Your continued use of our app will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
                        <p>This policy is effective as of 1 January 2021.</p>
                    </div>
                </div>
                <div style={{paddingTop: '10px'}}>
                    <center><Link to="/" >Kembali ke Login</Link></center>
                </div>
              </form>
              <div
                className="login100-more"
                style={{
                  backgroundImage:
                    "url(" + require(`../auth/styles/images/bg-01.jpg`) + ")",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};



export default Policy;
