import { GET_FIRMAN_CATEGORY3, FIRMAN_CATEGORY_ERROR } from "../actions/types";

const initialState = {
    firmanCategory3: [],
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_FIRMAN_CATEGORY3:
            return{
                ...state,
                firmanCategory3: payload,
                loading: false
            };
        case FIRMAN_CATEGORY_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}