import { GET_GEREJA_JADWAL_KEGIATAN, GEREJA_JADWAL_KEGIATAN_ERROR } from "../actions/types";

const initialState = {
    jadwalKegiatan: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_GEREJA_JADWAL_KEGIATAN:
            return{
                ...state,
                jadwalKegiatan: payload,
                loading: false
            };
        case GEREJA_JADWAL_KEGIATAN_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}