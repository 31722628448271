import React, { Fragment, useState, useEffect, } from "react";
import { MDBDataTable } from "mdbreact";
import {useParams, Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import parse from 'html-react-parser';
import moment from "moment";

const ConnectOperation = ({ auth: { user, token } }) => {
    const [dataProject, setProject] = useState([]);
    const [status, setStatus] = useState(true);
    const {id} = useParams();
    useEffect(() =>{
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        let kode_project = decodeString.split(';')[0];
        fetchData(kode_project);
   }, [user,id]);

   const fetchData = async (kode_project) => {

        let link='';

         link = `https://gkmi.one:8080/api/connectbackend/connect_operation/${kode_project}`;
         console.log("success",link);

     const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
        setProject(response.data);
   }


   const data = {
    columns: [
                    {
                        label: "No.",
                        field: "no",
                        sort: "asc",
                        width: 10,
                    },


                    {
                        label: "Topic",
                        field: "topic",
                        sort: "asc",
                        width: 150,
                    },
                    {
                        label: "Pembicara",
                        field: "pembicara",
                        sort: "asc",
                        width: 150,
                    },
                    {
                        label: "Tanggal",
                        field: "tanggal",
                        sort: "asc",
                        width: 30,
                    },
                    {
                        label: "Peserta",
                        field: "peserta",
                        sort: "asc",
                        width: 30,
                    },
                    {
                        label: "Status",
                        field: "status",
                        sort: "asc",
                        width: 30,
                    },

                  ],
              rows: dataProject.map((apiData, i) => {

                var active;
                  var classStatus;
                  if(apiData.status==1){
                    active="Offline";
                    classStatus="badge bg-gray"
                        }else{
                            active="Online";
                            classStatus="badge badge-danger"
                        }

                return({
                  no: (i+1),

                  topic: <span>{apiData.judul_topik}</span>,
                  pembicara: <span>{apiData.nama_pembicara}</span>,
                  tanggal: <span>{moment(apiData.tanggal_operasi).format("dddd, MMMM Do YYYY")}</span>,
                  peserta: <span>{apiData.peserta}</span>,
                   status:<span class={classStatus}>{active}</span>,

    });
  }),
};
    function cekStatus(){
        if(status){
            return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-4">
                    <div className="col-sm-2">
                    <Link to={`/connect`} className="btn btn-block bg-gradient-danger">Back</Link>
                </div>

                        <div className="col-sm-10">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardPIPKA">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Jadwal Connect</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                {/* Display Berita */}
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Jadwal Connect</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};


const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(ConnectOperation);
