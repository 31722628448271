import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/authAction";
import {API_URL_IMAGE} from '../../config/apiUrl'
const Header = ({ logout, auth: { user } }) => {

  const [toggle, setToggle]= useState(false);

  const showMenu = () => {
    setToggle(true);
    if(toggle === true){
      document.body.className = document.body.className.replace("sidebar-open","sidebar-closed sidebar-collapse");
      setToggle(false);
    }

  }

  const hideMenu = () => {

    if(toggle === false) {
      document.body.className = document.body.className.replace("sidebar-closed","sidebar-open");
      setToggle(true);
    }
  }

  useEffect(() => {
  }, [toggle]);

  return (
    <Fragment>
      {/* Navbar */}
      <div onClick={showMenu}>
      <nav className="main-header navbar navbar-expand navbar-danger">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a onClick={hideMenu}
              className="nav-link"
              data-widget="pushmenu"
              href="fake_url"
              role="button"
            >
              <i className="fas fa-bars" style={{color: "#ffffff"}} />
            </a>
          </li>
        </ul>

        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Notifications Dropdown Menu */}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="fake_url">
              <img
                src={API_URL_IMAGE + '/' + (user && (user.avatar !== '' ? 'user/' + user.gereja[0].logo : 'avatar.jpg'))}
                className="user-image"
                alt="User"
              />
              <span style={{color: "#ffffff"}}>{user && user.nama}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <a href="#!" className="dropdown-item">
                <i className="fas fa-user mr-2" /> Profile
              </a>
              <a href="/change-password" className="dropdown-item">
                <i className="fas fa-key" /> Change Password
              </a>
              <div className="dropdown-divider" />
              <a onClick={logout} href="#!" className="dropdown-item">
                <i className="fas fa-lock mr-2" /> Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      </div>
      {/* /.navbar */}
    </Fragment>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Header);
