import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { GET_JEMAAT, GET_JEMAAT2, JEMAAT_ERROR } from "./types";

// Get Jemaat
export const getJemaat = (kode_gereja) => async dispatch => {
    try{
        const res = await axios.get(`/api/profile/jemaat/${kode_gereja}`);

        dispatch({
            type: GET_JEMAAT,
            payload: res.data[0].jemaat,
        });
    }catch(err){
        dispatch({
            type: JEMAAT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}
//get Jemaat Gereja
export const getJemaat2 = () => async dispatch => {
    try{
        const res = await axios.get(`/api/profile/jemaatgereja`);

        dispatch({
            type: GET_JEMAAT2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: JEMAAT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Create Jemaat User
export const createJemaat = (formData, history) => async dispatch => {
    try{
		const res = await axios.post(`/api/profile/jemaat`, formData);
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }
        history.push('/jemaat');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: JEMAAT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Get Jemaat by Id
export const getJemaatById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/profile/jemaat/editprofile/${id}`);
       // console.log('OK: ' + res.data);
        //console.log("Data Masuk : "+JSON.stringify(res.data[0]));
        dispatch({
            type: GET_JEMAAT,
            payload: res.data[0].profile[0],
        });
    }catch(err){
        dispatch({
            type: JEMAAT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
        dispatch({
            type: GET_JEMAAT,
            payload: '',
        });
    }
}
// Update Jemaat Berdasarkan ID
export const updateJemaat = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/profile/jemaat/editprofile/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/jemaat');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: JEMAAT_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
