import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { getPipkaHomeSliderById } from "../../../actions/pipkaAction";
import { updateHomeSlider } from "../../../actions/pipkaAction";

const EditHomeSlider = ({ pipka: { loading, pipkaHomeSlider }, updateHomeSlider,getPipkaHomeSliderById, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [loadings, setLoadings] = useState(false);
    const [fileName, setFileName] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [formData, setFormData] = useState({

        urutan:"",
    });

    useEffect(() => {

        setFormData({

            urutan:pipkaHomeSlider && (pipkaHomeSlider.urutan ? pipkaHomeSlider.urutan : ''),
        });

      }, [loading, pipkaHomeSlider]);


      useEffect(() => {
        setFileName(pipkaHomeSlider && (pipkaHomeSlider.namafile ? pipkaHomeSlider.namafile : ''));
        console.log('NAMA: ' + fileName);
        setImagePreviewUrl((pipkaHomeSlider && (pipkaHomeSlider.tautan ? pipkaHomeSlider.tautan : ''))+fileName);
    }, [loading, pipkaHomeSlider, fileName]);

      useEffect(() => {
        // let decodeString = Buffer.from(id, 'base64').toString('ascii');
        // getGerejaSliderById(decodeString.split(';')[0]);
        // setNamaGereja(decodeString.split(';')[2]);
        getPipkaHomeSliderById(Buffer.from(id, 'base64').toString('ascii'));
      }, [getPipkaHomeSliderById, id]);


    const {

        urutan,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();

        data.append("urutan", urutan);
        data.append("image_slider", file);
        updateHomeSlider(data, Buffer.from(id, 'base64').toString('ascii'), history);

    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/pipka/pipka-slider" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardPIPKA">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <Link to="/pipka/pipka-slider">Home Slider</Link>
                    </li>

                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Home Slider {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Urutan</label>
                      <select className="form-control" required name="urutan" value={urutan} onChange={e => onChange(e)}>
                      <option value="">--Pilih--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>

                      </select>
                    </div>
                  </div>
                </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <center>
                            <img style={{border: "3px solid #adb5bd", padding: "3px"}} src={imagePreviewUrl}
                                className="img-fluid mb-3"
                                alt="Gereja"
                            />
                            </center>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Image slider</label>

                            <input type="file" className="form-control" placeholder="Image slider ..." name="namafile" onChange={fileOnChange} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditHomeSlider.propTypes = {
    updateHomeSlider: PropTypes.func.isRequired,
    getPipkaHomeSliderById: PropTypes.func.isRequired,
    // pipkaHomeSlider: PropTypes.object.isRequired

}
const mapStateToProps = state => ({
    auth: state.auth,
    pipka: state.pipka

});

export default connect(mapStateToProps, { updateHomeSlider, getPipkaHomeSliderById })(withRouter(EditHomeSlider));
