import React, { Fragment, useState, useEffect,useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import { default as ReactSelect } from "react-select";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import axios from 'axios';
//import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';
import { getBeritaGkmiKategori2 } from "../../../actions/beritaGkmiAction";
import { getBeritaGkmiEdisi2 } from "../../../actions/beritaGkmiAction";
import { getBeritaGkmiKontributor2 } from "../../../actions/beritaGkmiAction";
import { updateBeritaGkmi } from "../../../actions/beritaGkmiAction";
import { getBeritaGkmiById } from "../../../actions/beritaGkmiAction";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { SunEditorAtom } from '../../SunEditorAtom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  video,
  link
} from "suneditor/src/plugins";

const EditBeritaGkmi = ({ beritaGkmi: { loading, beritaGkmi }, beritaGkmiData,getBeritaGkmiById, updateBeritaGkmi,getBeritaGkmiKategori2,getBeritaGkmiEdisi2,getBeritaGkmiKontributor2, auth: { user ,token}, history }) => {
  const editor = useRef();
      const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [loadings, setLoadings] = useState(false);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [selectdata, setSelect] = useState();
    const [selectdatapenulis, setSelectData] = useState();
    const [selectBerita, setSelectBerita] = useState();
    const [values, setValues] = useState({
        status: '1',
      });
      const [valuep, setPayment] = useState({
        payment: '1',
      });
    const [formData, setFormData] = useState({
      judul_berita:'',
      deskripsi:'',
      kode_kategori:'',
      kode_edisi:'',
      kode_kontributor:'',
      deskripsi_foto:'',

    });
    const [isiberita, setIsiBerita] = useState();
    useEffect(() => {
        setFormData({
          judul_berita: beritaGkmi && (beritaGkmi.judul_berita ? beritaGkmi.judul_berita : ''),
          deskripsi: beritaGkmi && (beritaGkmi.deskripsi ? beritaGkmi.deskripsi : ''),
            status: beritaGkmi && (beritaGkmi.active ? beritaGkmi.active : ''),
            payment: beritaGkmi && (beritaGkmi.payment ? beritaGkmi.payment : ''),
            kode_kategori: beritaGkmi && (beritaGkmi.kode_kategori ? beritaGkmi.kode_kategori : ''),
            kode_edisi: beritaGkmi && (beritaGkmi.kode_edisi ? beritaGkmi.kode_edisi : ''),
            kode_kontributor: beritaGkmi && (beritaGkmi.kontributor ? beritaGkmi.kontributor : ''),
            deskripsi_foto: beritaGkmi && (beritaGkmi.deskripsi_foto ? beritaGkmi.deskripsi_foto : ''),
            selectOptions:beritaGkmi && beritaGkmi.multi_kontributor.map(d => ({
             value : d.id_kontributor,
             label : d.nama_kontributor
           })),
           selectBeritaTerkait:beritaGkmi && beritaGkmi.beritaterkait.map(d => ({
             value : d.id_berita,
             label : d.nama_berita
           }))
        });

      setIsiBerita((beritaGkmi && (beritaGkmi.isi_berita ? beritaGkmi.isi_berita : '')));
      var content = beritaGkmi && (beritaGkmi.isi_berita ? beritaGkmi.isi_berita : '');
       setBeritaByArtikel(beritaGkmi && (beritaGkmi.kode_edisi ? beritaGkmi.kode_edisi : ''));

    const fixedHTML =  content ? content.toString().replace(/<figure.*>/gi, "\n") : '';
      const blocksFromHtml = htmlToDraft("<p>"+fixedHTML+"</p>");
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
        setValues({ status: beritaGkmi && (beritaGkmi.active.toString()) });
        setPayment({ payment: beritaGkmi && (beritaGkmi.status.toString()) });
    }, [loading, beritaGkmi]);
    useEffect(() => {

         setFileName( beritaGkmi && (beritaGkmi.images[0].nama_image ? beritaGkmi.images[0].nama_image : ''));
         setImagePreviewUrl( beritaGkmi && (beritaGkmi.images[0].tautan ? beritaGkmi.images[0].tautan + '/' +fileName : ''));
       }, [loading, beritaGkmi, fileName]);


    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
      console.log("id "+decodeString.split(';')[0])
      getBeritaGkmiById( decodeString.split(';')[0]);
      getBeritaGkmiKategori2();
       getBeritaGkmiEdisi2();
       getBeritaGkmiKontributor2();
      }, [getBeritaGkmiById,getBeritaGkmiKategori2,getBeritaGkmiEdisi2,getBeritaGkmiKontributor2, id]);
      useEffect(() => {
            setSelectData(  beritaGkmi && beritaGkmi.multi_kontributor.map(d => ({
              value : d.id_kontributor,
              label : d.nama_kontributor
            })));



          }, [beritaGkmi]);
          useEffect(() => {
    setSelect( beritaGkmiData && beritaGkmiData.beritaGkmiKontributor3.map(d => ({
      value : d._id,
      label : d.nama
    })));

  }, [beritaGkmiData]);
    const {
      judul_berita,
      kode_kategori,
      kode_edisi,
      kode_kontributor,
      deskripsi,
      deskripsi_foto,
      selectOptions,
      selectBeritaTerkait
    } = formData;
    const setBeritaByArtikel =  async (idEdisi) => {
          let link='';


        link = 'https://gkmi.one:8080/api/beritagkmibackend/beritaByArtikel/'+idEdisi;
        console.log(link);

        const response = await axios.get(link, {
              headers: {
                  'x-auth-token': token
              }
          });
          setSelectBerita( response.data.map(d => ({
            value : d.id,
            label : d.judul_berita
          })))


        };
    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const onSubmit = async (e) => {
        e.preventDefault();
        //setLoadings(true);
        var varpayment;
        if(valuep.payment>1){
          varpayment="pay";
        }else{
          varpayment="free";
        }
        const data = new FormData();
        data.append("images", file);
        data.append("kode_kategori", formData.kode_kategori);
        data.append("kode_edisi", formData.kode_edisi);
        data.append("judul_berita", formData.judul_berita);
        data.append("deskripsi", formData.deskripsi);
        data.append("isi_berita", isiberita);
        data.append("kontributor", formData.kode_kontributor);
        data.append("status", valuep.payment);
         data.append("status_name", varpayment);
        data.append("active", values.status);
       data.append("deskripsi_foto", formData.deskripsi_foto);
         data.append("multi_kontributor", JSON.stringify(selectOptions));
          data.append("beritaterkait", JSON.stringify(selectBeritaTerkait));
        updateBeritaGkmi(data,  Buffer.from(id, 'base64').toString('ascii'), history);

    }
    const setStatus = (event) => {
        //console.log(event.target.value);
        setValues({ ...values, status: event.target.value });
        //console.log(values);
      };
      const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }
    const handleCategoryChange = e => {
      setFormData({ ...formData,
          kode_kategori: e.target.options[e.target.selectedIndex].value,
          nama_kategori: e.target.options[e.target.selectedIndex].text
      });
    }
    const handleEdisiChange = e => {
      setBeritaByArtikel(e.target.options[e.target.selectedIndex].value);
      setFormData({ ...formData,
          kode_edisi: e.target.options[e.target.selectedIndex].value,
          nama_edisi: e.target.options[e.target.selectedIndex].text,
           selectBeritaTerkait:[]
      });
    }
    const handleKontributorChange = e => {
      setFormData({ ...formData,
          kode_kontributor: e.target.options[e.target.selectedIndex].value,
          nama_kontributor: e.target.options[e.target.selectedIndex].text
      });
    }
    const handleKontributorMultipleChange = e => {
         setFormData({ ...formData,
           selectOptions:e
         });

       }
       const handleEdisiMultipleChange = e => {
      setFormData({ ...formData,
        selectBeritaTerkait:e
      });

    }
  function uploadImageCallBack(file, uploadHandler) {
      const uploadFile = file[0];
      return new Promise(
        (resolve, reject) => {
          const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
          xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
          xhr.setRequestHeader('x-auth-token', token);
          const data = new FormData(); // eslint-disable-line no-undef
          data.append('file', uploadFile);
          data.append("tautan", API_URL_IMAGE + '/ckeditor/')
          xhr.send(data);
          xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText);
            console.log('OK: ' + response);
            uploadHandler(response);
            resolve(response);
          });
          xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
          });
        },
      );
    }
    function handleImageUploadBefore(files, info, uploadHandler){
      // uploadHandler is a function
      console.log('UPLOAD: ' + files, info);

      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString());
      }
    }

    const getSunEditorInstance = (sunEditor) => {
      editor.current = sunEditor;

    };
    const setPaymentStatus = (event) => {
      //console.log(event.target.value);
      setPayment({ ...valuep, payment: event.target.value });
      //console.log(values);
      // setValPay('free')
    };
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Edisi Berita</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Berita {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                      <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                      <label>Status Berita </label>
                      <div class="jss213-small">Geratis/Bayar</div>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div onChange={setPaymentStatus}>
                      <input type="radio" value="1" name="payment" checked = {valuep.payment === '1'}/>&nbsp;Gratis&nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio" value="2" name="payment" checked = {valuep.payment === '2'}/>&nbsp;Bayar &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                      <label>Kategori </label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <select className="form-control" required name="kode_kategori" value={kode_kategori} onChange={handleCategoryChange}>
                   <option value="">--Pilih--</option>
                                        {
                                            beritaGkmiData && beritaGkmiData.beritaGkmiKategori3.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama_kategori}</option>)
                                        }
                                </select>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                      <label>Edisi </label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <select className="form-control" required name="kode_edisi" value={kode_edisi} onChange={handleEdisiChange}>
                   <option value="">--Pilih--</option>
                                        {
                                            beritaGkmiData && beritaGkmiData.beritaGkmiEdisi3.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama}</option>)
                                        }
                                </select>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Judul Berita</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Judul Berita ..." required name="judul_berita" value={judul_berita} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Deskription Berita</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <textarea className="form-control" rows="3" required placeholder="Deskripsi ..." name="deskripsi" value={deskripsi} onChange={e => onChange(e)}></textarea>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Isi Berita</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div style={{position: 'relative'}}>
                    <div style={{zIndex: 1}}>
                        <SunEditor
                            autoFocus={true}
                            lang="en"
                            width='100%'
                            height='450px'
                            setOptions={{
                            minWidth : '450px',
                            showPathLabel: false,
                            placeholder: "Enter your text here!!!",
                            plugins: [
                                align,
                                font,
                                fontColor,
                                fontSize,
                                formatBlock,
                                hiliteColor,
                                horizontalRule,
                                lineHeight,
                                list,
                                paragraphStyle,
                                table,
                                template,
                                textStyle,
                                image,
                                video,
                                link
                            ],
                            buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                                ],
                                ["fontColor", "hiliteColor"],
                                ["removeFormat"],
                                "/", // Line break
                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image","video",]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                                "Arial",
                                "Calibri",
                                "Comic Sans",
                                "Courier",
                                "Garamond",
                                "Georgia",
                                "Impact",
                                "Lucida Console",
                                "Palatino Linotype",
                                "Segoe UI",
                                "Tahoma",
                                "Times New Roman",
                                "Trebuchet MS"
                            ]
                            }}
                            onImageUploadBefore={handleImageUploadBefore}
                            setContents={isiberita}
                            onChange={setIsiBerita}

                        />

                    </div>

                    <div style={{zIndex: -999, position: 'absolute'}}>
                        <SunEditorAtom initialContent={isiberita}/>
                    </div>
                </div>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Foto</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div className="imgPreviewUpload">
                          <img className="imgPreview" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Deskripsi Foto</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Deskripsi Foto ..."  name="deskripsi_foto" value={deskripsi_foto} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
              {/*  <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                      <label>Edisi </label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <select className="form-control" required name="kode_edisi" value={kode_kontributor} onChange={handleKontributorChange}>
                   <option value="">--Pilih--</option>
                                        {
                                            beritaGkmiData && beritaGkmiData.beritaGkmiKontributor3.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama}</option>)
                                        }
                                </select>
                   </div>
                  </div>
                </div>*/}
                <div className="row">
                               <div className="col-sm-3">
                                   <div className="form-group">
                                   <label>Penulis</label>
                                   </div>
                                 </div>
                               <div className="col-sm-9">
                               <div className="form-group">
                               <ReactSelect options={selectdata}
                                onChange={handleKontributorMultipleChange}

                                 selectedValue={selectdatapenulis}
                                value={selectOptions}
                               isMulti/>
                                  </div>
                                 </div>
                               </div>

                               <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Berita Terkait</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <ReactSelect
                 options={selectBerita}
                 onChange={handleEdisiMultipleChange}
                 value={selectBeritaTerkait}
                isMulti/>
                   </div>
                  </div>
                </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditBeritaGkmi.propTypes = {
  updateBeritaGkmi: PropTypes.func.isRequired,
    getBeritaGkmiById: PropTypes.func.isRequired,
    getBeritaGkmiKategori2: PropTypes.func.isRequired,
    getBeritaGkmiEdisi: PropTypes.func.isRequired,
    getBeritaGkmiKontributor: PropTypes.func.isRequired,
    beritaGkmiData: PropTypes.func.isRequired,
    // beritaGkmi: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    beritaGkmi: state.beritaGkmi,
    beritaGkmiData: state.beritaGkmiData
});

export default connect(mapStateToProps, { updateBeritaGkmi, getBeritaGkmiById,getBeritaGkmiKategori2,getBeritaGkmiEdisi2,getBeritaGkmiKontributor2 })(withRouter(EditBeritaGkmi));
