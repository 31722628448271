import { GET_BERITA_CATEGORY, GET_BERITA_CATEGORY2, GET_BERITA, BERITA_CATEGORY_ERROR, BERITA_ERROR } from "../actions/types";

const initialState = {
    beritaCategory: null,
    beritaCategory2: [],
    berita: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_BERITA_CATEGORY:
            return{
                ...state,
                beritaCategory: payload,
                loading: false
            };
        case GET_BERITA_CATEGORY2:
            return{
                ...state,
                beritaCategory2: payload,
                loading: false
            };
        case GET_BERITA:
            return{
                ...state,
                berita: payload,
                loading: false
            };
        case BERITA_CATEGORY_ERROR:
        case BERITA_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
