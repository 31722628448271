import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import moment from "moment";

const Database = ({ auth: { user, token } }) => {
    const [menuHome, setMenuHome] = useState([]);
    const [status, setStatus] = useState(true);

    useEffect(() =>{
         fetchData();
    }, []);

    const fetchData = async () => {
    //   
      let link='';
      link = 'https://gkmi.one:8080/api/sinodebackend/database';
      const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
        setMenuHome(response.data);
    }

    

    const data = {
        columns: [
          {
            label: "No.",
            field: "no",
            sort: "asc",
            width: 10,
          },
          {
            label: "Avatar",
            field: "namafile_image",
            sort: "asc",
            width: 100,
        },
         
          {
              label: "Nama",
              field: "nama",
              sort: "asc",
              width: 200,
          },
          {
            label: "Chart",
            field: "chart",
            sort: "asc",
            width: 200,
        },
       

        {
            label: "Persentase %",
            field: "persentase",
            sort: "asc",
            width: 200,
        },
         
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 30,
        },
          {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 70,
            },

        ],
        rows: menuHome.map((apiData, i) => {
          let base64EncodeString = Buffer.from(`${apiData._id}`).toString('base64');
          let database64EncodeString = Buffer.from(`${apiData._id};${apiData.persentase}`).toString('base64');
          var active;
                  var classStatus;
                  if(apiData.active==1){
                    active="Active";
                    classStatus="badge bg-gray"
                        }else{
                            active="In Active";
                            classStatus="badge badge-danger"
                        }

                        

          return({
              no: (i+1),
              namafile_image: <center><img src={(apiData.images ? (apiData.images !== undefined ?  apiData.images : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')} className="profile-user-img img-fluid img-circle" alt="Avatar" /></center>,
              nama: <span>{apiData.nama}</span>,
              chart: <span>{apiData.type_chart}</span>,
              persentase: <span>{apiData.persentase}</span>,
              status:<span class={classStatus}>{active}</span>,
              action: <span><Link className="btn btn-block bg-gradient-primary" to={`/database/edit-database/${base64EncodeString}`}>Ubah</Link>
             <Link className="btn btn-block bg-gradient-secondary" to={`/database/data/${database64EncodeString}`}>Data Database</Link>
             </span>
        });
      }),
    };



    function cekStatus(){
        if(status){

          return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-4">
                            <Link to='/database/create-database' className="btn btn-block bg-gradient-green  ">Tambah Database</Link>
                        </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardSinode">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Database</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="content">
                <div className="container-fluid">
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Database</h3>
                    <div className="card-tools">
                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(Database);
