import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import moment from "moment";

const DashboardBerlangganan = ({ auth: { user, token } }) => {
    const [langganan, setLangganan] = useState([]);
    const [status, setStatus] = useState(true);

    useEffect(() =>{
         fetchData();
    }, []);

    const fetchData = async () => {
      let link='';
      link = 'https://gkmi.one:8080/api/dashboard/dashboardLanggananOrder';
      const response = await axios.get(link, {
            headers: {
                'x-auth-token': token
            }
        });
        setLangganan(response.data);
    }

    

    const data = {
        columns: [
          {
            label: "No.",
            field: "no",
            sort: "asc",
            width: 10,
          },
          {
              label: "Nama",
              field: "nama",
              sort: "asc",
              width: 150,
          },
          {
            label: "Langganan",
            field: "langganan",
            sort: "asc",
            width: 150,
        },
        {
            label: "Duration",
            field: "duration",
            sort: "asc",
            width: 150,
        },
        {
            label: "Gereja",
            field: "gereja",
            sort: "asc",
            width: 150,
        },
        {
            label: "Berahir",
            field: "end",
            sort: "asc",
            width: 150,
        },
        {
            label: "Harga",
            field: "harga",
            sort: "asc",
            width: 150,
        },
        

        ],
        rows: langganan.map((apiData, i) => {
           
          let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
         
          return({
              no: (i+1),
              gereja: <span>{apiData.gereja} </span>,
              nama: <span>{apiData.user_name}</span>,
              langganan: <span>{apiData.langganan_name}</span>,
              duration: <span>{apiData.duration} Bulan</span>,
              end: <span>{apiData.end_date}</span>,
              harga: <span>Rp. {apiData.harga}</span>,
             
        });
      }),
    };



    function cekStatus(){
        if(status){

          return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-4">
                    <Link to="/dashboardBeritaGkmi" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardBeritaGkmi">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Langganan Order</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="content">
                <div className="container-fluid">
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Langganan Order</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )
};

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(DashboardBerlangganan);
