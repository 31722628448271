import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { GET_GEREJA, GEREJA_ERROR } from "./types";

// Get Gereja
export const getGereja = () => async dispatch => {
    try{
        const res = await axios.get("/api/gereja/gereja_search2");

        dispatch({
            type: GET_GEREJA,
            payload: res.data[0].gereja,
        });
    }catch(err){
        dispatch({
            type: GEREJA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Create Gereja
export const createGereja = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/gereja`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        console.log('AAA RES: ' + res.status);

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            try{
                const res2 = await axios.get("/api/gereja/gereja_search/1");
                dispatch({
                    type: GET_GEREJA,
                    payload: res2.data[0].gereja,
                });
            }catch(err2){
                dispatch({
                    type: GEREJA_ERROR,
                    //payload: { msg: err2.response.statusText, status: err2.response.status }
					payload: { msg: err2.message, status: err2 }

                });
            }
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/gereja');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Gereja Berdasarkan Kode Gereja
export const updateGereja = (formData, kode_gereja, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/gereja/${kode_gereja}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            try{
                const res2 = await axios.get("/api/gereja/gereja_search/1");
                dispatch({
                    type: GET_GEREJA,
                    payload: res2.data[0].gereja,
                });
            }catch(err2){
                dispatch({
                    type: GEREJA_ERROR,
                    //payload: { msg: err2.response.statusText, status: err2.response.status }
					payload: { msg: err2.message, status: err2 }

                });
            }
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/gereja');

    }catch(err){
        const errors = err.response;
        if (errors) {
          const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create Gereja
export const createBranches = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/gereja/branches`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });


        if(res.status === 200){
            console.log("success",res.data[0].msg);
            try{
                const res2 = await axios.get("/api/gereja/gereja_search/1");
                dispatch({
                    type: GET_GEREJA,
                    payload: res2.data[0].gereja,
                });
            }catch(err2){
                dispatch({
                    type: GEREJA_ERROR,
                    //payload: { msg: err2.response.statusText, status: err2.response.status }
					payload: { msg: err2.message, status: err2 }

                });
            }
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/pipka/branches');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
export const updateBranches = (formData, kode_gereja, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/gereja/${kode_gereja}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            try{
                const res2 = await axios.get("/api/gereja/gereja_search/1");
                dispatch({
                    type: GET_GEREJA,
                    payload: res2.data[0].gereja,
                });
            }catch(err2){
                dispatch({
                    type: GEREJA_ERROR,
                    //payload: { msg: err2.response.statusText, status: err2.response.status }
					payload: { msg: err2.message, status: err2 }

                });
            }
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/pipka/branches');

    }catch(err){
        const errors = err.response;
        if (errors) {
          const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
