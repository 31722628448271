
import React, { Component, Fragment } from "react";
import { Link }  from "react-router-dom";
import {API_URL_IMAGE} from "../../config/apiUrl";
import { connect } from 'react-redux';
import { getJemaat } from '../../actions/jemaatAction';
import { getProfile } from '../../actions/profileAction';


class JemaatList extends Component {
	
     componentDidMount() {
		let kode_gereja = this.props.profile && (this.props.profile.level_user);
		//alert(kode_gereja);
		this.props.getJemaat("gr_0000004");
        this.props.getProfile();
        
     }

     componentDidUpdate(){
        const script = document.createElement("script");
        script.src = "dist/js/datatableContent.js";
        script.async = true;
        document.body.appendChild(script);
        
     }


     render() {
		 const {jemaat} = this.props.jemaat
		 const {profile} = this.props.profile
		 
		 function dataJemaat(){
            if (profile && (profile.level_user === 7 || profile.level_user === 8 || profile.level_user === 0)) {
                return(
                    <Fragment>
                        
                    </Fragment>
                )
            }
            return(
                <Fragment>
                    <table id="example1" className="table table-bordered table-striped">
                        <thead>
                        <tr>
                            <td>No.</td>
                            <td>Nama</td>
                            <td>Email</td>
                            <td>No. Telepon</td>
                            <td>Status</td>
                            <td>Role</td>
                        </tr>
                        </thead>
                        <tbody>                            
                        {
                                    
                            jemaat.map((jmt, index) => 
                            <tr key={jmt._id}>
                                <td width="2%"><center>{index+1}</center></td>
                                <td>{jmt.nama.toUpperCase()}</td>
                                <td>{jmt.email}</td>
                                <td>{jmt.no_telepon2}</td>
                                <td>{jmt.status}</td>
                                <td>{jmt.role}</td>
                            </tr>
                            )
                        }                          
                        </tbody>
                    </table>
                </Fragment>
            )
        }
		 
         return (
            
            <div>
                { dataJemaat() }
            </div>
         );
     }
}

function mapStateToProps(state){
    return{
		profile: state.profile,
        jemaat: state.jemaat
    };
    
};

export default connect(mapStateToProps, {getJemaat, getProfile})(JemaatList);