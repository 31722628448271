import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { updateBeritaGkmiKategori } from "../../../actions/beritaGkmiAction";
import { getBeritaGkmiKategoriById } from "../../../actions/beritaGkmiAction";

const EditBeritaGkmiKategori = ({ beritaGkmi: { loading, beritaGkmiKategori }, getBeritaGkmiKategoriById, updateBeritaGkmiKategori, auth: { user }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [loadings, setLoadings] = useState(false);
    const [values, setValues] = useState({
        status: '1',
      });
    const [formData, setFormData] = useState({
        nama_kategori:'',
    });

    useEffect(() => {
        setFormData({
            nama_kategori: beritaGkmiKategori && (beritaGkmiKategori.nama_kategori ? beritaGkmiKategori.nama_kategori : ''),
            status: beritaGkmiKategori && (beritaGkmiKategori.active ? beritaGkmiKategori.active : ''),
        });
        setValues({ status: beritaGkmiKategori && (beritaGkmiKategori.active.toString()) });
    }, [loading, beritaGkmiKategori]);

    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
      //  getBeritaGkmiKategoriById(Buffer.from(id, 'base64').toString('ascii'));
      console.log("id "+decodeString.split(';')[0])
        getBeritaGkmiKategoriById( decodeString.split(';')[0]);
      }, [getBeritaGkmiKategoriById, id]);

    const {
        nama_kategori,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);

        const data = {
            "nama_kategori": formData.nama_kategori,
            "active": values.status,
          }
          updateBeritaGkmiKategori(data,  Buffer.from(id, 'base64').toString('ascii'), history);
     //   updateBeritaGkmiKategori(formData, Buffer.from(id, 'base64').toString('ascii'), history);
    }
    const setStatus = (event) => {
        //console.log(event.target.value);
        setValues({ ...values, status: event.target.value });
        //console.log(values);
      };
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI/kategori" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Kategori Berita</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Kategori Berita {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                    <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Nama Kategori</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Nama Kategori ..." required name="nama_kategori" value={nama_kategori} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditBeritaGkmiKategori.propTypes = {
    updateBeritaGkmiKategori: PropTypes.func.isRequired,
    getBeritaGkmiKategoriById: PropTypes.func.isRequired,
    // beritaGkmi: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    beritaGkmi: state.beritaGkmi
});

export default connect(mapStateToProps, { updateBeritaGkmiKategori, getBeritaGkmiKategoriById })(withRouter(EditBeritaGkmiKategori));
