import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {API_URL_IMAGE} from '../../config/apiUrl'

const NavbarSinode = ({ auth: { user } }) => {
  const location = useLocation();
  const [gerejaClass, setGerejaClass] = useState("nav-item has-treeview");
  const [jadualClass, setJadualClass] = useState("nav-item has-treeview");
  const [firmanTreeClass, setFirmanTreeClass] = useState("nav-item has-treeview");
  const [beritaTreeClass, setBeritaTreeClass] = useState("nav-item has-treeview");
  const [karyaTreeClass, setKaryaTreeClass] = useState("nav-item has-treeview");
  const [actionTreeClass, setActionTreeClass] = useState("nav-item has-treeview");
  const [connectTreeClass, setConnectTreeClass] = useState("nav-item has-treeview");
  const [settingsTreeClass, setSettingsTreeClass] = useState("nav-item has-treeview");
 const [galleryTreeClass, setGalleryTreeClass] = useState("nav-item has-treeview");

  const homeClass = location.pathname === "/dashboard" ? "nav-link active" : "nav-link";


  const gerejaDataClass = location.pathname === "/gereja/gereja-data" ? "nav-link active" : "nav-link";
  const gerejaSliderClass = location.pathname === "/gereja/slider" ? "nav-link active" : "nav-link";
  const gerejaPemimpinClass = location.pathname === "/gereja/pemimpin" ? "nav-link active" : "nav-link";

  const jadualKegiatanClass = location.pathname === "/jadual/kegiatan" ? "nav-link active" : "nav-link";
  const jadualPembicaraClass = location.pathname === "/jadual/pembicara" ? "nav-link active" : "nav-link";

  const jemaatClass = location.pathname === "/jemaat" ? "nav-link active" : "nav-link";

  const notifikasiClass = location.pathname === "/notifikasi" ? "nav-link active" : "nav-link";

  const firmanCategoryClass = location.pathname === "/firman/category" ? "nav-link active" : "nav-link";
  const firmanClass = location.pathname === "/firman" ? "nav-link active" : "nav-link";

  const beritaCategoryClass = location.pathname === "/berita/category" ? "nav-link active" : "nav-link";
  const beritaClass = location.pathname === "/berita" ? "nav-link active" : "nav-link";

  const karyaCategoryClass = location.pathname === "/karya/category" ? "nav-link active" : "nav-link";
  const karyaTokoClass = location.pathname === "/karya/toko" ? "nav-link active" : "nav-link";

  const actionClass = location.pathname === "/action" ? "nav-link active" : "nav-link";

  const homeSliderClass = location.pathname === "/sliderhome" ? "nav-link active" : "nav-link";
  const topicClass = location.pathname === "/connect" ? "nav-link active" : "nav-link";
  const connectTopicClass = location.pathname === "/connect/topic" ? "nav-link active" : "nav-link";
  const connectLaguClass = location.pathname === "/connect/lagu" ? "nav-link active" : "nav-link";
  const outVisionClass = location.pathname === "/outvision" ? "nav-link active" : "nav-link";
  const historyClass = location.pathname === "/history" ? "nav-link active" : "nav-link";
  const lettersClass = location.pathname === "/letters" ? "nav-link active" : "nav-link";
  const databaseClass = location.pathname === "/database" ? "nav-link active" : "nav-link";
const cataGerejaClass = location.pathname === "/tatagereja" ? "nav-link active" : "nav-link";
const projectClass = location.pathname === "/project" ? "nav-link active" : "nav-link";
  const fotoClass = location.pathname === "/galleryfoto" ? "nav-link active" : "nav-link";
  const videoClass = location.pathname === "/galleryvideo" ? "nav-link active" : "nav-link";
  const userClass = location.pathname === "/user" ? "nav-link active" : "nav-link";
  const eksekutifClass = location.pathname === "/eksekutif" ? "nav-link active" : "nav-link";
  const scheduleClass = location.pathname === "/schedule" ? "nav-link active" : "nav-link";
  const handleOnClick = (menu) => {
    console.log("MENU: " + menu); // remove the curly braces

    if(menu !=="Dashboard")
      document.title = `GKMI ONE - ${menu}`;
    else
     document.title = `GKMI ONE`;
  };

  const handleClick = (e) => {
	e.preventDefault();
  }

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
  }, []);

  useEffect(() => {
	if(location.pathname === "/gereja"){
      setGerejaClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/gereja/gereja-slider"){
      setGerejaClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/gereja/pemimpin"){
      setGerejaClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/jadual/kegiatan"){
      setJadualClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/jadual/pembicara"){
      setJadualClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/firman/category"){
      setFirmanTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/firman"){
      setFirmanTreeClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/berita/category"){
      setBeritaTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/berita"){
      setBeritaTreeClass("nav-item has-treeview menu-open");
    }

    if(location.pathname === "/karya/category"){
      setKaryaTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/karya/toko"){
      setKaryaTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/action/kegiatan"){
      setActionTreeClass("nav-item has-treeview menu-open");
    }
    if(location.pathname === "/connect/topic"){
    setConnectTreeClass("nav-item has-treeview menu-open");
  }
  if(location.pathname === "/connect/lagu"){
    setConnectTreeClass("nav-item has-treeview menu-open");
  }

  if(location.pathname === "/outvision"){
        setSettingsTreeClass("nav-item has-treeview menu-open");
      }
      if(location.pathname === "/history"){
        setSettingsTreeClass("nav-item has-treeview menu-open");
      }
      if(location.pathname === "/letters"){
        setSettingsTreeClass("nav-item has-treeview menu-open");
      }
      if(location.pathname === "/galleryfoto"){
           setGalleryTreeClass("nav-item has-treeview menu-open");
         }
         if(location.pathname === "/galleryvideo"){
           setGalleryTreeClass("nav-item has-treeview menu-open");
         }
  }, [location.pathname]);
  return (
    <Fragment>

      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/dashboardSinode" className="brand-link navbar-danger" style={{lineHeight: "2.1"}}>
          <img
            src={process.env.PUBLIC_URL + '/logo-gkmi.png'}
            alt="GKMI ONE"
            className="brand-image img-circle"
            style={{ opacity: ".8", height: "58px", maxHeight: "58px", marginTop: "-12px", marginLeft: "1px" }}
          />
          <span className="brand-text font-weight-light">GKMI ONE</span>
        </Link>

        <div className="sidebar" style={{backgroundColor: "#3c8dbc"}}>
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                style={{ width: "2.8rem", marginTop: "6px", marginLeft: "-8px" }}
                src={API_URL_IMAGE + '/' + (user && (user.gereja[0].logo !== '' ? 'gereja/' + user.gereja[0].logo : 'avatar.jpg'))}
                className="img-circle elevation-2"
                alt="User"
              />
            </div>
            <div className="info">
              <div className="d-block" style={{ color: "#ffffff" }}>
                {user && user.gereja[0].nama}
                <br />
                <span style={{ fontSize: "12px" }}>{user && user.role}</span>
              </div>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-legacy"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >

             <li className="nav-item">
                <Link
                  to="/dashboardSinode"
                  className={homeClass}
                  onClick={() => handleOnClick("Dashboard")}
                >
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p className="linkcolor">
                    Dashboard
                    <i className="right" />
                  </p>
                </Link>
              </li>
              <li className={gerejaClass}>
              <a href="#!" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p className="linkcolor">
                  Gereja
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/gereja" className={gerejaDataClass} onClick={() => handleOnClick("Gereja")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Gereja</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/gereja/pemimpin" className={gerejaPemimpinClass} onClick={() => handleOnClick("Gereja Pemimpin")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Pemimpin</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={firmanTreeClass}>
              <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                <i className="nav-icon far fa fa-2x fa-book"></i>
                <p className="linkcolor">
                  Firman
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
          <li className="nav-item">
                  <Link to="/firman/category" className={firmanCategoryClass} onClick={() => handleOnClick("Kategori Firman")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Kategori</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/firman" className={firmanClass} onClick={() => handleOnClick("Firman")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Firman</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li className={beritaTreeClass}>
              <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                <i className="nav-icon far fa-newspaper"></i>
                <p className="linkcolor">
                  Berita
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
				<li className="nav-item">
                  <Link to="/berita/category" className={beritaCategoryClass} onClick={() => handleOnClick("Kategori Berita")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Kategori</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/berita" className={beritaClass} onClick={() => handleOnClick("Berita")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Berita</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={karyaTreeClass}>
                  <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                    <i className="nav-icon fas fa-store"></i>
                    <p className="linkcolor">
                      Karya
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/karya/category" className={karyaCategoryClass} onClick={() => handleOnClick("Kategori Karya")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p className="linkcolor">Kategori</p>
                      </Link>
                    </li>
                  </ul>
                  <ul className="nav nav-treeview">
                   <li className="nav-item">
                     <Link to="/karya/toko" className={karyaTokoClass} onClick={() => handleOnClick("Toko Karya")}>
                       <i className="far fa-circle nav-icon"></i>
                       <p className="linkcolor">Toko</p>
                     </Link>
                   </li>
                 </ul>
              </li>
              <li className={connectTreeClass}>
               <a onClick={(e) => handleClick(e)} href="#!" className="nav-link">
                 <i className="nav-icon far fa-calendar-alt"></i>
                 <p className="linkcolor">
                   Connect
                   <i className="right fas fa-angle-left"></i>
                 </p>
               </a>
               <ul className="nav nav-treeview">
         <li className="nav-item">
                   <Link to="/connect/topic" className={connectTopicClass} onClick={() => handleOnClick("Topic Connect")}>
                     <i className="far fa-circle nav-icon"></i>
                     <p className="linkcolor">Topic</p>
                   </Link>
                 </li>
                 <li className="nav-item">
                   <Link to="/connect/lagu" className={connectLaguClass} onClick={() => handleOnClick("Connect")}>
                     <i className="far fa-circle nav-icon"></i>
                     <p className="linkcolor">Pujian</p>
                   </Link>
                 </li>
               </ul>
             </li>
             <li className={settingsTreeClass}>
              <a href="#!" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p className="linkcolor">
                  Settings
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/outvision" className={outVisionClass} onClick={() => handleOnClick("Out Vision")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Our Vision</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/history" className={historyClass} onClick={() => handleOnClick("History")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">History</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/letters" className={lettersClass} onClick={() => handleOnClick("Letters")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Letters</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={galleryTreeClass}>
              <a href="#!" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p className="linkcolor">
                  Gallery
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">

                <li className="nav-item">
                  <Link to="/galleryfoto" className={fotoClass} onClick={() => handleOnClick("Foto")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Gallery Foto</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/galleryvideo" className={videoClass} onClick={() => handleOnClick("Video")}>
                    <i className="far fa-circle nav-icon"></i>
                    <p className="linkcolor">Gallery Video</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                to="/database"
                className={databaseClass}
                onClick={() => handleOnClick("Database")}
              >
                <i className="nav-icon fas fa-chart-pie" />
                <p className="linkcolor">
                Database
                  <i className="right" />
                </p>
              </Link>
            </li>
            <li className="nav-item">
                 <Link
                   to="/eksekutif"
                   className={eksekutifClass}
                   onClick={() => handleOnClick("Eksekutif")}
                 >
                   <i className="nav-icon fas fa-upload" />
                   <p className="linkcolor">
                   Eksekutif
                     <i className="right" />
                   </p>
                 </Link>
               </li>
            <li className="nav-item">
                 <Link
                   to="/tatagereja"
                   className={cataGerejaClass}
                   onClick={() => handleOnClick("Tata Gereja")}
                 >
                   <i className="nav-icon fas  fa-project-diagram" />
                   <p className="linkcolor">
                   Tata Gereja
                     <i className="right" />
                   </p>
                 </Link>
               </li>
               <li className="nav-item">
                 <Link
                   to="/project"
                   className={projectClass}
                   onClick={() => handleOnClick("Project")}
                 >
                   <i className="nav-icon  fas fa-project-diagram" />
                   <p className="linkcolor">
                   Project
                     <i className="right" />
                   </p>
                 </Link>
               </li>
               <li className="nav-item">
                 <Link
                   to="/schedule"
                   className={scheduleClass}
                   onClick={() => handleOnClick("Schedule")}
                 >
                   <i className="nav-icon fas fa-upload" />
                   <p className="linkcolor">
                   Schedule
                     <i className="right" />
                   </p>
                 </Link>
               </li>
              <li className="nav-item">
                 <Link
                   to="/notifikasi"
                   className={notifikasiClass}
                   onClick={() => handleOnClick("Notifikasi")}
                 >

                   <i className="nav-icon fas  fa-project-diagram" />
                   <p className="linkcolor">
                   Notifikasi
                     <i className="right" />
                   </p>
                 </Link>
               </li>
              <li className="nav-item">
              <Link to="/sliderhome" className={homeSliderClass} onClick={() => handleOnClick("Gereja Slider")}>
                  <i className="nav-icon fas fa-upload" />
                  <p className="linkcolor">
                  Home Slider
                    <i className="right" />
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/user"
                  className={userClass}
                  onClick={() => handleOnClick("user")}
                >
                  <i className="nav-icon fas fa-user" />
                  <p className="linkcolor">
                    User Role
                    <i className="right" />
                  </p>
                </Link>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </Fragment>
  );
};

NavbarSinode.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(NavbarSinode);
