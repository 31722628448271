import axios from "../util/http-common";

import { GET_PROFILE, PROFILE_ERROR } from "./types";

// Get Profile Me Gereja
export const getProfile = () => async dispatch => {
    try{
        const res = await axios.get("/api/profile/me");

        dispatch({
            type: GET_PROFILE,
            payload: res.data[0].profile[0],
        });
    }catch(err){
        dispatch({
            type: PROFILE_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}