import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/img_banner.jpeg";
import { updateBeritaGkmiIklan } from "../../../actions/beritaGkmiAction";
import { getBeritaGkmiIklanById } from "../../../actions/beritaGkmiAction";

const EditBeritaGkmiIklan = ({ beritaGkmi: { loading, beritaGkmiIklan }, getBeritaGkmiIklanById, updateBeritaGkmiIklan, auth: { user }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [loadings, setLoadings] = useState(false);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [values, setValues] = useState({
        status: '1',
      });
    const [formData, setFormData] = useState({
        nama:'',

    });

    useEffect(() => {
        setFormData({
            nama: beritaGkmiIklan && (beritaGkmiIklan.nama ? beritaGkmiIklan.nama : ''),
            status: beritaGkmiIklan && (beritaGkmiIklan.active ? beritaGkmiIklan.active : ''),
            urutan: beritaGkmiIklan && (beritaGkmiIklan.urutan ? beritaGkmiIklan.urutan : ''),
            keterangan: beritaGkmiIklan && (beritaGkmiIklan.keterangan ? beritaGkmiIklan.keterangan : ''),
            url: beritaGkmiIklan && (beritaGkmiIklan.url ? beritaGkmiIklan.url : ''),
        });
        setValues({ status: beritaGkmiIklan && (beritaGkmiIklan.active.toString()) });
    }, [loading, beritaGkmiIklan]);
    useEffect(() => {
        setFileName( beritaGkmiIklan && (beritaGkmiIklan.nama_image ? beritaGkmiIklan.nama_image : ''));

        setImagePreviewUrl( beritaGkmiIklan && (beritaGkmiIklan.tautan ? beritaGkmiIklan.tautan + '/' +fileName : ''));
      }, [loading, beritaGkmiIklan, fileName]);


    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
      console.log("id "+decodeString.split(';')[0])
      getBeritaGkmiIklanById( decodeString.split(';')[0]);
      }, [getBeritaGkmiIklanById, id]);

    const {
        nama,
        keterangan,
        urutan,
        url,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);

        const data = new FormData();
        data.append("image_iklan", file);
        data.append("urutan", formData.urutan);
        data.append("nama", formData.nama);
        data.append("keterangan", formData.keterangan);
        data.append("url", formData.url);
        data.append("active", values.status);

        updateBeritaGkmiIklan(data,  Buffer.from(id, 'base64').toString('ascii'), history);

    }
    const setStatus = (event) => {
        //console.log(event.target.value);
        setValues({ ...values, status: event.target.value });
        //console.log(values);
      };
      const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI/iklan" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Banner Iklan Berita</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Banner Iklan {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                      <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Urutan Iklan </label>
                    <div class="jss213-small">Urutan bedarkan ASC yang akan tayang </div>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <select className="form-control" required name="urutan" value={urutan} onChange={e => onChange(e)}>
                      <option value="">--Pilih--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                   </div>
                  </div>
                </div>
                    <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Nama</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Nama  ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Keterangan</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <textarea className="form-control" rows="3"  placeholder="Keterangan ..." name="keterangan" value={keterangan} onChange={e => onChange(e)}></textarea>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Gambar</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div className="imgPreviewUpload">
                          <img className="imgPreview270_600" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>URL Website</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="URL Website  ..."  name="url" value={url} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditBeritaGkmiIklan.propTypes = {
  updateBeritaGkmiIklan: PropTypes.func.isRequired,
    getBeritaGkmiIklanById: PropTypes.func.isRequired,
    // beritaGkmi: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    beritaGkmi: state.beritaGkmi
});

export default connect(mapStateToProps, { updateBeritaGkmiIklan, getBeritaGkmiIklanById })(withRouter(EditBeritaGkmiIklan));
