import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import Alert from "../alert/Alert";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { sendNotification } from "../../actions/notificationAction";

const SendNotification = ({ sendNotification, history, auth: { user } }) => {
  const {id} = useParams();
  const [kodeGereja, setKodeGereja] = useState();
  const [topic, setTopic] = useState();
  const [title, setTitle] = useState();
  const [loadings, setLoadings] = useState(false);
  const {handleSubmit, getFieldProps, errors, touched} = useFormik({
    initialValues: {
      judul: '',
      pesan: '',
    },
    validationSchema: Yup.object({
      judul: Yup.string().required('Judul harus diisi!'),
      pesan: Yup.string().required('Pesan harus diisi!'),
    }),
    onSubmit: (values, {setSubmitting, resetForm}) => {
     const data = {
      "topic": topic,
      "title": title,
      "body": values.judul,
      "message": values.pesan
    }
    setLoadings(true);
      console.log('ISI: ' + data.topic + ' - ' + data.title + ' - ' + data.body + ' - ' + data.message);
      sendNotification(data, history);
      resetForm({judul: '', pesan: ''});
      setSubmitting(false);
    }
  })

  useEffect(() => {
    let codeChurch = user && (user.gereja[0].kode_gereja);
    let nameChurch = user && (user.gereja[0].nama);
    if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
      setTopic("gkmione");
      setTitle("GKMI ONE");
    }else if(user && (user.level_user === 9)){
      setTopic(user && (user.gereja[0].pgmw));
      setTitle(nameChurch);
    }else{
        console.log('USER: ' + codeChurch);
        setKodeGereja(codeChurch);
        setTopic(codeChurch);
        setTitle(nameChurch);
    }
  }, [user]);

  return (

    <Fragment>
      <Header />
      <Navbar />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-2">

              </div>
              <div className="col-sm-10">
                <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Notifikasi</li>
                </ol>
              </div>
            </div>
            </div>
        </section>
        {/* Main content */}
        <section className="content">
        <div className="container-fluid">
          {/* Default box */}
          <div className="card">
          <form onSubmit={handleSubmit} id="notifikasi-form">
            <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
              <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Kirim Notifikasi</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fas fa-minus" />
                </button>

              </div>
            </div>

            <div className="card-body">
                <Alert />

                <div className="row">
                  <div className="col-sm-12">
                      <div className="form-group">
                      <label>Judul</label>

                      <input type="text"  className="form-control form_alert" placeholder="Judul ..." name="judul" id="judul" {...getFieldProps('judul')} />
                      { touched.judul && errors.judul ? <p className="error_alert">{errors.judul}</p> : null}
                      </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                      <div className="form-group">
                      <label>Pesan</label>
                      <textarea className="form-control form_alert" rows="4" cols="50" placeholder="Pesan ..." name="pesan" id="pesan" {...getFieldProps('pesan')}></textarea>
                      { touched.pesan && errors.pesan ? <p className="error_alert">{errors.pesan}</p> : null}
                      </div>
                  </div>
                </div>


            </div>

            {/* /.card-body */}
            <div className="card-footer">
            {
              loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
              :
              <input type="submit" className="btn btn-primary pull-right" value="Kirim" />
            }
            </div>
            {/* /.card-footer*/}
            </form>
          </div>
          {/* /.card */}

          </div>
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </Fragment>
  );
};

SendNotification.propTypes = {
    sendNotification: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps, { sendNotification })(withRouter(SendNotification));
