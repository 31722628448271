import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import DatePicker from "react-datepicker";
import moment from "moment";
import defaultImage from "../../auth/styles/images/noImage200.png";
import { createBeritaGkmiEdisi } from "../../../actions/beritaGkmiAction";

const CreateBeritaGkmiEdisi = ({ createBeritaGkmiEdisi, auth: { user }, history }) => {
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [loadings, setLoadings] = useState(false);
    const [formData, setFormData] = useState({
      tanggal: new Date(),
        nama_edisi:'',
    });

    const {
        nama_edisi,
        keterangan,
        tanggal
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        data.append("image", file);
        data.append("nama_edisi", formData.nama_edisi);
        data.append("keterangan", formData.keterangan);
        data.append("tanggal_edisi", moment(formData.tanggal).format('YYYY-MM-DD'));
        if(file){
            createBeritaGkmiEdisi(data, history);
           }else{
            alert('Image Edisi Tidak Boleh Kosong')
            //console.log('NO UPLOAD: ' );

           }


    }
    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }
    const handleDateChange = e => {
       setFormData({ ...formData,
         tanggal: new Date(e)
       });
     }
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/beritaGKMI/edisi" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardBeritaGkmi">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Edisi Berita</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create Edisi Berita {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tanggal</label>
                          <div class="jss213-small">Tanggal  </div>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal"
                                    dateFormat={moment(tanggal).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal}
                                    onChange={handleDateChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Nama Edisi</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Nama Edisi ..." required name="nama_edisi" value={nama_edisi} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Keterangan</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <textarea className="form-control" rows="3"  placeholder="Keterangan ..." name="keterangan" value={keterangan} onChange={e => onChange(e)}></textarea>
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Gambar</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div className="imgPreviewUpload">
                          <img className="imgPreview200" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                   </div>
                  </div>
                </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreateBeritaGkmiEdisi.propTypes = {
    createBeritaGkmiEdisi: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { createBeritaGkmiEdisi })(withRouter(CreateBeritaGkmiEdisi));
