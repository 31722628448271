import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage200.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import { createLetters } from "../../../actions/sinodeAction";

const CreateLetters = ({ createLetters, auth: { user }, history }) => {

    const [file, setFile] = useState();
    const [loadings, setLoadings] = useState(false);
    const [fileName, setFileName] = useState("");
    const [formData, setFormData] = useState({

      judul:'',
    });

    const {
      judul,

    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        data.append("file_pdf", file);
        data.append("judul", formData.judul);
        if(file){
          createLetters(data, history);
           }else{
            alert('File PDF tidak Boleh Kosong')
             setLoadings(false);

           }


    }
    const fileOnChange = e => {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
    const deleteFile  =  filename => {

      setFileName('');
      setFile('');

    }
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/letters" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardSinode">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Home Menu</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create Home Menu</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />

                    <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Judul</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Judul Letters  ..." required name="judul" value={judul} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>

                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Letters File Pdf</label>
                    </div>
                  </div>
                  <div className="col-sm-9">
                <div className="form-group">
                <div className="imgPreviewUpload">
                <div className="col-sm-6">
                <div className="file-card">
                <div className="file-inputs">
                 <input  type="file"  accept=".pdf"  placeholder="Pdf ..." onChange={fileOnChange} name="file-1[]" id="file-1" class="inputfile inputfile-1"   />
					       <label for="file-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/></svg> <span>Pilih File</span></label>
                </div>
                <p className="main">Supported files</p>
                <p className="info">PDF</p>

                </div>

                </div>


              {fileName =="" ? "":
               <div  class="files-list"
              >

               <p>{fileName}</p>
               <button class="delete-file" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteFile(fileName)}}>
               <img src={require('../../auth/styles/images/icons/ic_delete.png')}/>
                </button>


                          </div>
}

                </div>

                   </div>
                  </div>
                </div>


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreateLetters.propTypes = {
  createLetters: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { createLetters })(withRouter(CreateLetters));
