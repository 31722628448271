import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { updateBeritaPipka } from "../../../actions/pipkaAction";
import { getBeritaById } from "../../../actions/beritaAction";
import { getBeritaCategory2 } from "../../../actions/beritaAction";

import { SunEditorAtom } from '../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditBeritaPipka = ({ berita: { berita, loading }, getBeritaById,  beritaCategory , getBeritaCategory2, updateBeritaPipka, auth: { user, token }, history }) => {
    const {id} = useParams();const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [isi, setIsi] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl]  = useState(defaultImage);
    const [formData, setFormData] = useState({
        judul_berita:'',
        kode_kategori:'',
        images_id:'',
    });

    const [value, setValue] = useState();

    const {
        judul_berita,
        kode_kategori,
        images_id,
    } = formData;

    useEffect(() => {


      if(berita && berita.images[0]){
            console.log('KESINI KAN');
            setFormData({
                judul_berita: berita && (berita.judul_berita ? berita.judul_berita : ''),
                kode_kategori: berita && (berita.kode_kategori ? berita.kode_kategori : ''),
                images_id: berita && (berita.images[0].id ? berita.images[0].id : '')
            });
        }else{
            setFormData({
                judul_berita: berita && (berita.judul_berita ? '' : berita.judul_berita),
                kode_kategori: berita && (berita.kode_kategori ? berita.kode_kategori : ''),
                images_id: '0',
            });
        }

        setValue((berita && (berita.isi_berita ? berita.isi_berita : '')));

	}, [loading, berita]);

    useEffect(() => {
      if(berita && (berita.images[0])){
            setFileName(berita && (berita.images[0].nama_image ? berita.images[0].nama_image : ''));
            console.log('NAMA: ' + fileName);
            setImagePreviewUrl((berita && (berita.images[0].tautan ? berita.images[0].tautan : ''))+fileName);
        }else{
            setImagePreviewUrl(defaultImage);
        }

    }, [loading, berita, fileName]);

    useEffect(() => {
        getBeritaById(Buffer.from(id, 'base64').toString('ascii'));
    }, [getBeritaById, id]);

    useEffect(() => {
        getBeritaCategory2();
    }, [getBeritaCategory2]);


    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const handleCategoryChange = e => {
        setFormData({ ...formData,
            kode_kategori: e.target.options[e.target.selectedIndex].value,
            nama_kategori: e.target.options[e.target.selectedIndex].text
        });
      }

      function uploadImageCallBack(file, uploadHandler) {
        const uploadFile = file[0];
        console.log('KESINI: ' + uploadFile);
        return new Promise(
          (resolve, reject) => {
            const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
            xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
            xhr.setRequestHeader('x-auth-token', token);
            const data = new FormData(); // eslint-disable-line no-undef
            data.append('file', uploadFile);
            data.append("tautan", API_URL_IMAGE + '/ckeditor/')
            xhr.send(data);
            xhr.addEventListener('load', () => {
              const response = JSON.parse(xhr.responseText);
              console.log('OK: ' + response);
              uploadHandler(response);
              resolve(response);
            });
            xhr.addEventListener('error', () => {
              const error = JSON.parse(xhr.responseText);
              reject(error);
            });
          },
        );
      }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        data.append("nama_image", file);
        data.append("judul_berita", formData.judul_berita);
        data.append("kode_kategori", formData.kode_kategori);
        data.append("images_id", formData.images_id);
        data.append("isi_berita", value);
        console.log(file);
        updateBeritaPipka(data, Buffer.from(id, 'base64').toString('ascii'), history);
    }

    function handleImageUploadBefore(files, info, uploadHandler){
        // uploadHandler is a function
      console.log('UPLOAD: ' + files, info);
      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString());
      }
    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/pipka/berita" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardPIPKA">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Berita Pipka</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Berita {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Kategori</label>
                                <select className="form-control" required name="kode_kategori" value={kode_kategori} onChange={handleCategoryChange}>
                                    <option value="">--Pilih--</option>
                                        {
                                            beritaCategory && beritaCategory.beritaCategory3.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama_kategori}</option>)
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Judul Berita</label>
                          <input type="text" className="form-control" placeholder="Judul Berita ..." required name="judul_berita" value={judul_berita} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Isi Berita</label>
                            <div style={{position: 'relative'}}>
                              <div style={{zIndex: 1}}>
                                <SunEditor
                                      autoFocus={true}
                                      lang="en"
                                      width='100%'
                                      height='450px'
                                      setOptions={{
                                          minWidth : '450px',
                                        showPathLabel: false,
                                        placeholder: "Enter your text here!!!",
                                        plugins: [
                                          align,
                                          font,
                                          fontColor,
                                          fontSize,
                                          formatBlock,
                                          hiliteColor,
                                          horizontalRule,
                                          lineHeight,
                                          list,
                                          paragraphStyle,
                                          table,
                                          template,
                                          textStyle,
                                          image,
                                          link
                                        ],
                                        buttonList: [
                                          ["undo", "redo"],
                                          ["font", "fontSize", "formatBlock"],
                                          ["paragraphStyle"],
                                          [
                                            "bold",
                                            "underline",
                                            "italic",
                                            "strike",
                                            "subscript",
                                            "superscript"
                                          ],
                                          ["fontColor", "hiliteColor"],
                                          ["removeFormat"],
                                          "/", // Line break
                                          ["outdent", "indent"],
                                          ["align", "horizontalRule", "list", "lineHeight"],
                                          ["table", "link", "image"]
                                        ],
                                        formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                        font: [
                                          "Arial",
                                          "Calibri",
                                          "Comic Sans",
                                          "Courier",
                                          "Garamond",
                                          "Georgia",
                                          "Impact",
                                          "Lucida Console",
                                          "Palatino Linotype",
                                          "Segoe UI",
                                          "Tahoma",
                                          "Times New Roman",
                                          "Trebuchet MS"
                                        ]
                                      }}
                                      onImageUploadBefore={handleImageUploadBefore}
                                      setContents={value}
                                      onChange={setValue}

                                  />

                                </div>

                                <div style={{zIndex: -999, position: 'absolute'}}>
                                    <SunEditorAtom initialContent={value}/>
                                </div>
                              </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Gambar</label>
                            <div className="imgPreviewUpload">
                             <img className="imgPreview" src={imagePreviewUrl} />
                             <div >
                             <input type="file" className="form-control" placeholder="Gambar ..." name="nama_image" onChange={fileOnChange} />
                            <input type="hidden" className="form-control" name="images_id" value={images_id} onChange={e => onChange(e)} />
                             </div>
                             </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Gambar </label>
                            <img style={{border: "1px solid #adb5bd"}} src={imagePreviewUrl}
                                className="img-fluid mb-3"
                                alt=""
                            />
                            <input type="file" className="form-control" placeholder="Gambar ..." name="nama_image" onChange={fileOnChange} />
                            <input type="hidden" className="form-control" name="images_id" value={images_id} onChange={e => onChange(e)} />
                            </div>
                        </div>
                    </div>*/}


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditBeritaPipka.propTypes = {
  updateBeritaPipka: PropTypes.func.isRequired,
    getBeritaById: PropTypes.func.isRequired,
    getBeritaCategory2: PropTypes.func.isRequired,
    berita: PropTypes.object.isRequired,
    beritaCategory: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    berita: state.berita,
    beritaCategory: state.beritaCategory
});

export default connect(mapStateToProps, { updateBeritaPipka, getBeritaById, getBeritaCategory2 })(withRouter(EditBeritaPipka));
