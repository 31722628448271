import {GET_CONNECT_TOPIC,GET_CONNECT_LEADER,GET_CONNECT,GET_CONNECT_LAGU,CONNECT_TOPIC_ERROR,CONNECT_LEADER_ERROR,CONNECT_ERROR ,CONNECT_LAGU_ERROR } from "../actions/types";

const initialState = {

    connectLeader: [],
    topic: null,
    connect: null,
    lagu: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){

        case GET_CONNECT_TOPIC:
        return{
             ...state,
             topic: payload,
             loading: false
            };
            case GET_CONNECT_LEADER:
                return{
                        ...state,
                        connectLeader: payload,
                        loading: false
                       };
            case GET_CONNECT:
                return{
                      ...state,
                      connect: payload,
                      loading: false
                        };

            case GET_CONNECT_LAGU:
                return{
                      ...state,
                      lagu: payload,
                      loading: false
                      };
        case CONNECT_TOPIC_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
            case CONNECT_LEADER_ERROR:
                    return{
                        ...state,
                        error: payload,
                        loading: false
                    };
                    case CONNECT_ERROR:
                    return{
                        ...state,
                        error: payload,
                        loading: false
                    };

                    case CONNECT_LAGU_ERROR:
                        return{
                            ...state,
                            error: payload,
                            loading: false
                        };

        default:
            return state;
    }

}
