import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../config/apiUrl";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import { changePassword } from "../../actions/authAction";
import {useFormik} from 'formik';
import * as Yup from 'yup';

const ChangePassword = ({ changePassword, auth: { user }, history }) => {

    const {handleSubmit, getFieldProps, errors, touched} = useFormik({
        initialValues: {
          old_password: '',
          password: '',
          password2: '',
        },
        validationSchema: Yup.object({
          old_password: Yup.string().required('Password Lama harus diisi!'),
          password: Yup.string().required('Password harus diisi!').min(6, 'Harus 6 karakter atau lebih!'),
          password2: Yup.string().required('Konfirmasi Password harus diisi!').when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Konfirmasi password tidak sama dengan password"
            )
          }),
        }),
        onSubmit: (values) => {
         const data = {
          "old_password": values.old_password,
          "password": values.password,
        }
    
        changePassword(data, history);
          
        }
      })

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={handleSubmit}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Change Password</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Password Lama</label>
                            <input type="password" className="form-control form_alert" placeholder="Password Lama ..." name="old_password" id="old_password" {...getFieldProps('old_password')} />
                            { touched.old_password && errors.old_password ? <p className="error_alert">{errors.old_password}</p> : null}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control form_alert" placeholder="Password ..." name="password" id="password" {...getFieldProps('password')} />
                          { touched.password && errors.password ? <p className="error_alert">{errors.password}</p> : null}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Konfirmasi Password</label>
                            <input type="password" className="form-control" placeholder="Konfirmasi Password ..." name="password2" id="password2" {...getFieldProps('password2')} />
                            { touched.password2 && errors.password2 ? <p className="error_alert">{errors.password2}</p> : null}
                            </div>
                        </div>
                    </div>

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                    <input type="submit" className="btn btn-primary pull-right" value="Save" />
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

ChangePassword.propTypes = {
    changePassword: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { changePassword })(withRouter(ChangePassword));
