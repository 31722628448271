import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/img_store.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import { createAction } from "../../../actions/actionKegiatan";

const CreateAction = ({ createAction, auth: { user ,token}, history }) => {
  const [values, setValues] = useState({
    status: '1',
});
const [value, setOnOff] = useState({
  onOff: '1',
});

const {id} = useParams();
const [idToko, setIdToko] = useState();
    const [file, setFile] = useState();
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [fileDat1, setFileDat1] = useState(0);
    const [fileDat2, setFileDat2] = useState(0);
    const [fileDat3, setFileDat3] = useState(0);
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [imagePreviewUrl1, setImagePreviewUrl1] = useState(defaultImage);
    const [imagePreviewUrl2, setImagePreviewUrl2] = useState(defaultImage);
    // const [value, setValue] = useState('');

    const [formData, setFormData] = useState({
      tanggal: new Date(),
      tanggal_pendaftaran: new Date(),
      tanggal_ahir_pendaftaran: new Date(),

        youtube_url:'',
        facebook_url:'',
        instagram_url:'',
        twitter_url:'',
        tempat:'',
        alamat:'',
        map_url:'',

    });

    const {
      tanggal,tanggal_pendaftaran,tanggal_ahir_pendaftaran,nama_action,youtube_url,facebook_url,instagram_url,twitter_url,deskripsi,peserta,tempat,alamat,map_url
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl);
        setFileDat1(1)
    }
    const fileOnChange1 = e => {
      setFile1(e.target.files[0]);
      const objectUrl1= URL.createObjectURL(e.target.files[0])
      setImagePreviewUrl1(objectUrl1);
      setFileDat2(1)
  }
  const fileOnChange2 = e => {
    setFile2(e.target.files[0]);
    const objectUrl2= URL.createObjectURL(e.target.files[0])
    setImagePreviewUrl2(objectUrl2);
    setFileDat3(1)
}
  useEffect(() => {
  //  console.log("VAL: " + values.status);
  //  console.log("VAL a : " + value.onOff);

}, [values,value]);

//   useEffect(() => {
//     let decodeString3 = Buffer.from(id, 'base64').toString('ascii');
//     setIdToko(decodeString3.split(';')[0]);
// }, [id]);
const setStatus = (event) => {
  //console.log(event.target.value);
  setValues({ ...values, status: event.target.value });
  //console.log(values);
};

const setOnOffStatus = (event) => {
  //console.log(event.target.value);
  setOnOff({ ...value, onOff: event.target.value });
  //console.log(values);
};


    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        if (fileDat1>0){
           data.append("nama_image[]", file);
        }
       // if(file=)
       if (fileDat2>0){
         data.append("nama_image[]", file1);
      }
      if (fileDat3>0){
        data.append("nama_image[]", file2);
      }

      // console.log("VAL: " + values.status);
      // console.log("VAL: " + formData.nama_action);
      // console.log("VAL: " + formData.tanggal);
      // console.log("VAL: " + formData.tanggal_pendaftaran);
      // console.log("VAL: " + formData.tanggal_ahir_pendaftaran );
      // console.log("VAL: " + values.status);
      // console.log("VAL: " + formData.peserta);
      // console.log("VAL: " + formData.deskripsi);
      // console.log("VAL: " + value.onOff);
      // console.log("VAL: " + formData.facebook_url);
      // console.log("VAL: " + formData.youtube_url);
      // console.log("VAL: " + formData.instagram_url);
      // console.log("VAL: " + formData.twitter_url);


        data.append("nama_action", formData.nama_action);
        data.append("tanggal_action", moment(formData.tanggal).format('YYYY-MM-DD'));
        data.append("tanggal_daftar_awal", moment(formData.tanggal_pendaftaran).format('YYYY-MM-DD'));
        data.append("tanggal_daftar_akhir", moment(formData.tanggal_ahir_pendaftaran).format('YYYY-MM-DD'));
        data.append("type_peserta", values.status);
        data.append("max_jumlah_group_pergereja", formData.peserta);
        data.append("keterangan", formData.deskripsi);
        data.append("offline_online", value.onOff);
        data.append("facebook_url", formData.facebook_url);
        data.append("youtube_url", formData.youtube_url);
        data.append("instagram_url", formData.instagram_url);
        data.append("twitter_url", formData.twitter_url);
       //console.log("masuk");
       createAction(data, history);
    }


  const handleDateChange = e => {
    setFormData({ ...formData,
      tanggal: new Date(e)
    });
  }
  const handleDatePendaftaranChange = e => {
    setFormData({ ...formData,
      tanggal_pendaftaran: new Date(e)
    });
  }
  const handleDateAhirPendaftaranChange = e => {
    setFormData({ ...formData,
      tanggal_ahir_pendaftaran: new Date(e)
    });
  }
  function cekStatus(){
    if(values.status === "1"){ // ini isi firman
        return (
          <Fragment>
                    <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tempat</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Tempat ..." name="tempat" value={tempat} onChange={e => onChange(e)} />
                      </div>
                      </div>
                      </div>
                      <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Alamat</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                    <textarea className="form-control" rows="5"  placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Google Map Url</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Google Map ..." name="map_url" value={map_url} onChange={e => onChange(e)} />
                      </div>
                      </div>
                      </div>
      </Fragment>
        );
    }
}
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">

                    <Link to={`/action`} className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Action</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Tambah Action</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                  <div className="card">
                    <div className="card-body">
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Image Action *</label>
                          <div class="jss213-small">Format gambar .jpg .jpeg .png dan ukuran minimum 640 x 340px  </div>
                        </div>
                      </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                        <label for="imgInp1" className="imgPreviewUpload2">
                          <img className="imgPreview100" src={imagePreviewUrl} />
                          <div >
                          <input type="file"  id="imgInp1" className="hidden"  placeholder="Gambar Utama ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="form-group">
                        <label for="imgInp2" className="imgPreviewUpload2">
                          <img className="imgPreview100" src={imagePreviewUrl1} />
                          <div >
                          <input type="file"  id="imgInp2" className="hidden" placeholder="Gambar Utama ..." name="nama_image1"  onChange={fileOnChange1} />
                          </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="form-group">
                        <label for="imgInp3" className="imgPreviewUpload2">
                          <img className="imgPreview100" src={imagePreviewUrl2} />
                          <div >
                          <input type="file"  id="imgInp3" className="hidden" placeholder="Gambar Utama ..." name="nama_image2"  onChange={fileOnChange2} />
                          </div>
                          </label>
                        </div>
                      </div>
                    </div>



                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Nama Action *</label>


                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <input type="text" className="form-control" required placeholder="Nama Action ..." name="nama_action" value={nama_action} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tanggal Kegiatan</label>
                          <div class="jss213-small">Tanggal Kegiatan  </div>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal"
                                    dateFormat={moment(tanggal).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal}
                                    onChange={handleDateChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tanggal Pendaftaran</label>
                          <div class="jss213-small">Tanggal dimulainya pendaftaran  </div>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal_pendaftaran"
                                    dateFormat={moment(tanggal_pendaftaran).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal_pendaftaran}
                                    onChange={handleDatePendaftaranChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tanggal Batas Ahir Pendaftaran</label>
                          <div class="jss213-small">Batas Ahir Pendaftaran  </div>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal_ahir_pendaftaran"
                                    dateFormat={moment(tanggal_ahir_pendaftaran).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal_ahir_pendaftaran}
                                    onChange={handleDateAhirPendaftaranChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status Kegiatan</label>
                          <div class="jss213-small">Kegiatan Offline atau Online</div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;Offline (Onsite)&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;Online
                            </div>
                      </div>
                    </div>

                    {cekStatus()}
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status Peserta Kegiatan</label>
                          <div class="jss213-small">Kegiatan Offline atau Online</div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div onChange={setOnOffStatus}>
                                <input type="radio" value="1" name="onOff" checked = {value.onOff === '1'}/>&nbsp;Per Orangan&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="onOff" checked = {value.onOff === '2'}/>&nbsp;Group &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="3" name="onOff" checked = {value.onOff === '3'}/>&nbsp;Gereja
                            </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Max Jumlah Peserta</label>
                          <div class="jss213-small">Max Jumlah Peserta Kegiatan </div>
                        </div>
                      </div>
                    <div className="col-sm-9">
                      <input type="number"  className="form-control"  placeholder="Max Jumlah Peserta ..." name="peserta" value={peserta} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Keterangan</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <textarea className="form-control" rows="5"  placeholder="Deskripsi ..." name="deskripsi" value={deskripsi} onChange={e => onChange(e)}></textarea>
                      </div>
                    </div>

                    </div>
                   </div>





                   <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Sosial Media Promosi</label>
                          </div>
                        </div>
                        </div>
                        <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Youtube</label>

                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder=" Link Url Youtube..." name="youtube_url" value={youtube_url} onChange={e => onChange(e)} />
                      </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Facebook</label>
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Url Facebook ..." name="facebook_url" value={facebook_url} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    </div>


                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Instagram</label>
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Url Instagram ..." name="instagram_url" value={instagram_url} onChange={e => onChange(e)} />
                      </div>
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                        <label>Twitter</label>
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Link Url Twitter ..." name="twitter_url" value={twitter_url} onChange={e => onChange(e)} />
                      </div>
                      </div>
                    </div>

                    </div>
                    </div>










                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

CreateAction.propTypes = {
  createAction: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({
    auth: state.auth,


});

export default connect(mapStateToProps, {createAction})(withRouter(CreateAction));
