import React, { Fragment, useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link, withRouter } from "react-router-dom";
import Alert from "../../../../components/alert/Alert";
import { API_URL_IMAGE } from "../../../../config/apiUrl";
import Header from "../../../layouts/Header";
import Navbar from "../../../layouts/Navbar";
import Footer from "../../../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createGereja } from "../../../../actions/gerejaAction"
import { getProfile } from "../../../../actions/profileAction";
import defaultImage from "../../../auth/styles/images/noImageLogo.png";
import "../../../auth/styles/css/main.css";

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { SunEditorAtom } from '../../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditGereja = ({ profile: { profile, loading }, createGereja, getProfile, history, auth: { user, token } }) => {
  const [file, setFile] = useState();
  const [sejarah, setSejarah] = useState();
  const [fileName, setFileName] = useState();
  let [editorState, setEditorState] = useState(EditorState.createEmpty());
  //const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [formData, setFormData] = useState({
    image_pemimpin: '',
    nama: '',
    kota: '',
    email: '',
    telp: '',
    tagline: '',
    status: '',
    visi: '',
    misi: '',
    latitude: '',
    longitude: '',
    alamat: '',
    tautan: API_URL_IMAGE + '/gereja/',
	instagram: '',
    facebook: '',
    twitter: '',
    youtube: '',
    website: '',
    google: '',
    instagram_id: '',
    facebook_id: '',
    twitter_id: '',
    youtube_id: '',
    website_id: '',
    google_id: '',
    pgmw: '',
  });

  const [value, setValue] = useState();
  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    setFormData({
      nama:  profile && (profile.gereja[0].nama ? profile.gereja[0].nama : ''),
      kota:  profile && (profile.gereja[0].kota ? profile.gereja[0].kota : ''),
      email:  profile && (profile.gereja[0].email ? profile.gereja[0].email : ''),
      telp:  profile && (profile.gereja[0].telp ? profile.gereja[0].telp : ''),
      tagline:  profile && (profile.gereja[0].tagline ? profile.gereja[0].tagline : ''),
      status:  profile && (profile.gereja[0].status ? profile.gereja[0].status : ''),
      pgmw:  profile && (profile.gereja[0].pgmw ? profile.gereja[0].pgmw : ''),
      visi:  profile && (profile.gereja[0].visi ? profile.gereja[0].visi : ''),
      //image_pemimpin: profile && (profile.gereja[0].image_pemimpin ? profile.gereja[0].image_pemimpin : ''),
      misi:  profile && (profile.gereja[0].misi ? profile.gereja[0].misi : ''),
      latitude:  profile && (profile.gereja[0].latitude ? profile.gereja[0].latitude : ''),
      longitude:  profile && (profile.gereja[0].longitude ? profile.gereja[0].longitude : ''),
      alamat:  profile && (profile.gereja[0].alamat ? profile.gereja[0].alamat : ''),
      instagram:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
            profile.gerejaTautan[0][0].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][0].tautan :
            profile.gerejaTautan[0][1].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][1].tautan :
            profile.gerejaTautan[0][2].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][2].tautan :
            profile.gerejaTautan[0][3].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][3].tautan :
            profile.gerejaTautan[0][4].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][4].tautan :
            profile.gerejaTautan[0][5].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][5].tautan : ''),
          facebook:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][0].tautan :
              profile.gerejaTautan[0][1].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][1].tautan :
              profile.gerejaTautan[0][2].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][2].tautan :
              profile.gerejaTautan[0][3].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][3].tautan :
              profile.gerejaTautan[0][4].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][4].tautan :
              profile.gerejaTautan[0][5].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][5].tautan : ''),
          twitter:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][0].tautan :
              profile.gerejaTautan[0][1].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][1].tautan :
              profile.gerejaTautan[0][2].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][2].tautan :
              profile.gerejaTautan[0][3].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][3].tautan :
              profile.gerejaTautan[0][4].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][4].tautan :
              profile.gerejaTautan[0][5].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][5].tautan : ''),
          youtube:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][0].tautan :
              profile.gerejaTautan[0][1].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][1].tautan :
              profile.gerejaTautan[0][2].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][2].tautan :
              profile.gerejaTautan[0][3].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][3].tautan :
              profile.gerejaTautan[0][4].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][4].tautan :
              profile.gerejaTautan[0][5].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][5].tautan : ''),
          website:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Website' ? profile.gerejaTautan[0][0].tautan :
              profile.gerejaTautan[0][1].nama_tautan === 'Website' ? profile.gerejaTautan[0][1].tautan :
              profile.gerejaTautan[0][2].nama_tautan === 'Website' ? profile.gerejaTautan[0][2].tautan :
              profile.gerejaTautan[0][3].nama_tautan === 'Website' ? profile.gerejaTautan[0][3].tautan :
              profile.gerejaTautan[0][4].nama_tautan === 'Website' ? profile.gerejaTautan[0][4].tautan :
              profile.gerejaTautan[0][5].nama_tautan === 'Website' ? profile.gerejaTautan[0][5].tautan : ''),
          google:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Google' ? profile.gerejaTautan[0][0].tautan :
              profile.gerejaTautan[0][1].nama_tautan === 'Google' ? profile.gerejaTautan[0][1].tautan :
              profile.gerejaTautan[0][2].nama_tautan === 'Google' ? profile.gerejaTautan[0][2].tautan :
              profile.gerejaTautan[0][3].nama_tautan === 'Google' ? profile.gerejaTautan[0][3].tautan :
              profile.gerejaTautan[0][4].nama_tautan === 'Google' ? profile.gerejaTautan[0][4].tautan :
              profile.gerejaTautan[0][5].nama_tautan === 'Google' ? profile.gerejaTautan[0][5].tautan : ''),
          instagram_id:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][0].id :
              profile.gerejaTautan[0][1].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][1].id :
              profile.gerejaTautan[0][2].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][2].id :
              profile.gerejaTautan[0][3].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][3].id :
              profile.gerejaTautan[0][4].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][4].id :
              profile.gerejaTautan[0][5].nama_tautan === 'Instagram' ? profile.gerejaTautan[0][5].id : ''),
          facebook_id:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][0].id :
              profile.gerejaTautan[0][1].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][1].id :
              profile.gerejaTautan[0][2].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][2].id :
              profile.gerejaTautan[0][3].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][3].id :
              profile.gerejaTautan[0][4].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][4].id :
              profile.gerejaTautan[0][5].nama_tautan === 'Facebook' ? profile.gerejaTautan[0][5].id : ''),
          twitter_id:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][0].id :
              profile.gerejaTautan[0][1].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][1].id :
              profile.gerejaTautan[0][2].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][2].id :
              profile.gerejaTautan[0][3].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][3].id :
              profile.gerejaTautan[0][4].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][4].id :
              profile.gerejaTautan[0][5].nama_tautan === 'Twitter' ? profile.gerejaTautan[0][5].id : ''),
          youtube_id:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][0].id :
              profile.gerejaTautan[0][1].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][1].id :
              profile.gerejaTautan[0][2].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][2].id :
              profile.gerejaTautan[0][3].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][3].id :
              profile.gerejaTautan[0][4].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][4].id :
              profile.gerejaTautan[0][5].nama_tautan === 'Youtube' ? profile.gerejaTautan[0][5].id : ''),
          website_id:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Website' ? profile.gerejaTautan[0][0].id :
              profile.gerejaTautan[0][1].nama_tautan === 'Website' ? profile.gerejaTautan[0][1].id :
              profile.gerejaTautan[0][2].nama_tautan === 'Website' ? profile.gerejaTautan[0][2].id :
              profile.gerejaTautan[0][3].nama_tautan === 'Website' ? profile.gerejaTautan[0][3].id :
              profile.gerejaTautan[0][4].nama_tautan === 'Website' ? profile.gerejaTautan[0][4].id :
              profile.gerejaTautan[0][5].nama_tautan === 'Website' ? profile.gerejaTautan[0][5].id : ''),
          google_id:  profile && (profile.gerejaTautan[0].length <= 0 ? '' :
              profile.gerejaTautan[0][0].nama_tautan === 'Google' ? profile.gerejaTautan[0][0].id :
              profile.gerejaTautan[0][1].nama_tautan === 'Google' ? profile.gerejaTautan[0][1].id :
              profile.gerejaTautan[0][2].nama_tautan === 'Google' ? profile.gerejaTautan[0][2].id :
              profile.gerejaTautan[0][3].nama_tautan === 'Google' ? profile.gerejaTautan[0][3].id :
              profile.gerejaTautan[0][4].nama_tautan === 'Google' ? profile.gerejaTautan[0][4].id :
              profile.gerejaTautan[0][5].nama_tautan === 'Google' ? profile.gerejaTautan[0][5].id : ''),
    });
  //setSejarah( profile && (profile.gereja[0].sejarah ? profile.gereja[0].sejarah : ''));
  //setImagePreviewUrl(API_URL_IMAGE + '/gereja/'+image_pemimpin);
  setValue((profile && (profile.gereja[0].sejarah ? profile.gereja[0].sejarah : '')));


  var content =  profile && (profile.gereja[0].sejarah ? profile.gereja[0].sejarah : '');

const fixedHTML = content ? content.toString().replace(/<figure.*>/gi, "\n") : '';
        const blocksFromHtml = htmlToDraft("<p>"+fixedHTML+"</p>");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
  }, [loading, profile]);


  useEffect(() => {
    setFileName(profile && (profile.gereja[0].image_pemimpin ? profile.gereja[0].image_pemimpin : ''));
    console.log('NAMA: ' + fileName);
    setImagePreviewUrl((profile && (profile.gereja[0].tautan ? profile.gereja[0].tautan : ''))+fileName);

}, [loading, profile, fileName]);

  const onEditorStateChange = (editorState) => {
    console.log('OKK: ' + stateToHTML(editorState.getCurrentContent()));
    //setSejarah(editorState);
    setEditorState(editorState);
  }

  function uploadImageCallBack(file, uploadHandler) {
    const uploadFile = file[0];
    console.log('KESINI: ' + uploadFile);
    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
        xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
        xhr.setRequestHeader('x-auth-token', token);
        const data = new FormData(); // eslint-disable-line no-undef
        data.append('file', uploadFile);
        data.append("tautan", API_URL_IMAGE + '/ckeditor/')
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText);
          console.log('OK: ' + response);
          uploadHandler(response);
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      },
    );
  }

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const {
    nama,
    kota,
    email,
    telp,
    tagline,
    status,
    visi,
    misi,
    latitude,
    longitude,
    alamat,
	instagram,
    facebook,
    twitter,
    youtube,
    website,
    google,
    instagram_id,
    facebook_id,
    twitter_id,
    youtube_id,
    website_id,
    google_id,
    image_pemimpin,
    pgmw
  } = formData;

  const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

  const fileOnChange = e => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setImagePreviewUrl(objectUrl)
  }

  const sejarahOnChange = (e, editor) => {
    const data = editor.getData();
    console.log('EDITOR 1: ' + data);
    setSejarah(data);
  }

  const onSubmit = async (e) => {
      e.preventDefault();
      setLoadings(true);
	  const data = new FormData();
      data.append("image_pemimpin", file);
      //data.append("nama", formData.nama);
      //data.append("kota", formData.kota);
      //data.append("email", formData.email);
      //data.append("telp", formData.telp);
      //data.append("tagline", formData.tagline);
      data.append("status", formData.status);
      //data.append("visi", formData.visi);
      //data.append("misi", formData.misi);


    //data.append("sejarah", draftToHtml(convertToRaw(editorState.getCurrentContent())));
    data.append("sejarah", value);
	  data.append("tautan", API_URL_IMAGE + '/gereja/');
    if(typeof formData.nama !== 'undefined'){
      data.append("nama", formData.nama);
    }
    if(typeof formData.kota !== 'undefined'){
      data.append("kota", formData.kota);
    }
    if(typeof formData.email !== 'undefined'){
      data.append("email", formData.email);
    }
    if(typeof formData.telp !== 'undefined'){
      data.append("telp", formData.telp);
    }
    if(typeof formData.tagline !== 'undefined'){
      data.append("tagline", formData.tagline);
    }
    if(typeof formData.visi !== 'undefined'){
      data.append("visi", formData.visi);
    }
    if(typeof formData.misi !== 'undefined'){
      data.append("misi", formData.misi);
    }
    if(typeof formData.latitude !== 'undefined'){
      data.append("latitude", formData.latitude);
    }
    if(typeof formData.longitude !== 'undefined'){
      data.append("longitude", formData.longitude);
    }
    if(typeof formData.alamat !== 'undefined'){
      data.append("alamat", formData.alamat);
    }
	  if(typeof formData.instagram !== 'undefined'){
      data.append("instagram", formData.instagram);
    }
    if(typeof formData.facebook !== 'undefined'){
      data.append("facebook", formData.facebook);
    }
    if(typeof formData.twitter !== 'undefined'){
      data.append("twitter", formData.twitter);
    }
    if(typeof formData.youtube !== 'undefined'){
      data.append("youtube", formData.youtube);
    }
    if(typeof formData.website !== 'undefined'){
      data.append("website", formData.website);
    }
    if(typeof formData.google !== 'undefined'){
      data.append("google", formData.google);
    }
      data.append("pgmw", formData.pgmw);
      data.append("instagram_id", instagram_id);
      data.append("facebook_id", facebook_id);
      data.append("twitter_id", twitter_id);
      data.append("youtube_id", youtube_id);
      data.append("website_id", website_id);
      data.append("google_id", google_id);
      console.log(file);
      createGereja(data, history, "true");
      //createGereja(formData, history, true);
  }

  function handleImageUploadBefore(files, info, uploadHandler){
    try {
      uploadImageCallBack(files, uploadHandler);
    } catch (err) {
        uploadHandler(err.toString())
    }
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "dist/js/dataNote.js";
    script.async = true;
    document.body.appendChild(script);
  });

  return (
    <Fragment>
      <Header />
      <Navbar />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-2">
                <Link to="/gereja" className="btn btn-block bg-gradient-danger">Back</Link>
              </div>
              <div className="col-sm-10">
                <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Gereja</li>
                </ol>
              </div>
            </div>
            </div>
        </section>
        {/* Main content */}
        <section className="content">
        <div className="container-fluid">
          {/* Default box */}
          <div className="card">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
              <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Gereja</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fas fa-minus" />
                </button>

              </div>
            </div>
            <div className="card-body">
                <Alert />
                <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>PGMW</label>
                          <select className="form-control" required name="pgmw" value={pgmw} onChange={e => onChange(e)}>
                          <option value="">--Pilih--</option>
                            <option value="pgmw1">PGMW 1</option>
                            <option value="pgmw2">PGMW 2</option>
                            <option value="pgmw3">PGMW 3</option>
                            <option value="pgmw4">PGMW 4</option>
                            <option value="pgmw5">PGMW 5</option>
                            <option value="pgmw6">PGMW 6</option>
                          </select>
                        </div>
                      </div>
                    </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Nama</label>
                      <input type="text" className="form-control" placeholder="Nama ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Kota</label>
                      <input type="text" className="form-control" placeholder="Kota ..." name="kota" value={kota} onChange={e => onChange(e)} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input type="text" className="form-control" placeholder="Email ..." name="email" value={email} onChange={e => onChange(e)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Telepon</label>
                      <input type="text" className="form-control" placeholder="Telepon ..." name="telp" value={telp} onChange={e => onChange(e)} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Tagline</label>
                      <input type="text" className="form-control" placeholder="Tagline ..." name="tagline" value={tagline} onChange={e => onChange(e)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Status</label>
                      <select className="form-control" required name="status" value={status} onChange={e => onChange(e)}>
                      <option value="">--Pilih--</option>
                        <option value="dewasa">Dewasa</option>
                        <option value="cabang">Cabang</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Visi</label>
                      <input type="text" className="form-control" placeholder="Visi ..." name="visi" value={visi} onChange={e => onChange(e)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Misi</label>
                      <input type="text" className="form-control" placeholder="Misi ..." name="misi" value={misi} onChange={e => onChange(e)} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Image Pemimpin</label>
                      <div className="imgPreviewUpload">
                      <img className="imgPreview" src={imagePreviewUrl} />
                      <div >
                      <input type="file" placeholder="Image Pemimpin ..." name="image_pemimpin"  onChange={fileOnChange} />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

				<div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                        <label>Sejarah</label>
                        <div style={{position: 'relative'}}>
                              <div style={{zIndex: 1}}>
                                  <SunEditor
                                      autoFocus={true}
                                      lang="en"
                                      width='100%'
                                      height='450px'
                                      setOptions={{
                                      minWidth : '450px',
                                      showPathLabel: false,
                                      placeholder: "Enter your text here!!!",
                                      plugins: [
                                          align,
                                          font,
                                          fontColor,
                                          fontSize,
                                          formatBlock,
                                          hiliteColor,
                                          horizontalRule,
                                          lineHeight,
                                          list,
                                          paragraphStyle,
                                          table,
                                          template,
                                          textStyle,
                                          image,
                                          link
                                      ],
                                      buttonList: [
                                          ["undo", "redo"],
                                          ["font", "fontSize", "formatBlock"],
                                          ["paragraphStyle"],
                                          [
                                          "bold",
                                          "underline",
                                          "italic",
                                          "strike",
                                          "subscript",
                                          "superscript"
                                          ],
                                          ["fontColor", "hiliteColor"],
                                          ["removeFormat"],
                                          "/", // Line break
                                          ["outdent", "indent"],
                                          ["align", "horizontalRule", "list", "lineHeight"],
                                          ["table", "link", "image"]
                                      ],
                                      formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                      font: [
                                          "Arial",
                                          "Calibri",
                                          "Comic Sans",
                                          "Courier",
                                          "Garamond",
                                          "Georgia",
                                          "Impact",
                                          "Lucida Console",
                                          "Palatino Linotype",
                                          "Segoe UI",
                                          "Tahoma",
                                          "Times New Roman",
                                          "Trebuchet MS"
                                      ]
                                      }}
                                      onImageUploadBefore={handleImageUploadBefore}
                                      setContents={value}
                                      onChange={setValue}

                                  />

                              </div>

                              <div style={{zIndex: -999, position: 'absolute'}}>
                                  <SunEditorAtom initialContent={value}/>
                              </div>
                          </div>

                        </div>
                    </div>
                </div>

				<div className="row">
                  <div className="col-sm-6">
                      <div className="form-group">
                      <label>Instagram</label>
                      <input type="text" className="form-control" placeholder="Instagram ..." name="instagram" value={instagram} onChange={e => onChange(e)} />
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className="form-group">
                      <label>Facebook</label>
                      <input type="text" className="form-control" placeholder="Facebook ..." name="facebook" value={facebook} onChange={e => onChange(e)} />
                      </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                      <div className="form-group">
                      <label>Twitter</label>
                      <input type="text" className="form-control" placeholder="Twitter ..." name="twitter" value={twitter} onChange={e => onChange(e)} />
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className="form-group">
                      <label>Youtube</label>
                      <input type="text" className="form-control" placeholder="Youtube ..." name="youtube" value={youtube} onChange={e => onChange(e)} />
                      </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                      <div className="form-group">
                      <label>Website</label>
                      <input type="text" className="form-control" placeholder="Website ..." name="website" value={website} onChange={e => onChange(e)} />
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className="form-group">
                      <label>Google Map Share Link</label>
                      <input type="text" className="form-control" placeholder="Google ..." name="google" value={google}  onChange={e => onChange(e)} />
                      <input type="hidden" className="form-control" name="instagram_id" value={instagram_id} onChange={e => onChange(e)} />
                      <input type="hidden" className="form-control" name="facebook_id" value={facebook_id} onChange={e => onChange(e)} />
                      <input type="hidden" className="form-control" name="twitter_id" value={twitter_id} onChange={e => onChange(e)} />
                      <input type="hidden" className="form-control" name="youtube_id" value={youtube_id} onChange={e => onChange(e)} />
                      <input type="hidden" className="form-control" name="website_id" value={website_id} onChange={e => onChange(e)} />
                      <input type="hidden" className="form-control" name="google_id" value={google_id} onChange={e => onChange(e)} />
                      </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Google Map Latitude</label>
                      <input type="text" className="form-control" placeholder="Latitude ..." name="latitude" value={latitude} onChange={e => onChange(e)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Google Map Longitude</label>
                      <input type="text" className="form-control" placeholder="Longitude ..." name="longitude" value={longitude} onChange={e => onChange(e)} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Alamat</label>
                      <textarea className="form-control" rows="5" placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                    </div>
                  </div>
                </div>

            </div>
            {/* /.card-body */}
            <div className="card-footer">
            {
              loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
              :
              <input type="submit" className="btn btn-primary pull-right" value="Save" />
            }
            </div>
            {/* /.card-footer*/}
            </form>
          </div>
          {/* /.card */}

          </div>
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </Fragment>
  );
};

EditGereja.propTypes = {
  createGereja: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    profile: state.profile,
    auth: state.auth
})


export default connect(mapStateToProps, { createGereja, getProfile })(withRouter(EditGereja));
