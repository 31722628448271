import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { GET_FIRMAN_CATEGORY, GET_FIRMAN_CATEGORY2, GET_FIRMAN_CATEGORY3, FIRMAN_CATEGORY_ERROR, GET_FIRMAN, FIRMAN_ERROR } from "./types";

// Get Firman Category
export const getFirmanCategory = status => async dispatch => {
    try{
        const res = await axios.get(`/api/firman/kategori/${status}`);
        console.log('MASUK SINI: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_FIRMAN_CATEGORY2,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: FIRMAN_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Get Firman Category 2
export const getFirmanCategory2 = status => async dispatch => {
    try{
        const res = await axios.get(`/api/firman/kategori/${status}`);
        console.log('MASUK SINI 1: ' + res.data[0].nama_kategori);
        dispatch({
            type: GET_FIRMAN_CATEGORY3,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: FIRMAN_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }

        });
    }
}

// Get Firman Category by Id
export const getFirmanCategoryById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/firman/kategori/edit/${id}`);

        dispatch({
            type: GET_FIRMAN_CATEGORY,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: FIRMAN_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Create Firman Category
export const createFirmanCategory = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/firman/kategorifirman`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/firman/category');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: FIRMAN_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Firman Category Berdasarkan ID
export const updateFirmanCategory = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/firman/kategorifirman/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/firman/category');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: FIRMAN_CATEGORY_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Get Firman by Id
export const getFirmanById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/firman/firman/edit/${id}`);
        console.log('OK: ' + res.data[0].judul_firman);
        dispatch({
            type: GET_FIRMAN,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: FIRMAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Create Firman
export const createFirman = (formData, history) => async dispatch => {
    try{
        const res = await axios.post(`/api/firman/firman`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push('/firman');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: FIRMAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Firman Berdasarkan ID
export const updateFirman = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/firman/firman/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/firman');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: FIRMAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
