import { GET_BERITA_GKMI_CATEGORY3,GET_BERITA_GKMI_EDISI3,GET_BERITA_GKMI_KONTRIBUTOR3,BERITA_GKMI_CATEGORY_ERROR3,BERITA_GKMI_EDISI_ERROR3,BERITA_GKMI_KONTRIBUTOR_ERROR3 } from "../actions/types";

const initialState = {
   
    beritaGkmiKategori3: [],
     beritaGkmiEdisi3:[],
     beritaGkmiKontributor3:[],
    // beritaGkmiKategori: null,
    // beritaGkmiEdisi: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_BERITA_GKMI_CATEGORY3:
            return{
                ...state,
                beritaGkmiKategori3: payload,
                loading: false
            };
           
            case GET_BERITA_GKMI_EDISI3:
            return{
                ...state,
                beritaGkmiEdisi3: payload,
                loading: false
            };
            
            case GET_BERITA_GKMI_KONTRIBUTOR3:
            return{
                ...state,
                beritaGkmiKontributor3: payload,
                loading: false
            };
           
       
            case BERITA_GKMI_CATEGORY_ERROR3:
                return{
                    ...state,
                    error: payload,
                    loading: false
                };
           
            case BERITA_GKMI_EDISI_ERROR3:
            return{
                ...state,
                error: payload,
                loading: false
            };
           
            case BERITA_GKMI_KONTRIBUTOR_ERROR3:
            return{
                ...state,
                error: payload,
                loading: false
            };
            
        default:
            return state;
    }
    
}
