import { GET_HOME_SLIDER, HOME_SLIDER_ERROR } from "../actions/types";

const initialState = {
    homeSlider: [],
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_HOME_SLIDER:
            return{
                ...state,
                homeSlider: payload,
                loading: false
            };
        case HOME_SLIDER_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}