import axios from "../util/http-common";
import { setAlert } from "./alertAction";
import { GET_GEREJA_JADWAL_KEGIATAN,  GEREJA_JADWAL_KEGIATAN_ERROR } from "./types";

// Get Gereja Jadwal Kegiatan by Id
export const getJadwalKegiatanById = id => async dispatch => {
    try{
        const res = await axios.get(`/api/gereja/jadual/${id}`);

        dispatch({
            type: GET_GEREJA_JADWAL_KEGIATAN,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: GEREJA_JADWAL_KEGIATAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Create Jadual Kegiatan
export const createKegiatan = (formData, history) => async dispatch => {
    try{
		const res = await axios.post(`/api/gereja/jadual/kegiatan`, formData);
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }
        history.push('/jadual/kegiatan');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_JADWAL_KEGIATAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Gereja Jadual Kegiatan Berdasarkan ID
export const updateJadwalKegiatan = (formData, id, history) => async dispatch => {
    try{
        const res = await axios.put(`/api/gereja/jadual/${id}`, formData);

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }

		history.push('/jadual/kegiatan');

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_JADWAL_KEGIATAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Create Jadual Kegiatan
export const createBranchesKegiatan = (formData, history,path) => async dispatch => {
    try{
		const res = await axios.post(`/api/gereja/jadual/kegiatan`, formData);
        if(res.status === 200){
            console.log("success",res.data[0].msg);
            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }
        history.push(`/pipka/branches/kegiatan/${path}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_JADWAL_KEGIATAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Update Gereja Jadual Kegiatan Berdasarkan ID
export const updateBranchesKegiatan = (formData, id, history, path) => async dispatch => {
    try{
        const res = await axios.put(`/api/gereja/jadual/${id}`, formData);

        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 3000));
        }

        history.push(`/pipka/branches/kegiatan/${path}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: GEREJA_JADWAL_KEGIATAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
