import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import Alert from "../../alert/Alert";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {useFormik} from 'formik';
import * as Yup from 'yup';

import { createUserRole } from "../../../actions/beritaGkmiAction";

const CreateUserRole = ({ createUserRole, history, auth: { user } }) => {
  const {id} = useParams();
  const [kodeGereja, setKodeGereja] = useState();
  const [status, setStatus] = useState('');
  const [loadings, setLoadings] = useState(false);
  const {handleSubmit, getFieldProps, errors, touched} = useFormik({
    initialValues: {
      kode_gereja: '',
      nama: '',
      email: '',
      no_telepon: '',
      no_telepon2: '',
      password: '',
      password2: '',
      status: '',
      role: '',
      level_user: '',
    },
    validationSchema: Yup.object({
      nama: Yup.string().required('Nama harus diisi!'),
      //email: Yup.string().required('Email harus diisi!').email('Format email tidak valid!'),
      no_telepon: Yup.string().required('User Name harus diisi!').min(3, 'Harus 3 karakter atau lebih!'),
      password: Yup.string().required('Password harus diisi!').min(6, 'Harus 6 karakter atau lebih!'),
      password2: Yup.string().required('Password harus diisi!').when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Konfirmasi password tidak sama dengan password"
        )
      }),
      status: Yup.string().required('Harus dipilih!'),
    }),
    onSubmit: (values) => {
      setLoadings(true);
      let level = "5";
       if(values.status === "Church Administrator"){
        level = "5";
      }
     const data = {
      "kode_gereja": kodeGereja,
      "nama": values.nama,
      "email": values.email,
      "no_telepon": values.no_telepon,
      "no_telepon2": values.no_telepon2,
      "password": values.password,
      "status": values.status,
      "role": values.status,
      "level_user": level
    }

    createUserRole(data, history);

    }
  })

  useEffect(() => {

    let code = "0";
    let codeChurch = user && (user.gereja[0].kode_gereja);
    if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
        console.log('USER1: ' + user.level_user + " - " + user.gereja[0].kode_gereja);
    }else{
        code = codeChurch;
        console.log('USER: ' + codeChurch);
        setKodeGereja(codeChurch);
    }
  }, [user]);

  return (

    <Fragment>
      <Header />
      <Navbar />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-2">
                <Link to="/beritaGKMI/user" className="btn btn-block bg-gradient-danger">Back</Link>
              </div>
              <div className="col-sm-10">
                <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                  <li className="breadcrumb-item">
                    <Link to="/dashboardBeritaGkmi">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">User Role</li>
                </ol>
              </div>
            </div>
            </div>
        </section>
        {/* Main content */}
        <section className="content">
        <div className="container-fluid">
          {/* Default box */}
          <div className="card">
          <form onSubmit={handleSubmit}>
            <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
              <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Create User Role</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fas fa-minus" />
                </button>

              </div>
            </div>

            <div className="card-body">
                <Alert />
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Status Role</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <select name="status" className="form-control" id="status" {...getFieldProps('status')}>
                            <option value="">--Pilih--</option>
                            <option value="Church Administrator">Administrator</option>
                        </select>
                        { touched.status && errors.status ? <p className="error_alert">{errors.status}</p> : null}
                   </div>
                  </div>
                </div>

                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Nama</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control form_alert" placeholder="Nama ..." name="nama" id="nama" {...getFieldProps('nama')} />
                      { touched.nama && errors.nama ? <p className="error_alert">{errors.nama}</p> : null}
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>No. Telepon</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control form_alert" placeholder="No. Telepon ..." name="no_telepon2" id="no_telepon2" {...getFieldProps('no_telepon2')} />
                        { touched.no_telepon2 && errors.no_telepon2 ? <p className="error_alert">{errors.no_telepon2}</p> : null}
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Email</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control form_alert" placeholder="Email ..." name="email" id="email" {...getFieldProps('email')} />
                      { touched.email && errors.email ? <p className="error_alert">{errors.email}</p> : null}
                   </div>
                  </div>
                </div>

                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Username</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control form_alert" placeholder="User Name ..." name="no_telepon" id="no_telepon" {...getFieldProps('no_telepon')} />
                        { touched.no_telepon && errors.no_telepon ? <p className="error_alert">{errors.no_telepon}</p> : null}
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Password</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="password" className="form-control form_alert" placeholder="Password ..." name="password" id="password" {...getFieldProps('password')} />
                          { touched.password && errors.password ? <p className="error_alert">{errors.password}</p> : null}
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Konfirmasi Password</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="password" className="form-control" placeholder="Konfirmasi Password ..." name="password2" id="password2" {...getFieldProps('password2')} />
                        { touched.password2 && errors.password2 ? <p className="error_alert">{errors.password2}</p> : null}
                   </div>
                  </div>
                </div>





            </div>

            {/* /.card-body */}
            <div className="card-footer">
            {
              loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
              :
              <input type="submit" className="btn btn-primary pull-right" value="Save" />
            }
            </div>
            {/* /.card-footer*/}
            </form>
          </div>
          {/* /.card */}

          </div>
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </Fragment>
  );
};

CreateUserRole.propTypes = {
  createUserRole: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps, { createUserRole })(withRouter(CreateUserRole));
