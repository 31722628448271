import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { updateFirman } from "../../../actions/firmanAction";
import { getFirmanById } from "../../../actions/firmanAction";
import { getFirmanCategory2 } from "../../../actions/firmanAction";

import { SunEditorAtom } from '../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditFirman = ({ firman: { firman, loading }, getFirmanById,  firmanCategory , getFirmanCategory2, updateFirman, auth: { user, token }, history }) => {
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [isi, setIsi] = useState();
    const [codeCatg, setCodeCatg] = useState();
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [formData, setFormData] = useState({
        kode_kategori: '',
        judul_firman:'',
        kontributor_nama:'',
        link_url:'',
    });

    const [values, setValues] = useState({
        status: '1',
    });

    const [value, setValue] = useState();
    const [loadings, setLoadings] = useState(false);

    const {
        kode_kategori,
        judul_firman,
        kontributor_nama,
        link_url,
    } = formData;

    useEffect(() => {


        setFormData({
          judul_firman: firman && (firman.judul_firman ? firman.judul_firman : ''),
          kode_kategori: firman && (firman.kode_kategori ? firman.kode_kategori : ''),
          kontributor_nama: firman && (firman.kontributor_nama ? firman.kontributor_nama : ''),
          link_url: firman && (firman.link_url ? firman.link_url : ''),
          status: firman && (firman.status ? firman.status : ''),
        });

        //setIsi(firman && (firman.isi_firman ? firman.isi_firman : ''));
        setValue((firman && (firman.isi_firman ? firman.isi_firman : '')));
        setValues({ status: firman && (firman.status ? firman.status : '') });
        console.log('STATUS: ' + (firman && (firman.status ? firman.status : '')));

      var content = firman && (firman.isi_firman ? firman.isi_firman : '');

	   const fixedHTML =  content ? content.toString().replace(/<figure.*>/gi, "\n") : '';
       const blocksFromHtml = htmlToDraft("<p>"+fixedHTML+"</p>");
       const { contentBlocks, entityMap } = blocksFromHtml;
       const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
       const editorState = EditorState.createWithContent(contentState);
       setEditorState(editorState);

	}, [loading, firman]);

    useEffect(() => {
      if(firman && firman.nama_image){
          setFileName(firman && (firman.nama_image ? firman.nama_image : ''));
          console.log('NAMA: ' + fileName);
          setImagePreviewUrl((firman && (firman.tautan ? firman.tautan : ''))+fileName);
      }else{
          setImagePreviewUrl(defaultImage);
      }

    }, [loading, firman, fileName]);

    useEffect(() => {
        getFirmanById(Buffer.from(id, 'base64').toString('ascii'));
    }, [getFirmanById, id]);

    useEffect(() => {
        setCodeCatg(user && (user.firman_category_id));
        if (user && (user.level_user === 7)) {
            getFirmanCategory2("0");
        }else{
            getFirmanCategory2("1");
        }

    }, [getFirmanCategory2, user]);

    const setStatus = (event) => {
        console.log('AAA: ' + parseInt(event.target.value));
        setValues({ ...values, status: parseInt(event.target.value) });
        //console.log(values);
    };

    useEffect(() => {
        console.log("VAL: " + values.status);

    }, [values]);

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const handleCategoryChange = e => {
        setFormData({ ...formData,
            kode_kategori: e.target.options[e.target.selectedIndex].value,
            nama_kategori: e.target.options[e.target.selectedIndex].text
        });
      }

      const onEditorStateChange = (editorState) => {
        console.log('OKK: ' + stateToHTML(editorState.getCurrentContent()));
        setEditorState(editorState);
      }

      function uploadImageCallBack(file, uploadHandler) {
        const uploadFile = file[0];
        console.log('KESINI: ' + uploadFile);
        return new Promise(
          (resolve, reject) => {
            const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
            xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
            xhr.setRequestHeader('x-auth-token', token);
            const data = new FormData(); // eslint-disable-line no-undef
            data.append('file', uploadFile);
            data.append("tautan", API_URL_IMAGE + '/ckeditor/')
            xhr.send(data);
            xhr.addEventListener('load', () => {
              const response = JSON.parse(xhr.responseText);
              console.log('OK: ' + response);
              uploadHandler(response);
              resolve(response);
            });
            xhr.addEventListener('error', () => {
              const error = JSON.parse(xhr.responseText);
              reject(error);
            });
          },
        );
      }

      function cekLevelAkses(){
        if (user && (user.level_user === 10)) {
            return (
            <Fragment>
            <input type="hidden" className="form-control" placeholder="Kode Kategori ..." name="kode_kategori" value={codeCatg} onChange={e => onChange(e)} />
            </Fragment>
            );
        }else{
            return(
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Kategori</label>
                            <select className="form-control" required name="kode_kategori" value={kode_kategori} onChange={handleCategoryChange}>
                                <option value="">--Pilih--</option>
                                    {
                                        firmanCategory && firmanCategory.firmanCategory3.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama_kategori}</option>)
                                    }
                            </select>
                        </div>
                    </div>
                </div>
            </Fragment>
            );
        }
      }

      function cekStatus(){
        if(values.status === 1){ // ini isi firman
            return (
                <Fragment>
                    <label>Isi Firman</label>
                    <div style={{position: 'relative'}}>
                    <div style={{zIndex: 1}}>
                        <SunEditor
                            autoFocus={true}
                            lang="en"
                            width='100%'
                            height='450px'
                            setOptions={{
                            minWidth : '450px',
                            showPathLabel: false,
                            placeholder: "Enter your text here!!!",
                            plugins: [
                                align,
                                font,
                                fontColor,
                                fontSize,
                                formatBlock,
                                hiliteColor,
                                horizontalRule,
                                lineHeight,
                                list,
                                paragraphStyle,
                                table,
                                template,
                                textStyle,
                                image,
                                link
                            ],
                            buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                                ],
                                ["fontColor", "hiliteColor"],
                                ["removeFormat"],
                                "/", // Line break
                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                                "Arial",
                                "Calibri",
                                "Comic Sans",
                                "Courier",
                                "Garamond",
                                "Georgia",
                                "Impact",
                                "Lucida Console",
                                "Palatino Linotype",
                                "Segoe UI",
                                "Tahoma",
                                "Times New Roman",
                                "Trebuchet MS"
                            ]
                            }}
                            onImageUploadBefore={handleImageUploadBefore}
                            setContents={value}
                            onChange={setValue}

                        />

                    </div>

                    <div style={{zIndex: -999, position: 'absolute'}}>
                        <SunEditorAtom initialContent={value}/>
                    </div>
                </div>

                </Fragment>
            );
        }else{ // ini link youtube
            return (
                <Fragment>
                    <label>Youtube</label>
                    <input type="text" className="form-control" placeholder="Youtube ..." required name="link_url" value={link_url} onChange={e => onChange(e)} />
                </Fragment>
            );
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        data.append("nama_image", file);
        data.append("status", values.status);
        data.append("judul_firman", formData.judul_firman);
        data.append("kode_kategori", user && (user.level_user === 10 ? codeCatg : formData.kode_kategori));
        if(values.status === 1){
            //data.append("isi_firman", draftToHtml(convertToRaw(editorState.getCurrentContent())));
            data.append("isi_firman", value);
            data.append("link_url", "");
        }else{
            data.append("link_url", formData.link_url);
            data.append("isi_firman", "");
        }

        data.append("kontributor_nama", formData.kontributor_nama);
        console.log(file);
        updateFirman(data, Buffer.from(id, 'base64').toString('ascii'), history);
    }

    function handleImageUploadBefore(files, info, uploadHandler){
        try {
          uploadImageCallBack(files, uploadHandler);
        } catch (err) {
            uploadHandler(err.toString())
        }
      }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/firman" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Firman</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Firman {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    {cekLevelAkses()}
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Judul Firman</label>
                          <input type="text" className="form-control" placeholder="Judul Firman ..." required name="judul_firman" value={judul_firman} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Status</label>
                            <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === 1}/>&nbsp;Tulisan&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === 2}/>&nbsp;Link Youtube
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                        {cekStatus()}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Nama Kontributor</label>
                          <input type="text" className="form-control" placeholder="Nama Kontributor ..." name="kontributor_nama" value={kontributor_nama} onChange={e => onChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Gambar </label>
                            <img style={{border: "1px solid #adb5bd"}} src={imagePreviewUrl}
                                className="img-fluid mb-3"
                                alt=""
                            />
                            <input type="file" className="form-control" placeholder="Gambar ..." name="nama_image" onChange={fileOnChange} />

                            </div>
                        </div>
                    </div>

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditFirman.propTypes = {
    updateFirman: PropTypes.func.isRequired,
    getFirmanById: PropTypes.func.isRequired,
    getFirmanCategory2: PropTypes.func.isRequired,
    firman: PropTypes.object.isRequired,
    firmanCategory: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    firman: state.firman,
    firmanCategory: state.firmanCategory
});

export default connect(mapStateToProps, { updateFirman, getFirmanById, getFirmanCategory2 })(withRouter(EditFirman));
