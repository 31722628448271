
import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { useParams, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import moment from "moment";
import Alert from "../../alert/Alert";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { getProfile } from '../../../actions/profileAction';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import { getJadwalIbadahByKodeGerejaJadwal } from "../../../actions/gerejaJadwalIbadahAction";
import QRCode from 'qrcode.react';
import axios from 'axios';

const ListIbadah = ({auth: { user, token }, jadwalIbadah, getJadwalIbadahByKodeGerejaJadwal}) => {
    const {id} = useParams();
    const [title, setTitle] = useState();
    const [kegiatan, setKegiatan] = useState();
    const [jadualKegiatan, setJadualKegiatan] = useState([]);

    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        setTitle(decodeString.split(';')[2]);
        setKegiatan(decodeString.split(';')[3]);
      },[]);

    /*useEffect(() => {
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
        getJadwalIbadahByKodeGerejaJadwal(decodeString2.split(';')[1], decodeString2.split(';')[0]);
    }, [getJadwalIbadahByKodeGerejaJadwal, id]);
    */

    const downloadQR = (props) => {
        const canvas = document.getElementById(props);
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = kegiatan + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

    };

    useEffect(() =>{
        fetchData();
    }, [id]);

    const fetchData = async () => {
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
        let kode_gereja = decodeString2.split(';')[1];
        let jadwal_id = decodeString2.split(';')[0];
        let link='';
        link = `https://gkmi.one:8080/api/gereja/jadual/ibadah3/${kode_gereja}/${jadwal_id}`;
        console.log('LINK: ' + link);
        const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
      });
        setJadualKegiatan(response.data[0]);
        //console.log('DATA: ' + JSON.stringify(response.data[0]));
    }

    const data = {
        columns: [
          {
            label: "No.",
            field: "no",
            sort: "asc",
            width: 10,
          },
          {
            label: "Image",
            field: "image",
            sort: "asc",
            width: 150,
          },
          {
            label: "Tanggal",
            field: "tanggal",
            sort: "asc",
            width: 150,
          },
          {
            label: "Nama Ibadah",
            field: "nama_ibadah",
            sort: "asc",
            width: 150,
          },
          {
            label: "Pembicara",
            field: "pembicara",
            sort: "asc",
            width: 150,
          },
          {
            label: "Tema Ibadah",
            field: "tema_ibadah",
            sort: "asc",
            width: 150,
          },
          {
            label: "QR Code",
            field: "qr_code",
            sort: "asc",
            width: 150,
          },
          {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 150,
          },

        ],
        rows: jadualKegiatan.map((apiData, i) => {
            let decodeString3 = Buffer.from(id, 'base64').toString('ascii');
            let id2=decodeString3.split(';')[0];
            let kodegereja=decodeString3.split(';')[1];
            let namagereja=decodeString3.split(';')[2];
            let namaibadah=decodeString3.split(';')[3];
            let base64EncodeString = Buffer.from(`${id2};${kodegereja};${namagereja};${namaibadah};${apiData.id}`).toString('base64');
          return({
              no: (i+1),
              image: <center><img src={(apiData.namafile_image !== "" ? (apiData.namafile_image !== undefined ? apiData.tautan + apiData.namafile_image : API_URL_IMAGE + '/avatar.jpg') : API_URL_IMAGE + '/avatar.jpg')}
                  style={{border: "3px solid #adb5bd", padding: "3px", margin: "0 auto", width: 200}} class="profile-user-img img-fluid"
                  alt="Gereja" /></center>,
              tanggal: <span>{moment(apiData.tanggal).format('DD-MMMM-YYYY')}</span>,
              nama_ibadah: <span>{apiData.nama_ibadah}</span>,
              pembicara: <span>{apiData.nama_pembicara}</span>,
              tema_ibadah: <span>{apiData.tema_ibadah}</span>,
              qr_code: <span><QRCode
                    id={`${base64EncodeString}`}
                    value={`${base64EncodeString}`}
                    size={170}
                    level={"H"}
                    includeMargin={true}
                /><center><a style={{cursor: 'pointer'}} onClick={() => downloadQR(`${base64EncodeString}`)}> Download QR </a></center></span>,
              judul_berita: <span>{apiData.judul_berita}</span>,
              action: <span><Link className="btn btn-block bg-gradient-primary" to={`/jadual/ibadah/edit-ibadah/${base64EncodeString}`}>Ubah</Link>
              <Link className="btn btn-block bg-gradient-green" to={`/jadual/ibadah/jemaat-ibadah/${base64EncodeString}`}>Jemaat</Link>
              <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData.id) } }>Hapus</a></span>
        });
      }),
    };


    const deleteData = (id) => {
      let url2 = `https://gkmi.one:8080/api/gereja/jadual/ibadah3/${id}`
        axios.delete(url2, {
          headers: {
              'x-auth-token': token
          }
        }).then(res => {
            const del = jadualKegiatan.filter(tableRow => id !== tableRow.id)
            setJadualKegiatan(del);
        })

       console.log('OK: ' + id);
    }

    function cekLevelAkses(){
        if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
          return (
            <Fragment>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-2">
                            <Link to="/jadual/kegiatan" className="btn btn-block bg-gradient-danger">Back</Link>
                        </div>
                        <div className="col-sm-10">
                        <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                            <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/jadual/kegiatan">Jadual</Link>
                            </li>
                            <li className="breadcrumb-item active">Ibadah</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content">
                <div className="container-fluid">
                    {/* Default box */}
                    <div className="card">

                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                        <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Data Jadual Ibadah {title} {kegiatan}</h3>
                        <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                        >
                            <i className="fas fa-minus" />
                        </button>

                        </div>
                    </div>
                    <div className="card-body">
                        <Alert />
                        {
                            id !== "" ?
                            <div className="col-sm-5" style={{marginLeft: "-15px", marginBottom: "10px"}}>
                                <Link to={`/jadual/ibadah/create-ibadah/${id}`} className="btn btn-block bg-gradient-success">Tambah Jadwal Ibadah</Link>
                            </div>
                            : ''
                        }
                        <MDBDataTable responsive striped bordered hover data={data} />
                    </div>
                    </div>
                    {/* /.card */}

                    </div>
                </section>
                {/* /.content */}

                </div>
                {/* /.content-wrapper */}
            </Fragment>
          )
        }else{
            return(
                <Fragment>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-2">
                            <Link to="/jadual/kegiatan" className="btn btn-block bg-gradient-danger">Back</Link>
                        </div>
                        <div className="col-sm-10">
                        <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                            <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/jadual/kegiatan">Jadual</Link>
                            </li>
                            <li className="breadcrumb-item active">Ibadah</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content">
                <div className="container-fluid">
                    {/* Default box */}
                    <div className="card">

                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                        <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Data Jadual {title} {kegiatan}</h3>
                        <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                        >
                            <i className="fas fa-minus" />
                        </button>

                        </div>
                    </div>
                    <div className="card-body">
                        <Alert />
                        {
                            id !== "" ?
                            <div className="col-sm-5" style={{marginLeft: "-15px", marginBottom: "10px"}}>
                                <Link to={`/jadual/ibadah/create-ibadah/${id}`} className="btn btn-block bg-gradient-success">Tambah Jadwal Ibadah</Link>
                            </div>
                            : ''
                        }
                        <MDBDataTable responsive striped bordered hover data={data} />
                    </div>
                    </div>
                    {/* /.card */}

                    </div>
                </section>
                {/* /.content */}

                </div>
                {/* /.content-wrapper */}
            </Fragment>
                );
        }
    }

    return(
        <Fragment>
            <Header />
            <Navbar />
            {cekLevelAkses()}
            <Footer />
        </Fragment>
    )
}



ListIbadah.propTypes = {
    auth: PropTypes.object.isRequired,
    jadwalIbadah: PropTypes.object.isRequired,
    getJadwalIbadahByKodeGerejaJadwal: PropTypes.func.isRequired
  }

  const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    jadwalIbadah: state.jadwalIbadah,
  });

export default connect(mapStateToProps, {getProfile, getJadwalIbadahByKodeGerejaJadwal})(ListIbadah);
