import React, { Fragment, useState, useEffect,useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import DatePicker from "react-datepicker";
import moment from "moment";
import { updateConnect } from "../../../actions/connectAction";
import { getConnectId } from "../../../actions/connectAction";
import { getLeader } from "../../../actions/connectAction";
const EditConnect = ({ connect: { connect, loading }, getConnectId, updateConnect, getLeader,beritaCategory,auth: { user, token }, history }) => {
  const {id} = useParams();
    const [loadings, setLoadings] = useState(false);
    
    const [values, setValues] = useState({
      status: '1',
    });
    
    const [formData, setFormData] = useState({
      tanggal: new Date(),
        jam_mulai: new Date(),
        jam_selesai: new Date(),
        nama_connect:'',
        alamat:'',
        kota:'',
        hari:'',
        latitude:'',
        longitude:'',
        kode_leader:'',
        
    });

    const {
      nama_connect,
    alamat,
    kota,
    hari,
    latitude,
    longitude,
    tanggal,
    jam_mulai,
    jam_selesai,
    kode_leader,
    } = formData;

    useEffect(() => {


    
            setFormData({
              nama_connect: connect && (connect.nama_connect ? connect.nama_connect : ''),
              alamat: connect && (connect.alamat ? connect.alamat : ''),
              kota: connect && (connect.kota ? connect.kota : ''),
              hari: connect && (connect.hari ? connect.hari : ''),
              latitude: connect && (connect.latitude ? connect.latitude : ''),
              longitude: connect && (connect.longitude ? connect.longitude : ''),
               jam_mulai:  connect && (connect.jam_mulai  ?  new Date(connect.tgl_mulai +" "+connect.jam_mulai): ''),
               jam_selesai:  connect && (connect.jam_selesai  ?  new Date(connect.tgl_mulai +" "+connect.jam_selesai): ''),
              tanggal:  connect && (connect.tgl_mulai  ?  new Date(connect.tgl_mulai) : ''),
              kode_leader: connect && (connect.kode_leader ? connect.kode_leader : ''),
             
            });
        

       
        setValues({ status: connect && (connect.status.toString()) });

	}, [loading, connect]);

  
    useEffect(() => {

      getConnectId(Buffer.from(id, 'base64').toString('ascii'));
    }, [getConnectId, id]);

   
    useEffect(() => {
      getLeader();
  }, [getLeader]);
    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});


    const setStatus = (event) => {
      //console.log(event.target.value);
      setValues({ ...values, status: event.target.value });
      //console.log(values);
    };
    
    const onSubmit = async (e) => {
        e.preventDefault();
         setLoadings(true);
        const data = new FormData();
       
        data.append("nama_connect", formData.nama_connect);
        data.append("kota", formData.kota);
        data.append("alamat", formData.alamat);
        data.append("hari", formData.hari);
        data.append("latitude", formData.latitude);
        data.append("longitude", formData.longitude);
        data.append("tgl_mulai", moment(formData.tanggal).format('YYYY-MM-DD'));
        data.append("jam_mulai", moment(formData.jam_mulai).format("HH:mm"));
        data.append("jam_selesai", moment(formData.jam_selesai).format("HH:mm"));
        data.append("kode_leader", formData.kode_leader);
        data.append("status", values.status);
       
        updateConnect(data, Buffer.from(id, 'base64').toString('ascii'), history);
    }

    const handleDateChange = e => {
      setFormData({ ...formData,
          tanggal: new Date(e)
      });
    }
    const handleJamMulaiChange = e => {
      setFormData({ ...formData,
        jam_mulai: new Date(e)
      });
    }
  
    const handleJamSelesaiChange = e => {
      setFormData({ ...formData,
        jam_selesai: new Date(e)
      });
    }
    const handleLeaderChange = e => {
      setFormData({ ...formData,
        kode_leader: e.target.options[e.target.selectedIndex].value,
          nama: e.target.options[e.target.selectedIndex].text
      });
    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/connect" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Connect</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Connect</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="0" name="status" checked = {values.status === '0'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                    <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Leader *</label>
                  <div class="jss213-small">Pemimpin Connect   </div>
                  </div>
                </div>
              <div className="col-sm-9">
              
              <div className="form-group">
                               
                                <select className="form-control" required name="kode_leader" value={kode_leader} onChange={handleLeaderChange}>
                                    <option value="">--Pilih--</option>
                                        {
                                            beritaCategory && beritaCategory.connectLeader.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama}</option>)
                                        }
                                </select>
                            </div>
                 </div>
              
              </div>
              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Nama Connect *</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="Nama Connect ..." required name="nama_connect" value={nama_connect} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>
             
              <div className="row">
                  <div className="col-sm-3">
                        <div className="form-group">
                          <label>Alamat *</label>
                          
                        </div>
                      </div>
                    <div className="col-sm-9">
                    <div className="form-group">
                    <textarea className="form-control" rows="3"  placeholder="Alamat ..." name="alamat" value={alamat} onChange={e => onChange(e)}></textarea>
                      </div>
                      </div>
                      </div>
              
              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Kota *</label>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="Kota ..." required name="kota" value={kota} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Hari *</label>
                  <div class="jss213-small">Hari Pelaksanaan Connect  </div>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="Hari ..." required name="hari" value={hari} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>
              
              <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Tanggal</label>
                          <div class="jss213-small">Tanggal Pelaksanaan Connect </div>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal"
                                    dateFormat={moment(tanggal).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal}
                                    onChange={handleDateChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Jam Mulai *</label>
                        
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                        <DatePicker
                                selected={jam_mulai}
                                onChange={(date) => handleJamMulaiChange(date)}
                                showTimeSelect
                                className="form-control"
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onFocus={e => e.target.blur()}
                              />
                                </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Jam Selesai *</label>
                        </div>
                      </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                        <div className="customDatePickerWidth">
                        <DatePicker
                                selected={jam_selesai}
                                onChange={(date) => handleJamSelesaiChange(date)}
                                showTimeSelect
                                className="form-control"
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onFocus={e => e.target.blur()}
                              />

                                </div>
                        </div>
                      </div>
                    </div>

              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Latitude *</label>
                  <div class="jss213-small">Latitude Lokasi  </div>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="latitude ..." required name="latitude" value={latitude} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>

              <div className="row">
              <div className="col-sm-3"> 
                  <div className="form-group">
                  <label>Longitude *</label>
                  <div class="jss213-small">Longitude Lokasi  </div>
                  </div>
                </div>
              <div className="col-sm-9">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="Longitude ..." required name="longitude" value={longitude} onChange={e => onChange(e)} />
                 </div>
                </div>
              </div>


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditConnect.propTypes = {
  updateConnect: PropTypes.func.isRequired,
  getConnectId: PropTypes.func.isRequired,
  getLeader: PropTypes.func.isRequired,
   connect: PropTypes.object.isRequired,
   beritaCategory:  PropTypes.object.isRequired,
   
}
const mapStateToProps = state => ({
    auth: state.auth,
    connect: state.connect,
    beritaCategory: state.connect
});

export default connect(mapStateToProps, { updateConnect, getConnectId,getLeader })(withRouter(EditConnect));
