import React, {Fragment, useEffect, useState} from "react";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import { getGereja } from "../../actions/gerejaAction"

const UserRole  = ({auth: { user,token }}) => {
  const [dataJemaat, setDataJemaat] = useState([]);
  const [status, setStatus] = useState(true);
  const deleteData = (id) => {
    let url2 = `https://gkmi.one:8080/api/profile/jemaat/deleteprofile/${id}`
    axios.delete(url2, {
        headers: {
            'x-auth-token': token
        }
    }).then(res => {
        const del = dataJemaat.filter(tableRow => id !== tableRow.id)
        setDataJemaat(del);
    })
  }

  useEffect(() =>{
    fetchData();
}, [user]);

const fetchData = async () => {

let codeChurch = '';
      if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
          codeChurch = user && (user.kode_gereja);
      }else{
          codeChurch = user && (user.gereja[0].kode_gereja);
      }

      let link='';
      let codeMasterChurch2 = 0;
      link = `https://gkmi.one:8080/api/profile/jemaat/${codeChurch}`;

 const response = await axios.get(link, {
  headers: {
      'x-auth-token': token
  }
});
// console.log('MASUK: ' + JSON.stringify(response.data[0].gerejaSlider));
setDataJemaat(response.data[0].jemaat);

}

const data = {
columns: [
  {
      label: "No.",
      field: "no",
      sort: "asc",
      width: 10,
  },
  {
    label: "Nama",
    field: "nama",
    sort: "asc",
    width: 150,
  },
  {
      label: "User Name",
      field: "username",
      sort: "asc",
      width: 150,
  },
  {
    label: "Password",
    field: "password",
    sort: "asc",
    width: 150,
},
  {
      label: "No. Telepon",
      field: "no_telepon",
      sort: "asc",
      width: 150,
  },
  // {
  //     label: "Status",
  //     field: "rule",
  //     sort: "asc",
  //     width: 150,
  // },
  {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
  },
  {
    label: "Action",
    field: "action",
    sort: "asc",
    width: 150,
},
],


rows: dataJemaat.map((apiData, i) => {
let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
var roleUser;
                  if(apiData.level_user==5){
                    roleUser="Administrator";

                        }else{
                          roleUser=apiData.role;

                        }
 return({
    no: (i+1),

    nama: <span>{apiData.nama}</span>,
    username: <span>{apiData.no_telepon}</span>,
    no_telepon: <span>{apiData.no_telepon2}</span>,
    password: <span>************</span>,
  //   rule: <span>{apiData.status}</span>,
    status: <span>{roleUser}</span>,
    action: <span><Link className="btn btn-block bg-gradient-primary" to={`/beritaGKMI/edit-user-role/${base64EncodeString}`}>Ubah</Link>
                <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData.id) } }>Hapus</a></span>
  });

}),
};


function cekStatus(){
  if(status){

    return(
          <Fragment>
              <MDBDataTable responsive striped bordered hover data={data} />
          </Fragment>
      );
  }else{
      return(
          <Fragment>
              <span>Data tidak ada</span>
          </Fragment>
      )
  }
}
return(
  <Fragment>
      <Header />
      <Navbar />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
          <div className="container-fluid">
              <div className="row mb-2">
                  <div className="col-sm-4">
                      <Link to='/beritaGKMI/create-user-role' className="btn btn-block bg-gradient-green  ">Tambah User Role</Link>
                  </div>
                  <div className="col-sm-8">
                      <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                      <li className="breadcrumb-item">
                          <Link to="/dashboardBeritaGkmi">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">User Role</li>
                      </ol>
                  </div>
              </div>
              </div>
          </section>
          <section className="content">
          <div className="container-fluid">
          <div className="card">
              <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
              <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>User Role</h3>
              <div className="card-tools">


              </div>
              </div>
              <div className="card-body">
              <Alert />
              {cekStatus()}
              </div>
              {/* /.card-body */}

          </div>
          </div>
          </section>
          {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
  </Fragment>
)
};
const mapStateToProps = state => ({
auth: state.auth,
});
export default connect(mapStateToProps, { getGereja })(UserRole);
