import React, {Fragment, useEffect, useState} from "react";
import { useParams, Link, withRouter } from "react-router-dom";
import {API_URL_IMAGE} from "../../config/apiUrl";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import store from "../../store";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import parse from 'html-react-parser';
import { MDBDataTable } from "mdbreact";
import { getGereja } from "../../actions/gerejaAction";
import { getGerejaSlider } from "../../actions/gerejaSliderAction";

const BranchesSlider = ({gereja, gerejaSlider, auth: { user,token }}) => {
    const [dataGerejaSlider, setGerejaSlider] = useState([]);
    const [status, setStatus] = useState(true);
    const [title, setTitle] = useState();
    const {id} = useParams();
    const [namaGereja, setNamaGereja] = useState();
    const [kodeBranch, setKodeBranch] = useState();

    const [formData, setFormData] = useState({
        kode_gereja: ''
    });



    useEffect(() =>{
        // let codeChurch = '';
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        // codeChurch
        console.log("id kode gereja: :  "+decodeString.split(';')[1])
        setKodeBranch(decodeString.split(';')[0]);
        setNamaGereja(decodeString.split(';')[1]);
        fetchData(decodeString.split(';')[0]);
   }, [user,id]);
   const deleteData = (id) => {
    let url2 = `https://gkmi.one:8080/api/gereja/gereja-slider/slider/${id}`
    axios.delete(url2, {
        headers: {
            'x-auth-token': token
        }
    }).then(res => {
        const del = dataGerejaSlider.filter(tableRow => id !== tableRow.id)
        setGerejaSlider(del);
    })
  }
   const fetchData = async (codeChurch) => {
    
    //  if (user && (user.level_user === 10)) {
    //      codeChurch = user && (user.kode_gereja);
    //  }else{
    //      codeChurch = user && (user.gereja[0].kode_gereja);
    //  }
     console.log('AA: ' + codeChurch);
     let link='';
     let codeMasterChurch2 = 0;
     link = `https://gkmi.one:8080/api/gereja/gereja-slider/slider/${codeChurch}`;
   const response = await axios.get(link, {
    headers: {
        'x-auth-token': token
    }
  });

  

 // console.log('MASUK: ' + JSON.stringify(response.data[0].gerejaSlider));
   setGerejaSlider(response.data[0].gerejaSlider);

}

const data = {
    columns: [
      {
          label: "No.",
          field: "no",
          sort: "asc",
          width: 10,
      },
      {
          label: "Image",
          field: "nama_image",
          sort: "asc",
          width: 150,
      },
      {
         label: "Urutan",
         field: "urutan",
         sort: "asc",
         width: 10,
     },
      {
          label: "Action",
          field: "action",
          width: 100,
      },
    ],
   rows: dataGerejaSlider.map((apiData, i) => {
    let base64EncodeString = Buffer.from(`${apiData.id};${kodeBranch};${namaGereja};${apiData.namafile};${apiData.urutan};${id}`).toString('base64');

     // let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
      return({
        no: (i+1),
        nama_image:<center>
        <img style={{border: "3px solid #adb5bd", padding: "3px"}} src={API_URL_IMAGE + '/' + (apiData.namafile !== '' ? 'gereja/' + apiData.namafile : 'avatar.jpg')}
            className="img-fluid mb-3"
            alt="Image"
        />
        </center>,
       urutan: <center>{apiData.urutan}</center>,

        action: <span><Link className="btn btn-block bg-gradient-primary" to={`/pipka/branches/slider/edit-slider/${base64EncodeString}`}>Ubah</Link>
        <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData.id) } }>Hapus</a></span>

      });

}),
};


   function cekStatus(){
    if(status){
        return(
            <Fragment>
                <MDBDataTable responsive striped bordered hover data={data} />
            </Fragment>
        );
    }else{
        return(
            <Fragment>
                <span>Data tidak ada</span>
            </Fragment>
        )
    }
}
return(
    <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/pipka/branches" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                    <div className="col-sm-3">
                    <Link to={`/pipka/branches/slider/create-slider/${ (Buffer.from(`${kodeBranch};${namaGereja}`).toString('base64'))}`} className="btn btn-block bg-gradient-success">Tambah  Slider Branches</Link>
                   
                  
                    </div>
                    <div className="col-sm-7">
                        <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                        <li className="breadcrumb-item">
                            <Link to="/dashboardPipka">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                        <Link to="/pipka/branches">Branches</Link>
                        </li>
                        <li className="breadcrumb-item active">Branches Slider </li>

                        </ol>
                    </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Display Firman */}
            <div className="card">
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}> Slider {namaGereja}</h3>
                <div className="card-tools">


                </div>
                </div>
                <div className="card-body">
                <Alert />
                {cekStatus()}
                </div>
                {/* /.card-body */}

            </div>
            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
    </Fragment>
)
}








const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, null)(BranchesSlider);
