import { GET_GEREJA_JADWAL, GEREJA_JADWAL_ERROR } from "../actions/types";

const initialState = {
    jadwal: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_GEREJA_JADWAL:
            return{
                ...state,
                jadwal: payload,
                loading: false
            };
        case GEREJA_JADWAL_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}