import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import moment from "moment";
import { getJadwalIbadah, updateBranchesIbadah } from "../../../actions/gerejaJadwalIbadahAction";
import { getPembicara } from "../../../actions/pembicaraAction";
import "react-datepicker/dist/react-datepicker.css";
import "../../auth/styles/css/main.css";
import defaultImage from "../../auth/styles/images/noImage640.png";
import {API_URL_IMAGE} from "../../../config/apiUrl";

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { SunEditorAtom } from '../../SunEditorAtom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";

const EditIBranchesbadah = ({ jadwalIbadah: { loading, jadwalIbadah }, pembicara, updateBranchesIbadah, getJadwalIbadah, getPembicara, history, auth: {user, token } }) => {
    const {id} = useParams();
    const [file, setFile] = useState();
    const [file2, setFile2] = useState();
    //const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [pdfPreviewUrl, setPdfPreviewUrl] = useState();
    const [namaGereja, setNamaGereja] = useState();
    const [namaKegiatan, setNamaKegiatan] = useState();
    const [sinopsis, setSinopsis] = useState();
   
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [formData, setFormData] = useState({
        jadwal_id: '',
        kode_gereja: '',
        tanggal: new Date(),
        nama_ibadah: '',
        kode_pembicara: '',
        nama_pembicara: '',
        tema_ibadah: '',
       // sinopsis: '',
        stream_url: '',
        facebook_url:'',
        instagram_url:'',
        zoom_url:'',
        googlemeet_url:'',
        slot: 0,
    });

    const [value, setValue] = useState();
    const [loadings, setLoadings] = useState(false);

    useEffect(() => {
        let decodeString3 = Buffer.from(id, 'base64').toString('ascii');
        setFormData({
            jadwal_id: decodeString3.split(';')[0],
            kode_gereja: decodeString3.split(';')[1],
            nama_ibadah: decodeString3.split(';')[3],
            kode_pembicara:  jadwalIbadah && (jadwalIbadah.kode_pembicara  ? jadwalIbadah.kode_pembicara : ''),
            tema_ibadah:  jadwalIbadah && (jadwalIbadah.tema_ibadah  ? jadwalIbadah.tema_ibadah : ''),

            stream_url:  jadwalIbadah && (jadwalIbadah.stream_url ? jadwalIbadah.stream_url : ''),
            facebook_url:  jadwalIbadah && (jadwalIbadah.facebook_url ? jadwalIbadah.facebook_url : ''),
            instagram_url:  jadwalIbadah && (jadwalIbadah.instagram_url ? jadwalIbadah.instagram_url : ''),
            zoom_url:  jadwalIbadah && (jadwalIbadah.stream_url ? jadwalIbadah.zoom_url : ''),
            googlemeet_url:  jadwalIbadah && (jadwalIbadah.googlemeet_url ? jadwalIbadah.googlemeet_url : ''),
            slot:  jadwalIbadah && (jadwalIbadah.slot  ? jadwalIbadah.slot : ''),
            nama_pembicara:  jadwalIbadah && (jadwalIbadah.nama_pembicara  ?  jadwalIbadah.nama_pembicara : ''),
            tanggal:  jadwalIbadah && (jadwalIbadah.tanggal  ?  new Date(jadwalIbadah.tanggal) : '')
        });
        //setSinopsis( jadwalIbadah && (jadwalIbadah.sinopsis  ? jadwalIbadah.sinopsis : ''));

		setImagePreviewUrl( jadwalIbadah && (jadwalIbadah.namafile_image  ? API_URL_IMAGE + '/gereja/' + jadwalIbadah.namafile_image : ''));
    setPdfPreviewUrl( jadwalIbadah && (jadwalIbadah.warta.nama_pdf  ? API_URL_IMAGE + '/warta/' + jadwalIbadah.warta.nama_pdf : ''));
        /*var content =  jadwalIbadah && (jadwalIbadah.sinopsis ? jadwalIbadah.sinopsis : '');
		const fixedHTML = content ? content.toString().replace(/<figure.*>/gi, "\n") : '';
        const blocksFromHtml = htmlToDraft("<p>"+fixedHTML+"</p>");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        */
        setValue((jadwalIbadah && (jadwalIbadah.sinopsis  ? jadwalIbadah.sinopsis : '')));
      }, [loading, jadwalIbadah, id]);

      const onEditorStateChange = (editorState) => {
        console.log('OKK: ' + stateToHTML(editorState.getCurrentContent()));
        setEditorState(editorState);
      }

      function uploadImageCallBack(file, uploadHandler) {
        const uploadFile = file[0];
        console.log('KESINI: ' + uploadFile);
        return new Promise(
          (resolve, reject) => {
            const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
            xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
            xhr.setRequestHeader('x-auth-token', token);
            const data = new FormData(); // eslint-disable-line no-undef
            data.append('file', uploadFile);
            data.append("tautan", API_URL_IMAGE + '/ckeditor/')
            xhr.send(data);
            xhr.addEventListener('load', () => {
              const response = JSON.parse(xhr.responseText);
              console.log('OK: ' + response);
              uploadHandler(response);
              resolve(response);
            });
            xhr.addEventListener('error', () => {
              const error = JSON.parse(xhr.responseText);
              reject(error);
            });
          },
        );
      }

      useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
        getJadwalIbadah(decodeString.split(';')[4]);
        setNamaGereja(decodeString.split(';')[2]);
        setNamaKegiatan(decodeString.split(';')[3]);
      
      }, [getJadwalIbadah, id]);

      useEffect(() => {
        let decodeString2 = Buffer.from(id, 'base64').toString('ascii');
          getPembicara(decodeString2.split(';')[1]);
      }, [getPembicara, id]);

    const {
        jadwal_id,
        kode_gereja,
        nama_ibadah,
        tanggal,
        kode_pembicara,
        nama_pembicara,
        tema_ibadah,
        //sinopsis,
        stream_url,
        facebook_url,
        instagram_url,
        zoom_url,
        googlemeet_url,
        slot
    } = formData;

    const handleDateChange = e => {
        setFormData({ ...formData,
            tanggal: new Date(e)
        });
    }

    const handlePembicaraChange = e => {
        setFormData({ ...formData,
            kode_pembicara: e.target.options[e.target.selectedIndex].value,
            nama_pembicara: e.target.options[e.target.selectedIndex].text
        });
    }

    // Ini Resize Image
    const [userInfo, setuserInfo] = useState({
        file1:[],
        filepreview:null,
    });
    let reader = new FileReader();
    const fileOnChange = e => {

      const imageFile = e.target.files[0];
      const imageFilname = e.target.files[0].name;


      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {

//------------- Resize img code ----------------------------------
         var canvas = document.createElement('canvas');
         var ctx = canvas.getContext("2d");
         ctx.drawImage(img, 0, 0);

         var MAX_WIDTH = 437;
         var MAX_HEIGHT = 437;
         var width = img.width;
         var height = img.height;

         if (width > height) {
           if (width > MAX_WIDTH) {
             height *= MAX_WIDTH / width;
             width = MAX_WIDTH;
           }
         } else {
           if (height > MAX_HEIGHT) {
             width *= MAX_HEIGHT / height;
             height = MAX_HEIGHT;
           }
         }
         canvas.width = width;
         canvas.height = height;
         var ctx = canvas.getContext("2d");
         ctx.drawImage(img, 0, 0, width, height);
         ctx.canvas.toBlob((blob) => {
           const file1 = new File([blob], imageFilname, {
               type: 'image/jpeg',
               lastModified: Date.now()
           });
           setuserInfo({
              ...userInfo,
              file1:file1,
              filepreview:URL.createObjectURL(imageFile),
         })
         }, 'image/jpeg', 1);
       };
        img.onerror = () => {

          return false;
        };
        //debugger
        img.src = e.target.result;
      };
      reader.readAsDataURL(imageFile);

      /*console.log(e);
      setFile(e.target.files[0]);
      const objectUrl = URL.createObjectURL(e.target.files[0])
      setImagePreviewUrl(objectUrl)
      */
    }

    const fileOnChange2 = e => {
      console.log(e);
      setFile2(e.target.files[0]);
    }

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const sinopsisOnChange = (e, editor) => {
        const data = editor.getData();
        console.log('EDITOR 1: ' + data);
        setSinopsis(data);
      }
    const onSubmit = async (e) => {
        let decodeString4 = Buffer.from(id, 'base64').toString('ascii');
        console.log('ISI ID: ' + decodeString4.split(';')[4]);
        e.preventDefault();
        setLoadings(true);
        const data = new FormData();
        //data.append("namafile_image", file);
        data.append("namafile_image", userInfo.file1);
        data.append("jadwal_id", formData.jadwal_id);
        data.append("kode_gereja", formData.kode_gereja);
        data.append("nama_ibadah", formData.nama_ibadah);
        data.append("tanggal", formData.tanggal);
        data.append("kode_pembicara", formData.kode_pembicara);
        data.append("nama_pembicara", formData.nama_pembicara);
        data.append("tema_ibadah", formData.tema_ibadah);
        //data.append("sinopsis", draftToHtml(convertToRaw(editorState.getCurrentContent())));
        data.append("sinopsis", value);
		if(typeof formData.stream_url !== 'undefined'){
            data.append("stream_url", formData.stream_url);
        }
        if(typeof formData.facebook_url !== 'undefined'){
            data.append("facebook_url", formData.facebook_url);
        }
        if(typeof formData.instagram_url !== 'undefined'){
            data.append("instagram_url", formData.instagram_url);
        }
        if(typeof formData.zoom_url !== 'undefined'){
            data.append("zoom_url", formData.zoom_url);
        }
        if(typeof formData.googlemeet_url !== 'undefined'){
            data.append("googlemeet_url", formData.googlemeet_url);
        }
        //data.append("slot", formData.slot);
		if(typeof formData.slot !== 'undefined'){
            data.append("slot", formData.slot);
        }
        data.append("nama_pdf", file2);
        updateBranchesIbadah(data, history, decodeString4.split(';')[4], id);
    }

    function handleImageUploadBefore(files, info, uploadHandler){
      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString())
      }
    }

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-2">
                        <Link to={`/pipka/branches/kegiatan/ibadah/${id}`} className="btn btn-block bg-gradient-danger">Back</Link>
                    </div>
                    <div className="col-sm-10">
                        <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="/jadual/kegiatan">Jadual</Link>
                        </li>
                        <li className="breadcrumb-item active">Ibadah</li>
                        </ol>
                    </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Jadual Ibadah {namaGereja} {namaKegiatan}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Tanggal Ibadah</label>
                                <div className="customDatePickerWidth">
                                    <DatePicker
                                    name="tanggal"
                                    dateFormat={moment(tanggal).format('DD-MM-YYYY')}
                                    className="form-control"
                                    selected={tanggal}
                                    onChange={handleDateChange}
                                    onFocus={e => e.target.blur()}/>
                                </div>
                               {/*} <input type="date"  className="form-control" placeholder="Tanggal ..." name="tanggal" value={tanggal} onChange={e => onChange(e)} />*/}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Pembicara</label>
                                <input type="hidden" className="form-control" name="jadwal_id" value={jadwal_id} onChange={e => onChange(e)} />
                                <input type="hidden" className="form-control" name="kode_gereja" value={kode_gereja} onChange={e => onChange(e)} />
                                <input type="hidden" className="form-control" name="nama_ibadah" value={nama_ibadah} onChange={e => onChange(e)} />
                                <input type="hidden" className="form-control" name="nama_pembicara" value={nama_pembicara} onChange={e => onChange(e)} />
                                <select className="form-control" required name="kode_pembicara" value={kode_pembicara} onChange={handlePembicaraChange}>
                                    <option value="">--Pilih--</option>
                                        {
                                            pembicara && pembicara.pembicara.map((pem, index) => <option key={pem._id} value={pem._id}>{pem.nama}</option>)
                                        }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Tema Ibadah</label>
                                <input type="text" className="form-control" placeholder="Tema Ibadah ..." required name="tema_ibadah" value={tema_ibadah} onChange={e => onChange(e)} />
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-8">
                            <div className="form-group">
                                <label>Link Youtube Ibadah Online/Live Streaming URL</label>
                                <input type="text" className="form-control" placeholder="Live Streaming URL ..." name="stream_url" value={stream_url} onChange={e => onChange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>Kapasitas Ruangan</label>
                                <input type="number" min="0" max="1000" className="form-control" placeholder="Kapasitas Ruangan ..." name="slot" value={slot} onChange={e => onChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Link Ibadah Facebook</label>
                                <input type="text" className="form-control" placeholder="Facebook Live Streaming URL ..." name="facebook_url" value={facebook_url} onChange={e => onChange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Link Ibadah Instagram</label>
                                <input type="text" className="form-control" placeholder="Instagram Live Streaming URL ..." name="instagram_url" value={instagram_url} onChange={e => onChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Link Ibadah Zoom</label>
                                <input type="text" className="form-control" placeholder="ZoomLive Streaming URL ..." name="zoom_url" value={zoom_url} onChange={e => onChange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Link Ibadah Google Meet</label>
                                <input type="text" className="form-control" placeholder="Google Meet Live Streaming URL ..." name="googlemeet_url" value={googlemeet_url} onChange={e => onChange(e)} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Sinopsis</label>
                            <div style={{position: 'relative'}}>
                                  <div style={{zIndex: 1}}>
                                      <SunEditor
                                          autoFocus={true}
                                          lang="en"
                                          width='100%'
                                          height='450px'
                                          setOptions={{
                                          minWidth : '450px',
                                          showPathLabel: false,
                                          placeholder: "Enter your text here!!!",
                                          plugins: [
                                              align,
                                              font,
                                              fontColor,
                                              fontSize,
                                              formatBlock,
                                              hiliteColor,
                                              horizontalRule,
                                              lineHeight,
                                              list,
                                              paragraphStyle,
                                              table,
                                              template,
                                              textStyle,
                                              image,
                                              link
                                          ],
                                          buttonList: [
                                              ["undo", "redo"],
                                              ["font", "fontSize", "formatBlock"],
                                              ["paragraphStyle"],
                                              [
                                              "bold",
                                              "underline",
                                              "italic",
                                              "strike",
                                              "subscript",
                                              "superscript"
                                              ],
                                              ["fontColor", "hiliteColor"],
                                              ["removeFormat"],
                                              "/", // Line break
                                              ["outdent", "indent"],
                                              ["align", "horizontalRule", "list", "lineHeight"],
                                              ["table", "link", "image"]
                                          ],
                                          formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                          font: [
                                              "Arial",
                                              "Calibri",
                                              "Comic Sans",
                                              "Courier",
                                              "Garamond",
                                              "Georgia",
                                              "Impact",
                                              "Lucida Console",
                                              "Palatino Linotype",
                                              "Segoe UI",
                                              "Tahoma",
                                              "Times New Roman",
                                              "Trebuchet MS"
                                          ]
                                          }}
                                          onImageUploadBefore={handleImageUploadBefore}
                                          setContents={value}
                                          onChange={setValue}

                                      />

                                  </div>

                                  <div style={{zIndex: -999, position: 'absolute'}}>
                                      <SunEditorAtom initialContent={value}/>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Gambar/Image Untuk Ibadah</label>
                            <div className="imgPreviewUpload">
                            {userInfo.filepreview !== null ?
                            <img style={{border: "1px solid #adb5bd"}} src={userInfo.filepreview} className="imgPreview" alt=""/>
                            : <img style={{border: "1px solid #adb5bd"}} src={imagePreviewUrl} className="imgPreview" alt=""/>}
                             <div >
                             <input type="file"  placeholder="Image ..." name="namafile_image"  onChange={fileOnChange} />
                             </div>
                             </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: "10px"}}>
                        <div className="col-sm-12">
                            <div className="form-group">
                            <label>Warta Jemaat</label>
                            <label>{pdfPreviewUrl}</label>
                             <input type="file"  placeholder="PDF ..." name="nama_pdf"  onChange={fileOnChange2} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditIBranchesbadah.propTypes = {
    getJadwalIbadah: PropTypes.func.isRequired,
    getPembicara: PropTypes.func.isRequired,
    jadwalIbadah: PropTypes.object.isRequired,
    updateBranchesIbadah: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    jadwalIbadah: state.jadwalIbadah,
    pembicara: state.pembicara,
    auth: state.auth
});

export default connect(mapStateToProps, { getJadwalIbadah, getPembicara, updateBranchesIbadah })(withRouter(EditIBranchesbadah));
