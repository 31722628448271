import React from "react";
import { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";



export const SunEditorAtom = ({initialContent = ""}) =>{
    console.log('DATA: ' + initialContent);
    const [content, setContent] = useState(initialContent);
    console.log('DATA2 : ' + content);
    return(
        
            <SunEditor
            //hide={true}
            autoFocus={true}
            width="100%"
            setOptions={{
            buttonList: [
                // default
                ["undo", "redo"],
                ["bold", "underline", "italic", "list"],
                ["table", "link", "image"],
                ["fullScreen"]
            ]
            }}
            setContents={initialContent}
            onChange={setContent}
        />
      
    );
}

