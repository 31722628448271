import React, { Fragment, useState, useEffect,useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import { Editor } from 'react-draft-wysiwyg';
import axios from 'axios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import { getDocumentById } from "../../../actions/sinodeAction";
import { updateDocument } from "../../../actions/sinodeAction";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { SunEditorAtom } from '../../SunEditorAtom';
import SunEditor from 'suneditor-react';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  video,
  link
} from "suneditor/src/plugins";

const EditTataGerejaDocument = ({ sinode: { loading, tatagerejadocument }, getDocumentById, updateDocument, auth: { user ,token}, history }) => {
  const editor = useRef();
      const {id} = useParams();
    const [loadings, setLoadings] = useState(false);
    
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
   
    const [values, setValues] = useState({
        status: '1',
      });
    
    const [formData, setFormData] = useState({
      nama:'',
      
    });
    const [isiberita, setIsiBerita] = useState();
    useEffect(() => {
        setFormData({
          nama: tatagerejadocument && (tatagerejadocument.nama ? tatagerejadocument.nama : ''),
      
            status: tatagerejadocument && (tatagerejadocument.active ? tatagerejadocument.active : ''),
          

           
        });
      
      setIsiBerita((tatagerejadocument && (tatagerejadocument.document ? tatagerejadocument.document : '')));
      var content = tatagerejadocument && (tatagerejadocument.document ? tatagerejadocument.document : '');
      
    const fixedHTML =  content ? content.toString().replace(/<figure.*>/gi, "\n") : '';
      const blocksFromHtml = htmlToDraft("<p>"+fixedHTML+"</p>");
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
        setValues({ status: tatagerejadocument && (tatagerejadocument.active.toString()) });
      
    }, [loading, tatagerejadocument]);
   

      
    useEffect(() => {
        let decodeString = Buffer.from(id, 'base64').toString('ascii');
      console.log("id "+decodeString.split(';')[0])
      getDocumentById( decodeString.split(';')[0]);
     
      }, [getDocumentById, id]);

    const {
      nama,
     
      deskripsi,
     
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const onSubmit = async (e) => {
        e.preventDefault();
        //setLoadings(true);
       
        const data = new FormData();
       
         data.append("document", isiberita);
         data.append("nama", formData.nama);
         data.append("active", values.status);
         updateDocument(data,  Buffer.from(id, 'base64').toString('ascii'), history);
     
    }
    const setStatus = (event) => {
        //console.log(event.target.value);
        setValues({ ...values, status: event.target.value });
        //console.log(values);
      };
      
    
  function uploadImageCallBack(file, uploadHandler) {
      const uploadFile = file[0];
      return new Promise(
        (resolve, reject) => {
          const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
          xhr.open('POST', 'https://gkmi.one:8080/api/setting/upload');
          xhr.setRequestHeader('x-auth-token', token);
          const data = new FormData(); // eslint-disable-line no-undef
          data.append('file', uploadFile);
          data.append("tautan", API_URL_IMAGE + '/ckeditor/')
          xhr.send(data);
          xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText);
            console.log('OK: ' + response);
            uploadHandler(response);
            resolve(response);
          });
          xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
          });
        },
      );
    }
    function handleImageUploadBefore(files, info, uploadHandler){
      // uploadHandler is a function
      console.log('UPLOAD: ' + files, info);

      try {
        uploadImageCallBack(files, uploadHandler);
      } catch (err) {
          uploadHandler(err.toString());
      }
    }

    
    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/tatagereja" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardSinode">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Tata Gereja</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Tata Gereja </h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>
                         
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                      <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Judul Document * </label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Judul Document ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
               
                <div className="row">
                <div className="col-sm-3"> 
                    <div className="form-group">
                    <label>Tata Gereja *</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div style={{position: 'relative'}}>
                    <div style={{zIndex: 1}}>
                        <SunEditor
                            autoFocus={true}
                            lang="en"
                            width='100%'
                            height='450px'
                            setOptions={{
                            minWidth : '450px',
                            showPathLabel: false,
                            placeholder: "Enter your text here!!!",
                            plugins: [
                                align,
                                font,
                                fontColor,
                                fontSize,
                                formatBlock,
                                hiliteColor,
                                horizontalRule,
                                lineHeight,
                                list,
                                paragraphStyle,
                                table,
                                template,
                                textStyle,
                                image,
                                video,
                                link
                            ],
                            buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                                ],
                                ["fontColor", "hiliteColor"],
                                ["removeFormat"],
                                "/", // Line break
                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image","video",]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                                "Arial",
                                "Calibri",
                                "Comic Sans",
                                "Courier",
                                "Garamond",
                                "Georgia",
                                "Impact",
                                "Lucida Console",
                                "Palatino Linotype",
                                "Segoe UI",
                                "Tahoma",
                                "Times New Roman",
                                "Trebuchet MS"
                            ]
                            }}
                            onImageUploadBefore={handleImageUploadBefore}
                            setContents={isiberita}
                            onChange={setIsiBerita}

                        />

                    </div>

                    <div style={{zIndex: -999, position: 'absolute'}}>
                        <SunEditorAtom initialContent={isiberita}/>
                    </div>
                </div>
                   </div>
                  </div>
                </div>
                

                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditTataGerejaDocument.propTypes = {
  updateDocument: PropTypes.func.isRequired,
    getDocumentById: PropTypes.func.isRequired,
   
}
const mapStateToProps = state => ({
    auth: state.auth,
    sinode: state.sinode,
   
});

export default connect(mapStateToProps, { updateDocument, getDocumentById})(withRouter(EditTataGerejaDocument));
