import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import axios from 'axios';

const ViewFirman = ({dashboard, auth: { user, token } }) => {
    const [dataGereja, setDataGereja] = useState([]);
    const [kode, setKode] = useState();
    const [status, setStatus] = useState(true);

    useEffect(() => {
        fetchData();
    }, [user]);

    const fetchData = async () => {
        let link='';
            link = 'https://gkmi.one:8080/api/dashboard/dashboardViewFirman';
         const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
        });

       setDataGereja(response.data);

      }



    const data = {
        columns: [
            {
                label: "No",
                field: "no",
                sort: "asc",
                width: 10,
            },
            {
                label: "Nama",
                field: "nama",
                sort: "asc",
                width: 150,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 100,
            },
            {
                label: "View",
                field: "view",
                sort: "asc",
                width: 100,
            },

        ],

        rows: dataGereja.map((apiData, i) => {
            var status;
            if(apiData.status==1){
                status="Tulisan";

                    }else{
                        status="Youtube";

                    }
            return({
              no: (i+1),
                nama: <span>{apiData.judul_firman}</span>,


                status:<span>{status}</span>,
                view:<span>{apiData.view}</span>,
          });
        }),
        // rows: dataGereja ? dataGereja.map((apiData, i) => (

        //     {

        //     no: (i+1),

        //     nama: <span>{apiData.judul_firman}</span>,
        //     status: apiData.view,
        //     vie: apiData.view,


        // })) : '',
      };


    function cekStatus(){
        if(status){

          return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }
    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-4">
                    <Link to="/dashboardFirman" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardFirman">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Firman View</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="content">
                <div className="container-fluid">
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Gereja Dewasa</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )





};

ViewFirman.propTypes = {
    dashboard: PropTypes.func.isRequired,
    //gerejaData: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, )(ViewFirman);
