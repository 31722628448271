import React, {Fragment, useEffect, useState} from "react";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import { Link }  from "react-router-dom";
import { getGereja } from "../../actions/gerejaAction";
import { getGerejaSlider } from "../../actions/gerejaSliderAction";

const JemaatList2 = ({auth: { user,token }}) => {
    const [dataJemaat, setDataJemaat] = useState([]);

    const deleteData = (id) => {
      let url2 = `https://gkmi.one:8080/api/profile/jemaat/deleteprofile/${id}`
      axios.delete(url2, {
          headers: {
              'x-auth-token': token
          }
      }).then(res => {
          const del = dataJemaat.filter(tableRow => id !== tableRow.id)
          setDataJemaat(del);
      })
    }

    useEffect(() =>{
      fetchData();
 }, [user]);

 const fetchData = async () => {

  let codeChurch = '';
        if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
            codeChurch = user && (user.kode_gereja);
        }else{
            codeChurch = user && (user.gereja[0].kode_gereja);
        }
        console.log('AA: ' + codeChurch);
        let link='';
        let codeMasterChurch2 = 0;
        link = `https://gkmi.one:8080/api/profile/jemaat/${codeChurch}`;

   const response = await axios.get(link, {
    headers: {
        'x-auth-token': token
    }
  });
 // console.log('MASUK: ' + JSON.stringify(response.data[0].gerejaSlider));
 setDataJemaat(response.data[0].jemaat);

}

const data = {
  columns: [
    {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 10,
    },
    {
      label: "Nama",
      field: "nama",
      sort: "asc",
      width: 150,
    },
    {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
    },
    {
        label: "No. Telepon",
        field: "no_telepon",
        sort: "asc",
        width: 150,
    },
    // {
    //     label: "Status",
    //     field: "rule",
    //     sort: "asc",
    //     width: 150,
    // },
    {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
    },
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 150,
  },
  ],


 rows: dataJemaat.map((apiData, i) => {
  let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
  //let base64EncodeString = Buffer.from(`${apiData._id};${formData.kode_gereja};${title}`).toString('base64');
   // let base64EncodeString = Buffer.from(`${apiData.id}`).toString('base64');
   return({
      no: (i+1),

      nama: <span>{apiData.nama}</span>,
      email: <span>{apiData.email}</span>,
      no_telepon: <span>{apiData.no_telepon2}</span>,
    //   rule: <span>{apiData.status}</span>,
      status: <span>{apiData.role}</span>,
      action: <span><Link className="btn btn-block bg-gradient-primary" to={`/jemaat/edit-jemaat/${base64EncodeString}`}>Ubah</Link>
                  <a className="btn btn-block bg-gradient-red" onClick={() => { if (window.confirm('Anda yakin untuk menghapus item ini?')) deleteData(apiData.id) } }>Hapus</a></span>
    });

}),
};



    function cekLevelAkses(){
        if (user && (user.level_user === 7 || user.level_user === 8 || user.level_user === 0)) {
          return (
            <Fragment>
            </Fragment>
          );
        }else{
            return(
            <Fragment>
                <MDBDataTable striped bordered hover data={data} />
            </Fragment>
            );
       }
      }


    return(
        <Fragment>
            {cekLevelAkses()}
        </Fragment>
    )
}
const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(JemaatList2);
