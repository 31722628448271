import React, { Fragment, useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link }  from "react-router-dom";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import Alert from "../alert/Alert";
import {API_URL_IMAGE} from "../../config/apiUrl";
import PropTypes from "prop-types";
import { getGereja } from "../../actions/gerejaAction";
import { connect } from 'react-redux';
import axios from 'axios';

const GerejaDewasa = ({ getGereja, gerejaData, auth: { user, token } }) => {
    const [dataGereja, setDataGereja] = useState([]);
    const [kode, setKode] = useState();
    const [status, setStatus] = useState(true);

    useEffect(() => {
        fetchData();
    }, [user]);

    const fetchData = async () => {
        let link='';
            link = 'https://gkmi.one:8080/api/dashboard/gereja_dewasa';
         const response = await axios.get(link, {
          headers: {
              'x-auth-token': token
          }
        });

       setDataGereja(response.data[0].gereja);

      }



    const data = {
        columns: [
            {
                label: "No Urut",
                field: "no",
                sort: "asc",
                width: 10,
            },

            {
                label: "Nama",
                field: "nama",
                sort: "asc",
                width: 150,
            },
            {
                label: "PGMW",
                field: "pgmw",
                sort: "asc",
                width: 50,
            },
            {
                label: "Kota",
                field: "kota",
                sort: "asc",
                width: 100,
            },
            {
                label: "Anggota",
                field: "anggota",
                sort: "asc",
                width: 50,
            },
            {
                label: "Simpatisan",
                field: "simpatisan",
                sort: "asc",
                width: 50,
            },
            {
                label: "Pengunjung",
                field: "gues",
                width: 50,
            },
        ],


        rows: dataGereja ? dataGereja.map((apiData, i) => ({
            no: (i+1),

            nama: <span>{apiData.nama}</span>,
            gues: apiData.gues,
            kota: apiData.kota,
            pgmw: apiData.gereja_pgmw,
            anggota: apiData.anggota,
            simpatisan: apiData.simpatisan,
            gues: apiData.gues,
            // misi: apiData.misi,
            // alamat: apiData.alamat,

            // action: <span></span>



        })) : '',
        //rows: res[0].gereja.map((apiData, i) => <tr> <td> <a href={`/item/${apiData.nama}`}> {apiData.nama} </a> </td> <td> {apiData.nama} </td> </tr>),

      };


    // return(
    //     <Fragment>
    //          <Header />
    //   <Navbar />
    //         <MDBDataTable responsive striped bordered hover data={data} />
    //     </Fragment>
    // )
    function cekStatus(){
        if(status){

          return(
                <Fragment>
                    <MDBDataTable responsive striped bordered hover data={data} />
                </Fragment>
            );
        }else{
            return(
                <Fragment>
                    <span>Data tidak ada</span>
                </Fragment>
            )
        }
    }
    return(
        <Fragment>
            <Header />
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-4">
                    <Link to="/dashboardSinode" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                        <div className="col-sm-8">
                            <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                            <li className="breadcrumb-item">
                                <Link to="/dashboardSinode">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Gereja Dewasa</li>
                            </ol>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="content">
                <div className="container-fluid">
                <div className="card">
                    <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                    <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Gereja Dewasa</h3>
                    <div className="card-tools">


                    </div>
                    </div>
                    <div className="card-body">
                    <Alert />
                    {cekStatus()}
                    </div>
                    {/* /.card-body */}

                </div>
                </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />
        </Fragment>
    )





};

GerejaDewasa.propTypes = {
    getGereja: PropTypes.func.isRequired,
    gerejaData: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(mapStateToProps, {getGereja})(GerejaDewasa);
