import { GET_COUNT_USER, COUNT_USER_ERROR } from "../actions/types";

const initialState = {
    userCount: null,
    loading: true,
    error: {}
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_COUNT_USER:
            return{
                ...state,
                userCount: payload,
                loading: false
            };
        case COUNT_USER_ERROR:
            return{
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}