import axios from "../util/http-common";
import { setAlert } from "./alertAction";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL
} from "./types";
import setAuthToken from "../util/setAuthToken";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/profile/me");
    dispatch({
      type: USER_LOADED,
      payload: res.data[0].profile[0],
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const login = (no_telepon, password) => async (dispatch) => {
  const body = JSON.stringify({ no_telepon, password });
  try {
    const res = await axios.post("/api/auth/web", body);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data[0],
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response;
    if (errors) {
      const errors2 = errors.data[0].errors;
      errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Change Password
export const changePassword = (formData, history) => async dispatch => {
  try{
  const res = await axios.put(`/api/users/change_password`, formData);
      if(res.data.status === "success"){
          console.log("success",res.data.message);
          dispatch(setAlert(res.data.message, 'success', 5000));
          dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: { msg: res.message, status: res }
          });
      }else{
          console.log("failed",res.data.message);
          dispatch(setAlert(res.data.message, 'danger', 5000));
      }
      history.push('/change-password');


  }catch(err){
      const errors = err.response;
      if (errors) {
          console.log("failed",errors.data.message);
          dispatch(setAlert(errors.data.message, 'danger', 5000));
      }
  }
}

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
