import React, { Fragment, useState, useEffect,useRef } from 'react';
import { useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {API_URL_IMAGE} from "../../../config/apiUrl";
import Header from "../../layouts/Header";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Alert from "../../alert/Alert";
import defaultImage from "../../auth/styles/images/noImage640.png";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/styles.css';

import DatePicker from "react-datepicker";
import moment from "moment";
import { updateGalleryVideo } from "../../../actions/pipkaAction";
import { getGalleryVideoById } from "../../../actions/pipkaAction";


const EditGalleryVideo = ({ pipka: { galleryVideo, loading }, getGalleryVideoById, updateGalleryVideo, auth: { user, token }, history }) => {
    const {id} = useParams();const [namaGereja, setNamaGereja] = useState();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [isi, setIsi] = useState();
    const [loadings, setLoadings] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl]  = useState(defaultImage);
    const editor = useRef();
    const [values, setValues] = useState({
      status: '1',
    });
    const [formData, setFormData] = useState({
      nama:'',
      url_youtube:''
    });
 
    

    const {
      nama,
     url_youtube,
    } = formData;

    useEffect(() => {
            setFormData({
              nama: galleryVideo && (galleryVideo.name ? galleryVideo.name : ''),
              url_youtube: galleryVideo && (galleryVideo.video ? galleryVideo.video : ''),
              
            });
        

       
        setValues({ status: galleryVideo && (galleryVideo.active.toString()) });

	}, [loading, galleryVideo]);

    useEffect(() => {
      if(galleryVideo && (galleryVideo.thumbnail)){
            
            setImagePreviewUrl(galleryVideo && (galleryVideo.tautan ? galleryVideo.tautan  : '')+"/"+galleryVideo.thumbnail);
        }else{
            setImagePreviewUrl(defaultImage);
        }

    }, [loading, galleryVideo, fileName]);

    useEffect(() => {
      getGalleryVideoById(Buffer.from(id, 'base64').toString('ascii'));
    }, [getGalleryVideoById, id]);

   

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const fileOnChange = e => {
        setFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setImagePreviewUrl(objectUrl)
    }

    const setStatus = (event) => {
      //console.log(event.target.value);
      setValues({ ...values, status: event.target.value });
      //console.log(values);
    };
      
    const onSubmit = async (e) => {
        e.preventDefault();
        // setLoadings(true);
        const data = new FormData();
        data.append("images_video", file);
        data.append("nama", formData.nama);
        data.append("url_youtube", formData.url_youtube);
        data.append("active", values.status);
        updateGalleryVideo(data, Buffer.from(id, 'base64').toString('ascii'), history);
    }

   

    return(
        <Fragment>
        <Header />
        <Navbar />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-2">
                    <Link to="/pipka/galleryvideo" className="btn btn-block bg-gradient-danger">Back</Link>
                </div>
                <div className="col-sm-10">
                    <ol className="breadcrumb float-sm-right" style={{marginRight: "20px", marginTop: "10px"}}>
                    <li className="breadcrumb-item">
                        <Link to="/dashboardPIPKA">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Video Gallery</li>
                    </ol>
                </div>
                </div>
                </div>
            </section>
            {/* Main content */}
            <section className="content">
            <div className="container-fluid">
            {/* Default box */}
            <div className="card">
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="card-header" style={{ padding: ".40rem 1.25rem"}}>
                <h3 className="card-title" style={{marginTop: "8px", marginBottom: "5px"}}>Ubah Video Gallery {namaGereja}</h3>
                <div className="card-tools">
                    <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    >
                    <i className="fas fa-minus" />
                    </button>

                </div>
                </div>
                <div className="card-body">
                    <Alert />
                    <div className="row">
                    <div className="col-sm-3">
                        <div className="form-group">
                          <label>Status *</label>
                         
                        </div>
                      </div>
                    <div className="col-sm-9">
                        <div className="form-group">
                        <div onChange={setStatus}>
                                <input type="radio" value="1" name="status" checked = {values.status === '1'}/>&nbsp;&nbsp;Active &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" value="2" name="status" checked = {values.status === '2'}/>&nbsp;&nbsp;Tidak Active &nbsp;&nbsp;
                            </div>
                        </div>
                      </div>
                      </div>
                      <div className="row">
                <div className="col-sm-3"> 
                    <div className="form-group">
                    <label>Judul Gallery</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Judul Gallery ..." required name="nama" value={nama} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3"> 
                    <div className="form-group">
                    <label>Url Youtube</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <input type="text" className="form-control" placeholder="Url Youtube ..." required name="url_youtube" value={url_youtube} onChange={e => onChange(e)} />
                   </div>
                  </div>
                </div>
                
                <div className="row">
                <div className="col-sm-3"> 
                    <div className="form-group">
                    <label>Thumnail Youtube</label>
                    </div>
                  </div>
                <div className="col-sm-9">
                <div className="form-group">
                <div className="imgPreviewUpload">
                          <img className="imgPreview" src={imagePreviewUrl} />
                          <div >
                          <input type="file" placeholder="Gambar ..." name="nama_image"  onChange={fileOnChange} />
                          </div>
                          </div>
                   </div>
                  </div>
                </div>
                
                


                </div>
                {/* /.card-body */}
                <div className="card-footer">
                {
                  loadings ? <div><button class="btn btn-primary pull-right" disabled={loadings}><i id="loading-spinner" class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</button></div>
                  :
                  <input type="submit" className="btn btn-primary pull-right" value="Save" />
                }
                </div>
                {/* /.card-footer*/}
                </form>
            </div>
            {/* /.card */}

            </div>
            </section>
            {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
        </Fragment>
    )
};

EditGalleryVideo.propTypes = {
  updateGalleryVideo: PropTypes.func.isRequired,
  getGalleryVideoById: PropTypes.func.isRequired,
   
}
const mapStateToProps = state => ({
    auth: state.auth,
    pipka: state.pipka,
});

export default connect(mapStateToProps, { updateGalleryVideo, getGalleryVideoById })(withRouter(EditGalleryVideo));
