import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { GET_ACTION,GET_ACTION_KEGIATAN,ACTION_ERROR,ACTION_KEGIATAN_ERROR} from "./types";


export const createAction = (formData, history) => async dispatch => {
 try{
     console.log("action masuk");
     const res = await axios.post(`/api/backendaction`, formData, {
         headers: {
             'Content-Type': 'multipart/form-data'
         }
     });
     if(res.status === 200){
         dispatch(setAlert(res.data[0].msg, 'success', 5000));
         console.log("action error");
     }
     history.push(`/action`);
 }catch(err){
     const errors = err.response;
     if (errors) {
         const errors2 = errors.data[0].errors;
         errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
     }
     dispatch({
         type: ACTION_ERROR,
         //payload: { msg: err.response.statusText, status: err.response.status }
   payload: { msg: err.message, status: err }
     });
 }
}
// Get Karya Toko by Id
export const getActionId = id => async dispatch => {

    try{
        const res = await axios.get(`/api/backendaction/${id}`);
        //console.log('MASUK SINI 1: ' + res.data[0].nama_action);
        dispatch({
            type: GET_ACTION,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: ACTION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}
// Get Karya Toko by Id
export const updateAction = (formData, id, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/backendaction/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

		history.push('/action');

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: ACTION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}

// Post Action Kegiatan
export const createActionKegiatan = (formData, history,path) => async dispatch => {
    try{
		const res = await axios.post(`/api/backendaction/kegiatan`, formData);
        if(res.status === 200){
            console.log("success",res.data[0].msg);

            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push(`/action/kegiatan/${path}`);
    }catch(err){
        const errors = err.response;
        if (errors) {
            const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: ACTION_KEGIATAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Get Karya Toko by Id
export const getActionKegiatanId = id => async dispatch => {

    try{
        const res = await axios.get(`/api/backendaction/actionKegiatan/${id}`);

        dispatch({
            type: GET_ACTION_KEGIATAN,
            payload: res.data,
        });
    }catch(err){
        dispatch({
            type: ACTION_KEGIATAN_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}

// Update Karya Toko by Id
export const updateActionKegiatan = (formData, id,path, history) => async dispatch => {

    try{
        const res = await axios.put(`/api/backendaction/kegiatan/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

        if(res.status === 200){
            dispatch(setAlert(res.data[0].msg, 'success', 5000));
        }

        history.push(`/action/kegiatan/${path}`);

    }catch(err){
        const errors = err.response;
        if (errors) {
           const errors2 = errors.data[0].errors;
            errors2.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: ACTION_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
			payload: { msg: err.message, status: err }
        });
    }
}
// Get Karya Toko by Id
