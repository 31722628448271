import axios from "../util/http-common";
import { setAlert } from "./alertAction";

import { GET_GEREJA_JADWAL,  GEREJA_JADWAL_ERROR } from "./types";



// Get Gereja Jadwal
export const getJadwal = kode_gereja => async dispatch => {
    try{
        const res = await axios.get(`/api/gereja/${kode_gereja}`);

        dispatch({
            type: GET_GEREJA_JADWAL,
            payload: res.data[0],
        });
    }catch(err){
        dispatch({
            type: GEREJA_JADWAL_ERROR,
            //payload: { msg: err.response.statusText, status: err.response.status }
            payload: { msg: err.message, status: err }
        });
    }
}